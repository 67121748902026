export const USERACCESSROLES = [
  { value: 1, label: "Invite User" },
  { value: 2, label: "Create Doctor" },
  { value: 3, label: "Create Patient" },
  { value: 4, label: "Schedule Appointment" },
  { value: 5, label: "CheckIn & CheckOut" },
  { value: 6, label: "Signoff Appointment" },
  { value: 7, label: "View Doctor" },
  { value: 8, label: "View Patient" },
  { value: 9, label: "View Appointment" }
];
export const CLINICAL_NOTES_TEMPLATE_OPTIONS = [
  {
    label: "History & Physical",
    id: "history_&_physical",
    key: "history_&_physical-option",
  },
  {
    label: "SOAP Note",
    id: "soap_note",
    key: "soap_note-option",
  },
  { label: "Consultation", id: "consultation", key: "consultation-option" },
  {
    label: "Progress Notes",
    id: "progress_notes",
    key: "progress_notes-option",
  },
  {
    label: "Discharge Summary",
    id: "discharge_summary",
    key: "discharge_summary-option",
  },
  {
    label: "Short Stay Summary",
    id: "short_stay_summary",
    key: "short_stay_summary-option",
  },
  {
    id: "physician_intraoperative_note",
    key: "physician_intraoperative_note",
    label: "Physician Intraoperative Note",
  },
  {
    id: "operative_report",
    key: "operative_report",
    label: "Operative Report",
  },
];
export const CLINICAL_NOTE_TEMPLATES = {
  "History & Physical": {
    key: "history_&_physical",
    label: "History & Physical",
  },
  soap_note: {
    key: "soap_note",
    label: "SOAP Note",
  },
  consultation: { key: "consultation", label: "Consultation" },
  progress_notes: { key: "progress_notes", label: "Progress Notes" },
  discharge_summary: { key: "discharge_summary", label: "Discharge Summary" },
  short_stay_summary: {
    key: "short_stay_summary",
    label: "Short Stay Summary",
  },
  physician_intraoperative_note: {
    key: "physician_intraoperative_note",
    label: "Physician Intraoperative Note",
  },
  operative_report: {
    key: "operative_report",
    label: "Operative Report",
  },
  pdf_file: {
    key: "pdf_file",
    label: "Document",
  },
};
