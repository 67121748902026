import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../API/store";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import DataDecode from "../../utils/DataDecode";
import BlueRondedCrossIcon from "../../components/Assets/BlueRondedCrossIcon.png";
import CreatableSelect from "react-select/creatable";
import {
  setusernames,
  setradiologyUsernames,
  setNotRadiologyAddedUser,
  setNotLabAddedUser,
} from "../../Redux/features/LaboratoryAndRadiology/LabandRadiologySlice";
const LaboratoryFiles = () => {
  const dispatch = useDispatch();
  const previewTheme = useSelector((state) => state.theme.colors);
  const previewFont = useSelector((state) => state.theme.fontFamily);
  var usernames = useSelector(
    (state) => state.labAndRadiologyDetails.addedLabUsers
  );
  var notLabAddedUser = useSelector(
    (state) => state.labAndRadiologyDetails.avaiLableUserForLab
  );
  var notRadiologyAddedUser = useSelector(
    (state) => state.labAndRadiologyDetails.availableRadiologyUser
  );
  var radiologyUsernames = useSelector(
    (state) => state.labAndRadiologyDetails.addedRadiologyUsers
  );
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var loggeduser = useSelector((state) => state.login.loginuserData);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAddLabUser, setAddLabUser] = useState(false);
  const [showAddRadiologyUser, setshowAddRadiologyUser] = useState(false);
  const [selecteduser, setSelectedUser] = useState([]);
  const [addUser, setAddUser] = useState([]);
  const [user_name, setUsername] = useState([]);
  const [activePage, setActivePage] = useState("Laboratory");
  const [confirmBox, setConfirmBox] = useState(false);
  const [dltUser, setDltuser] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handlePageClick = (page) => {
    setActivePage(page);
  };
  const handleShowAddUserPopup = (isOpen) => {
    setSelectedUser([]);
    setAddLabUser(isOpen);
  };
  const handleShowAddRadiologyUserPopup = (isOpen) => {
    setSelectedUser([]);
    setshowAddRadiologyUser(isOpen);
  };

  const addRadiologyNotification = async () => {
    if (selecteduser?.length == 0) {
      dispatch(
        setNotification({
          message: "select anyone user",
          status: "error",
          action: true,
        })
      );
      return;
    }
    const labels = selecteduser.map((user) => user.label);
    setUsername(labels);
    let payload = {
      user_names: labels,
      clinic_guid: loggeduser?.facilityID,
    };

    await store
      .UpdateRadiologyUserActive(
        loggedusertoken,
        DataDecode.encryptPayload(payload)
      )
      .then((response) => {
        if (response.status === 200) {
          FetchLabAndRadiologyData();
          dispatch(
            setNotification({
              message: "SuccessFully added Users",
              status: "success",
              action: true,
            })
          );
          setSelectedUser([]);
          setshowAddRadiologyUser(false);
        }
        response.json((res) => {
          dispatch(
            setNotification({
              message: "Error while Adding users",
              status: "error",
              action: true,
            })
          );
        });
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  const addLabNotificationstoUsers = async () => {
    if (selecteduser?.length == 0) {
      dispatch(
        setNotification({
          message: "select anyone user",
          status: "error",
          action: true,
        })
      );
      return;
    }
    const labels = selecteduser.map((user) => user.label);
    setUsername(labels);
    let payload = {
      user_names: labels,
      clinic_guid: loggeduser?.facilityID,
    };
    await store
      .UpdateLabUserActive(loggedusertoken, DataDecode.encryptPayload(payload))
      .then((response) => {
        if (response.status === 200) {
          FetchLabAndRadiologyData();
          dispatch(
            setNotification({
              message: "SuccessFully added Users",
              status: "success",
              action: true,
            })
          );
          setSelectedUser([]);
          setAddLabUser(false);
        }
        response.json((res) => {
          dispatch(
            setNotification({
              message: "Error while fetching users",
              status: "error",
              action: true,
            })
          );
        });
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const handleSelectTest = (selected) => {
    setSelectedUser(selected || []);
  };
  const sshandleSelectTest = (test) => {
    let inputValue = test[test?.length - 1]?.label;

    const hasValue = selecteduser.some((item) => item.label === inputValue);

    if (!hasValue && test?.length != 0) {
      const newOption = { value: 0, label: inputValue };
      setSelectedUser((prevSelected) => [...prevSelected, newOption]);
    } else {
      const updatedOptions = selecteduser.filter(
        (option) => option.label !== inputValue
      );
      setSelectedUser(updatedOptions);
    }
  };
  const handleDeleteUser = (name) => {
    setDltuser(name);
    setConfirmBox(true);
  };
  const handleDeletesubmit = () => {
    setDisableButton(true);
    if (activePage === "Laboratory") {
      handleDeleteUserFromLab(dltUser);
    }
    if (activePage === "Radiology") {
      handleDeleteUserFromRadiology(dltUser);
    }
    setDisableButton(false);
    setDltuser("");
    setConfirmBox(false);
  };
  const handleDeleteUserFromLab = async (name) => {
    let payload = {
      user_name: name,
      clinic_guid: loggeduser?.facilityID,
    };
    await store
      .DeleteUserFromLaboratory(
        loggedusertoken,
        DataDecode.encryptPayload(payload)
      )
      .then((response) => {
        FetchLabAndRadiologyData();

        if (response.status === 200) {
          dispatch(
            setNotification({
              message: "Successfully deleted  user from laboratory",
              status: "success",
              action: true,
            })
          );
          setConfirmBox(false);
        } else {
          dispatch(
            setNotification({
              message: "Error while Deleting user from laboratory",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  const handleDeleteUserFromRadiology = async (name) => {
    let payload = {
      user_name: name,
      clinic_guid: loggeduser?.facilityID,
    };
    await store
      .DeleteUserFromRadiology(
        loggedusertoken,
        DataDecode.encryptPayload(payload)
      )
      .then((response) => {
        FetchLabAndRadiologyData();

        if (response.status === 200) {
          dispatch(
            setNotification({
              message: "Successfully deleted user from Radiology",
              status: "success",
              action: true,
            })
          );
          setConfirmBox(false);
        } else {
          dispatch(
            setNotification({
              message: "Error while Deleting user from Radiology",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const FetchLabAndRadiologyData = async () => {
    let payload = loggeduser?.facilityID;
    await store
      .FetchLabAndRadiologyUsers(
        loggedusertoken,
        DataDecode.encryptPayload(payload)
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            const activeLabUsers = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  user.lab_Management?.is_Lab_Active === true
              )
              .map((user) => user.full_name || "");

            const radiologyUsers = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  user.lab_Management?.is_Radiology_Active === true
              )
              .map((user) => user.full_name || "");
            const users = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  !user.lab_Management?.is_Lab_Active
              )
              .map((user) => user.full_name || "");
            const transformedData = users.map((item, index) => ({
              value: index + 1,
              label: item,
            }));
            const radiologyUs = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  !user.lab_Management?.is_Radiology_Active
              )
              .map((user) => user.full_name || "");
            const RadtransformedData = radiologyUs.map((item, index) => ({
              value: index + 1,
              label: item,
            }));
            dispatch(setNotRadiologyAddedUser(RadtransformedData));
            dispatch(setNotLabAddedUser(transformedData));
            dispatch(setusernames(activeLabUsers));
            dispatch(setradiologyUsernames(radiologyUsers));
            // const newUsers = labManagementUsers.map((user, index) => ({
            //   value: index + 1, // Assign user_id as index + 1
            //   label: user,
            // }));
            // // Filter users with valid user_name and merge with usernames
            // const validUsers = newUsers.filter((user) => user.user_name !== "");
            // setAddUser((prev) => [...prev, ...validUsers]);
          });
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error while fetching users",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (loggeduser?.facilityID) {
        FetchLabAndRadiologyData();
      }
    }, 5000);
  }, [loggedusertoken]);

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        display: "flex",
        alignItems: "center",
        backgroundColor: state.isFocused
          ? "#fcdcdc"
          : state.isSelected
          ? "#e4a2a2"
          : "white",
        fontWeight: provided.fontWeight,
        padding: "10px",
        minHeight: "36px",
        cursor: "pointer",
      };
    },

    control: (base, state) => ({
      ...base,
      borderRadius: "8px",
      padding: "5px",
      border: state.isFocused ? "2px solid #311f78" : "1px solid #ddd",
      boxShadow: state.isFocused ? "none" : "none",
      outline: "none",
    }),
    input: (base) => ({
      ...base,
      "input:focus": {
        boxShadow: "none",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      maxHeight: "none",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#e6f0ff",
      borderRadius: "12px",
      padding: "3px 8px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "auto",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#000",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#0000ff",
      ":hover": {
        color: "#00008b",
      },
    }),
  };

  return (
    <>
      <>
        {confirmBox && (
          <div
            id={"LaboraotryFilesdeleteLab"}
            tabIndex="-1"
            aria-hidden="true"
            className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
          >
            <div
              id={"LaboraotryFilesLa"}
              className="relative p-4 w-full max-w-md h-full md:h-auto"
            >
              <div
                id={"LaboraotryFilesdeletecontent"}
                className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5"
              >
                <svg
                  id="LaboratorydeleteLabIcon"
                  className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <p
                  id="LaboraotorydeleteLabText"
                  className="mb-4 text-gray-500 dark:text-gray-300"
                >
                  Are you sure you want to delete this User?
                </p>
                <div
                  id="LaboratoryDivdeleteLab"
                  className="flex justify-center items-center space-x-4"
                >
                  <button
                    id="LaboratoryBtndeleteLabCancel"
                    onClick={() => setConfirmBox(false)}
                    disabled={disableButton}
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                  <button
                    id="LaboratoryBtndeleteLabConfirm"
                    type="button"
                    onClick={handleDeletesubmit}
                    disabled={disableButton}
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-800 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Yes, I'm sure{" "}
                    {disableButton && (
                      <svg
                        id="LaboratorySvgdeleteLabConfirmIcon"
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 ml-2 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <div className="bg-white mx-auto px-4 py-2 my-20 w-100%">
        <header
          id="LaboratoryHeadersettingsTitle"
          className={`flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
          style={{ fontFamily: previewFont.fontFamily }}
        > <button id="settingBack" className='mr-2' onClick={() => { window.history.back() }}>
        <svg id="MobPatientProfileSvgmobReloadIcon"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 12" fill="none" >
          <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" className="fill-current" />
        </svg>
      </button>
          Order Notifications
        </header>

        <div className="flex justify-between mx-auto mt-5 gap-4">
          <div className="flex justify-center w-full">
            <h1
              id="LaboratoryH1oTitle"
              className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
        ${
          activePage === "Laboratory"
            ? "bg-gray-200 border-gray-200"
            : "bg-gray-50 border-gray-50"
        } `}
              onClick={() => handlePageClick("Laboratory")}
            >
              Laboratory
            </h1>
            <h1
              id="LaboratoryH1RadiologyTitle"
              className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
        ${
          activePage === "Radiology"
            ? "bg-gray-200 border-gray-200"
            : "bg-gray-50 border-gray-50"
        }`}
              onClick={() => handlePageClick("Radiology")}
            >
              Radiology
            </h1>
          </div>
        </div>
        {activePage === "Laboratory" && (
          <div>
            <header id="LaboratoryHeader" className="flex items-center">
              <div
                id="LaboratoryDivIdTitle"
                className={`origin-top-left flex font-bold mb-2 mt-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-2 ${previewTheme.headingTitleColor}`}
                style={{ fontFamily: previewFont.fontFamily }}
              >
                Lab Users
              </div>
            </header>
            <div
              id="LaboratoryDivIdActions"
              className="flex flex-col md:flex-row justify-between items-center mb-4 px-2"
            >
              <div
                id="LaboratoryDivaddIdContainer"
                className="hidden lg:flex items-center"
              >
                <button
                  id="LaboratoryBtnaddIdButton"
                  className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`}
                  style={{ fontFamily: previewFont.fontFamily }}
                  onClick={() => {
                    handleShowAddUserPopup(true);
                  }}
                >
                  <svg
                    id="LaboratorySvgaddIdIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                  </svg>
                  Add User For Laboratory Notification
                </button>
              </div>
              <div
                id="LaboratorySearchIDContainer"
                className="flex flex-col sm:flex-row items-center w-full md:w-auto mt-2 md:mt-0"
              >
                <div
                  id="LaboratoryDivsearchInputContainer"
                  className="flex sm:flex-row items-center"
                >
                  <div
                    id="SettingsDivsearchInputContainer"
                    className="relative items-center gap-2"
                  >
                    <input
                      type="text"
                      id="SettingsInputsearchDepartment"
                      className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                      placeholder="Search here"
                    />
                    <svg
                      id="SettingsSvgsearchIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </div>
                  <button
                    id="SettingsBtnmobileAddDepartment"
                    className="sm:flex md:flex lg:hidden sm:top-section "
                    onClick={() => handleShowAddUserPopup(true)}
                  >
                    <svg
                      id="SettingsSvgmobileAddDepartmentIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-10 h-10 fill-red-700"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <button
                    id="SettingsBtnreload"
                    className="items-center "
                    onClick={() => window.location.reload()}
                  >
                    <svg
                      id="SettingsSvgreloadIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-700 hover:text-red-800"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                      <path d="M20 4v5h-5" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="pt-4 overflow-x-auto">
              <table className="w-full border rounded-md">
                <thead id="LaboratoryTHead" className={`rounded-lg text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12 `}>
                  <tr
                    id="LaboratoryTrsHeaderRow"
                    className="px-5 py-2 text-left border-b"
                  >
                    <th id="LaboratoryTHdepa" className="px-2 py-2 text-left">
                      User Name
                    </th>
                    <th
                      id="LaboratoryTHIdAction"
                      className="px-4 py-2 text-left"
                    >
                      
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usernames?.length > 0 ? (
                    usernames.map((user, index) => (
                      <tr key={index} className="border-b h-12 text-[#424242] hover:bg-white bg-[#FBFBFB] text-sm">
                        <td className="px-2 py-2 text-left">{user || "-"}</td>
                        <td className="px-10 py-2 text-left">
                          <button onClick={() => handleDeleteUser(user)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                            >
                              <path
                                d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z"
                                fill="#A31B1B"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="2"
                        className="text-center py-4 text-xl font-bold text-red-700"
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {showAddLabUser && (
          <>
            <div
              id={"LaboratoryhandleShowAddUserPopupIdNoRecords"}
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-20  flex justify-center items-center"
            >
              <div
                className={`w-[95%] md:w-[650px] bg-white rounded-md shadow-lg p-6 relative transition-all duration-400 overflow-y-auto ${
                  isDropdownOpen
                    ? "max-h-[450px]"
                    : selecteduser.length > 6
                    ? "max-h-[350px]"
                    : "max-h-[250px]"
                }`}
              >
                {" "}
                <button
                  id={"LaboratoryIdhandleShowAddUserPopup"}
                  className="absolute top-3 right-3 pt-2 pr-2 text-gray-400 hover:text-gray-600"
                  onClick={() => {
                    handleShowAddUserPopup(false);
                  }}
                >
                  <img src={BlueRondedCrossIcon} />
                </button>
                <p
                  className={`text-2xl font-bold ${previewTheme.headingTitleColor} mb-4`}
                  style={{ fontFamily: previewFont.fontFamily }}
                >
                  Add Notification to User
                </p>
                <div className="relative">
                  <p className="text-[#4e5052] text-xl from-neutral-50 mb-2">
                    Add UserName
                  </p>
                  <CreatableSelect
                    id="LaboratoryNotificationUserId"
                    options={notLabAddedUser}
                    value={selecteduser}
                    isMulti
                    isClearable
                    isValidNewOption={() => false}
                    onMenuOpen={() => setIsDropdownOpen(true)}
                    onMenuClose={() => setIsDropdownOpen(false)}
                    onChange={handleSelectTest}
                    closeMenuOnSelect={false}
                    placeholder="Select Test"
                    styles={customStyles}
                  />
                  <div
                    className={`flex justify-end bottom-auto ${
                      isDropdownOpen ? "pt-52" : "pt-10"
                    }`}
                  >
                    <button
                      id={"LaboratoryIdNohandleShowAddUserPopup"}
                      className="px-4 py-2 rounded-md hover:bg-[#9c9393]  bg-[#777777] text-[#fdfefe] mr-2"
                      onClick={() => {
                        handleShowAddUserPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      id={"LaboratoryIdNoaddLabtificationstoUsers"}
                      className="px-4 py-2 rounded-md hover:bg-red-500 bg-[#a93333] text-white"
                      onClick={() => {
                        addLabNotificationstoUsers();
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {showAddRadiologyUser && (
          <>
            <div
              id={"LaboratoryIdshowAddRadiologyUser"}
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-20  flex justify-center items-center"
            >
              <div
                className={`w-[95%] md:w-[650px] bg-white rounded-md shadow-lg p-6 relative transition-all duration-400 overflow-y-auto ${
                  isDropdownOpen
                    ? "max-h-[450px]"
                    : selecteduser.length > 6
                    ? "max-h-[350px]"
                    : "max-h-[250px]"
                }`}
              >
                {" "}
                <button
                  id={"LaboratoryIdhandleShowAddRadiologyUserPopup"}
                  className="absolute top-3 right-3 pt-2 pr-2 text-gray-400 hover:text-gray-600"
                  onClick={() => {
                    handleShowAddRadiologyUserPopup(false);
                  }}
                >
                  <img src={BlueRondedCrossIcon} />
                </button>
                <p
                  className={`text-2xl font-bold ${previewTheme.headingTitleColor} mb-4`}
                  style={{ fontFamily: previewFont.fontFamily }}
                >
                  Add Notification to User
                </p>
                <div className="relative">
                  <p className="text-[#454647] text-xl from-neutral-50 mb-2">
                    Add UserName
                  </p>
                  <CreatableSelect
                    id="RadiologyNotificationUserId"
                    options={notRadiologyAddedUser}
                    value={selecteduser}
                    isMulti
                    isClearable
                    isValidNewOption={() => false}
                    onMenuOpen={() => setIsDropdownOpen(true)}
                    onMenuClose={() => setIsDropdownOpen(false)}
                    onChange={handleSelectTest}
                    closeMenuOnSelect={false}
                    placeholder="Select Test"
                    styles={customStyles}
                  />
                  <div
                    className={`flex justify-end bottom-auto ${
                      isDropdownOpen ? "pt-52" : "pt-10"
                    }`}
                  >
                    <button
                      id="RadiologyNotificationCancel"
                      className="px-4 py-2 rounded-md hover:bg-[#9c9393]  bg-[#777777] text-[#fdfefe] mr-2"
                      onClick={() => {
                        handleShowAddRadiologyUserPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      id="RadiologyNotificationProceed"
                      className="px-4 py-2 rounded-md hover:bg-red-500 bg-[#a93333] text-white"
                      onClick={() => {
                        addRadiologyNotification();
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {activePage === "Radiology" && (
          <div>
            <header
              id="HeaderRadiologyUsersId"
              className="flex flex-col sm:flex-row  justify-between px-4 py-2"
            >
              <div
                id="RadiologyDivRadiologyTitle"
                className={`flex font-bold text-2xl pt-2 sm:text-xl ${previewTheme.headingTitleColor}`}
                style={{ fontFamily: previewFont.fontFamily }}
              >
                Radiology Users
              </div>
            </header>

            <div
              id="RadiologyDivRadiologyActions"
              className="flex flex-col sm:flex-row justify-between items-center mb-4 px-4"
            >
              <div
                id="RadDivaddRadiologyContainer"
                className="hidden lg:flex items-center"
              >
                <button
                  id="RadioloBtnaddRadiologyButton"
                  className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} hover:${previewTheme.addBtnHoverColor}`}
                  onClick={() => handleShowAddRadiologyUserPopup(true)}
                >
                  Add User For Radiology Notification
                </button>
              </div>

              <div
                id="RdaiologyDivsearchInputContainer"
                className="flex sm:flex-row items-center"
              >
                <div
                  id="RadiologyDivsearchInputContainer"
                  className="relative items-center gap-2"
                >
                  <input
                    type="text"
                    id="RadiologyInputsearchDepartment"
                    className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                    placeholder="Search here"
                  />
                  <svg
                    id="RadiologySvgsearchIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                </div>
                <button
                  id="RadiologyBtnmobileAddDepartment"
                  className="sm:flex md:flex lg:hidden sm:top-section "
                  onClick={() => handleShowAddRadiologyUserPopup(true)}
                >
                  <svg
                    id="RdaiologySvgmobileAddDepartmentIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-10 h-10 fill-red-700"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  id="RdaiologyBtnreload"
                  className="items-center "
                  onClick={() => window.location.reload()}
                >
                  <svg
                    id="rdaiologySvgreloadIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-700 hover:text-red-800"
                    width="44"
                    height="44"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                    <path d="M20 4v5h-5" />
                  </svg>
                </button>
              </div>
            </div>

            <div className="pt-4 overflow-x-auto">
              <table className="w-full border rounded-md">
                <thead id="RadiologyTHead"  className={`rounded-lg text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12 `}>
                  <tr
                    id="RadiologyTrsHeaderRow"
                    className="px-5 py-2 text-left border-b"
                  >
                    <th id="RadiologyTHdepa" className="px-2 py-2 text-left">
                      User Name
                    </th>
                    <th
                      id="RadiologyTHIdAction"
                      className="px-4 py-2 text-left"
                    >
                     
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {radiologyUsernames?.length > 0 ? (
                    radiologyUsernames.map((user, index) => (
                      <tr key={index} className="border-b h-12 text-[#424242] hover:bg-white bg-[#FBFBFB] text-sm">
                        <td className="px-2 py-2 text-left">{user || "-"}</td>
                        <td className="px-10 py-2 text-left">
                          <button onClick={() => handleDeleteUser(user)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                            >
                              <path
                                d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z"
                                fill="#A31B1B"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="2"
                        className="text-center py-4 text-xl font-bold text-red-700"
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default LaboratoryFiles;
