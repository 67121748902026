import DataDecode from "../utils/DataDecode";
import DateFunction from "../utils/DateFunctions";

const store = {
  ResetPassword: (resetPasswordParam) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERGRAPH + "/ResetUserPassword", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: resetPasswordParam,
    }).then((response) => response);
  },
  DeleteUserFromDBAzureAdb2c: (DeleteUser) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERGRAPH + "/DeleteUser", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: DeleteUser,
    }).then((response) => response);
  },
  GetUserCreds: (usercreds) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetUserCreds", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
      body: usercreds,
    }).then((response) => response);
  },
  GetOtp: (otpGeneraterParam) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GenerateOtp", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
      body: otpGeneraterParam,
    }).then((response) => response);
  },
  EnableAudioRecording: (Payload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/EnableAudioRecording", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: Payload,
    }).then((response) => response);
  },
  validateOtp: (payload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/ValidateOtp", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload,
    }).then((response) => response);
  },
  ForgotUsernameApiCall: (userNameForgotWithEmail) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/User_FD_ByEmail",
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: userNameForgotWithEmail,
      }
    ).then((response) => response);
  },
  VerifyUsername: (userNameVerification) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetUserNameVerification",
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: userNameVerification,
      }
    ).then((response) => response);
  },
  AdminForgotUserPassword: (AdminForgotEmail) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/AdminUserForgotFlow",
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: AdminForgotEmail,
      }
    ).then((response) => response);
  },
  GetUserdetais: (logindetails, token) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetCurrentUser",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logindetails),
      }
    ).then((response) => response);
  },
  GetLaboratoryDetails:(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/FetchFacilityLaborataries",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
 
  },
  FetchLabAndRadiologyUsers :(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/FetchLabAndRadiologyUsers",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
 
  },
  CreateLabAppointment :(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/CreateLabAppointment",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
 
  },
  
  CreateRadiologyAppointment :(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/CreateRadoiologyAppointment",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
  },
  SendUserOrderNotification :(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/SendUserOrderNotification",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
  },
  FetchAppointmentsByAppointmentId :(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/FetchAppointmentsByAppointmentId",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
  },
  
  UpdateLabUserActive:(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/UpdateLabUserActive",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
 
  },
  UpdateRadiologyUserActive:(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/UpdateRadiologyUserActive",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
  },
  DeleteUserFromLaboratory:(token,Payload)=>{
     
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/DeleteUserFromLaboratory",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
  },
  DeleteUserFromRadiology:(token,Payload)=>{
    return fetch(
      process.env.REACT_APP_EMRAXIS_LAB_AND_RADIOLOGY+"/DeleteUserFromRadiology",
      {
         method:"POST",
         headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
         body: Payload
      }
).then((response)=> response)
  },
  GetTranscription: (Payload) => {

    return fetch(process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/GenerateClinicalNotes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Payload,

    }).then((response) => response);

  },
  GetClinicalNotesTemplates: (payload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/GetClinicalNotesTemplates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body:payload

    }).then((response) => response);

  },
  UpdateClinicalNotes: (Payload) => {

    return fetch(process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES+"/UpdatelinicalNoteData", {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
            body: Payload,
      
    }).then((response) => response);
  },
  GetTranscriptionFiles : async (Payload) => {
          return    await  fetch(process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES+"/GetTranscriptionFiles", {
          method: "POST",
          headers: {
             "Content-Type": "application/json",
          },
                body: Payload,
          
        }).then((response) => response);
       },
   
  FetchTranscritpionResponse: (Payload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/GetTranscriptionResponse", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Payload,

    }).then((response) => response);

  },
  DeleteClinicalNotes: (Payload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/DeleteClinicalNotes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: Payload,

    }).then((response) => response);

  },
  GetUserRole: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERROLE, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  GetSubscription: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_SUBSCRIPTION, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  GetFeatures: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FEATURE, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  SaveUserRole: (token, userroles) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERROLE, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userroles),
    }).then((response) => response);
  },
  SaveSubscription: (token, subscriptions) => {
    return fetch(process.env.REACT_APP_EMRAXIS_SUBSCRIPTION, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subscriptions),
    }).then((response) => response);
  },
  SaveFeature: (token, feature) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FEATURE, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feature),
    }).then((response) => response);
  },
  UpdateUserRole: (token, userroles) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERROLE, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userroles),
    }).then((response) => response);
  },
  DeleteUserRole: (id, token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERROLE + "/" + id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  GetFacilityDetails: (token, page) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/" + page, {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  GetFacilityUserDetails: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetFacilityUserData",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SearchUsers: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/SearchUsers", {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetFacilityinfo: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/GetFacilityData",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetFacilityDataForSuperAdmin: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/GetFacilityByClinicId",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SearchFacility: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/SearchFacilityData",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  FetchUsers: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetUsers", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  SaveUserDetails: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/saveuserdetails",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  InactiveUser: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/InActiveUser", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  ActiveUser: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/UpdateActiveUser",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetMoreUserDetails: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetUserDetails",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  UpdateUserDetails: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_USERPROFILE + "/UpdateUserDetails",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },

  InviteFacilitybySuperAdmin: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/invitefacility",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SaveFacilityDetails: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/AddFacilityData",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json'
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  deleteLogo: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/DeleteLogo", {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  UpdateFacilityQrCodeSettings: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/UpdateFacilityQrCodeSettings",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetFacilityQrCodeSettings: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/GetFacilityQrCodeSettings",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetFacilityByQrCode: (encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/GetFacilityByQrCode",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetPatientShareLink: (encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/GetPatientShareLink",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetPatientIntakeLinkStatus: (encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/GetPatientIntakeLinkStatus",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SendPatientShareLink: (encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/SendPatientShareLink",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SaveSharedPatient: (encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/SaveSharedPatient",
      {
        method: "post",
        headers: {
          // "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SaveFeedback: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FEEDBACK, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetAllFacilityDetails: async (token) => {
    const response = await fetch(
      process.env.REACT_APP_HMS_FACILITYDATA + "/GetAllFacilityData",
      {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  },
  GetUserDetails: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/GetUserData", {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  GetDoctorsDetails: (token, clinicguid) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDoctors", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clinicguid),
    }).then((response) => response);
  },
  GetPatientDetails: (token, clinicguid) => {
    return fetch(process.env.REACT_APP_EMRAXIS_PATIENT + "/GetPatients", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clinicguid),
    }).then((response) => response);
  },
  GetDoctorsCount: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDoctorsCount", {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  GetPatientCount: (token) => {
    return fetch(process.env.REACT_APP_EMRAXIS_PATIENT + "/GetPatientsCount", {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  },
  searchPatientForAppointment: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/SearchPatientForAppointment",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  searchPatient: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_PATIENT + "/SearchPatient", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetNoOfPatientsForDoctor: async (token, encryptedPayload) => {
    const response = await fetch(process.env.REACT_APP_EMRAXIS_APPOINTMENT + '/GetNoOfPatientsForDoctor',
      {
        method: 'Post',
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: encryptedPayload
      });
    return response;
  },
  searchAppointment: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/SearchAppointments",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  getDoctorForAppointment: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDoctorForAppointment",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  saveAppointment: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/SaveAppointment",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  updateAppointment: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/UpdateAppointment",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  UpdateVitals: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/UpdateVitals", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  updateTimeInTimeOut: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/UpdateTimeInTimeOut",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  deleteAppointment: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/DeleteAppointment",
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  getAppointments: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAppointments",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  getAppointmentsByDoctorId: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAppointmentsByDoctorId",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllPatients: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_PATIENT + "/GetAllPatients", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetPatient: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_PATIENT + "/GetPatient", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetAllDoctors: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetAllDoctors", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetDepartmentForDoctor: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDepartmentForDoctor",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  AddPatientDetails: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_PATIENT + "/PostPatientDetails",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("add patient details error", err);
    }
  },
  UpdatePatientDetails: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_PATIENT + "/UpdatePatientDetails",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("add patient details error", err);
    }
  },
  deletePatient: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_PATIENT + "/DeletePatient", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
      console.log("add patient details error", err);
    }
  },
  GetAppointmnet: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAppointment",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  CompleteSignOff: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/CompleteSignOff",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetCurrentMedicines: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetCurrentMedicines",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetPastHistory: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetPastHistory",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetPatientVitals: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetPatientVitals",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  updateDoctorNotes: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/UpdateDoctorNotes",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
        keepalive: true,
      }
    ).then((response) => response);
  },
  AddPrescription: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/AddPrescription",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
        keepalive: true,
      }
    ).then((response) => response);
  },
  UpdatePrescription: (token, encryptedPayload) => {
    
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/UpdatePrescription",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      
      }
    ).then((response) => response);
  },
  GetPrescription: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetPrescription",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  SavePatientFile: (token, formData) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/SavePatientFile",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    ).then((response) => response);
  },
  GetPatientFiles: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetPatientFiles",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  DeletePatientFile: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/DeletePatientFile",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GenerateToken: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GenerateToken", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetPastPrescription: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetPastPrescription",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  DeleteEnteredPrescriptions: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/DeleteEnteredPrescriptions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  AddDoctor: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DOCTOR + "/PostDoctorDetails",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          //"Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  UpdateDoctorDetails: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DOCTOR + "/UpdateDoctorDetails",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          //"Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  deleteDoctor: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/DeleteDoctor", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
      console.log("Delete doctor error", err);
    }
  },
  searchDoctor: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/SearchDoctor", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetDoctorInfo: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDoctorInfo", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  UpdateDoctorInfo: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/UpdateDoctorInfo", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
      console.log("Update doctor Information error", err);
    }
  },
  GetAllDepartments: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DEPARTMENT + "/GetAllDepartments",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  AddDepartment: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_DEPARTMENT + "/AddDepartment",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("add department details error", err);
    }
  },
  UpdateDepartment: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_DEPARTMENT + "/UpdateDepartment",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("Update department error", err);
    }
  },
  deleteDepartment: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_DEPARTMENT + "/DeleteDepartment",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("Delete department error", err);
    }
  },
  InstallFeature: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/InstallFeature",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("Error in Install feature");
    }
  },
  GetInvoiceTerms: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_INVOICE + "/GetInvoiceTerms", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
      console.log("Error in GetInvoiceTerms");
    }
  },
  UpdateInvoiceTerms: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_INVOICE + "/UpdateInvoiceTerms",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("Error in UpdateInvoiceTerms");
    }
  },
  GetAllMasterTariff: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_MASTERTARIFF + "/GetAllMasterTariff",
        {
          method: "Post",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("GetAllMasterTariff");
    }
  },
  CreateMasterTariff: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_MASTERTARIFF + "/CreateMasterTariff",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("CreateMasterTariff");
    }
  },
  UpdateMasterTariff: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_MASTERTARIFF + "/UpdateMasterTariff",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("UpdateMasterTariff");
    }
  },
  DeleteMasterTariff: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_MASTERTARIFF + "/DeleteMasterTariff",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("DeleteMasterTariff");
    }
  },
  UpdateDoctorConultantFee: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_DOCTOR + "/UpdateDoctorConultantFee",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("UpdateDoctorConultantFee");
    }
  },
  SaveAppointmnetInvoice: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_INVOICE + "/SaveAppointmnetInvoice",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("SaveAppointmnetInvoice");
    }
  },
  CheckIsInvoiceExist: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_INVOICE + "/CheckIsInvoiceExist",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("SaveAppointmnetInvoice");
    }
  },
  GetAllInvoice: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_INVOICE + "/GetAllInvoice", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
     
    }
  },
  GetInvoice: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_INVOICE + "/GetInvoice", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
     
    }
  },
  UpdateAppointmnetInvoice: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_INVOICE + "/UpdateAppointmnetInvoice",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
  
    }
  },
  CreateClinicalNotes: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/CreateClinicalNotes",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: encryptedPayload,
          keepalive: true,
        }
      ).then((response) => response);
    } catch (err) {
     
    }
  },
  GetClinicalNotes:async (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/GetClinicalNotes",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
    
    }
  },
  GetAllMedicine: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAllMedicines",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllPatientDrNotes: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAllPatientDrNotes",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllPatientPrescriptions: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAllPatientPrescriptions",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllUploadedData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAllUploadedData",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllPatientLabNotes: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/GetAllPatientLabNotes",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllPatientRadiologyNotes: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT +
      "/GetAllPatientRadiologyNotes",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAllPatientDataAavailable: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT +
      "/GetAllPatientDataAavailable",
      {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  UpdateTheme: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/UpdateTheme", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  UploadPatientDocument: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/UploadPatientDocument",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("UploadPatientDocument");
    }
  },
  postContactData: (encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_CONTACTUSAPIENDPOINT, {
      method: "Post",
      headers: {
        // "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(encryptedPayload),
    }).then((response) => response);
  },
  AddSchedule: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_DOCTOR + "/AddDoctorSchedule",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    } catch (err) {
      console.log("add doctor schedule error", err);
    }
  },
  GetDoctorSchedules: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDoctorSchedule", {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  deleteDoctorSlot: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/DeleteTimeSlot", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }).then((response) => response);
    } catch (err) {
      console.log("Delete doctor schedule error", err);
    }
  },
  GetPatientRecycleBinData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/GetPatientRecycleBinData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  RestorePatientData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_PATIENT + "/RestorePatientData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetAppointmentRecycleBinData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT +
      "/GetAppointmentRecycleBinData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  RestoreAppointmentData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/RestoreAppointmentData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetDoctorRecycleBinData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DOCTOR + "/GetDoctorRecycleBinData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  RestoreDoctorData: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_DOCTOR + "/RestoreDoctorData", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetDepartmentRecycleBinData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_DEPARTMENT + "/GetDepartmentRecycleBinData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  RestoreClinicalNotes: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/RestoreClinicalNotes",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetClinicalNotesRecycleBinData: (token,encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_CLINICAL_NOTES + "/GetClinicalNotesRecycleBinData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  GetPatientFilesRecycleBinData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT +
      "/GetPatientFilesRecycleBinData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  RestorePatientFilesData: (token, encryptedPayload) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_APPOINTMENT + "/RestorePatientFilesData",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: encryptedPayload,
      }
    ).then((response) => response);
  },
  AddUserPic: (token, encryptedPayload) => {
    try {
      return fetch(
        process.env.REACT_APP_EMRAXIS_USERPROFILE + "/UpdateUserProfilePic",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            //"Content-Type": "application/json",
          },
          body: encryptedPayload,
        }
      ).then((response) => response);
    }
    catch (err) {
      console.log("Error occurred while updating user pic", err);
    }
  },
  deleteUserPic: (token, encryptedPayload) => {
    try {
      return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/DeleteUserProfilePic",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: encryptedPayload,
        }).then((response) => response);
    }
    catch (err) {
      console.log("Error occurred while deleting user pic", err);
    }
  },
  UpdateAppointmentGrid: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/UpdateAppointmentGrid", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  GetFacilityuserRoles:(token,encryptPayload)=>{
  return fetch(process.env.REACT_APP_EMRAXIS_USERROLE+"/GetFacilityUserRoles",{
    method:"POST",
    headers:{
      Authorization:`Bearer ${token}`,
      "Content-Type":"application/json"
    },
    body:encryptPayload
  }).then((response)=>response);
  },
  UpdateFacilityUserRole:(token,encryptPayload)=>{
    return fetch(process.env.REACT_APP_EMRAXIS_USERROLE+"/UpdateFacilityUserRole",{
      method:"POST",
      headers:{
        Authorization:`Bearer ${token}`,
        "Content-Type":"application/json"
      },
      body:encryptPayload
    }).then((response)=>response);
    },
    DeleteFacilityUserRole:(token,encryptPayload)=>{
      return fetch(process.env.REACT_APP_EMRAXIS_USERROLE+"/DeleteFacilityUserRole",{
        method:"POST",
        headers:{
          Authorization:`Bearer ${token}`,
          "Content-Type":"application/json"
        },
        body:encryptPayload
      }).then((response)=>response);
      },
  PostFacilityUserRole:(token,encryptPayload)=>{
    return fetch(process.env.REACT_APP_EMRAXIS_USERROLE+"/PostFacilityUserRoles",{
      method:"POST",
      headers:{
        Authorization:`Bearer ${token}`,
        "Content-Type":"application/json"
      },
      body:encryptPayload
    }).then((response)=>response);
    },
  CreateActivityLogs: (loggeduser, screen, activity, user_action) => {
    return fetch(
      process.env.REACT_APP_EMRAXIS_ACTIVITY_LOGS + "/CreateActivityLogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: DataDecode.encryptPayload({
          screen: screen,
          activity: activity,
          user_action: user_action,
          user_name: loggeduser.user_name,
          clinic_guid: loggeduser.facilityID,
          created_on: DateFunction.SetDbCurrentTimeStamp()
        }),
        keepalive: true,
      }
    ).then((response) => response);
  },
  GetAllUsersActivityLogs: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_ACTIVITY_LOGS + "/GetAllUsersActivityLogs", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  DownloadUsersActivityLogs: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_ACTIVITY_LOGS + "/DownloadActivityLogs", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text/json'
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  OnboardFacilityNumber: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/OnboardFacilityNumber", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  },
  validateWhatsAppOtp: (payload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_FACILITYDATA + "/validateOtp", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
      body: payload,
    }).then((response) => response);
  },
  sendUserLimitRequest: (token, encryptedPayload) => {
    return fetch(process.env.REACT_APP_EMRAXIS_USERPROFILE + "/sendUserLimitRequest", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: encryptedPayload,
    }).then((response) => response);
  }
};
export default store;
