import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { theme } from '../EmraxisTheme/Theme';

function ExcelReports() {
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
  return (
    <h1 id="SuperexcelReportH1title" className={`mt-20 flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Excel Reports</h1>
  )
}

export default ExcelReports