import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { OutlinedInput, InputAdornment } from "@mui/material";
import store from "../../API/store";
import DataDecode from "../../utils/DataDecode";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import CancelImage from "../../../src/components/Assets/cancelImage.png";
import EditIcon from "../../../src/components/Assets/edit.png";
import BlueRoundCrossIcon from "../../../src/components/Assets/BlueRondedCrossIcon.png";
import { USERACCESSROLES } from "../../utils/Constant";
import CreatableSelect from "react-select/creatable";
import { theme } from "../../EmraxisTheme/Theme";
import Select, { defaultTheme } from "react-select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const Userroles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var isDesktopUserScreenOpen = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  const previewTheme =
  useSelector((state) => state.theme.colors) || theme.defaultTheme;

  var loggeduser = useSelector((state) => state.login.loginuserData);
  var facility = useSelector((state) => state.facility.facility_id);
  const [searchText, setSearchText] = useState("");
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);
  const [activeRole, setActiveRole] = useState(null);
  const [isTextVisible, setIsTextVisible] = useState(true);
  const [isTestDropdownOpen, setIsTestDropdownOpen] = useState(false);
  const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(false);
  const [showAddUserRolePopup, setshowAddUserRolePopup] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isRoletoDelete, setIsRoletoDelete] = useState("");
  const inputRef = useRef(null);
  const [newRole, setNewRole] = useState([]);
  const [userType, setUserType] = useState("");
  const [createRoleName, setCreateRoleName] = useState("");
  const [dropdownStates, setDropdownStates] = useState({});
  const [confirmBox, setConfirmBox] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error, setError] = useState("");
  const [index, setIndex] = useState();
  const [isStandarduserDropdownOpen, setIsStandarduserDropdownOpen] =
    useState(false);
  const [dictionary, setDictionary] = useState({
    key: [
      {
        name: "",
        value: false,
      },
    ],
  });
  const [userAccessRoles, setUserRoles] = useState([
    { value: 1, label: "Admin" },
    { value: 2, label: "Doctor" },
    { value: 3, label: "User" },
  ]);
  const [userRolesPayload, setUserRolesPayload] = useState({
    userRoleName: "",
    admin: false,
    doctor: false,
    user: false,
  });
  const [FullRoles, setFullRoles] = useState([
    {
      roleActions: [],
      userRoleName: "",
    },
  ]);
  const [updatedValues, setupdatedValues] = useState([{ value: 1, label: "" }]);
  const [updateRole, setupdateRole] = useState([{ value: 1, label: "" }]);
  const [Admin, setAdmin] = useState([
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ]);
  const [Doctor, setDoctor] = useState([
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ]);
  const [User, setUser] = useState([
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ]);

  const toggleDropdown = (id) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleToggleText = () => {
    setIsTextVisible((prevState) => !prevState);
  };
  const handleUpdateMethod = (item, name, index) => {
    const updateRoles = FullRoles[index]?.roleActions?.flatMap((Admin) =>
      Object.entries(Admin).map(([key, value], subIndex) => ({
        value: subIndex + 1,
        label: key,
      }))
    );
    const selectedRoles = FullRoles[index]?.roleActions?.flatMap((Admin) =>
      Object.entries(Admin)
        .filter(([key, value]) => value === true)
        .map(([key, value], subIndex) => ({
          value: subIndex + 1,
          label: key,
        }))
    );
    setupdatedValues(selectedRoles);
    setupdateRole(updateRoles);
    setNewRole([]);
    setUserType({ value: 1, label: name });
    setIsDelete(item);
  };
  const handleAddUserRolePopup = (item) => {
    setNewRole([]);
    setCreateRoleName("");
    setUserRolesPayload({});
    setshowAddUserRolePopup(item);
  };

  const handleSelectUserType = (name) => {
    setUserType(name);
  };
  const handleNewRoleSelectTest = (selected) => {
    const commonObjects = FullRoles.filter((obj1) =>
      selected.some(
        (obj2) =>
          obj2.label === obj1.userRoleName && obj2.label === obj1.userRoleName
      )
    );

    if (commonObjects.length > 0) {
      dispatch(
        setNotification({
          message: "This action name already existing",
          status: "error",
          action: true,
        })
      );
      return;
    }
    setNewRole(selected || []);
  };
  const handleSelectTest = (selected) => {
    setupdatedValues(selected);
    setNewRole(selected || []);
  };

  const handleInputChange = (e) => {
    setError("");
    setCreateRoleName(e);
    if (createRoleName.length > 30) {
      setError("UserRole Name should exceeds text limit.");
    } else {
      setError("");
    }
  };

  const handlePostUserRole = async () => {
    let selectedRoles = newRole.map((role) => role.label);

    if (createRoleName === "" || createRoleName === null) {
      dispatch(
        setNotification({
          message: "User RoleName should be a mandatory field",
          status: "error",
          action: true,
        })
      );
      return;
    }
    if (createRoleName.length > 30) {
      setError("UserRole Name should exceeds text limit.");
      return;
    }

    const roleDictionary = USERACCESSROLES.reduce((acc, role) => {
      acc[role?.label] = selectedRoles.includes(role?.label);
      return acc;
    }, {});
    let PayloadPostUserroles = {
      FacilityId: facility,
      RoleName: createRoleName,
      UserRolesActions: roleDictionary,
    };

    try {
      const response = await store.PostFacilityUserRole(
        loggedusertoken,
        DataDecode.encryptPayload(PayloadPostUserroles)
      );

      if (response.status === 200) {
        await handleFetchFacilityUserRoles();
        setshowAddUserRolePopup(false);
        dispatch(
          setNotification({
            message: "Successfully added user role",
            status: "success",
            action: true,
          })
        );
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: "Error Occurred",
          status: "error",
          action: true,
        })
      );
    }
  };

  const handleUpdateUserRole = async () => {
    let selectedRoles = newRole.map((role) => role.label);
    let PayloadPostUserroles = {
      FacilityId: facility,
      RoleName: userType?.label,
      UserRoles: selectedRoles,
    };
    await store
      .UpdateFacilityUserRole(
        loggedusertoken,
        DataDecode.encryptPayload(PayloadPostUserroles)
      )
      .then(async (reponse) => {
        if (reponse.status === 200) {
          await handleFetchFacilityUserRoles();
          setshowAddUserRolePopup(false);
          dispatch(
            setNotification({
              message: "Successfully Updated userrole",
              status: "success",
              action: true,
            })
          );
          setIsDelete(false);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  const handleDeleteuserRole = async () => {
    const isRoleActionEnabled = FullRoles[index]?.roleActions?.flatMap(
      (Admin) =>
        Object.entries(Admin)
          .filter(([key, value]) => value === true)
          .map(([key, value], subIndex) => ({
            value: subIndex + 1,
            label: key,
          }))
    );
    if (isRoleActionEnabled.length > 0) {
      dispatch(
        setNotification({
          message:
            "this action role is assigned to someone before deleting disable the action role.",
          status: "info",
          action: true,
        })
      );
      return;
    }
    let PayloadPostUserroles = {
      FacilityId: facility,
      RoleName: isRoletoDelete,
    };
    await store
      .DeleteFacilityUserRole(
        loggedusertoken,
        DataDecode.encryptPayload(PayloadPostUserroles)
      )
      .then(async (reponse) => {
        if (reponse.status === 200) {
          await handleFetchFacilityUserRoles();
          setshowAddUserRolePopup(false);
          dispatch(
            setNotification({
              message: "Successfully Deleted userrole",
              status: "success",
              action: true,
            })
          );
          setConfirmBox(false);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  const handleFetchFacilityUserRoles = async () => {
    let FacilityId = facility;
    await store
      .GetFacilityuserRoles(
        loggedusertoken,
        DataDecode.encryptPayload(FacilityId)
      )
      .then((response) => {
        if (response.status == 200) {
          response.json().then((res) => {
            setFullRoles(res);
          });
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    if (facility) {
      handleFetchFacilityUserRoles();
    }
  }, [facility]);
  const handleAdminSvgClick = (user) => {
    setIsAdminDropdownOpen(!isAdminDropdownOpen);
    setIsTestDropdownOpen(true);
    setActiveRole(user);
  };
  const handleStandarduserSvgClick = () => {
    setIsStandarduserDropdownOpen(!isStandarduserDropdownOpen);
    setActiveRole("Standarduser");
  };
  const handleManagerSvgClick = () => {
    setIsManagerDropdownOpen(!isManagerDropdownOpen);
    setIsStandarduserDropdownOpen(true);
    setActiveRole("Manager");
  };

  return (
    <>
      {confirmBox && (
        <div
          id="PatientsdeletePatientConfirm"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-gray-500 dark:text-gray-300">
                Are you sure you want to delete this UserRole?
              </p>
              <div className="flex justify-center items-center space-x-4">
                <button
                  id="patientBtnDeleteCancel"
                  onClick={() => setConfirmBox(false)}
                  disabled={disableButton}
                  type="button"
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor}
                  ${previewTheme.cancelBtnBgColor}
                  ${
                    disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.cancelBtnHoverColor}`
                  }
                  `}
                >
                  No, cancel
                </button>
                <button
                  id="patientBtnDeletePatient"
                  type="button"
                  onClick={handleDeleteuserRole}
                  disabled={disableButton}
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor}
                      ${previewTheme.saveBtnBgColor}
                      ${
                        disableButton
                          ? "opacity-50 cursor-not-allowed"
                          : `hover:${previewTheme.saveBtnHoverColor}`
                      }`}
                >
                  Yes, I'm sure{" "}
                  {disableButton && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAddUserRolePopup && (
        <div
          id="showAddUserRolePopupId"
          className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-35 backdrop-blur-sm z-10"
        >
          <div className="relative bg-white shadow-xl p-8 min-w-[370px] md:w-[750px] md:h-2/2 max-h-[80vh] ">
            <div className="flex justify-between mb-6">
              <p className={`font-bold text-2xl sm:mb:6 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}>Add UserRole</p>
              <button
                id="setshowAddUserRolePopupId"
                className="text-red-700"
                onClick={() => {
                  handleAddUserRolePopup(false);
                }}
              >
                <svg
                  id="AddAppointmentSvgcloseIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-10 w-10 transition-colors duration-300 ease-in-out hover:text-red-600"
                >
                  <path
                    id="AddAppointmentPathcloseIcon"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
            <div className="container mx-auto px-4">
              <div className="flex justify-between">
                <div className="h-12 w-full md:w-40 ">
                  <p className="text-gray-700 text-xl pr-10 ">User Role *</p>

                  <input
                    type="text"
                    value={createRoleName}
                    onChange={(e) => {
                      handleInputChange(e.target.value);
                    }}
                    className="h-12 w-60 rounded-md shadow-sm border-teal-300"
                  />
                  {error && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                    >
                      {error}
                    </p>
                  )}
                </div>

                <div className="h-12 w-full md:w-[400px] pl-4 pr-8 ">
                  <label className="text-gray-700 pb-1 block">
                    Select User Actions
                  </label>
                  <CreatableSelect
                    id="UserRoleSelectID"
                    options={USERACCESSROLES}
                    value={newRole}
                    onChange={handleNewRoleSelectTest}
                    isMulti
                    isClearable
                    isValidNewOption={() => false}
                    closeMenuOnSelect={false}
                    placeholder=""
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: state.isFocused
                          ? "#fcdcdc"
                          : state.isSelected
                          ? "#e4a2a2"
                          : "white",
                        fontWeight: provided.fontWeight,
                        padding: "10px",
                        minHeight: "36px",
                        cursor: "pointer",
                      }),
                      control: (base, state) => ({
                        ...base,
                        borderRadius: "8px",
                        padding: "5px",
                        border: state.isFocused ? "2px solid #311f78" : "1px solid #ddd",
                        boxShadow: state.isFocused ? "none" : "none",
                        outline: "none",
                        maxHeight: "140px",  
                        overflowY: "auto",  
                      }),
                      input: (base) => ({
                        ...base,
                        "input:focus": {
                          boxShadow: "none",
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                        maxHeight: "200px",  
                        overflowY: "auto",
                      }),
                    
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px",
                        overflowY: "auto",
                      }),
                      multiValue: (styles) => ({
                        ...styles,
                        backgroundColor: "#e6f0ff",
                        borderRadius: "12px",
                        padding: "3px 8px",
                      }),
                      multiValueLabel: (styles) => ({
                        ...styles,
                        color: "#000",
                      }),
                      multiValueRemove: (styles) => ({
                        ...styles,
                        color: "#0000ff",
                        ":hover": {
                          color: "#00008b",
                        },
                      }),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className=" flex justify-between mt-36   pt-8">
              <button
                id="Laboratorycancelbtn"
                className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}
                onClick={() => {
                  handleAddUserRolePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                id="LabProceedbtn"
                className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.saveBtnColor} 
                  ${previewTheme.saveBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                onClick={() => {
                  handlePostUserRole();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {isDelete && (
        <div
          id="showAddUserRolePopupId"
          className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-35 backdrop-blur-sm z-10"
        >
          <div className="relative bg-white shadow-xl p-8 min-w-[370px] lg:w-auto md:w-auto max-h-[80vh] overflow-visible">
            {" "}
            <div className="flex justify-between mb-6">
              <p className="text-2xl font-bold text-[#923434]">
                Update UserRole
              </p>
              <button
                id="setshowAddUserRolePopupId"
                className="text-red-700"
                onClick={() => {
                  setIsDelete(false);
                }}
              >
                <svg
                  id="AddAppointmentSvgcloseIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-10 w-10 transition-colors duration-300 ease-in-out hover:text-red-600"
                >
                  <path
                    id="AddAppointmentPathcloseIcon"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
            <div className="container mx-auto px-4">
              <div className="flex justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="h-12 w-full md:w-80 pt-1">
                    <p className="text-gray-700">User Role *</p>
                    <Select
                      styles={{
                        input: (base) => ({
                          ...base,
                          "input:focus": {
                            boxShadow: "none",
                            borderColor: "#999999",
                            borderRadius: "14px",
                          },
                        }),
                        control: (base) => ({
                          ...base,
                          minHeight: "20px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          overflow: "hidden",
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          maxWidth: "300px",
                          height: "30px",
                          overflow: "hidden",
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "center",
                        }),
                        indicatorsContainer: (base) => ({
                          ...base,
                          height: "45px",
                        }),
                        multiValue: (base) => ({
                          ...base,
                          maxWidth: "80px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                        }),
                        multiValueLabel: (base) => ({
                          ...base,
                          maxWidth: "70px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          cursor: "pointer",
                          ":hover": {
                            backgroundColor: "#ccc",
                          },
                        }),
                      }}
                      placeholder=""
                      isClearable={true}
                      isSearchable={true}
                      value={userType}
                      isDisabled={true}
                    />
                  </div>
                  <div className="h-12 w-full md:w-96 pr-8">
                    <label className="text-gray-700 pb-1 block">
                      Select User Actions
                    </label>
                    <div className="flex">
                      <Autocomplete
                        id="autocomplete"
                        multiple
                        options={updateRole.map((option) => option.label)}
                        value={updatedValues.map((value) => value.label)}
                        onChange={(event, newValue) => {
                          const formattedValues = newValue.map((label) => {
                            const existingOption = updateRole.find(
                              (option) => option.label === label
                            );
                            return (
                              existingOption || {
                                label,
                                value: label.toLowerCase(),
                              }
                            );
                          });
                          handleSelectTest(formattedValues);
                        }}
                        renderTags={(value, getTagProps) => (
                          <div
                            style={{
                              maxHeight: "100px",
                              overflowY: "auto",
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "4px",
                            }}
                          >
                            {value.map((option, index) => (
                              <Chip
                                key={index}
                                label={option}
                                {...getTagProps({ index })}
                                style={{
                                  backgroundColor: "#e6f0ff",
                                  borderRadius: "12px",
                                  padding: "3px 8px",
                                  fontSize: "0.9rem",
                                  cursor: "default",
                                }}
                              />
                            ))}
                          </div>
                        )}
                        renderOption={(props, option, { selected }) => (
                          <Box
                            component="li"
                            {...props}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: selected ? "#cc8f8f" : "white",
                              padding: "8px",
                              minHeight: "30px",
                              cursor: "pointer",
                              fontSize: "0.85rem",
                              "@media (max-width: 600px)": {
                                fontSize: "0.8rem",
                                padding: "6px",
                              },
                              "@media (max-width: 400px)": {
                                fontSize: "0.75rem",
                                padding: "4px",
                              },
                            }}
                          >
                            {option}
                          </Box>
                        )}
                        sx={{
                          width: "100%",
                          maxWidth: "600px",
                          "@media (max-width: 900px)": {
                            maxWidth: "90%",
                          },
                          "& .MuiAutocomplete-listbox": {
                            maxHeight: "100px",
                            overflowY: "auto",
                            "& .MuiAutocomplete-option": {
                              fontSize: "0.85rem",
                              padding: "6px",
                              "@media (max-width: 600px)": {
                                fontSize: "0.8rem",
                                padding: "5px",
                              },
                              "@media (max-width: 400px)": {
                                fontSize: "0.75rem",
                                padding: "4px",
                              },
                            },
                          },
                          "& .MuiAutocomplete-paper": {
                            maxHeight: "100px",
                            overflowY: "auto",
                            "@media (max-width: 600px)": {
                              maxHeight: "80px",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select roles"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              fontSize: "1rem",
                              width: "100%",
                              "@media (max-width: 600px)": {
                                fontSize: "0.9rem",
                              },
                              "@media (max-width: 400px)": {
                                fontSize: "0.8rem",
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-between mt-36   pt-8">
              <button
                id="Laboratorycancelbtn"
                className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}
                onClick={() => {
                  setIsDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                id="LabProceedbtn"
                className={`py-2 px-4 text-sm font-medium  h-12 w-32 rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.saveBtnColor} 
                  ${previewTheme.saveBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                onClick={() => {
                  handleUpdateUserRole();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white mx-auto px-4  my-20 w-100%">
        <div className="flex  items-center lg:ml-1 "></div>
        <div className="flex justify-between items-center lg:ml-1 ">
          <div
            id="UserRolesDivuserRole"
            className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
            style={{ fontFamily: previewTheme.fontFamily }}>
            User Role
          </div>
          <div className="flex">
            <span className="flex justify-between items-center px-6 py-4 transition delay-700 duration-1000 ease-in">
              <OutlinedInput
                id="UserRolesOutlinedtableDataSearchField"
                type="text"
                size="small"
                fullWidth
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  e.stopPropagation();
                  setSearchText(e?.target?.value);
                }}
                inputRef={inputRef}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      border: "2px solid #1a8aa8",
                      background: "#F5F5F5",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    "&:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                  },
                }}
              />
            </span>
            <div className="pt-4 pr-1">
              <button
                id={"UserRolesSvgsearchIconIDbtn"}
                onClick={() => {
                  handleAddUserRolePopup(true);
                }}
                className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }}
              >
                Add UserRole
              </button>
            </div>
            <div className="flex justify-between items-center mb-4 px-2 mt-4">
              <button
                id={"UserRolesSvgsearchIconIDbtn"}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <svg
                  id="UserRolesSvgsearchIcon"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.5333 8.46671C22.5465 7.47317 21.3728 6.68479 20.0799 6.14702C18.7869 5.60924 17.4003 5.33271 16 5.33338C10.1067 5.33338 5.34668 10.1067 5.34668 16C5.34668 21.8934 10.1067 26.6667 16 26.6667C20.9733 26.6667 25.12 23.2667 26.3067 18.6667H23.5333C22.9841 20.2261 21.9645 21.5765 20.6151 22.5318C19.2658 23.4871 17.6533 24.0001 16 24C11.5867 24 8.00001 20.4134 8.00001 16C8.00001 11.5867 11.5867 8.00004 16 8.00004C18.2133 8.00004 20.1867 8.92004 21.6267 10.3734L17.3333 14.6667H26.6667V5.33338L23.5333 8.46671Z"
                    fill="#A31B1B"
                  />
                </svg>
              </button>
            </div>
            <h1 className="flex justify-between items-center mb-4 px-2 mt-4">
              <div className=" items-center flex flex-row"></div>
            </h1>
          </div>
        </div>
        <div>
          {" "}
          <div className=" mx-auto px-1 lg:px-4 py-6 overflow-x-scroll lg:overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-sm">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-black">
                    <div
                      id="UserRolesDivuserRole"
                      className="flex text-[18px] justify-between"
                      onClick={handleToggleText}
                    >
                      <div className="justify-start pt-2">User Role</div>
                    </div>
                  </th>
                </tr>
              </thead>
              {FullRoles?.map((role, index) => (
                <>
                  <thead>
                    <tr className="bg-red-100">
                      <th className="px-6 py-3 text-left text-sm font-medium text-black">
                        <div className="flex justify-between">
                          <div
                            id="UserRolesDivadmin"
                            className="flex items-center justify-evenly"
                          >
                            <div>{role?.userRoleName}</div>
                            <div>
                              <span className="">
                                {dropdownStates[index] ? (
                                  <svg
                                    id="UserRolesSvgadminIcon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    onClick={() => {
                                      toggleDropdown(index);
                                      setIndex(index);
                                    }}
                                    className="rotate-180 transition-transform"
                                  >
                                    <path
                                      d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z"
                                      fill="black"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    id="UserRolesSvgadminIcon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    onClick={() => {
                                      toggleDropdown(index);
                                    }}
                                    className="transition-transform"
                                  >
                                    <path
                                      d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z"
                                      fill="black"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="justify-end ">
                            <div className="justify-between">
                              {!isEditable ? (
                                <button
                                  onClick={() => {
                                    handleUpdateMethod(
                                      true,
                                      role?.userRoleName,
                                      index
                                    );
                                    setIndex(index);
                                  }}
                                  className="pl-3 pt-2"
                                >
                                  <span>
                                    <img src={EditIcon} />
                                  </span>
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleUpdateMethod(
                                      false,
                                      role?.userRoleName
                                    );
                                    setIndex(index);
                                  }}
                                  className="pl-3 pt-4"
                                >
                                  <span>
                                    <img src={BlueRoundCrossIcon} />
                                  </span>
                                </button>
                              )}
                              {
                                <button
                                  id="BtnDeleteRole"
                                  onClick={() => {
                                    setConfirmBox(true);
                                    setIsRoletoDelete(role?.userRoleName);
                                  }}
                                  className=" pl-2 text-[#9B1C1C] disabled:text-[#D88C8C]"
                                >
                                  <svg
                                    id="BtnDeleteAppIcon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6 "
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </th>
                      <td className="px-6 py-4 flex justify-end"></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white">
                      <td colSpan="1">
                        {dropdownStates[index] && (
                          <div className="bg-white  w-full">
                            <div
                              id="UserRolesDivadminRole"
                              className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500"
                            >
                              {`${role?.userRoleName} Role listing`}
                            </div>
                            <div className="overflow-x-auto max-w-full">
                              <table className="min-w-full bg-white">
                                <thead>
                                  <tr>
                                    {role?.roleActions?.map((Admin, index) => {
                                      return Object.entries(Admin).map(
                                        ([key, value], subIndex) => (
                                          <th
                                            id="UserRolesThadmin"
                                            key={subIndex}
                                            className="px-3 py-2 text-center text-xs font-medium text-gray-500"
                                          >
                                            {key}
                                          </th>
                                        )
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="bg-white">
                                    {role?.roleActions?.map((Admin, index) => {
                                      return Object.entries(Admin).map(
                                        ([key, value], subIndex) => (
                                          <td
                                            id="UserRolesTdindex"
                                            key={`${index}-${subIndex}`}
                                            align="center"
                                            className="px-6 py-4"
                                          >
                                            {value ? (
                                              <svg
                                                id="UserRolesSvgadminIcon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                              >
                                                <path
                                                  d="M9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5ZM7.5 12.75L3.75 9L4.8075 7.9425L7.5 10.6275L13.1925 4.935L14.25 6L7.5 12.75Z"
                                                  fill="#008767"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                id="UserRolesSvgdoctorRolesIcon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-4 w-4"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                />
                                              </svg>
                                            )}
                                          </td>
                                        )
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userroles;
