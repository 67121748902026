import React, { useEffect, useState } from 'react';
import store from '../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../utils/DataDecode';
import { setNotification } from '../Redux/features/toast/toastSlice';
import 'react-phone-input-2/lib/style.css';
import "react-datepicker/dist/react-datepicker.css";
import { theme } from '../EmraxisTheme/Theme';

const AddDepartment = ({ isOpen, onClose, setRefreshData, department }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    department_name: '',
  });
  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
  const [newDepartment, setNewDepartment] = useState({
    department_id: 0,
    department_name: '',
  });
  const [submitDisable, setSubmitDisable] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setNewDepartment({
        department_id: (department && department?.department_id) ? department?.department_id : 0,
        department_name: (department && department?.department_name) ? department?.department_name : '',
      });
    }, 0);
  }, [department]);

  if (!isOpen) return null;
  function createDepartment() {
    if (!validateForm()) {
      dispatch(setNotification({ message: '* Department Name is required!', status: 'error', action: true }));
    }
    else {
      setSubmitDisable(true);
      if (newDepartment.department_id == 0) {
        store.AddDepartment(loggedusertoken, DataDecode.encryptPayload(newDepartment))
          .then((result) => {
            setSubmitDisable(false);
            if (result.status == 201) {
              store.CreateActivityLogs(loggeduser, "Settings", `Department Created - for ${newDepartment.department_name}`, "Create");
              dispatch(setNotification({ message: 'Department Created Successfully!', status: 'success', action: true }));
              setRefreshData(r => !r);
              onClose();
            }
            else {
              dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
            }
          })
          .catch((error) => {
            console.error("Error occurred while saving department details:", error);
            onClose();
          });

      }
      else {
        store.UpdateDepartment(loggedusertoken, DataDecode.encryptPayload(newDepartment))
          .then((result) => {
            setSubmitDisable(false);
            if (result.status == 204) {
              store.CreateActivityLogs(loggeduser, "Settings", `Department Updated - for ${newDepartment.department_name}`, "Update");
              dispatch(setNotification({ message: 'Department Updated Successfully!', status: 'success', action: true }));
              setRefreshData(r => !r);
              onClose();
            }
            else {
              dispatch(setNotification({ message: 'No Changes to Update!', status: 'info', action: true }));
            }

          })
          .catch((error) => {
            console.error("Error occurred while Updating department details:", error);
            onClose();
          });
      }
    }
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = { department_name: ''};

    if (!newDepartment.department_name) {
      newErrors.department_name = 'Name is required';
      valid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(newDepartment.department_name)) {
      newErrors.department_name = 'Name must contain only letters ';
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleChange = (event) => {
    setNewDepartment({
      ...newDepartment,
      clinic_guid: loggeduser.facilityID,
      [event.target.name]: event.target.value
    });
  };
  return (
    <div className="fixed inset-0 flex justify-center items-center p-8 z-50">
      <div className="absolute inset-0 bg-black opacity-25 "></div>
      <div className="relative bg-white shadow-xl p-8 max-h-[80vh] lg:max-h-[70vh] 2xl:max-h-[70vh] overflow-y-auto " style={{ width: "600px" }} >
        <div className="flex items-center justify-between ">
          <div className="text-black font-bold text-2xl sm:mb:6">{newDepartment.department_id == 0 ? <span id="AddDepartmentSpanaddNewDepartment">Add New Department</span> : <span id="AddDepartmentSpanupdateDepartment">Update Department</span>}</div>
          <button className="text-red-800 sm:justify-left" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10 ">
              <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </button>
        </div>
        <form>
          <div className="block lg:flex w-[100%] ">
            <div className=" flex-grow w-[100%] lg:w-[50%] lg:pr-2 pb-2">
              <label id="AddDepartmentLabeldepartmentName" htmlFor="name" className="block text-gray-700 mb-2 sm:w-auto sm:text-left lg:w-auto">Department Name *</label>
              <div className="relative">
                <input  type="text" id="AddDepartmentInputdepartmentName" name="department_name" value={newDepartment.department_name} onChange={(event) => { handleChange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2" style={{ paddingLeft: '10px' }} />
                {errors.department_name && <p id="AddDepartmentPdepartmentName" className="text-red-500 text-sm">{errors.department_name}</p>}
              </div>
            </div>
          </div>
        </form>
        <div className="flex space-x-4 justify-end mt-5">
          <button id="AddDepartmentBtncancel" disabled={submitDisable} className={`px-4 py-2 border ${previewTheme.cancelBtnColor} rounded-md ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}  onClick={onClose}>Cancel</button>
          <button id="AddDepartmentBtnsave" className={`px-4 py-2 border ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} rounded-md`} onClick={createDepartment}>Save
            {submitDisable &&
              <svg id="AddDepartmentSvgsubmit" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
              </svg>
            }
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddDepartment;