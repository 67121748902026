import React, { useState, useEffect } from 'react';
import store from '../../API/store';
import { useSelector, useDispatch } from 'react-redux';
import { ChromePicker } from 'react-color';
import PhoneInput from '../PhoneInput';
import DataDecode from '../../utils/DataDecode';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import AddMembers from '../Usermangement/AddMembers';
import UploadLogo from './UploadLogo';
import { FaWhatsapp } from "react-icons/fa";
import {
    addFacilityName,
    addFacilityID,
    addFacilityEmail,
    addFacilityColor,
    addFacilityLogo,
} from "../../Redux/features/facility/facilitySlice";
import { theme } from '../../EmraxisTheme/Theme';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddWhatsApp from './AddWhatsApp';

const AddFacility = ({ isOpen, onClose, facilityguid, setRefreshData, setShowLimitPopup, userLimitPopup }) => {
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [openPicker, setOpenPicker] = useState(false);
    const [phone, setPhone] = useState("");
    const [countryCodeDial, setCountryCodeDial] = useState("")
    const [emailErr, setEmailErr] = useState({ emailEmpty: false, emailValidation: false })
    const [errEmailFlag, setEmailErrFlag] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alerType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState("");
    const [showuserPopup, setShowuserPopup] = useState(false);
    const [facility, setfacility] = useState(null);
    const [confirmBox, setConfirmBox] = useState(false);
    const [deleteLogoId, setDeleteLogoId] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const [isUploadLogo, setIsUploadLogo] = useState(false);
    const [file, setFile] = useState(null);
    const [fileRefresh, setFileRefresh] = useState(false);
    const [openTime, setOpenTime] = useState(null);
    const [closeTime, setCloseTime] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const handleOpenPopup = () => {
        if (form.phonenumber_id) {
            dispatch(setNotification({ message: ('Onboarding Completed!'), status: 'info', action: true }));
        } else {
            setIsPopupOpen(true);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };


    const dispatch = useDispatch();
    const [form, setState] = useState({
        facility_id: 0,
        facility_name: "",
        email_id: "",
        facility_plan_id: 1,
        facility_central_state: "",
        facility_admin: "",
        facility_type: "General",
        facility_about: "",
        phonenumber: phone,
        facility_address: "",
        facility_state: "",
        facility_district: "",
        facility_pincode: "",
        facility_logo: "",
        facility_color: "#d3d3d3",
        open_time: null,
        close_time: null,
        user_limit: 5,
        phonenumber_id: 0
    })
    const [facilityErr, setFacilityErr] = useState(false)
    useEffect(() => {
        //getting facility data
        if (facilityguid != "") {
            fetchFacilityData();
        }

    }, [facilityguid != undefined, fileRefresh])
    /*   if (!isOpen) return null; */

    const clearFacilityForm = () => {
        setState({
            ...form,
            facility_id: 0,
            facility_name: "",
            email_id: "",
            facility_plan_id: 1,
            facility_central_state: "",
            facility_admin: "",
            facility_type: "General",
            facility_about: "",
            phonenumber: "",
            facility_address: "",
            facility_state: "",
            facility_district: "",
            facility_pincode: "",
            facility_logo: "",
            facility_color: "#d3d3d3",
            open_time: null,
            close_time: null,
            user_limit: 0,
            phonenumber_id: 0
        });
    }
    const onBlurFacilityName = () => {
        if ((form.facility_name === "" || form.facility_name === undefined || form.facility_name === null)) {
            setFacilityErr(true)
        } else {
            setFacilityErr(false)
        }
    }
    if (!isOpen) onClose();
    const onBlurEmail = () => {
        EmailValidation();
    }
    const EmailValidation = () => {
        let err = false;
        var emails = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (form.email_id === "") {
            if (form.email_id === "" || form.email_id === undefined || form.email_id === null) {
                setEmailErr({ ...emailErr, emailEmpty: true, emailValidation: false })
                err = true
            } else if (form.email_id.match(emails)) {
                setEmailErr({ ...emailErr, emailValidation: false, emailEmpty: false })
                err = false
            } else {
                setEmailErr({ ...emailErr, emailValidation: true, emailEmpty: false })
                err = true
            }
        }
        else {
            if (form.email_id.match(emails)) {
                setEmailErr({ ...emailErr, emailValidation: false, emailEmpty: false })
                err = false
            } else {
                setEmailErr({ ...emailErr, emailValidation: true, emailEmpty: false })
                err = true
            }
        }
        setEmailErrFlag(err)
    }

    let Phone;
    const onPhonechange = (value, data) => {
        let dots = data.format.split('')
        // let filtred = dots.filter((val, index) => val === '.');
        setPhone(value);
        setCountryCodeDial(data.dialCode)
        setState({
            ...form,
            phonenumber: value
        })
    }
    Phone = <PhoneInput
        phone={form.phonenumber}
        setPhone={setPhone}
        onPhonechange={onPhonechange}

    // onBlurPhone={onBlurPhone}
    />
    const openPopup = (e) => {
        e.preventDefault();
        setOpenPicker(true);
    };

    function chooseImage() {
        document.getElementById('uploadimage') != null && document.getElementById('uploadimage').click();
    }

    const handleColorChange = (newColor) => {
        setState({
            ...form,
            facility_color: newColor.hex
        });
    };

    const parseTimeString = (timeString) => {
        if (!timeString) return null;

        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return null;

        const date = new Date();
        date.setHours(hours, minutes, seconds, 0);
        return isNaN(date.getTime()) ? null : date;
    };

    const fetchFacilityData = async () => {
        await store.GetFacilityinfo(loggedusertoken, DataDecode.encryptPayload(facilityguid))
            .then((result) => {

                if (result.status == 201) {

                    result.json().then((res) => {
                        let response = JSON.parse(DataDecode.decryptResponse(res));
                        setState(response);
                        setOpenTime(response?.open_time ? parseTimeString(response.open_time) : null)
                        setCloseTime(response?.close_time ? parseTimeString(response.close_time) : null)
                    })

                }
                else {
                    onClose();
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }



    const clicksave = async (event) => {
        event.preventDefault();
        form.phonenumber = phone;
        form.facility_name === "" ? setFacilityErr(true) : setFacilityErr(false);
        EmailValidation();
        if (facilityErr == false && errEmailFlag == false) {
            setDisableButton(true);
            const formData = new FormData();
            if (file) {
                formData.append("files", file);
            }
            setTimeout(async () => {
                let encData = JSON.parse(DataDecode.encryptPayload(form));
                formData.append('iv', encData.iv);
                formData.append('payload', encData.payload);
                await store.SaveFacilityDetails(loggedusertoken, formData)
                    .then((result) => {
                        setShowAlert(true);
                        setDisableButton(false);
                        if (result.status == 201) {
                            result.json().then((res) => {
                                if (facilityguid == "") {
                                    dispatch(addFacilityName(form.facility_name));
                                    dispatch(addFacilityID(form.facility_id));
                                    dispatch(addFacilityEmail(form.email_id));
                                    dispatch(addFacilityColor(form.facility_color));
                                    dispatch(addFacilityLogo(form.facility_logo));

                                    setfacility(res);
                                    clearFacilityForm();
                                    //redirecting the user to invite user screen
                                    setShowuserPopup(true);
                                    dispatch(setNotification({ message: ('Facility Created!'), status: 'success', action: true }));
                                }
                                else {
                                    dispatch(setNotification({ message: ('Facility Updated!'), status: 'success', action: true }));
                                    onClose();
                                    if(userLimitPopup)
                                        {
                                        setShowLimitPopup(false);
                                    }
                                }
                                setRefreshData(r => !r)
                                //setAlertType('success');
                                //setAlertMessage(facilityguid == "" ? 'Facility Created Successfully' : 'Facility Updated Successfully');
                            })
                        }
                        else {
                            dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                        }
                    })
                    .catch(err => {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    })
            }, 100)
        }
        else {

        }

    }

    function clickcancel(event) {
        event.preventDefault();
        alert('Are you sure you want to cancel?');
    }

    const handlerchange = (e) => {

        if (facilityguid != "") {
            if (e.target.name == "facility_name" || e.target.name == "email_id") {
                setState({
                    ...form,
                    [e.target.name]: e.target.name == "facility_name" ? form.facility_name : form.email_id
                });
            }
            else {
                setState({
                    ...form,
                    [e.target.name]: e.target.value
                });
            }
        }
        else {
            setState({
                ...form,
                [e.target.name]: e.target.value
            });
        }
    }

    const deleteLogo = (del_data) => {
        setDeleteLogoId(del_data.facility_id);
        setConfirmBox(true);
    }

    const handleDeleteSubmit = () => {
        setDisableButton(true);
        store.deleteLogo(loggedusertoken, DataDecode.encryptPayload({ 'facility_id': deleteLogoId }))
            .then((result) => {
                if (result.status == 204) {
                    setRefreshData(r => !r);
                    setDisableButton(false);
                    setConfirmBox(false);
                    setFileRefresh(o => !o);
                    setState({ ...form, facility_logo: "" })
                    dispatch(setNotification({ message: 'Logo Deleted Successfully!', status: 'success', action: true }));
                } else {
                    dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    const handleTimeChange = (time, status) => {
        if (time) {
            const selectedTime = new Date(time);
            const timeString = selectedTime.toTimeString().split(' ')[0];
            if (status === "open") {
                setOpenTime(selectedTime)
                setCloseTime(null)
                setState({ ...form, open_time: timeString, close_time: null })
            } else {
                setCloseTime(selectedTime)
                setState({ ...form, close_time: timeString })
            }
        }
        else if (time === null && status === "open") {
            setOpenTime(null)
            setCloseTime(null)
            setState({ ...form, open_time: null, close_time: null })
        }
        else if (time === null && status === "close") {
            setCloseTime(null)
            setState({ ...form, close_time: null })
        }
    };

    // Filter times for End Time
    const filterCloseTime = (currentTime) => {
        if (!openTime) {
            return true; // Allow all times if no open time is selected
        }
        const hours = currentTime.getHours();
        const hoursOpen = openTime.getHours();
        const minutes = currentTime.getMinutes();
        const minutesOpen = openTime.getMinutes();

        const isStartSlot = (hoursOpen === hours && minutes >= minutesOpen) || (hours > hoursOpen && hours < 24) || (hours === 24 && minutes <= minutesOpen);

        return isStartSlot
    };

    return (
        <>
            {confirmBox &&
                <div id="AddFacilityDivconfirmBoxAddFacility" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] z-[999999]">
                    <div id="AddFacilityDivconfirmBoxAddFacilityContentDialog" className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div id="AddFacilityDivconfirmBoxAddFacilityContentDialogBox" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="AddFacilitySvgconfirmBoxAddFacilityIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p id="AddFacilityPconfirmBoxAddFacilityMessage" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Logo?</p>
                            <div id="AddFacilityDivconfirmBoxAddFacilityButtons" className="flex justify-center items-center space-x-4">
                                <button id="AddFacilityButtonconfirmBoxAddFacilitycancel" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                    No, cancel
                                </button>
                                <button id="AddFacilityButtonconfirmBoxAddFacilityconfirm" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                    Yes, I'm sure {disableButton &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showuserPopup && facilityguid == "" ? <AddMembers isOpen={showuserPopup} onClose={() => { setShowuserPopup(false); onClose() }} facility={facility} /> :
                <>


                    {/* <ToastMessage type={alerType} message={alertMessage} show={showAlert} setShow={setShowAlert} /> */}
                    <div id="AddFacilityDivaddFacility" className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
                        <div id="AddFacilityDivoverlay" className="absolute inset-0 bg-black opacity-25"></div>
                        <div id="AddFacilityDivContent" className="relative bg-white shadow-xl p-8 lg:mt-[3%] max-h-[80vh] overflow-y-auto lg:mt-[2%] " style={{ width: "700px" }}>
                            <div id="AddFacilityDivheader" className="flex justify-between items-center mb-6">
                                <div id="AddFacilityDivtitle" className={` flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Facility Details</div>
                                <button id="AddFacilityBtncloseConfirmBox" className="text-red-700" onClick={onClose}>
                                    <svg id="AddFacilitySvgcloseIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </button>
                            </div>
                            <form id="AddFacilityForm">
                                <div id="AddFacilityDivfacilityName" className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-2 xs:gap-1">
                                    <div id="AddFacilityDivfacilityNameInput" className="p-2">
                                        <label id="AddFacilityLabelfacilityName" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Facility Name</label>{" "}<span className="text-red-500 text-xl font-semibold">*</span>
                                        <input id="AddFacilitySpanfacilityNameRequired" type="text" name="facility_name" value={form.facility_name} placeholder="Enter your Facility Name" onBlur={onBlurFacilityName} onChange={(event) => { handlerchange(event); setFacilityErr(false) }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>
                                        {facilityErr && (<p id="AddFacilityPfacilityNameError" className='text-red-500 text-sm xs:mt-1' >Facility Name is Required</p>)}

                                    </div>
                                    <div id="AddFacilityDivfacilityType" className="p-2">
                                        <label id="AddFacilityLabelfacilityType" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Facility Type</label>{" "}<span id="AddFacilitySpanFacilityType" className="text-red-500 text-xl font-semibold"></span>
                                        <input type="text" id="AddFacilityfacility_type" name="Inputfacility_type" placeholder='Enter your Facility Type' value={form.facility_type} onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>
                                    </div>
                                </div>


                                <div id="AddFacilityDivfacilityContact" className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-2 xs:gap-1">
                                    <div id="AddFacilityDivfacilityMobile" className="p-2">
                                        <label id="AddFacilityLabelfacilityMobile" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Mobile</label>{" "}<span id="AddFacilitySpanfacilityMobile" className="text-red-500 text-xl font-semibold"></span>
                                        {Phone}
                                    </div>
                                    <div id="AddFacilityDivfacilityEmail" className="p-2 relative">
                                        <label id="AddFacilityLabelfacilityEmail" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Email</label>{" "}<span id="AddFacilitySpanFacilityEmailRequired" className="text-red-500 text-xl font-semibold">*</span>
                                        <input id="AddFacilityInputfacilityEmail" type="email" name="email_id" value={form.email_id} placeholder="Enter your Email Address" onChange={(event) => { handlerchange(event); setEmailErr({ ...emailErr, emailValidation: false, emailEmpty: false }) }} onBlur={onBlurEmail}

                                            className="sm:mb-0 block w-full px-[14px] py-[6px] bg-white border border-[#CACACA] rounded  shadow-sm placeholder-[#B7B7B7]
                                        focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black" />

                                        <svg id="AddFacilitySvgfacilityEmailIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute top-[54px] right-3 -translate-y-1/2 text-gray-500">
                                            <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                                        </svg>
                                        {emailErr.emailEmpty && (<p id="AddFacilityPfacilityEmailErrorEmpty" className='text-red-500 text-sm' >Email ID is Required</p>)}
                                        {emailErr.emailValidation && (<p id="AddFacilityPfacilityEmailErrorInvalid" className='text-red-500 text-sm'>Please enter Eg:(abc@xyzcorp.com)</p>)}

                                    </div>
                                </div>

                                <div id="AddFacilityDivfacilityTime" className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-2 xs:gap-1">
                                    <div id="AddFacilityDivfacilityTimeStartSlots" className="p-2">
                                        <label id="AddFacilityLabelfacilityOpenTime" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Facility Open Time</label>
                                        <div id="AddAppointmentDivInputOpenTime" className="relative">
                                            <DatePicker
                                                id="AddFacilityOpenTime"
                                                selected={openTime}
                                                onChange={(e) => handleTimeChange(e, "open")}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="hh:mm aa"
                                                timeIntervals={10}
                                                className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                                                timeCaption="Time"
                                                dateFormat="hh:mm aa"
                                                placeholderText="Start Time"
                                            />
                                            <svg id="AddFacilitySvgfacilityTimeStartSlots" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400">
                                                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM18 12C18 12.1989 17.921 12.3897 17.7803 12.5303C17.6397 12.671 17.4489 12.75 17.25 12.75H12C11.8011 12.75 11.6103 12.671 11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12V6.75C11.25 6.55109 11.329 6.36032 11.4697 6.21967C11.6103 6.07902 11.8011 6 12 6C12.1989 6 12.3897 6.07902 12.5303 6.21967C12.671 6.36032 12.75 6.55109 12.75 6.75V11.25H17.25C17.4489 11.25 17.6397 11.329 17.7803 11.4697C17.921 11.6103 18 11.8011 18 12Z" fill="#666666" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div id="AddFacilityDivfacilityTimeCLoseSlots" className="p-2">
                                        <label id="AddFacilityLabelfacilityMobile" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Facility Close Time</label>
                                        <div id="AddAppointmentDivInputCloseTime" className="relative">
                                            <DatePicker
                                                id="AddFacilityCloseTime"
                                                selected={closeTime}
                                                onChange={(e) => handleTimeChange(e, "close")}
                                                disabled={openTime === null ? true : false}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="hh:mm aa"
                                                timeIntervals={10}
                                                className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                                                timeCaption="Time"
                                                dateFormat="hh:mm aa"
                                                placeholderText="Close Time"
                                                filterTime={filterCloseTime}
                                            />
                                            <svg id="AddFacilitySvgfacilityTimeCloseSlots" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400">
                                                <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM18 12C18 12.1989 17.921 12.3897 17.7803 12.5303C17.6397 12.671 17.4489 12.75 17.25 12.75H12C11.8011 12.75 11.6103 12.671 11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12V6.75C11.25 6.55109 11.329 6.36032 11.4697 6.21967C11.6103 6.07902 11.8011 6 12 6C12.1989 6 12.3897 6.07902 12.5303 6.21967C12.671 6.36032 12.75 6.55109 12.75 6.75V11.25H17.25C17.4489 11.25 17.6397 11.329 17.7803 11.4697C17.921 11.6103 18 11.8011 18 12Z" fill="#666666" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div id="AddFacilityDivfacilityAbout" className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                                    <div id="AddFacilityDivfacilityAboutInput" className="p-2">
                                        <label id="AddFacilityLabelfacilityAbout" className='font-sans text-[15px] sm:text-[16px] font-semibold'>About</label>{" "}<span id="AddFacilitySpanfacilityAbout" className="text-red-500 text-xl font-semibold"></span>
                                        <input id="AddFacilityInputfacilityAbout" type="text" name="facility_about" placeholder="Enter About" value={form.facility_about} onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>

                                    </div>
                                    <div id="AddFacilityDivfacilityuserLimitInput" className="p-2">
                                        <label id="AddFacilityLabelfacilityuserLimit" className='font-sans text-[15px] sm:text-[16px] font-semibold'>User Limit</label>{" "}<span id="AddFacilitySpanfacilityuserLimit" className="text-red-500 text-xl font-semibold">*</span>
                                        <input id="AddFacilityInputfacilityuserLimit" type="number" name="user_limit" placeholder="Enter User Limit" value={form.user_limit} onChange={(event) => { handlerchange(event);}} onWheel={(e) => e.preventDefault()} step="1"
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>
                                    </div>
                                </div>


                                <div id="AddFacilityDivfacilityCentralState" className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                                    <div id="AddFacilityDivfacilityGovtType" className="p-2">
                                        <label id="AddFacilityLabelfacilityGovtType" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Central/State Govt</label>{" "}<span id="AddFacilitySpanfacilityGovtType" className="text-red-500 text-xl font-semibold"></span>
                                        <input id="AddFacilityInputfacilityGovtType" type="text" name="facility_central_state" value={form.facility_central_state} placeholder="Enter your Central/State Govt" onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>

                                    </div>
                                    <div id="AddFacilityDivfacilityState" className="p-2">
                                        <label id="AddFacilityLabelfacilityStateType" className='font-sans text-[15px] sm:text-[16px] font-semibold'>State</label>{" "}<span id="AddFacilitySpanfacilityStateType" className="text-red-500 text-xl font-semibold"></span>
                                        <input id="AddFacilityInputfacilitystateType" type="text" name="facility_state" placeholder='Enter your State' value={form.facility_state} onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>
                                    </div>
                                </div>

                                <div id="AddFacilityDivfacilityDistrict" className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                                    <div className="p-2">
                                        <label id="AddFacilityLabelfacilityDistrictType" className='font-sans text-[15px] sm:text-[16px] font-semibold'>District</label>{" "}<span id="AddFacilitySpanfacilityDistrictType" className="text-red-500 text-xl font-semibold"></span>
                                        <input type="text" id="AddFacilityInputfacilityDistrict" name="facility_district" value={form.facility_district} placeholder="Enter your District" onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>

                                    </div>
                                    <div id="AddFacilityDivfacilityPincode" className="p-2">
                                        <label id="AddFacilityLabelfacilityDistrictType" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Pin Code</label>{" "}<span id="AddFacilitySpanfacilityPincodeType" className="text-red-500 text-xl font-semibold"></span>
                                        <input id="AddFacilityInputfacilityPincode" type="text" name="facility_pincode" placeholder='Enter your Pin Code' value={form.facility_pincode} onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>
                                    </div>
                                </div>

                                <div className="grid lg:grid-cols-1 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                                    <div id="AddFacilityDivfacilityAddress" className="p-2">
                                        <label id="AddFacilityLabelfacilityAddressType" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Address</label>{" "}<span id="AddFacilitySpanfacilityAddressType" className="text-red-500 text-xl font-semibold"></span>
                                        <input type="text" id="AddFacilityInputfacilityAddress" name="facility_address" placeholder="Enter your Address" value={form.facility_address} onChange={(event) => { handlerchange(event); }}
                                            className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"/>

                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                                    <div id="AddFacilityDivfacilityPlan" className="p-2">
                                        <label id="AddFacilityLabelfacilityPlan" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Plan</label>{" "}<span id="AddFacilitySpanfacilityPlan" className="text-red-500 text-xl font-semibold">*</span>
                                        <select id="AddFacilitySelectdropDown" type="text" disabled aria-readonly className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black">
                                            <option id="AddFacilityOptiontrialPlan">Trial plan</option>
                                            <option id="AddFacilityOptionbase">Base</option>
                                        </select>
                                    </div>
                                    <div className="p-2">
                                        <label id="AddFacilityLabeldisplayColor" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Display Color</label>{" "}<span className="text-red-500 text-xl font-semibold"></span>
                                        <div className="flex justify-between mt-1 sm:mb-0 w-full px-[14px] py-[7px] bg-white border border-[#CACACA] rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black">
                                            {openPicker == false ?
                                                <div className='flex justify-start w-full'>
                                                    <div className='w-[50%]'>
                                                        <button id="AddFacilityBtnchooseColor" className='text-nowrap' onClick={(e) => { openPopup(e) }}>Choose Color</button>
                                                    </div>
                                                    <div id="AddFacilityDivfacilityColor" className='w-full ml-2' style={{ backgroundColor: form.facility_color }}></div>
                                                </div>
                                                :
                                                <div className='flex justify-start'>
                                                    <ChromePicker id="AddFacilityChromePickercolorChange" color={form.facility_color} onChange={(e) => { handleColorChange(e); }} />
                                                    <button id="AddFacilityBtnopenColorPicker" type='button' onClick={() => { setOpenPicker(false) }} className='ml-4 text-red-700 border-2 border-red-700 rounded-full size-6 text-center flex justify-center items-center z-[999999]'>
                                                        <svg id="AddFacilitySvgopenColorPickerIcon" xmlns="http://www.w3.org/2000/svg" strokeWidth={2} viewBox="0 0 24 24" fill="currentColor" className="size-4 text-center font-semibold">
                                                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>

                                <div className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                                    <div className={`p-2 ${form.facility_id ? 'lg:col-span-1' : 'lg:col-span-2'}`}>
                                        <div className="flex mt-1 sm:mb-0 w-full px-[14px] py-[7px] bg-white border border-[#CACACA] rounded shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black">
                                            {file ? (
                                                <div className="w-24 h-24 pr-2 pt-2">
                                                    <img id="AddFacilityImgfileUrl" src={URL.createObjectURL(file)} alt={file.name} className="h-auto max-w-full" />
                                                </div>
                                            ) : form.facility_logo ? (
                                                <div className="w-24 h-24 pr-2 pt-2 relative">
                                                    <img id="AddFacilityImgfileLogo" src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + form.facility_logo} alt={form.facility_name} className="object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-full h-auto" />
                                                    <button id="AddFacilityBtndeleteLogo" className="absolute top-2 right-2 bg-gray-100 rounded-full p-1 shadow" type="button" onClick={() => { deleteLogo(form); }}>
                                                        <svg id="AddFacilitySvgdeleteLogoIcon" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            ) : (
                                                <svg id="AddFacilitySvglogoIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-20 h-20 mr-2 bg-gray-300 rounded-sm p-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                                </svg>
                                            )}
                                            <div>
                                                <label id="AddFacilityLabeluploadImageText" className="block text-gray-700 mb-2 pl-2 italic text-base">
                                                    Please upload square image, size less than 100KB
                                                </label>
                                                <div className="relative pl-2">
                                                    <button id="AddFacilityBtnchooseImage" type="button" onClick={() => { setIsUploadLogo(true); }} className={`py-1 px-2 cursor-pointer ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md ml-4 hover:${previewTheme.saveBtnHoverColor}`}>
                                                        Choose Image
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {form.facility_id && (
                                        <div className="flex items-center justify-center p-2">
                                            <button
                                                type="button"
                                                className={`py-2 px-4 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md ml-4 hover:${previewTheme.saveBtnHoverColor} flex items-center`}
                                                onClick={handleOpenPopup}
                                            >
                                                <FaWhatsapp className="mr-1 mb-1" /> Enable WhatsApp Messaging
                                            </button>
                                            {isPopupOpen && <AddWhatsApp facilityguid={facilityguid} onClose={handleClosePopup} />}
                                        </div>
                                    )}
                                </div>

                            </form>
                            <div className="flex justify-between space-x-4">
                                <button id="AddFacilityBtnClose" disabled={disableButton} className={`px-4 py-2 border ${previewTheme.cancelBtnColor} rounded-md ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor} disabled:cursor-not-allowed disabled:opacity-50`} onClick={onClose}> Cancel</button>
                                <button id="AddFacilityBtnSave" disabled={disableButton} className={`px-4 py-2 border ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} rounded-md disabled:cursor-not-allowed disabled:opacity-50`}  onClick={(event) => clicksave(event)}>Save</button>
                            </div>
                        </div>

                    </div>
                    {isUploadLogo && <UploadLogo setIsUploadLogo={setIsUploadLogo} file={file} setFile={setFile} existingLogo={form.facility_logo} saveDirectly={false} setFileRefresh={setFileRefresh} facilityData={form} />}
                </>
            }
        </>
    );
};

export default AddFacility;