import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../../EmraxisTheme/Theme';
import WeekView from './WeekView';
import WorkWeekView from './WorkWeekView';
import { setAppGridViewSelection, setAppShowTodaySection, setIsDefaultGridView } from '../../../Redux/features/appointmentGridView/gridviewSlice';
import DateFunction from '../../../utils/DateFunctions';
import store from '../../../API/store';
import { setNotification } from '../../../Redux/features/toast/toastSlice';
import DataDecode from '../../../utils/DataDecode';
import { setAddAppointmentPopup } from '../../../Redux/features/popup/popupSlice';
import AddAppointment from '../AddAppointment';
import MonthView from './MonthView';
import DayView from './DayView';
import TodayView from './TodayView';
import { addLoginDetails } from '../../../Redux/features/login/loginSlice';
import AddPatient from '../../Patients/AddPatient';
import GenerateInvoice from '../../Invoice/GenerateInvoice';
import { setSideDateToShow } from '../../../Redux/features/appointmentGridView/gridViewTodaySlice';

const AppGridView = () => {
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const viewType = useSelector(state => state.appGridView.appGridViewSelection);
    const appShowTodaySection = useSelector(state => state.appGridView.appShowTodaySection);

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showAddAppointmentpopup = useSelector(state => state.popup.showAddAppointment);
    var showAddPatientpopup = useSelector((state) => state.popup.showAddPatient);
    const isDefaultGridView = useSelector(state => state.appGridView.isDefaultGridView);
    var patientAddAppointment = useSelector((state) => state.popup.setPatientAddAppointment);
    var showAppointmentInvoicePrint = useSelector((state) => state.popup.showAppointmentInvoicePrint);
    var sideDateToShow = useSelector((state) => state.appGridViewToday.sideDateToShow);

    const dispatch = useDispatch();
    const [appointments, setAppointments] = useState([]);
    const [todaysAppointments, setTodaysAppointments] = useState([])
    const [doctors, setDoctors] = useState([]);
    const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
    const [doctorsTimeslot, setDoctorsTimeSlot] = useState([]);
    const [sortType, setSortType] = useState("all");
    const [refreshData, setRefreshData] = useState(false);
    const [selectedEditAppointment, setSelectedEditAppointment] = useState(null);
    const [appointmentList, setAppointmentList] = useState([]);
    const [isChangesUpdate, setIsChangesUpdate] = useState(false);

    const [disableButton, setDisableButton] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [confirmBox, setConfirmBox] = useState(false);

    useEffect(() => {
        if (loggeduser && loggeduser.facilityID) {
            GetDoctors();
        }
    }, [loggeduser])

    useEffect(() => {
        GetAppointments();
    }, [loggeduser, refreshData])

    useEffect(() => {
        setTimeout(() => {
            if (isChangesUpdate) {
                UpdateAppointmentGrid();
            }
        }, 100)
    }, [viewType, appShowTodaySection])

    useEffect(() => {
        UpdateAppointmentGrid();
    }, [isDefaultGridView])

    useEffect(() => {
        if (patientAddAppointment?.isOpenAppointment) {
            dispatch(setAddAppointmentPopup(true));
        }
    }, [patientAddAppointment]);

    const UpdateAppointmentGrid = () => {
        let updateData = { appGridViewSelection: viewType, appShowTodaySection: appShowTodaySection, isDefaultGridView: isDefaultGridView };
        store.UpdateAppointmentGrid(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, user_id: loggeduser.user_details_id, appointment_grid_view: updateData }))
            .then((result) => {
                if (result.status === 200) {
                    dispatch(addLoginDetails({ ...loggeduser, appointment_grid_view: updateData }));
                    setIsChangesUpdate(false)
                }
            })
            .catch(error => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            });
    };

    const GetDoctors = () => {
        store.getDoctorForAppointment(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        setDoctors(data.drDetails);
                        setDoctorsTimeSlot(data.drDetailstimeSlots);
                    })
                } else {
                    setDoctors([])
                    setDoctorsTimeSlot([])
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    const GetAppointments = () => {
        setIsAppointmentLoading(true);
        store.getAppointments(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'currentDate': DateFunction.SetDbCurrentTimeStamp(), 'page': 0, 'sort_by': sortType }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var myObject = JSON.parse(response);
                        // Sort the appointments by appointment time (assuming it's in a sortable format)
                        let sortedAppointments = myObject.appointmentData.sort((a, b) => new Date(b.appointment_time) - new Date(a.appointment_time));
                        setAppointmentList(sortedAppointments);
                        setAppointments(sortedAppointments);
                        const today = new Date(sideDateToShow);
                        today.setHours(0, 0, 0, 0);

                        const filteredDates = sortedAppointments.filter((app) => {
                            const date = new Date(app.appointment_time);
                            date.setHours(0, 0, 0, 0); // Remove the time part from the date
                            return date.getTime() === today.getTime();
                        });
                        let sortedAppointmentTimes = filteredDates.sort((a, b) => new Date(`2024-10-05T${a.app_time}`) - new Date(`2024-10-05T${b.app_time}`));
                        setTodaysAppointments(sortedAppointmentTimes);
                    })
                } else {
                    setAppointmentList([])
                    setAppointments([]);
                    setTodaysAppointments([]);
                }
                setTimeout(() => {
                    setIsAppointmentLoading(false);
                }, 100)
            })
            .catch(err => {
                setIsAppointmentLoading(false);
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    const bookAppointment = () => {
        setSelectedEditAppointment(null)
        dispatch(setAddAppointmentPopup(true));
    }

    const handleSelectMonth = () => {
        setIsChangesUpdate(true);
        dispatch(setAppGridViewSelection("month"))
    }

    const handleTodaySection = sta => {
        setIsChangesUpdate(true)
        dispatch(setAppShowTodaySection(sta))
    }

    function searchAppointments(keyword) {
        keyword = keyword.toLowerCase();
        if (!keyword == "") {
            const results = appointmentList.filter((appointment) => {
                return (
                    appointment.patient_op_number.toLowerCase().includes(keyword) ||
                    (appointment?.FirstName + " " + appointment?.MiddleName + " " + appointment?.LastName)?.toLowerCase()?.includes(keyword) ||
                    appointment?.MiddleName?.toLowerCase()?.includes(keyword) ||
                    appointment?.LastName?.toLowerCase()?.includes(keyword) ||
                    appointment.mobile_number.toLowerCase().includes(keyword)
                );
            });
            const today = new Date(sideDateToShow);
            today.setHours(0, 0, 0, 0);

            const filteredDates = results.filter((app) => {
                const date = new Date(app.appointment_time);
                date.setHours(0, 0, 0, 0); // Remove the time part from the date
                return date.getTime() === today.getTime();
            });
            let sortedAppointmentTimes = filteredDates.sort((a, b) => new Date(`2024-10-05T${a.app_time}`) - new Date(`2024-10-05T${b.app_time}`));
            setTodaysAppointments(sortedAppointmentTimes);
            setAppointments(results);
        } else {
            setAppointments(appointmentList);
        }
    }

    useEffect(() => {
        const today = new Date(sideDateToShow);
        today.setHours(0, 0, 0, 0);

        const filteredDates = appointmentList.filter((app) => {
            const date = new Date(app.appointment_time);
            date.setHours(0, 0, 0, 0); // Remove the time part from the date
            return date.getTime() === today.getTime();
        });
        let sortedAppointmentTimes = filteredDates.sort((a, b) => new Date(`2024-10-05T${a.app_time}`) - new Date(`2024-10-05T${b.app_time}`));
        setTodaysAppointments(sortedAppointmentTimes);
    }, [sideDateToShow])

    const deleteAppointment = (app) => {
        setDeleteId(app);
        setConfirmBox(true);
    };

    const handleDeleteSubmit = () => {
        setDisableButton(true);
        store.deleteAppointment(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, appointment_id: deleteId.appointment_id })
        ).then((result) => {
            setDisableButton(false);
            setConfirmBox(false);
            setDisableButton(false);
            if (result.status == 204) {
                store.CreateActivityLogs(loggeduser, (isDefaultGridView ? "Appointment Grid View" : "Appointment List View"), `Appointment Deleted - ${(deleteId.doctor_name ? "for " + deleteId.doctor_name?.trim():"")} patient ${deleteId.FirstName} ${deleteId.MiddleName?.charAt(0)} ${deleteId.LastName}(${deleteId.patient_op_number}) on ${DateFunction.GetDate(deleteId.appointment_time)} ${(deleteId.app_time ? " at " + DateFunction.Convert12Hour(deleteId.app_time) : "")}`,"Delete");
                setRefreshData((r) => !r);                
                dispatch(setNotification({ message: "Appointment Deleted Successfully!", status: "success", action: true }));
            }else if(result.status == 409){
                dispatch(setNotification({ message: "Data available for the selected appointment, hence the appointment cannot be deleted!", status: "info", action: true }));
            }else{
                dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true }));
            }
        }).catch((err) => {
            dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true }));
        });
    };

    const [isHovered, setIsHovered] = useState(false); // State to manage hover

    return (

        <>
            {confirmBox && (
                <div id="AppointmentDivdeleteAppointment" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] z-50">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="AppointmentSvgdeleteIcon" className="text-[#9B1C1C] dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"                            >
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"
                                ></path>
                            </svg>
                            <p id="AppointmentPdeleteAppText" className={`mb-4 `} style={{ fontFamily: previewTheme.fontFamily }}>
                                Are you sure you want to delete this appointment?
                            </p>
                            <div id="AppointmentDivcancel" className="flex justify-center items-center space-x-4">
                                <button id="btnAppCancelCfmdDeleteFile" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none  ease-in-out ${previewTheme.cancelBtnColor}  ${previewTheme.cancelBtnBgColor} ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}`}>
                                    No, cancel
                                </button>
                                <button id="AppointmentBtnsure" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className={`py-2 px-3 text-sm font-medium text-center text-white rounded-lg  focus:ring-4 focus:outline-none focus:ring-red-300 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed`}>
                                    Yes, I'm sure{" "}
                                    {disableButton && (
                                        <svg id="AppointmentSvgsureIcon" aria-hidden="true"
                                            role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                            />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="mx-auto w-100% bg-white">
                <div className='px-4 py-2'>
                    <div className='sticky top-[4.8rem] bg-white z-20'>
                        <header className="flex justify-between items-center py-4 px-2">
                            <h1 id="AppointmentGridHeaderAppointment" className={`font-bold mb-2 text-2xl lg:text-2xl sm:text-sm pl-2.5 pt-1 mr-2 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Appointment</h1>

                            <div className='flex justify-end items-center  gap-2 '>
                                {isDefaultGridView ? (
                                    <button className={`p-2 disabled:cursor-not-allowed`} disabled={!isDefaultGridView} id='BtnListListViewButton' onClick={() => { dispatch(setIsDefaultGridView(false)) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20 7H4M15 12H4M9 17H4" stroke= "#282358" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                    </button>
                                ) : (
                                    <button className={`p-2 disabled:cursor-not-allowed`} disabled={isDefaultGridView} id='BtnListGridViewButton' onClick={() => { dispatch(setIsDefaultGridView(true)) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <g clipPath="url(#clip0_1767_3218)">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9 13C9.53043 13 10.0391 13.2107 10.4142 13.5858C10.7893 13.9609 11 14.4696 11 15V19C11 19.5304 10.7893 20.0391 10.4142 20.4142C10.0391 20.7893 9.53043 21 9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15C3 14.4696 3.21071 13.9609 3.58579 13.5858C3.96086 13.2107 4.46957 13 5 13H9ZM19 13C19.5304 13 20.0391 13.2107 20.4142 13.5858C20.7893 13.9609 21 14.4696 21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15C14.4696 21 13.9609 20.7893 13.5858 20.4142C13.2107 20.0391 13 19.5304 13 19V15C13 14.4696 13.2107 13.9609 13.5858 13.5858C13.9609 13.2107 14.4696 13 15 13H19ZM9 3C9.53043 3 10.0391 3.21071 10.4142 3.58579C10.7893 3.96086 11 4.46957 11 5V9C11 9.53043 10.7893 10.0391 10.4142 10.4142C10.0391 10.7893 9.53043 11 9 11H5C4.46957 11 3.96086 10.7893 3.58579 10.4142C3.21071 10.0391 3 9.53043 3 9V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9C21 9.53043 20.7893 10.0391 20.4142 10.4142C20.0391 10.7893 19.5304 11 19 11H15C14.4696 11 13.9609 10.7893 13.5858 10.4142C13.2107 10.0391 13 9.53043 13 9V5C13 4.46957 13.2107 3.96086 13.5858 3.58579C13.9609 3.21071 14.4696 3 15 3H19Z" fill="#282358" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1767_3218">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </button>
                                )}
                                <div className='flex justify-center items-center border shadow-md'>
                                    {(viewType === "1day" || viewType === "2days" || viewType === "3days" || viewType === "4days" || viewType === "5days" || viewType === "6days") ?
                                        <select id="SelectDayGridViewForDays" value={viewType}
                                            className={`${(viewType === "1day" || viewType === "2days" || viewType === "3days" || viewType === "4days" || viewType === "5days" || viewType === "6days") ? "bg-[#d9d9d9]" : "bg-white"} px-2 cursor-pointer border-0 ring-0 focus:border-0 focus:ring-0`}
                                            onChange={e => { setIsChangesUpdate(true); dispatch(setAppGridViewSelection(e.target.value)); dispatch(setSideDateToShow(new Date().toISOString())); }}
                                        >
                                            <option value="1day">Day</option>
                                            <option value="2days">2 Days</option>
                                            <option value="3days">3 Days</option>
                                            <option value="4days">4 Days</option>
                                            <option value="5days">5 Days</option>
                                            <option value="6days">6 Days</option>
                                        </select>
                                        :
                                        <button type="button" id="BtnDayGridViewForDays" className={`${viewType === "1day" ? "bg-[#d9d9d9]" : "bg-white"} px-2 py-2`}
                                            onClick={e => { setIsChangesUpdate(true); dispatch(setAppGridViewSelection("1day")); dispatch(setSideDateToShow(new Date().toISOString()));}}
                                        >
                                            Day
                                        </button>
                                    }
                                    <select id="SelectDayGridViewForWeek" value={viewType}
                                        className={`${(viewType === "week" || viewType === "work-week") ? "bg-[#d9d9d9]" : "bg-white"} px-2 cursor-pointer border-0 ring-0 focus:border-0 focus:ring-0`}
                                        onChange={e => { if (e.target.value !== "") { setIsChangesUpdate(true); dispatch(setAppGridViewSelection(e.target.value)); dispatch(setSideDateToShow(new Date().toISOString()));} }}>
                                        <option value="">Select Week</option>
                                        <option value="week">Week</option>
                                        <option value="work-week">Work Week</option>
                                    </select>
                                    <button type="button" id="SelectDayGridViewForMonth" className={`${viewType === "month" ? "bg-[#d9d9d9]" : "bg-white"} px-2 py-2`}
                                        onClick={handleSelectMonth}
                                    >
                                        Month
                                    </button>
                                </div>
                                <div id="AppointmentGridDivsearchContainer" className="relative items-center gap-2">
                                    <input id="AppointmentGridInputsearch" type="text" className="md:w-28 lg:w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search Id, Name, Phone"
                                        onChange={(e) => searchAppointments(e.target.value)}
                                    />
                                    <svg id="AppointmentSvgsearchIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                    </svg>
                                </div>
                                <div id="AppointmentGridDivbuttonContainer" className="">
                                    <button id="AppointmentButtonscheduleAppointment" onClick={bookAppointment} className={`py-2 truncate px-4 rounded-md flex text-sm items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                                            <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                                        </svg>
                                        Book Appointment
                                    </button>

                                </div>
                                <button id="AppointmentGridButtonreload" className="items-center" onClick={() => window.location.reload()}>
                                    <svg id="AppointmentGridButtonreloadIcon" xmlns="http://www.w3.org/2000/svg"
                                        className={`icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2  text-[#9B1C1C] hover:text-[#7A1414]`}
                                        width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                                        <path d="M20 4v5h-5" />
                                    </svg>
                                </button>
                            </div>

                        </header>
                    </div>
                    <section id="sectionGridViews" className='bg-white'>
                        <div className={`${appShowTodaySection ? 'flex flex-col md:flex-row' : 'flex flex-row'} bg-white`}>
                            <div className={`${appShowTodaySection ? 'flex-grow lg:basis-[70%] xl:basis-[80%]' : 'w-auto'} bg-white`}>
                                {viewType === "week" && <WeekView appointments={appointments} doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} deleteAppointment={deleteAppointment} />}
                                {viewType === "work-week" && <WorkWeekView appointments={appointments} doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} deleteAppointment={deleteAppointment} />}
                                {viewType === "month" && <MonthView appointments={appointments} doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} deleteAppointment={deleteAppointment} />}
                                {(viewType === "1day" || viewType === "2days" || viewType === "3days" || viewType === "4days" || viewType === "5days" || viewType === "6days")
                                    && <DayView appointments={appointments} doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} deleteAppointment={deleteAppointment} />
                                }
                            </div>
                            <div className={`${appShowTodaySection ? 'flex-grow lg:basis-[30%] xl:basis-[20%]' : 'w-auto'} bg-white mx-2`}>
                                <div className={`sticky top-[9.6rem] bg-[#E8E8E8] px-1 py-1 pb-2 scrollbar-hide h-[calc(100vh-10rem)] ${isHovered ? 'overflow-y-auto' : 'overflow-hidden'}`} id="todayDivSection"
                                    onMouseEnter={() => setIsHovered(true)} // Enable scroll on hover
                                    onMouseLeave={() => setIsHovered(false)} // Disable scroll when not hovered
                                >
                                    {appShowTodaySection ?
                                        <button id="SelectDayGridViewForTodayDisable" className='border-2 border-[#282358] p-0.5 m-1 rounded-e-lg' onClick={() => { handleTodaySection(false) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </button>
                                        :
                                        <button id="SelectDayGridViewForTodayEnable" className='border-2 border-[#282358] p-0.5 m-1 rounded-s-lg' onClick={() => { handleTodaySection(true) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                            </svg>
                                        </button>}
                                    {appShowTodaySection && <TodayView todaysAppointments={todaysAppointments} appointments={appointments} doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} deleteAppointment={deleteAppointment} />}
                                </div>
                            </div>
                        </div>

                    </section>
                    {showAddAppointmentpopup && <AddAppointment setRefreshData={setRefreshData} appointment={selectedEditAppointment} doctors={doctors} doctorsTimeslot={doctorsTimeslot} page="Appointment Grid View"/>}
                    {showAddPatientpopup && <AddPatient doctors={doctors} setRefreshData={setRefreshData} patient={{}} page="Grid View Book Appointment Popup"/>}
                    {showAppointmentInvoicePrint && <GenerateInvoice />}
                </div>
            </div>
        </>
    )
}

export default AppGridView