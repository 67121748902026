import React, { useState, useMemo, useEffect } from 'react';
import store from '../../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../overrides.css'
import DateFunction from '../../utils/DateFunctions';
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { theme } from '../../EmraxisTheme/Theme';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent";
import { setAddPatientPopup, setPatientAppointment, setRefreshAppointment,setAddAppointmentPopup} from '../../Redux/features/popup/popupSlice';

const AddPatient = ({ setRefreshData, doctors, patient, editFlag, page }) => {
    const dispatch = useDispatch();

    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var patientAddAppointment = useSelector(state => state.popup.setPatientAddAppointment);
    var refreshAppointment = useSelector(state => state.popup.refreshAppointment);
    const [loading, setLoading] = useState(false);
    const [originalPatient, setOriginalPatient] = useState(null);
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [newPatient, setNewPatient] = useState({
        patient_id: 0,
        FirstName: '',
        MiddleName: "",
        LastName: "",
        email_id: '',
        gender: '',
        dob: '',
        blood_group: '',
        mobile_number: '',
        status: 'New',
        address: '',
        patient_pic: ''
    });
    const [submitDisable, setSubmitDisable] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    // const [startDate, setStartDate] = useState(DateFunction.getDateTimeAppointment10Minutes());
    const [startDate, setStartDate] = useState(null);
    const [errors, setErrors] = useState({});
    const showToast = () => {

        setTimeout(() => {
            setToast({ show: false, message: '', type: '' });
        }, 3000);
    };
    useEffect(() => {
        setTimeout(() => {
            setNewPatient({
                patient_id: (patient && patient?.patient_id) ? patient?.patient_id : 0,
                FirstName: (patient && patient?.FirstName) ? patient?.FirstName : '',
                MiddleName: (patient && patient?.MiddleName) ? patient?.MiddleName : '',
                LastName: (patient && patient?.LastName) ? patient?.LastName : '',
                email_id: (patient && patient?.email_id) ? patient?.email_id : '',
                gender: (patient && patient?.gender) ? patient?.gender : '',
                dob: (patient && patient?.dob) ? patient?.dob : '',
                blood_group: (patient && patient?.blood_group) ? patient?.blood_group : '',
                mobile_number: (patient && patient?.mobile_number) ? patient?.mobile_number : '',
                address: (patient && patient?.address) ? patient?.address : '',
                patient_pic: (patient && patient?.patient_pic) ? patient?.patient_pic : '',
                status: 'New',
                clinic_guid: loggeduser.facilityID,
                patient_op_number: (patient && patient?.patient_op_number) ? patient?.patient_op_number : ''
            });
            setDateOfBirth((patient && patient?.dob) ? new Date(patient?.dob) : null);
        }, 0);
    }, [patient])

    // useEffect(() => {
    //     // Set default date time on mount
    //     setStartDate(DateFunction.getDateTimeAppointment10Minutes());
    // }, []);

    const [newAppointment, setNewAppointment] = useState({
        doctor_id: 0,
        appointment_time: null,
        appointment_type: 'First Time',
        purpose_of_visit: '',
    });

    useEffect(() => {
        setNewPatient({ ...newPatient, dob: DateFunction.SetDbDateOfBirth(dateOfBirth) })
    }, [dateOfBirth])

    const closeAddPopup = () => {
        dispatch(setAddPatientPopup(false));
         if (patientAddAppointment.path === "appointment"){
            dispatch(setAddAppointmentPopup(true));  
         }
    }

    const allDoctors = useMemo(() => {
        return doctors.flatMap(group => group.options);
    }, [doctors]);

    function validateFields() {
        let valid = true;
        const errors = { FirstName: '',MiddleName:'', LastName: '', mobile_number: '', email_id: '', gender:'' };
        if (!newPatient.FirstName) {
            errors.FirstName = 'First Name is required';
            valid = false;
        }
        if (!newPatient.LastName) {
            errors.LastName = 'Last Name is required';
            valid = false;
        }
        if (/^[a-zA-Z\s'-]+$/.test(newPatient.FirstName) == false) {
            errors.FirstName = 'First Name must contain only letters ';
            valid = false;
        }
        if (/^[a-zA-Z\s'-]+$/.test(newPatient.MiddleName) == false && newPatient.MiddleName.length > 0) {
            errors.MiddleName = 'Middle Name must contain only letters ';
            valid = false;
        }
        if (/^[a-zA-Z\s'-]+$/.test(newPatient.LastName) == false) {
            errors.LastName = 'Last Name must contain only letters ';
            valid = false;
        }
        if (newPatient.mobile_number.trim() === "") {
            errors.mobile_number = 'Mobile number is required';
        }


        if (newPatient.email_id && !/\S+@\S+\.\S+/.test(newPatient.email_id)) {
            errors.email_id = 'Email is invalid';
            valid = false;
        }
        if (!newPatient.gender) {
            errors.gender = 'Gender is required';
            valid = false;
        }

        setErrors(errors);
        return valid;
    }

    useEffect(() => {
        if (patient) {
            setOriginalPatient(patient);
            setNewPatient(patient);
        }
    }, [patient]);

    function hasPatientChanged() {
        return (
            originalPatient.FirstName !== newPatient.FirstName ||
            originalPatient.MiddleName !== newPatient.MiddleName ||
            originalPatient.LastName !== newPatient.LastName ||
            originalPatient.mobile_number !== newPatient.mobile_number ||
            originalPatient.email_id !== newPatient.email_id ||
            originalPatient.gender !== newPatient.gender ||
            originalPatient.dob !== newPatient.dob ||
            originalPatient.blood_group !== newPatient.blood_group ||
            originalPatient.address !== newPatient.address ||
            originalPatient.patient_pic !== newPatient.patient_pic
        );
    }

    function chooseImage() {
        document.getElementById('uploadimage').click();
    }

    const createPatient = () => {
        const error = validateFields();
        if (!error) {
            dispatch(setNotification({ message: 'Please fill the mandatory fields!', status: 'info', action: true }));
            return;
        }

        if (newPatient.FirstName.trim() === "" || newPatient.mobile_number.trim() === "") {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
            return;
        }
        if (originalPatient && !hasPatientChanged()) {
            dispatch(setNotification({ message: 'No Changes to Update!', status: 'info', action: true }));
            return;
        }

        setSubmitDisable(true);
        const formData = new FormData();

        if (file) {
            formData.append("files", file);
        }
        newPatient.mobile_number = newPatient.mobile_number;
        const encData = JSON.parse(DataDecode.encryptPayload({ patients: newPatient, appointments: newAppointment }));
        formData.append('iv', encData.iv);
        formData.append('payload', encData.payload);
        if (newPatient.patient_id === 0) {
            if (newAppointment.doctor_id > 0 && newAppointment.appointment_time === null) {
                dispatch(setNotification({ message: 'Please choose an appointment date or unselect doctor!', status: 'error', action: true }));
            } else {
                store.AddPatientDetails(loggedusertoken, formData)
                    .then((result) => {
                        setSubmitDisable(false);
                        if (result.status === 201) {
                            if (patientAddAppointment.path === "appointment" && newAppointment.appointment_time == null) {
                                result.json().then((res) => {
                                    let response = DataDecode.decryptResponse(res);
                                    var pat = JSON.parse(response);
                                    dispatch(setPatientAppointment({ isOpenAppointment: true, patient: pat, path: 'appointment' }));
                                    store.CreateActivityLogs(loggeduser, page, `Patient Created - for ${pat.FirstName} ${pat.MiddleName?.charAt(0)} ${pat.LastName}(${pat.patient_op_number})`, "Create");
                                });
                            } else {
                                result.json().then((res) => {
                                    let response = DataDecode.decryptResponse(res);
                                    var pat = JSON.parse(response);
                                    dispatch(setRefreshAppointment(!refreshAppointment));
                                    store.CreateActivityLogs(loggeduser, page, `Patient Created - for ${pat.FirstName} ${pat.MiddleName?.charAt(0)} ${pat.LastName}(${pat.patient_op_number})`, "Create");
                                });                                
                            }                            
                            dispatch(setNotification({ message: 'Patient Created Successfully!', status: 'success', action: true }));
                            setRefreshData(r => !r);
                            closeAddPopup();
                        } else if (result.status === 409) {
                            dispatch(setNotification({ message: 'Appointment Not Available!', status: 'info', action: true }));
                        } else {
                            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                        }
                    })
                    .catch((error) => {
                        setSubmitDisable(false);
                        setToast({ show: true, message: "Error occurred while saving Patient details", type: "error" });
                        showToast();
                        setLoading(false);
                        closeAddPopup();
                    });
            }
        } else {
            store.UpdatePatientDetails(loggedusertoken, formData)
                .then((result) => {
                    setSubmitDisable(false);
                    if (result.status === 204) {
                        dispatch(setNotification({ message: 'Patient Updated Successfully!', status: 'success', action: true }));
                        store.CreateActivityLogs(loggeduser, page, `Patient Updated - for ${newPatient.FirstName} ${newPatient.MiddleName?.charAt(0)} ${newPatient.LastName}(${newPatient.patient_op_number})`, "Update");
                        setRefreshData(r => !r);
                        closeAddPopup();
                    } else {
                        dispatch(setNotification({ message: 'No Changes to Update!', status: 'info', action: true }));
                    }
                })
                .catch((error) => {
                    setSubmitDisable(false);
                    setToast({ show: true, message: "Error occurred while saving Patient details", type: "error" });
                    showToast();
                    setLoading(false);
                    closeAddPopup();
                });
        }
    };

    const handleProfilePicChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
            setNewPatient(prevPatient => ({
                ...prevPatient,
                clinic_guid: loggeduser.facilityID,
                patient_pic: selectedFile.name
            }));
        }
    };
    const handleChange = (e) => {
        setNewPatient(prevPatient => ({
            ...prevPatient,
            clinic_guid: loggeduser.facilityID,
            [e.target.name]: e.target.value
        }));
    };

    const addMonths = (date, months) => {
        var newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    }

    const handleSelectDoctor = (selectedOption) => {
        setNewAppointment({
            ...newAppointment, doctor_id: selectedOption ? selectedOption.value : 0
        })
    };

    return (
        <div className="fixed inset-0 flex justify-center items-center lg:p-8 z-50">
            <div className="absolute inset-0 bg-black opacity-25 "></div>
            <div className="relative bg-white shadow-xl p-2 lg:p-8 max-h-[80vh] lg:max-h-[70vh] 2xl:max-h-[70vh] overflow-y-auto " style={{ width: "600px" }} >
                <div className="flex justify-between items-center mb-4">
                    <div className={`font-bold text-2xl ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>{newPatient.patient_id == 0 ? <span>Add New Patient</span> : <span>Update Patient</span>}</div>
                    <button id="AddPatientBtncloseAddPopup" className={`text-red-700 sm:justify-left`} onClick={closeAddPopup}>
                        <svg id="AddPatientSvgcloseAddPopupIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>
                </div>
                <div className="flex w-[100%] lg:gap-1 gap-2">
                    <div className=" flex-grow w-[30%] lg:pr-2 pb-2">
                        <label id="AddPatientLabelname" htmlFor="name" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">First Name<span className='text-red-700'>*</span></label>
                        <div className="relative">
                            <input type="text" id="AddPatientFirstName" name="FirstName" value={newPatient.FirstName} onChange={(event) => { handleChange(event) }} className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                        </div>
                        {errors.FirstName && <p id="AddPatientPerrorFirstName" className="text-red-500 text-sm">{errors.FirstName}</p>}
                    </div>

                    <div className=" flex-grow w-[30%] pb-1">
                        <label id="AddPatientLabelLastname" htmlFor="name" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Last Name<span className='text-red-700'>*</span></label>
                        <div className="relative">
                            <input type="text" id="AddPatientLastName" name="LastName" value={newPatient.LastName} onChange={(event) => { handleChange(event) }} className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                        </div>
                        {errors.LastName && <p id="AddPatientPerrorLastName" className="text-red-500 text-sm">{errors.LastName}</p>}

                    </div>
                </div>
                <div className="flex w-[100%]  gap-2">
                    <div className="flex-grow w-[50%] pb-1">
                        <label id="AddPatientLabelmiddleName" htmlFor="name" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Middle Name </label>
                        <div className="relative">
                            <input type="text" id="AddPatientMiddleName" name="MiddleName" value={newPatient.MiddleName} onChange={(event) => { handleChange(event) }} className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                        </div>
                        {errors.MiddleName && <p id="AddPatientPerrorMiddleName" className="text-red-500 text-sm">{errors.MiddleName}</p>}
                    </div>
                    <div className="flex-grow pb-1 w-[50%]">
                        <label id="AddPatientLabelmobile" htmlFor="name" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Mobile<span className='text-red-700'>*</span></label>
                        <div className="relative">
                            <PhoneInput id="AddPatientPhoneInput"
                                country={'in'}
                                onlyCountries={['in']}
                                value={newPatient.mobile_number}
                                countryCodeEditable={false}
                                disableDropdown={true}
                                enableSearch={false}
                                onChange={(value) => { handleChange({ target: { name: 'mobile_number', value } }) }}
                                inputProps={{
                                    name: 'mobile_number',
                                    id: 'mobile',
                                    className: "block w-full border rounded-md focus:outline-none focus:border-gray-400 pl-14"
                                }}
                                specialLabel=""
                            />


                            <svg id="AddPatientSvgphoneIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="absolute right-2 top-[54%] transform -translate-y-1/2 w-4 h-4 text-black hidden lg:block">
                                <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 0 1 3.5 2h1.148a1.5 1.5 0 0 1 1.465 1.175l.716 3.223a1.5 1.5 0 0 1-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 0 0 6.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 0 1 1.767-1.052l3.223.716A1.5 1.5 0 0 1 18 15.352V16.5a1.5 1.5 0 0 1-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 0 1 2.43 8.326 13.019 13.019 0 0 1 2 5V3.5Z" clipRule="evenodd" />
                            </svg>
                        </div>
                        {errors.mobile_number && <p id="AddPatientPerrorMobNumber" className="text-red-500 text-sm">{errors.mobile_number}</p>}
                    </div>

                </div>

                <div className="flex w-[100%] gap-2">
                    <div
                        id="AddPatientDivemailField"
                        className="flex-grow w-[50%] pb-2" >
                        <label id="AddPatientLabelemail" htmlFor="email" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Email</label>
                        <div className="relative">
                            <input type="text" id="AddPatientInputemail" name="email_id" onChange={(event) => { handleChange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-7" value={newPatient.email_id} />
                            <svg id="AddPatientSvgemailIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute right-2 top-[54%] transform -translate-y-1/2 w-4 h-4 text-black">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>
                            {errors.email_id && <p id="AddPatientPerrorEmail" className="text-red-500 text-sm">{errors.email_id}</p>}
                        </div>
                    </div>
                    <div
                        id="AddPatientDivgenderField"
                        className="flex-grow w-[50%] pb-2"
                    >
                        <label
                            id="AddPatientLabelgender"
                            htmlFor="AddPatientgender"
                            className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto"
                        >
                            Gender<span className='text-red-700'>*</span>
                        </label>
                        <div id="ddPatientDivgenderInputContainer" className="relative">
                            <select
                                id="AddPatientSelectgender"
                                name="gender"
                                value={newPatient.gender}
                                onChange={handleChange}
                                className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                            >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        {errors.gender && <p id="AddPatientPerrorGender" className="text-red-500 text-sm">{errors.gender}</p>}
                    </div>
                </div>

                {/* <div id="AddPatientDivgenderField" className="flex-grow w-[100%] lg:w-[50%]  lg:pr-2 pb-2">
                        <label id="AddPatientLabelgender" htmlFor="AddPatientgender" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">
                            Gender
                        </label>
                        <div id="AddPatientDivgenderInputContainer" className="relative">
                            <div id="AddPatientDivradioOptions" className="flex pt-2">
                                <div id="AddPatientDivMale" className="flex items-center me-4">
                                    <input id="AddPatientInputmale" type="radio" name="gender" value="Male" className="mr-2" checked={newPatient.gender === "Male"} onChange={(event) => { handleChange(event) }} />
                                    <label id="AddPatientLabelmale" htmlFor="AddPatientInputmale">
                                        Male
                                    </label>
                                </div>
                                <div id="AddPatientDivFemale" className="flex items-center me-4">
                                    <input id="AddPatientInputfemale" type="radio" name="gender" value="Female" checked={newPatient.gender === "Female"} className="mr-2" onChange={(event) => { handleChange(event) }} />
                                    <label id="AddPatientLabelfemale" htmlFor="AddPatientInputfemale" >
                                        Female
                                    </label>
                                </div>
                                <div id="AddPAtientDivOthers" className="flex items-center me-4">
                                    <input id="AddPatientInputothers" type="radio" name="gender" value="Others" checked={newPatient.gender === "Others"} className="mr-2" onChange={(event) => { handleChange(event) }} />
                                    <label id="AddPatientLabelothers" htmlFor="AddPatientInputothers">
                                        Others
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> */}
                <div className="flex w-[100%] lg:gap-1 gap-2">
                    <div className="flex-grow w-[50%] lg:pr-2 pb-2">
                        <label id="AddPatientLabeldateofBirth" htmlFor="name" className="block text-gray-700 mb-1 sm:w-1 lg:w-auto">Date of Birth</label>
                        <div className="relative">
                            <DatePicker id='DatePicker'
                                selected={dateOfBirth}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(date) => { setDateOfBirth(date) }}
                                maxDate={new Date()}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                showMonthDropdown
                                scrollableMonthYearDropdown
                                className='block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2'
                                dateFormat="dd-MM-yyyy"
                                calendarClassName="patient-datepicker"
                            />
                        </div>
                    </div>
                    <div className=" flex-grow w-[50%] lg:pr-2 pb-2">
                        <label id="AddPatientLabelbloodGroup" htmlFor="name" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Blood Group</label>
                        <div className="relative">
                            <select id="AddPatientSelectbloodGroup" name="blood_group" value={newPatient.blood_group} onChange={(event) => { handleChange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2">
                                <option id="AddPatientOptionbloodGroup" value="">Select Blood Group</option>
                                <option id="AddPatientOptionA+" value="A+">A+</option>
                                <option id="AddPatientOptionA-" value="A-">A-</option>
                                <option id="AddPatientOptionB+" value="B+">B+</option>
                                <option id="AddPatientOptionB-" value="B-">B-</option>
                                <option id="AddPatientOptionAB+" value="AB+">AB+</option>
                                <option id="AddPatientOptionAB" value="AB-">AB-</option>
                                <option id="AddPatientOptionO+" value="O+">O+</option>
                                <option id="AddPatientOptionO-" value="O-">O-</option>
                            </select>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {newPatient?.patient_id == 0 &&
                    <>

                        {/* <div className="block lg:flex w-[100%] ">
                            <div className="flex-grow w-[100%] lg:w-[50%] lg:pr-2 pb-2 ">
                                <div className="capitalize">
                                    <label htmlFor="mobile" className="block text-gray-700 mb-1 sm:w-1 lg:w-auto ">Appointment Date and Time</label>
                                    <DatePicker
                                        selected={startDate}
                                        onKeyDown={(e) => e.preventDefault()}
                                        onChange={(date) => { setStartDate(date); setNewAppointment({ ...newAppointment, appointment_time: DateFunction.AppointmentDateTimePicker(date) }) }}
                                        showTimeSelect
                                        timeFormat="hh:mm aa"
                                        timeIntervals={10}
                                        minDate={new Date()}
                                        maxDate={addMonths(new Date(), 3)}
                                        className='block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2'
                                        timeCaption="Time"
                                        dateFormat="MMM dd, yyyy hh:mm aa"
                                    />
                                </div>
                            </div>


                            <div className="flex-grow w-[100%] lg:w-[50%] pb-1 mr-2 sm:mr-2">
                                <label htmlFor="doctor" className="block text-gray-700 mb-1 sm:w-1 lg:w-auto">Select Doctor</label>
                                <div className="relative">
                                    <Select
                                        styles={{
                                            input: (base) => ({
                                                ...base,
                                                'input:focus': {
                                                    boxShadow: 'none',
                                                },
                                            }),
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderColor: state.isFocused ? 'blue' : '#6B7280',
                                                '&:hover': {
                                                    borderColor: state.isFocused ? 'blue' : 'gray',
                                                },
                                            }),
                                        }}
                                        className="border border-gray-200 rounded-md lg:ml-2"
                                        placeholder="Select Doctor"
                                        isClearable={true}
                                        isSearchable={true}
                                        options={doctors}
                                        onChange={handleSelectDoctor}
                                        value={newAppointment.doctor_id > 0 ? allDoctors.find(option => option.value === newAppointment.doctor_id) : null}
                                    />
                                </div>
                            </div>

                        </div> */}
                        {/* <div className="lg:hidden flex w-full">
                            <div className="w-full mb-4">
                                <label htmlFor="name" className="block text-gray-700 mb-1 sm:w-1 lg:w-auto">
                                    Date of Birth
                                </label>
                                <div className="relative">
                                    <DatePicker
                                        selected={dateOfBirth}
                                        onKeyDown={(e) => e.preventDefault()}
                                        onChange={(date) => setDateOfBirth(date)}
                                        maxDate={new Date()}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        showMonthDropdown
                                        scrollableMonthYearDropdown
                                        className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 mt-6 px-2 py-2 mr-2 w-[95%]"
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                            </div>
                            <div className="w-full">
                                <label htmlFor="mobile" className="block text-gray-700 mb-1 sm:w-1 lg:w-auto">
                                    Appointment Date and Time
                                </label>
                                <DatePicker
                                    selected={startDate}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setNewAppointment({ ...newAppointment, appointment_time: DateFunction.AppointmentDateTimePicker(date) });
                                    }}
                                    showTimeSelect
                                    timeFormat="hh:mm aa"
                                    timeIntervals={10}
                                    minDate={new Date()}
                                    maxDate={addMonths(new Date(), 3)}
                                    className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 mr-2 px-2 py-2 w-[95%]"
                                    timeCaption="Time"
                                    dateFormat="MMM dd, yyyy hh:mm aa"
                                />
                            </div> */}
                        {/* </div> */}



                    </>

                }


                {/* {newPatient?.patient_id == 0 &&
                    <div className="grid grid-cols-2 gap-2 mt-2">
                        <div className="col-span-2">
                            <label htmlFor="purpose_of_visit" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Purpose of Visit</label>
                            <div className="relative">
                                <input type="text" id="AddPatientpurpose_of_visit" onChange={(e) => {
                                    setNewAppointment({
                                        ...newAppointment, purpose_of_visit: e.target.value
                                    })
                                }} value={newAppointment.purpose_of_visit} name="purpose_of_visit" className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                            </div>
                        </div>
                    </div>
                } */}
                <div className="grid grid-cols-2 gap-2 mt-2">
                    <div className="col-span-2">
                        <label id="AddPatientLabeladdress" htmlFor="address" className="block text-gray-700 mb-1 sm:w-auto sm:text-left lg:w-auto">Address</label>
                        <div className="relative">
                            <textarea id="AddPatienttextAreaAddress" onChange={(event) => { handleChange(event) }} name="address" className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" value={newPatient.address}></textarea>
                        </div>
                    </div>
                </div>
                {/* <div className="pb-2 focus:border-gray-400 cursor-pointer w-[100%] border rounded-md focus:outline-none flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-20 h-20 mr-2 bg-gray-200 rounded-sm">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                        </svg>
                        <div>
                            <label htmlFor="uploadimage" className="block text-gray-700 mb-1 text-sm italic">Please upload square image, size less than 100KB</label>
                            <div className="relative">
                                <button type="button" onClick={chooseImage} className="py-2 px-4 bg-red-700 text-white transition rounded-md ml-4">
                                    Choose Image
                                </button>
                                <input type="file" id="AddPatientuploadimage" name="patient_pic" onChange = {handleProfilePicChange} className="hidden" accept="image/*" />
                            </div>
                        </div>
                    </div> */}
                <div className="flex mt-5 lg:gap-[4.75rem]">
                    <div className="w-[100%]">
                        <div
                            id="AddPatientDivuploadField"
                            className="focus:border-gray-500 cursor-pointer w-[100%] border rounded-md focus:outline-none p-2 pr-2 flex"
                        >
                            {file ? (
                                <img
                                    id="AddPatientImgfileUrl"
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    className="object-cover w-24 h-24"
                                />
                            ) : patient && patient.patient_pic ? (
                                <img
                                    id="AddPatientImgfilePic"
                                    src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${patient.patient_pic}`}
                                    alt="Patient Profile"
                                    className="object-cover w-24 h-24"
                                />
                            ) : (
                                <svg
                                    id="AddPatientSvgplaceholder"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-24 h-24 bg-gray-300 rounded-sm p-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 15.75L7.409 10.591a2.25 2.25 0 013.182 0l5.159 5.159M15.75 12.75l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909M3 21h16.5a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0019.5 4.5H3.75A1.5 1.5 0 002.25 6v12A1.5 1.5 0 003 21z"
                                    />
                                </svg>
                            )}

                            <div>
                                <label
                                    id="AddPatientLabeluploadText"
                                    htmlFor="AddPatientInputuploadimage"
                                    className="block text-gray-900 mb-2 italic text-base ml-4"
                                >
                                    Choose Profile Photo
                                </label>
                                <div className="relative">
                                    <button
                                        id="AddPatientBtnchoseImage"
                                        type="button"
                                        onClick={chooseImage}
                                        className={`py-1 px-2 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md ml-4 hover:${previewTheme.saveBtnHoverColor}`}
                                    >
                                        Choose Image
                                    </button>
                                    <input
                                        type="file"
                                        id="uploadimage"
                                        name="patient_pic"
                                        onChange={handleProfilePicChange}
                                        className="hidden"
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mt-4 ">
                    <button
                        id="AddPatientBtnsave"
                        disabled={submitDisable}
                        className={`px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} ${submitDisable ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                        onClick={createPatient}
                    >
                        {newPatient.patient_id == 0 ? <span>Add</span> : <span>Update</span>}
                        {submitDisable && (
                            <svg
                                id="AddPatientSvgsubmitIcon"
                                aria-hidden="true"
                                role="status"
                                className="inline w-4 h-4 ml-2 text-white animate-spin"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            </div>
            {toast.show && (
                <SnackBarComponent
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast({ show: false, message: "", type: "" })}
                />
            )}
        </div >
    );
};

export default AddPatient;
