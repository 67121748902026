import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    isDesktopSidebarOpen:true,
    isMobileSidebarOpen: false,
    isDesktopUserScreenOpen: false,
    isSuperAdminDropdownOpen: false,
    isAddOnFeatureData: [],
    isClinicGuid: "",
    isActiveData: [],
    isAddOnFeatureData: [],
    isActiveAppointmentMenu: false
  },
  reducers: {
    setIsDesktopSidebarOpen:(state, action) => {
      state.isDesktopSidebarOpen = action.payload;
    },
    setIsMobileSidebarOpen: (state, action) => {
      state.isMobileSidebarOpen = action.payload;
    },
    setIsDesktopUserScreenOpen: (state, action) => {
      state.isDesktopUserScreenOpen = action.payload;
    },
    setIsSuperAdminDropdownOpen: (state, action) => {
      state.isSuperAdminDropdownOpen = action.payload;
     },
    setIsAddOnFeatureData: (state, action) => {
      state.isAddOnFeatureData = action.payload;
    },
    setIsClinicGuid: (state, action) => {
      state.isClinicGuid = action.payload;
    },
    setIsActiveData: (state, action) => {
      state.isActiveData = action.payload;
    },
  },
});

export const {
  setIsDesktopSidebarOpen,
  setIsMobileSidebarOpen,
  setIsDesktopUserScreenOpen,
  setIsSuperAdminDropdownOpen,
  setIsAddOnFeatureData,
  setIsClinicGuid,
  setIsActiveData,
} = drawerSlice.actions;
export default drawerSlice.reducer;
