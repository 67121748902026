import { createSlice } from '@reduxjs/toolkit';

const LabandRadiologySlice = createSlice({

   name: 'labAndRadiologyDetails', 
   initialState: {
    avaiLableUserForLab: [],
    addedLabUsers:[],
    availableRadiologyUser:[],
    labOptions:[
      { value: 1, label: "Blood Test" },
      { value: 2, label: "Sugar Test" },
      { value: 3, label: "Insulin Test" },      
     ],
    radiologyOptions:[
      { value: 1, label: "X-Ray" },
      { value: 2, label: "MRA" },
      { value: 3, label: "MRI" },
    ],
    labAppointments:[
      {
      },
    ],
    radiologyAppointments: [
    {
    },
   ]
  },

  reducers: {
    setradiologyUsernames: (state,action) => {
      state.addedRadiologyUsers = action.payload;
    },
    setNotLabAddedUser: (state,action) => {
        state.avaiLableUserForLab = action.payload;
      },
    setusernames: (state,action) => {
        state.addedLabUsers = action.payload;
      }, 
      setNotRadiologyAddedUser: (state,action) => {
        state.availableRadiologyUser = action.payload;
      },
      setLabOptions:(state,action)=>{
       state.labOptions=action.payload;
      },
      setRadiologyOptions:(state,action)=>{
        state.radiologyOptions=action.payload;
      },
      setLabAppointments:(state,action)=>{
        state.labAppointments=action.payload;
      },
      setRadiologyAppointments:(state,action)=>{
        state.radiologyAppointments=action.payload
      }
  },
});

export const {setradiologyUsernames,setNotLabAddedUser,setusernames,setNotRadiologyAddedUser,setLabOptions,setRadiologyOptions,setLabAppointments,setRadiologyAppointments} = LabandRadiologySlice.actions;
export default LabandRadiologySlice.reducer;