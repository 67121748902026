import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import store from "../../API/store";
import AddSubscriptions from "./AddSubscriptions";
import { theme } from "../../EmraxisTheme/Theme";
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"

const Subscription = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [subscription, setsubscription] = useState([]);
  const [editdata, seteditdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  const previewTheme =
    useSelector((state) => state.theme.colors) || theme.defaultTheme;
  const [openDropdown, setOpenDropdown] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const menuRef = useRef();
  const showToast = () => {
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };
  const openPopup = (status) => {
    seteditdata(status);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setrefresh((oldKey) => oldKey + 1);
  };

  //load the user Role from the database
  useEffect(() => {
    const fetchsubscription = async () => {
      await store
        .GetSubscription(loggedusertoken)
        .then((result) => {
          result.json().then((res) => {
            setsubscription(res);
          });
        })
        .catch((err) => {
          setToast({ show: true, message: "Error occured while Getting the User Roles", type: "error" });
          showToast();
          setLoading(false);

        });
    };

    if (
      loggeduser != null &&
      loggeduser.length != 0 &&
      loggeduser.user_role == "SuperAdmin"
    ) {
      fetchsubscription();
    }
  }, [refresh]);

  const handleuserdelete = async (data) => {
    await store
      .DeleteUserRole(data.user_role_id, loggedusertoken)
      .then((result) => {
        result.json().then((res) => {
          if (res == true) {
            setrefresh((oldKey) => oldKey + 1);
            setToast({ show: true, message: "Added", type: "success" });
            showToast();
            setLoading(false);


          } else {
            setToast({ show: true, message: "Failed", type: "error" });
            showToast();
            setLoading(false);

          }
        });
      })
      .catch((err) => {
        setToast({ show: true, message: "Error occured while Deleting UserRoles", type: "error" });
        showToast();
        setLoading(false);
      });
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className=" mx-auto  my-20 w-100% ">
      <div className="flex items-center justify-between">
        <div id="SubscrptionDivsubscriptionTitle" className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
          style={{ fontFamily: previewTheme.fontFamily }}>
          Subscriptions
        </div>
        <header className="flex justify-between items-center my-2 mb-4 px-2">
          <div className="flex items-center">
            <button id="SubscrptionBtnaddSubscription"
              className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }}
              onClick={() => {
                openPopup([]);
              }}
            >
              <svg id="SubscrptionSvgaddSubscriptionIcon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
              </svg>
              Add New Subscription
            </button>
          </div>
        </header>
      </div>

      {showPopup && (
        <AddSubscriptions
          isOpen={showPopup}
          onClose={closePopup}
          subscriptions={subscription}
        />
      )}
      <table className="inline-table w-[100%] lg:border rounded-md focus:outline-none  border-color:rgb(2 6 23);">
        <thead className={`rounded-lg text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12 `}>
          <tr>
            <th id="SubscrptionThname" className="px-10 py-2 text-left">Name</th>
            <th id="SubscrptionThdays" className="px-10 py-2 text-left">Validity/days</th>
            <th id="SubscrptionThuserLimit" className="px-10 py-2 text-left">User Limit</th>
            <th id="SubscrptionThmonth" className="px-10 py-2 text-left">Amount/Month</th>
            <th id="SubscrptionThyear" className="px-10 py-2 text-left">Amount/Year</th>
            <th id="SubscrptionThactions" className="px-10 py-2 text-center"></th>
          </tr>
        </thead>
        <tbody>
          {subscription.map((userdata, index) => (
            <tr key={index} className="border-b h-12 text-[#424242] hover:bg-white bg-[#FBFBFB] text-sm"
              onMouseEnter={() => setHoveredRowId(index)}
              onMouseLeave={() => {
                if (!openDropdown) {
                  setHoveredRowId(null);
                }
              }}>
              <td id="SubscrptionTdname" className="px-10 py-2">
                {userdata.subscription_name || "-"}
              </td>
              <td id="SubscrptionTdvalidity" className="px-10 py-2">
                {userdata.subscription_validity || "-"}
              </td>
              <td id="SubscrptionTduserLimit" className="px-10 py-2">
                {userdata.subscription_userlimit || "-"}
              </td>
              <td id="SubscrptionTdmonthly" className="px-10 py-2">
                {userdata.subscription_monthly || "-"}
              </td>
              <td id="SubscrptionTdyearly" className="px-10 py-2">
                {userdata.subscription_yearly || "-"}
              </td>
              <td id="DoctorsTDmobileActions" className="px-2 py-2 text-left cursor-pointer">
                <button id="SubscrptionBtnOpenDropdown" onClick={() => toggleDropdown(index)} className={`transition-opacity duration-200 ${hoveredRowId === index ? 'opacity-100' : 'opacity-0'}`}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 mt-2">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1.5C3 1.10218 3.15804 0.720644 3.43934 0.43934C3.72064 0.158035 4.10218 0 4.5 0H4.51C4.90782 0 5.28936 0.158035 5.57066 0.43934C5.85197 0.720644 6.01 1.10218 6.01 1.5V1.51C6.01 1.90782 5.85197 2.28936 5.57066 2.57066C5.28936 2.85196 4.90782 3.01 4.51 3.01H4.5C4.10218 3.01 3.72064 2.85196 3.43934 2.57066C3.15804 2.28936 3 1.90782 3 1.51V1.5ZM3 8.5C3 8.10218 3.15804 7.72064 3.43934 7.43934C3.72064 7.15804 4.10218 7 4.5 7H4.51C4.90782 7 5.28936 7.15804 5.57066 7.43934C5.85197 7.72064 6.01 8.10218 6.01 8.5V8.51C6.01 8.90782 5.85197 9.28936 5.57066 9.57066C5.28936 9.85196 4.90782 10.01 4.51 10.01H4.5C4.10218 10.01 3.72064 9.85196 3.43934 9.57066C3.15804 9.28936 3 8.90782 3 8.51V8.5ZM4.5 14C4.10218 14 3.72064 14.158 3.43934 14.4393C3.15804 14.7206 3 15.1022 3 15.5V15.51C3 15.9078 3.15804 16.2894 3.43934 16.5707C3.72064 16.852 4.10218 17.01 4.5 17.01H4.51C4.90782 17.01 5.28936 16.852 5.57066 16.5707C5.85197 16.2894 6.01 15.9078 6.01 15.51V15.5C6.01 15.1022 5.85197 14.7206 5.57066 14.4393C5.28936 14.158 4.90782 14 4.51 14H4.5Z" fill="black" />
                  </svg>
                </button>
                {openDropdown === index && (
                  <div id="SubscrptionDivEditDelete" ref={menuRef} className="absolute right-14 w-30 bg-white border border-gray-300 rounded-md z-10">
                    <ul>
                      <li id="SubscrptionBtnuserData" className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2" onClick={() => { openPopup(userdata); toggleDropdown(); }}>
                        Edit</li>
                      <li id="SubscrptionBtdelete" className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2" onClick={() => { handleuserdelete(userdata); toggleDropdown(); }}>
                        Delete</li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {toast.show && (
        <SnackBarComponent
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: '', type: '' })}
        />
      )}
    </div>
  );
};

export default Subscription;
