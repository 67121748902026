import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../API/store";
import AddMembers from "./AddMembers";
import { useNavigate } from "react-router-dom";
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"
import DataDecode from "../../utils/DataDecode";
import {
  addFacilityUser,
  addFacilityPhone,
  addUserEmail,
  addUserRole,
} from "../../Redux/features/users/userSlice";
import Toggle from "./Toggle";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import { theme } from "../../EmraxisTheme/Theme";

import SearchIcon from "@mui/icons-material/Search";

import { OutlinedInput, InputAdornment } from "@mui/material";
import AddFacility from "../Facility/AddFacility";

const Users = () => {
  const navigate = useNavigate();

  var isDesktopUserScreenOpen = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );
  const [edit, setedit] = useState("create");
  const [showPopup, setShowPopup] = useState(false);
  const [userdata, setuserdata] = useState([]);

  const [searchText, setSearchText] = useState("");
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var facilityName = useSelector((state) => state.facility.facility_name);
  var facilityLogo = useSelector((state) => state.facility.facility_logo);
  var facilityID = useSelector((state) => state.facility.facility_id);
  var facilityColor = useSelector((state) => state.facility.facility_color);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeRole, setActiveRole] = useState(null);
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);
  const [isTestDropdownOpen, setIsTestDropdownOpen] = useState(false);
  const [isStandarduserDropdownOpen, setIsStandarduserDropdownOpen] =
    useState(false);
  const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [editableText, setEditableText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("UserManagement");
  const [userDetails, setUserDetails] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [showlimitPopup, setShowLimitPopup] = useState(false);
  const [userLimits, setUserLimits] = useState();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const menuRef = useRef();
  const UserRoles = [{ name: "Admin" }, { name: "Doctor" }, { name: "Users" }];
  const previewTheme =
    useSelector((state) => state.theme.colors) || theme.defaultTheme;
  const showToast = () => {

    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };
  const handleSuperAdminDeleteUser = async () => {

    const adminUsers = userdata.filter(user => user.user_role === 'Admin');
    if (adminUsers.length <= 1 && userDetails.user_role === "Admin") {
      dispatch(
        setNotification({
          message: "We cant Delete Admin user If one Admin left in facility",
          status: "info",
          action: true,
        })
      );
      setDeletePopup(false);
      return;
    };
    if (loggeduser && loggeduser.user_role === "SuperAdmin" && adminUsers.length >= 1) {
      setDisableButton(true);
      const DeleteUser = {
        UserName: userDetails.user_name,
        EmailId: userDetails.email_id,
      };
      await store
        .DeleteUserFromDBAzureAdb2c(DataDecode.encryptPayload(DeleteUser))
        .then((response) => {
          if (response.status === 200) {

            dispatch(
              setNotification({
                message: "User deleted Successfully",
                status: "success",
                action: true,
              })
            );
            fetchUserData();
            setDeletePopup(false);


          } else {
            dispatch(
              setNotification({
                message: "Failed While deleting user",
                status: "error",
                action: true,
              })
            );
          }
          setDisableButton(false);
          setDeletePopup(false);
        });
    } else {
      dispatch(
        setNotification({
          message: "Super Admins can only delete the users",
          status: "error",
          action: true,
        })
      );
      setDeletePopup(false);

    }
  };
  // Load the user details from the database corresponding to that facility
  const fetchUserData = async () => {
    await store
      .GetFacilityUserDetails(
        loggedusertoken,
        DataDecode.encryptPayload(facilityID)
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            //let response = DataDecode.decryptResponse(res)
            //var myObject = JSON.parse(response);
            setUserDetails(res);
            setuserdata(res);
          });
        } else {
        }
      })
      .catch((err) => {
        setToast({ show: true, message: "Error occured", type: "error" });
        showToast();
        setLoading(false);


      });
  };
  useEffect(() => {
    if (loggeduser && loggeduser.user_role === "SuperAdmin") {
      fetchUserData();
    }
  }, [
    loggeduser,
    loggedusertoken,
    facilityID,
    facilityName,
    showPopup,
    refreshData,
  ]);

  const handleFacilityEdit = (userdata) => {
    //allow only the Phone number and role to edit
    //add all the credentials in the redux store
    if (userdata.user_status == true && userdata.user_verified == true) {
      dispatch(addFacilityUser(userdata.user_name));
      dispatch(addFacilityPhone(userdata.phonenumber));
      dispatch(addUserEmail(userdata.email_id));
      dispatch(addUserRole(userdata.user_role));
      setedit("edit");
      setShowPopup(true);
    }
  };
  const togglebutton = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (role) => {
    // Add delete logic here

  };

  const handleEdit = (role) => {
    // Add edit logic here

  };
  const toggleDropdown = (role) => {
    if (activeRole === role) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setActiveRole(role);
      setIsDropdownOpen(true);
    }
  };
  const handleAdminSvgClick = () => {
    setIsAdminDropdownOpen(!isAdminDropdownOpen);
    setIsTestDropdownOpen(false);
    setActiveRole("Admin");
  };

  const handleTestSvgClick = () => {
    setIsTestDropdownOpen(!isTestDropdownOpen);
    setIsAdminDropdownOpen(false);
    setActiveRole("Test");
  };
  const handleStandarduserSvgClick = () => {
    setIsStandarduserDropdownOpen(!isStandarduserDropdownOpen);
    setActiveRole("Standarduser");
  };
  const handleManagerSvgClick = () => {
    setIsManagerDropdownOpen(!isManagerDropdownOpen);
    setIsStandarduserDropdownOpen(false);
    setActiveRole("Manager");
  };
  const handleToggleAdminText = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionSelect = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected !== option)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const FullRoles = [
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const Admin = [
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const Doctor = [
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const User = [
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];

  const handleToggleText = () => {
    setIsTextVisible((prevState) => !prevState);
  };

  const OpenUserLimitPopup = () => {
    setShowLimitPopup(true);
  }
  const closeUserLimitPopup = () => {
    setShowLimitPopup(false);
  }
  const fetchFacilityData = async () => {
    await store.GetFacilityinfo(loggedusertoken, DataDecode.encryptPayload(facilityID))
      .then((result) => {

        if (result.status == 201) {

          result.json().then((res) => {
            let response = JSON.parse(DataDecode.decryptResponse(res));
            setUserLimits(response.user_limit);
          })

        }
        else {
          dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
      })
  }
  useEffect(() => {
    if (loggeduser) {
      fetchFacilityData();
    }

  }, [loggeduser, refreshData])

  return (
    <div className="bg-white mx-auto my-20 w-100%">
      <div className="flex  items-center lg:ml-1 ">
        <div className=" flex items-center "></div>
      </div>
      <div className="flex justify-between items-center lg:ml-1 ">
        <div className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
          style={{ fontFamily: previewTheme.fontFamily }}
        >Users</div>
        <div className="flex">
          <span className="flex justify-between items-center px-6 py-4 transition delay-700 duration-1000 ease-in">
            <OutlinedInput
              id="UsersOutlinedtableDataSearchField"
              type="text"
              size="small"
              fullWidth
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                e.stopPropagation();
                setSearchText(e?.target?.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon id="UsersSearchIcon" fontSize="small" />
                </InputAdornment>
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    border: "2px solid #1a8aa8",
                    background: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                  "& fieldset": {
                    borderColor: "transparent", // Hide default border
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Hide hover border
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Hide focus border
                  },
                },
                "& .MuiOutlinedInput-input": {
                  "&:focus": {
                    outline: "none",
                    boxShadow: "none",
                  },
                },
              }}
            />
          </span>

          <h1 className="flex justify-between items-center mb-4 px-2 mt-4">
            <div className=" items-center flex flex-row">
              <button id="UsersBtnnewUser"
                className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`}
                style={{ fontFamily: previewTheme.fontFamily }}
                onClick={() => {
                  if (userdata.length >= userLimits) {
                    OpenUserLimitPopup();
                  } else {
                    setShowPopup(true);
                    setedit("create");
                  }
                }}
              >
                <svg id="UsersSvgnewUsericon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 mr-2"
                >
                  <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                </svg>
                Add New User
              </button>
              <button id="UserButtonreload" className="hidden lg:block items-center lg:ml-6 sm:ml-4 mx-4" onClick={() => window.location.reload()}>
                <svg id="UserButtonreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-800 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                  <path d="M20 4v5h-5" />
                </svg>
              </button>
              {showPopup && (
                <AddMembers
                  isOpen={showPopup}
                  onClose={() => setShowPopup(false)}
                  formstatus={edit}
                  userdata={userdata}
                />
              )}
            </div>
          </h1>
        </div>
      </div>
      <div className="flex justify-between mx-auto mt-5 gap-4">
        <div className="flex justify-center w-full"></div>
      </div>
      {/* //UserManagement */}
      {activeTab === "UserManagement" && (
        <div className="text-black">
          <div className="overflow-x-scroll lg:overflow-x-auto hidden lg:block">
            <table className="table-auto w-full border rounded-md focus:outline-none">
              <thead className={`rounded-lg text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12 `}>
                <tr className="px-5 py-2 text-left">
                  <th id="UsersThname" className="px-5 py-2 text-left">Name</th>
                  <th id="UsersThemailId" className="px-2 py-2 text-left">EMail ID</th>
                  <th id="UsersThphoneNumber" className="px-2 py-2 text-left">PhoneNumber</th>
                  <th id="UsersThrole" className="px-2 py-2 text-left">Role</th>
                  <th id="UsersThstatus" className="px-2 py-2 text-left">Status</th>
                  <th id="UsersThactive" className="px-2 py-2 text-left ">InActive/Active</th>
                  <th id="UsersThaction" className="px-2 py-2 text-left"></th>
                </tr>
              </thead>
              <tbody>
                {userdata.map((userdata, index) => (
                  <tr
                    key={"user-table-" + index}
                    className="border-b h-12 text-[#424242] hover:bg-white bg-[#FBFBFB] text-sm"
                    onMouseEnter={() => setHoveredRowId(index)}
                    onMouseLeave={() => {
                      if (!openDropdown) {
                        setHoveredRowId(null);
                      }
                    }}
                  >
                    <td id="UsersTduserName" className="px-2 py-2 text-left">
                      {userdata.user_name || "-"}
                    </td>
                    <td id="UsersTdemailId" className="px-2 py-2 text-left">
                      {userdata.email_id || "-"}
                    </td>
                    <td id="UsersTdphoneNumber" className="px-2 py-2 text-left">
                      {userdata.phonenumber || "-"}
                    </td>
                    <td id="UsersTduserRole" className="px-2 py-2 text-left">
                      {userdata.user_role || "-"}
                    </td>
                    <td id="UsersTduserData" className="px-2 py-2 text-left">
                      {userdata.user_verified == true ? "Verified" : "Pending"}
                    </td>
                    <td className="px-2 py-2 text-left">
                      <Toggle
                        key={"usersactive-toggle-" + index}
                        user={userdata}
                        setRefreshData={setRefreshData}
                      />
                    </td>
                    <td id="DoctorsTDmobileActions" className="px-2 py-2 text-left cursor-pointer">
                      <button id="UsersBtntoggleDropdownButton" onClick={() => togglebutton(index)} className={`transition-opacity duration-200 ${hoveredRowId === index ? 'opacity-100' : 'opacity-0'}`}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 mt-2">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1.5C3 1.10218 3.15804 0.720644 3.43934 0.43934C3.72064 0.158035 4.10218 0 4.5 0H4.51C4.90782 0 5.28936 0.158035 5.57066 0.43934C5.85197 0.720644 6.01 1.10218 6.01 1.5V1.51C6.01 1.90782 5.85197 2.28936 5.57066 2.57066C5.28936 2.85196 4.90782 3.01 4.51 3.01H4.5C4.10218 3.01 3.72064 2.85196 3.43934 2.57066C3.15804 2.28936 3 1.90782 3 1.51V1.5ZM3 8.5C3 8.10218 3.15804 7.72064 3.43934 7.43934C3.72064 7.15804 4.10218 7 4.5 7H4.51C4.90782 7 5.28936 7.15804 5.57066 7.43934C5.85197 7.72064 6.01 8.10218 6.01 8.5V8.51C6.01 8.90782 5.85197 9.28936 5.57066 9.57066C5.28936 9.85196 4.90782 10.01 4.51 10.01H4.5C4.10218 10.01 3.72064 9.85196 3.43934 9.57066C3.15804 9.28936 3 8.90782 3 8.51V8.5ZM4.5 14C4.10218 14 3.72064 14.158 3.43934 14.4393C3.15804 14.7206 3 15.1022 3 15.5V15.51C3 15.9078 3.15804 16.2894 3.43934 16.5707C3.72064 16.852 4.10218 17.01 4.5 17.01H4.51C4.90782 17.01 5.28936 16.852 5.57066 16.5707C5.85197 16.2894 6.01 15.9078 6.01 15.51V15.5C6.01 15.1022 5.85197 14.7206 5.57066 14.4393C5.28936 14.158 4.90782 14 4.51 14H4.5Z" fill="black" />
                        </svg>
                      </button>
                      {openDropdown === index && (
                        <div id="DoctorsDivActionMenu" ref={menuRef} className="absolute right-4 w-30 bg-white border border-gray-300 rounded-md z-10">
                          <ul>
                            <li
                              id="AdminUserButtonfacilityEdit"
                              className={`px-4 py-2 ${loggeduser.user_name == userdata.user_name || !userdata.user_verified ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100 cursor-pointer'} flex items-center gap-2`}
                              disabled={loggeduser.user_name == userdata.user_name || !userdata.user_verified}
                              onClick={() => { handleFacilityEdit(userdata); togglebutton(); }}
                            >
                              Edit
                            </li>
                            <li
                              id="DeleteUsersBtnfacility"
                              className={`px-4 py-2 ${loggeduser.user_name == userdata.user_name || !userdata.user_verified ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100 cursor-pointer'} flex items-center gap-2`}
                              disabled={loggeduser.user_name == userdata.user_name || !userdata.user_verified}
                              onClick={() => { setUserDetails(userdata); setDeletePopup(true); togglebutton(); }}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="lg:hidden">
            {userdata.map((userdata, index) => (
              <div className="grid grid-cols-1 gap-5" key={index}>
                <div className="bg-white rounded-lg p-2 shadow-md  h-auto">
                  <div className="px-2 py-[4px] text-left flex text-[17px] font-[600]">
                    <div id="UsersDivname">Name:</div>{" "}
                    <div id="UsersDivuserName" className="ml-2 text-[#616565]">
                      {userdata.user_name || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivemailId">EMail ID:</div>
                    <div id="UsersDivuserEmailId" className="ml-2 text-[#616565]">
                      {userdata.email_id || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivphoneNumber">PhoneNumber:</div>
                    <div id="UsersDivuserPhoneNumber" className="ml-2 text-[#616565]">
                      {userdata.phonenumber || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivrole">Role:</div>
                    <div id="UsersDivuserRole" className="ml-2 text-[#616565]">
                      {userdata.user_role || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivstatus">Status:</div>
                    <div id="UsersDivuserData" className="ml-2 text-[#616565]">
                      {userdata.user_verified == true ? "Verified" : "Pending"}
                    </div>
                  </div>
                  <div className="flex justify-between items-center px-2 py-[4px]">
                    {" "}
                    <Toggle
                      key={"usersactive-toggle-" + index}
                      user={userdata}
                      setRefreshData={setRefreshData}
                    />{" "}
                    <button id="UsersBtnfacilityEdit"
                      className=" w-6 h-6"
                      onClick={() => handleFacilityEdit(userdata)}
                    >
                      <svg id="UsersSvgfacilityEditIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 text-blue-600"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                      </svg>
                    </button>
                    <div className="flex justify-between items-center px-2 py-[4px]">
                      <button
                        id="MObDeleteUsersBtnfacility"
                        className="w-6 h-6 text-red-800 disabled:cursor-not-allowed text-[#9B1C1C] disabled:text-[#D88C8C]"
                        disabled={userdata.user_name === loggeduser.user_name}
                        onClick={(e) => {
                          setUserDetails(userdata);
                          setDeletePopup(true);
                        }}
                      >
                        <svg
                          id="MObuserDeleteActionIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-5 h-5 "
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {deletePopup && (
        <div
          id="UserDivconfirmBoxAppointment"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
        >
          <div
            id="UserssDivconfirmBoxAppointmentContentDialog"
            className="relative p-4 w-full max-w-md h-full md:h-auto"
          >
            <div
              id="UsersDivconfirmBoxContentDialogBox"
              className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5"
            >
              <svg
                id="UsersSvgconfirmBoxIcon"
                className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p
                id="UsersPconfirmBoxMessage"
                className={`mb-4 `}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Are you sure you want to delete this Account Permanently?
              </p>
              <div
                id="UsersDivconfirmBoxButtons"
                className="flex justify-center items-center space-x-4"
              >
                <button
                  id="UsersButtonconfirmBoxcancel"
                  onClick={() => setDeletePopup(false)}
                  disabled={disableButton}
                  type="button"
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.cancelBtnHoverColor}`
                    }
                  `}
                >
                  No, cancel
                </button>

                <button
                  id="UsersButtonconfirmBoxconfirm"
                  type="button"
                  onClick={handleSuperAdminDeleteUser}
                  disabled={disableButton}
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.saveBtnHoverColor}`
                    }`}
                >
                  Yes, I'm sure{" "}
                  {disableButton && (
                    <svg
                      id="UsersSvgconfirmBoxIcon"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showlimitPopup && (
        <div className="fixed inset-0 z-50 flex justify-center items-center p-8">
          <div className="absolute inset-0 bg-black opacity-25"></div>
          <div
            className="relative bg-white shadow-xl p-8 "
            style={{ width: "500px" }}
          >
            <div className="flex justify-between items-center mb-6">
              <div id="AddMembersDivinviteUser" className="text-red-700 font-bold text-md">
                Limit Exceeded
              </div>
              <button id="AddMembersButtonclose" className="text-red-700" onClick={closeUserLimitPopup}>
                <svg
                  id="AddMembersSvgcloseIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-10 w-10"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>

            <div
              id="UserslimitDivconfirmBoxContentDialogBox"
            >
              <p
                id="UserslimitPconfirmBoxMessage"
                className="mb-6 text-md font-semibold"
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                User Creation Limit Exceeded. Do you want to increase user limit?
              </p>
              <div
                id="UserslimitDivconfirmBoxButtons"
                className="flex justify-center items-center space-x-6"
              >
                <button
                  id="UserslimitButtonconfirmBoxconfirm"
                  type="button"
                  onClick={() => { setShowPopup(true) }}
                  disabled={disableButton}
                  className={`py-3 px-5 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ease-in-out
          ${previewTheme.saveBtnColor} 
          ${previewTheme.saveBtnBgColor} 
          ${disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.saveBtnHoverColor}`}`}
                >
                  Sure{" "}
                  {disableButton && (
                    <svg
                      id="UserslimitSvgconfirmBoxIcon"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            {showPopup && (
              <AddFacility
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                facilityguid={facilityID}
                setRefreshData={setRefreshData}
                setShowLimitPopup={setShowLimitPopup}
                userLimitPopup={true}
              />
            )}
          </div>
        </div>
      )}
      {toast.show && (
        <SnackBarComponent
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: '', type: '' })}
        />
      )}
    </div>
  );
};

export default Users;
