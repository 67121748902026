import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../EmraxisTheme/Theme';
import { IoMdSave } from "react-icons/io";
import store from '../API/store';
import DataDecode from '../utils/DataDecode';
import { setNotification } from '../Redux/features/toast/toastSlice';
import PhoneInput from '../Pages/PhoneInput';
import 'react-phone-input-2/lib/style.css';
import { addLoginDetails } from '../Redux/features/login/loginSlice';

const UserProfile = (facility, formstatus) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const dispatch = useDispatch();
    const { facility_user, user_email, user_role, user_phone } = useSelector(state => state.users);
    const [refreshData, setRefreshData] = useState(false);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var facilityEmail = useSelector(state => state.facility.facility_email);
    var facilityName = useSelector(state => state.facility.facility_name);
    const [expandedAbout, setAboutExpanded] = useState(false);
    const [expandedSkills, setSkillsExpanded] = useState(false);
    const [expandedEducation, setEducationExpanded] = useState(false);
    const [expandedExperience, setExperienceExpanded] = useState(false);
    const [dialCode, setDialCode] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [showUploadPopup, setShowUploadPopup] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [file, setFile] = useState(null);
    const [confirmBox, setConfirmBox] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const contentRef = useRef(null);
    const textareaAboutRef = useRef(null);
    const textareaSkillsRef = useRef(null);
    const textareaEducationRef = useRef(null);
    const textareaExperienceRef = useRef(null);
    var facilityGuid = useSelector(state => state.facility.facility_id)
    const [userDetails, setUserDetails] = useState({
        Username: formstatus == "edit" ? facility_user : loggeduser.user_name,
        UserEmailId: formstatus == "edit" ? user_email : facility != null ? facility.email_id : facilityEmail != "" ? facilityEmail : loggeduser.email_id,
        Facilityname: facility != null ? facility.facility_name : facilityName != "" ? facilityName : loggeduser.facility_name,
        UserMobilenumber: formstatus == "edit" ? user_phone : loggeduser.phone_number,
        Userrole: formstatus == "edit" ? user_role : facility != null ? "Admin" : "",
        Usersby: loggeduser.user_role == "SuperAdmin" ? "SuperAdmin" : loggeduser.user_name

    })
    const [userData, setUserData] = useState({
        id: 0,
        address: '',
        about: '',
        skills: '',
        education: '',
        experience: '',
        user_pic: '',
        clinic_guid: loggeduser.facilityID,
        user_details_id: loggeduser.userId,
    })
    const [facilitydata, setfacilitydata] = useState({
        clinic_guid: facilityGuid != "" ? facilityGuid : loggeduser.facilityID,
    })
    const fetchUserData = () => {
        store.GetMoreUserDetails(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, user_details_id: loggeduser.userId }))
            .then((result) => {
                if (result.status === 200) {
                    result.json().then((res) => {
                        try {
                            let response = DataDecode.decryptResponse(res);
                            let myObject = JSON.parse(response);
                            setUserData(myObject)
                            if (myObject.user_pic !== loggeduser.user_pic) {
                                dispatch(addLoginDetails({ ...loggeduser, user_pic: myObject.user_pic }));
                            }
                        } catch (error) {
                            dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
                            setUserData({});
                        }
                    });
                } else {
                    setUserData({});
                }
            })
            .catch(error => {
                dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
                setUserData({});
            });
    };
    useEffect(() => {
        if (loggeduser) {
            fetchUserData();
        }
    }, []);

    useEffect(() => {
        if (loggeduser) {
            setUserDetails({ ...userDetails, UserMobilenumber: loggeduser.phone_number });
        }
    }, [loggeduser]);

    function updateUserData() {
        store.UpdateUserDetails(loggedusertoken, DataDecode.encryptPayload(userData))
            .then((result) => {
                if (result.status === 204) {
                    fetchUserData();
                    dispatch(setNotification({ message: 'User Information Updated Successfully!', status: 'success', action: true }));
                    setRefreshData(r => !r);

                }
                else {
                    dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                }
            })
            .catch((error) => {
                dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
            });
    }
    const updatePhoneNumber = async () => {
        let details = { userDetails: userDetails, facility: (facility == null ? facilitydata : facility) }
        await store.SaveUserDetails(loggedusertoken, DataDecode.encryptPayload(details))
            .then((result) => {
                if (result.status === 200) {
                    setUserDetails();
                    dispatch(addLoginDetails({ ...loggeduser, phone_number: userDetails.UserMobilenumber }));
                    dispatch(setNotification({ message: 'User Information Updated Successfully!', status: 'success', action: true }));
                    setRefreshData(r => !r);
                }
                else {
                    dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                }
            })
            .catch((error) => {
                dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
            });
    }
    const handleEditClick = () => {
        setIsEditing(true);
        adjustTextareaHeight();
    };
    const handleGlobalSave = () => {
        updateUserData();
        updatePhoneNumber();
        setIsEditing(false)
    };

    const handlePhoneChange = (value, data) => {
        const formattedValue = value.replace(data.dialCode, `${data.dialCode} `);
        setDialCode(data.dialCode);
        setUserDetails((userDetails) => ({
            ...userDetails,
            UserMobilenumber: formattedValue,
        }));
    };
    const handleChange = (e) => {
        setUserData({
            ...userData,
            clinic_guid: loggeduser.facilityID, user_details_id: loggeduser.userId,
            [e.target.name]: e.target.value
        });
    };

    const handleAddressToggle = () => {
        setIsExpanded(!isExpanded);
    };
    const handleImageClick = () => {
        setShowOptions(true);
    };

    const handleCloseOptions = () => {
        setShowOptions(false);
    };

    const handleOpenUploadPopup = () => {
        setShowUploadPopup(true);
        handleCloseOptions();
    };

    const handleCloseUploadPopup = () => {
        setShowUploadPopup(false);
        setPreviewImage(null);
        setFile(null);
    };

    const handleProfilePicChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleClickFileInput = () => {
        document.getElementById('file-input').click();
    };

    const handleRemoveImage = () => {
        setConfirmBox(true);
        setShowOptions(false);
        setPreviewImage(null);
        setFile(null);
    };
    const handleDeleteSubmit = () => {
        setDisableButton(true);
        store.deleteUserPic(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'user_details_id': loggeduser.userId }))
            .then((result) => {
                if (result.status == 204) {
                    dispatch(addLoginDetails({ ...loggeduser, user_pic: result.user_pic }));
                    setUserData({ ...userData, user_pic: "" })
                    setRefreshData(r => !r);
                    dispatch(setNotification({ message: 'User pic deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }
                setDisableButton(true);
                setConfirmBox(false);
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    function handleUpload() {
        if (file) {
            const formData = new FormData();
            formData.append("files", file);

            const payload = {
                clinic_guid: loggeduser.facilityID,
                user_details_id: loggeduser.userId
            };

            const encData = JSON.parse(DataDecode.encryptPayload(payload));
            formData.append('iv', encData.iv);
            formData.append('payload', encData.payload);

            store.AddUserPic(loggedusertoken, formData)
                .then((result) => {
                    if (result.status == 204) {
                        dispatch(setNotification({ message: 'Image Uploaded Successfully!', status: 'success', action: true }));
                        setPreviewImage(null);
                        setFile(null);
                        setRefreshData(r => !r);
                        setShowUploadPopup(false);
                        fetchUserData();
                        dispatch(addLoginDetails({ ...loggeduser, user_pic: formData.user_pic }));
                    } else {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    }
                })
                .catch((err) => {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                });
        }
    }

    const textareaRef = useRef(null);

    const adjustTextareaHeight = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto'; 
            textarea.style.height = Math.min(textarea.scrollHeight, 500) + 'px'; 
        }
    };

    useEffect(() => {
        adjustTextareaHeight(textareaAboutRef.current);
        adjustTextareaHeight(textareaSkillsRef.current);
        adjustTextareaHeight(textareaEducationRef.current);
        adjustTextareaHeight(textareaExperienceRef.current);
    }, [userData.about, userData.skills, userData.education, userData.experience]);

    const aboutExpand = () => {
        setAboutExpanded(!expandedAbout);
    };
    const skillsExpand = () => {
        setSkillsExpanded(!expandedSkills)
    }
    const educationExpand = () => {
        setEducationExpanded(!expandedEducation)
    }
    const experienceExpand = () => {
        setExperienceExpanded(!expandedExperience)
    }
    const maxLength = 20;
    return (

        <div id="UserProfileDivuserProfileSection"
            className=" mx-auto px-4 py-2 my-20 w-100% pb-20">

            <div id="UserProfileDivuserProfileHeader" className="flex justify-between items-center mb-4">
                <header id="UserProfileHeaderuserProfileTitle" className={`font-bold text-2xl  ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                <button id='UserProfileBack' className=' mr-2' onClick={() => { window.history.back() }}>
                  <svg id="MobPatientProfileSvgmobReloadIcon"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 12" fill="none">
                    <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" className="fill-current" />
                  </svg>
                </button>User Profile</header>
            </div>
            <div className="flex justify-center items-center">
                <div className='border border-gray-200 rounded-sm shadow mt-2 py-4 px-3 w-full id="UserProfileDivuserProfileDetails"'>
                    <div className="flex flex-col items-center mt-2 mb-2">
                        <div id="UserProfileDivuserImage" className="p-4 flex flex-col sm:flex-row">
                            {userData.user_pic ? (
                                <img
                                    id="UserProfileIMGuserImage"
                                    className="w-20 h-20 rounded-full flex items-center cursor-pointer"
                                    src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${userData.user_pic}`}
                                    alt="user"
                                    onClick={handleImageClick}
                                />
                            ) : (
                                <img
                                    id="UserProfileIMGdefaultImage"
                                    className="w-20 h-20 rounded-full flex items-center cursor-pointer"
                                    src="./image/user.png"
                                    alt="default user"
                                    onClick={handleImageClick}
                                />
                            )}
                        </div>
                        {showOptions && (
                            <div className="flex gap-1">
                                <button
                                    className={`py-1 px-1 mb-2 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md hover:${previewTheme.saveBtnHoverBgColor}`}
                                    onClick={handleOpenUploadPopup}
                                >
                                    Upload
                                </button>
                                <button
                                    className={`py-1 px-1 mb-2 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} transition rounded-md hover:${previewTheme.cancelBtnHoverBgColor}`}
                                    onClick={handleRemoveImage}
                                >
                                    Remove
                                </button>
                            </div>
                        )}
                        {showUploadPopup && (
                            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
                                <div className="absolute inset-0 bg-black opacity-25"></div>
                                <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "600px" }}>
                                    <div className="text-right">
                                        <button
                                            className="text-red-500 hover:text-red-700"
                                            onClick={handleCloseUploadPopup}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2 mt-2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='min-h-[200px] pl-8 pr-8'>
                                        <span className='text-lg font-medium text-gray-700 mb-2'>Please upload a profile picture</span>
                                        <div className="flex flex-col items-center border border-dashed border-green-400 p-2 rounded-md">
                                            <input
                                                id="file-input"
                                                type="file"
                                                onChange={handleProfilePicChange}
                                                accept="image/*"
                                                className="hidden"
                                            />
                                            <div
                                                onClick={handleClickFileInput}
                                                className="flex justify-center items-center cursor-pointer my-2"
                                            >
                                                {!previewImage ? (
                                                    <div>
                                                        <img src="./images/upload-icon.png" className="w-16 h-auto" alt="User Image" />
                                                        <p className='text-center font-bold cursor-pointer'>
                                                            <span className='text-red-800'>Browse</span>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col items-center">
                                                        <img src={previewImage} alt="Selected" className="w-32 h-32 object-cover rounded-full" />
                                                        <p className='text-center font-bold mt-2'>
                                                            <span className='text-green-600'>User profile Picture</span>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {previewImage && (
                                            <div className="mt-4 mb-2 text-center">
                                                <button
                                                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                                                    onClick={handleUpload}
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {confirmBox && (
                            <div id="UserPicDivConfirmBox" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%] sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%]">
                                <div id="UserPicDivConfirmBoxContentDialog" className="relative p-4 w-full max-w-md h-full md:h-auto">
                                    <div id="UserPicDivConfirmBoxContentDialogBox" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                                        <svg id="UserPicSvgConfirmBoxIcon" className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                        </svg>
                                        <p id="UserPicPConfirmBoxMessage" className={`mb-4`} style={{ fontFamily: previewTheme.fontFamily }}>Are you sure you want to delete this profile picture?</p>
                                        <div id="UserPicDivConfirmBoxButtons" className="flex justify-center items-center space-x-4">
                                            <button
                                                id="UserPicButtonConfirmBoxCancel"
                                                onClick={() => setConfirmBox(false)}
                                                className={`py-2 px-3 text-sm font-medium rounded-lg border ${previewTheme?.cancelBtnColor || 'text-gray-700'} ${previewTheme?.cancelBtnBgColor || 'bg-white'} ${disableButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            >
                                                No, cancel
                                            </button>
                                            <button
                                                id="UserPicButtonConfirmBoxConfirm"
                                                type="button"
                                                onClick={handleDeleteSubmit}
                                                className={`py-2 px-3 text-sm font-medium rounded-lg border ${previewTheme?.saveBtnColor || 'text-white'} ${previewTheme?.saveBtnBgColor || 'bg-red-600'} ${disableButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            >
                                                Yes, I'm sure
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div id="UserProfileDivuserName" className='sm:flex items-center'> {loggeduser.user_name}</div>
                    </div>
                    <div id="UserProfileDivuserRole" className='flex lg:w-1/2 justify-center lg:max-w-md mx-auto'>
                        <div className='px-4 py-2'>
                            <>
                                <div className='flex items-center p-1'>
                                    <span id="UserProfileSpanfacilityText" className='mr-2 w-[70px]'>Facility:</span><span id="UserProfileSpanuserFacility">{loggeduser.facility_name}</span>
                                </div>
                                <div className='flex items-center p-1'>
                                    <span id="UserProfileSpanroleText" className='mr-2 w-[70px]'>Role:</span><span id="UserProfileSpanuserRole">{loggeduser.user_role}</span>
                                </div>
                                <div className='p-1' id="UserProfileDivuserAddress">
                                    <div className='flex items-center'>
                                        <span id="UserProfileSpanAddressText" className=' w-[70px]'>Address:</span>
                                        <div className='flex-1 flex items-center'>
                                            {isEditing ? (
                                                <div>
                                                    <input id="UserProfileInputuserAddress"
                                                        placeholder="Characters should not exceed more than 500."
                                                        className="ml-2 border border-gray-300 rounded-lg w-full sm:w-auto"
                                                        type="text"
                                                        name="address"
                                                        value={userData.address || ''}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (inputValue.length <= 500) {
                                                                handleChange(e);
                                                            } else {
                                                                handleChange({
                                                                    target: {
                                                                        name: 'address',
                                                                        value: inputValue.slice(0, 500)
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                        maxLength={500}
                                                    />
                                                    <div className="text-right text-xs text-gray-500">
                                                        {(userData.address || '').length} / 500 characters
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='p-1'>
                                                    {userData.address ? (
                                                        isExpanded ? (
                                                            userData.address.split('\n').map((line, index) => (
                                                                <div style={{ width: '200px', overflowWrap: 'break-word' }} id={`address-line-${index}`} key={index}>{line}</div>
                                                            ))
                                                        ) : (
                                                            <div>
                                                                {userData.address.length > maxLength ? (
                                                                    <>
                                                                        {userData.address.substring(0, maxLength)}
                                                                        <span>...</span>
                                                                    </>
                                                                ) : (
                                                                    userData.address
                                                                )}
                                                            </div>
                                                        )
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center p-1'>
                                    <span className='mr-2 w-[70px]'></span>
                                    {!isEditing && userData.address && userData.address.length > maxLength && (
                                        <div className='flex-1'>
                                            <button id="UserProfileBtnaddress" onClick={handleAddressToggle} className="text-blue-500 text-sm">
                                                {isExpanded ? 'View Less' : 'View More'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='flex items-center p-1'>
                                    <span id="UserProfileSpanmobText" className='mr-2 w-[70px]'>Mobile:</span>
                                    {isEditing ? (
                                        <PhoneInput
                                            country={'in'}
                                            enableSearch={true}
                                            onlyCountries={["in"]}
                                            disableDropdown={true}
                                            placeholder="Mobile Number"
                                            phone={userDetails.UserMobilenumber}
                                            onPhonechange={handlePhoneChange}
                                            inputProps={{
                                                name: 'Phone',
                                                id: 'PhoneInputPhoneNumber',
                                                className: "block w-full border rounded-md focus:outline-none focus:border-gray-400 pl-14 py-2"
                                            }}
                                            specialLabel=""
                                        />
                                    ) : (
                                        <div id="UserProfileDivuserMobNumber">
                                            {userDetails.UserMobilenumber ? (
                                                <>
                                                    +{dialCode}{userDetails.UserMobilenumber.replace(dialCode, '')}
                                                </>
                                            ) : ''}

                                        </div>
                                    )}
                                </div>
                                <div className='flex items-center p-1'>
                                    <span id="UserProfileSpanemailId" className='mr-2 w-[70px]'>Email:</span><span>{loggeduser.email_id}</span>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <>
                <div className='border border-gray-200 rounded-sm shadow mt-2 w-full py-4 px-3'>
                    <div className='pb-1 border-b border-gray-400 w-2/3'>
                        <h1 id="UserProfileH1aboutTitle" className="font-medium text-2xl flex items-center">
                            About
                        </h1>
                    </div>
                    {isEditing ? (
                        <div>
                            <textarea id="UserProfileTextareaAbout"
                                name='about'
                                placeholder='Characters should not exceed more than 1000.'
                                value={userData.about || ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 1000) {
                                        handleChange(e);
                                        adjustTextareaHeight(textareaAboutRef.current);
                                    } else {
                                        adjustTextareaHeight(textareaAboutRef.current);
                                        handleChange({
                                            target: {
                                                name: 'about',
                                                value: inputValue.slice(0, 1000)
                                            }
                                        });
                                    }
                                }}
                                className="w-full mt-2 overflow-hidden"
                                ref={textareaAboutRef}
                                maxLength={1000}
                            />
                            <div className="text-right text-sm text-gray-500 mt-1">
                                {(userData.about || '').length} / 1000 characters
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '100%', wordBreak: 'break-word' }}>
                            <div id="UserProfileDivuserDataAbout"
                                ref={contentRef}
                                className={`mt-2 ${expandedAbout ? '' : 'line-clamp-1'}`}
                            >
                                {userData.about || ''}
                            </div>
                            {userData.about && userData.about.length > maxLength && (
                                <button id="UserProfileBtnexpandAbout"
                                    onClick={aboutExpand}
                                    className="text-blue-500 hover:text-blue-700 mt-1 text-sm"
                                >
                                    {expandedAbout ? 'View Less' : 'View More'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className='border border-gray-200 rounded-sm shadow mt-2 w-full py-4 px-3'>
                    <div className='pb-1 border-b border-gray-400 w-2/3'>
                        <h1 id="UserProfileH1skillsText" className="font-medium text-2xl flex items-center">
                            Skills
                        </h1>
                    </div>
                    {isEditing ? (
                        <div>
                            <textarea id="UserProfiletextAreaSkill"
                                name='skills'
                                placeholder='Characters should not exceed more than 1000.'
                                value={userData.skills || ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 1000) {
                                        handleChange(e);
                                        adjustTextareaHeight(textareaSkillsRef.current);
                                    } else {
                                        adjustTextareaHeight(textareaSkillsRef.current);
                                        handleChange({
                                            target: {
                                                name: 'skills',
                                                value: inputValue.slice(0, 1000)
                                            }
                                        });
                                    }
                                }}
                                className="w-full mt-2 overflow-hidden"
                                ref={textareaSkillsRef}
                            />
                            <div className="text-right text-sm text-gray-500 mt-1">
                                {(userData.skills || '').length} / 1000 characters
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '100%', wordBreak: 'break-word' }}>
                            <div id="UserProfileDivuserDataSkill"
                                ref={contentRef}
                                className={`mt-2 ${expandedSkills ? '' : 'line-clamp-1'}`}
                            >
                                {userData.skills || ''}
                            </div>
                            {userData.skills && userData.skills.length > maxLength && (
                                <button id="UserProfileBtnSkillExpand"
                                    onClick={skillsExpand}
                                    className="text-blue-500 hover:text-blue-700 mt-1 text-sm"
                                >
                                    {expandedSkills ? 'View Less' : 'View More'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </>

            <>
                <div className='border border-gray-200 rounded-sm shadow mt-2 w-full py-4 px-3'>
                    <div className='pb-1 border-b border-gray-400 w-2/3'>
                        <h1 id="UserProfileH1educationText" className="font-medium text-2xl flex items-center">
                            Education
                        </h1>
                    </div>
                    {isEditing ? (
                        <div>
                            <textarea id="UserProfileTextAreaEducation"
                                name='education'
                                placeholder='Characters should not exceed more than 1000.'
                                value={userData.education || ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 1000) {
                                        handleChange(e);
                                        adjustTextareaHeight(textareaEducationRef.current);
                                    } else {
                                        adjustTextareaHeight(textareaEducationRef.current);
                                        handleChange({
                                            target: {
                                                name: 'education',
                                                value: inputValue.slice(0, 1000)
                                            }
                                        });
                                    }
                                }}
                                className="w-full mt-2 overflow-hidden"
                                ref={textareaEducationRef}
                            />
                            <div className="text-right text-sm text-gray-500 mt-1">
                                {(userData.education || '').length} / 1000 characters
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '100%', wordBreak: 'break-word' }} >
                            <div id="UserProfileDivuserdataEducation"
                                ref={contentRef}
                                className={`mt-2 ${expandedEducation ? '' : 'line-clamp-1'}`}
                            >
                                {userData.education || ''}
                            </div>
                            {userData.education && userData.education.length > maxLength && (
                                <button id="UserProfileBtneducationExpand"
                                    onClick={educationExpand}
                                    className="text-blue-500 hover:text-blue-700 mt-1 text-sm"
                                >
                                    {expandedEducation ? 'View Less' : 'View More'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className='border border-gray-200 rounded-sm shadow mt-2 w-full py-4 px-3'>
                    <div className='pb-1 border-b border-gray-400 w-2/3'>
                        <h1 id="UserProfileH1Experience" className="font-medium text-2xl flex items-center">
                            Experience
                        </h1>
                    </div>
                    {isEditing ? (
                        <div>
                            <textarea id="UserProfileTextAreaExperience"
                                name='experience'
                                placeholder='Characters should not exceed more than 1000.'
                                value={userData.experience || ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 1000) {
                                        handleChange(e);
                                        adjustTextareaHeight(textareaExperienceRef.current);
                                    } else {
                                        adjustTextareaHeight(textareaExperienceRef.current);
                                        handleChange({
                                            target: {
                                                name: 'experience',
                                                value: inputValue.slice(0, 1000)
                                            }
                                        });
                                    }
                                }}
                                className="w-full mt-2 overflow-hidden"
                                ref={textareaExperienceRef}
                            />
                            <div className="text-right text-sm text-gray-500 mt-1">
                                {(userData.experience || '').length} / 1000 characters
                            </div>
                        </div>

                    ) : (
                        <div style={{ width: '100%', wordBreak: 'break-word' }}>
                            <div id="UserProfileDivuserDataExperience"
                                ref={contentRef}
                                className={`mt-2 ${expandedExperience ? '' : 'line-clamp-1'}`}
                            >
                                {userData.experience || ''}
                            </div>
                            {userData.experience && userData.experience.length > maxLength && (
                                <button id="UserProfileBtnExpandexperience"
                                    onClick={experienceExpand}
                                    className="text-blue-500 hover:text-blue-700 mt-1 text-sm"
                                >
                                    {expandedExperience ? 'View Less' : 'View More'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </>
            <div className="fixed bottom-24 right-4 z-40">
                {!isEditing ? (
                    <button
                    id='EditUserData'
                        className={`${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor} p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors`} 
                        onClick={handleEditClick}
                    >
                        Edit
                    </button>
                ) : (
                    <button
                    id='saveUserData'
                        className={`${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor} p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors`}
                        onClick={handleGlobalSave}
                    >
                        Save
                    </button>
                )}
            </div>
        </div>
    )
}
export default UserProfile;