import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PastHistoryDetails from './Profile/VisitHistory/PastHistoryDetails';


const PastHistories = () => {
    const [pastHistory, setPastHistory] = useState([]);
    const [viewHistory, setViewHistory] = useState(false);
    const [patient, setPatient] = useState({});
    const [appointmentId, setAppointmentId] = useState(null);
    const [appointment, setAppointment] = useState({});
    const [doctors, setDoctors] = useState({});

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
const queryParamValue = queryParams.get('history');
const { appointment_id, patient_id, doctor_id, clinic_guid ,viewHistoryData} = JSON.parse(atob(queryParamValue));

if (queryParamValue) {
    const decodedData = JSON.parse(decodeURIComponent(atob(queryParamValue)));
    const { appointment_id, patient_id, clinic_guid, viewHistoryData } = decodedData;
    
}
   
    useEffect(() => {
        setAppointmentId(appointment_id);
          
        setPatient({});
        setPastHistory({});
        
        setViewHistory(true);
        setAppointmentId(appointment_id);
    }, [appointment_id]);


    return (
        <div>
           
                <PastHistoryDetails
                    setViewHistory={setViewHistory} 
                    viewHistoryData={viewHistoryData} 
                    patient={patient} 
                    doctors={doctors}
                    patient_id={patient_id}
                    appointment_id={appointmentId} 
                    appointment={appointment}
                />
                
          
        </div>
    );
}

export default PastHistories;
