import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Doctors from './Doctors';

const DoctorsViewport = () => {
    const [isMobile, setIsMobile] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        setIsMobile(mediaQuery.matches);

        const handleMediaChange = (e) => {
            if (e.matches) {
                navigate('/doctors');
            } else {
                navigate('/settings')
            }
            setIsMobile(e.matches);
        };

        mediaQuery.addListener(handleMediaChange);
        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);

    return (
        <>
            <Doctors />
        </>
    )
};

export default DoctorsViewport;
