import React from "react";
import { addFeedback } from "../Redux/features/feedback/feedbackSlice";
import { useSelector, useDispatch } from "react-redux";
import FooterImg from "./Assets/homeImages/footerbgImage.jpg";
import MobileFooterImg from "./Assets/homeImages/mobileFooterbgImage.jpg";
import DateFunction from "../utils/DateFunctions";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";

const Footer = () => {
	const dispatch = useDispatch();
	var loggeduser = useSelector((state) => state.login.loginuserData);
	const handleFeedback = () => {
		dispatch(addFeedback(true));
	};
	const currentYear = new Date().getFullYear();

	return (
		<>
			{loggeduser != null && loggeduser.length != 0 ? (
				<>
					<footer
						id="FooterFooterfooterTab"
						className="shadow fixed bottom-0 w-full z-10"
					>
						<div className="mx-auto">
							<div className="bg-black text-white flex justify-between items-center h-10">
								<div className="flex-shrink-0"></div>
								<div id="FooterDivcopyRights" className="text-center w-full">
									<p id="FooterPcopyRights" className="text-sm text-white">
										&copy; Copyrights {DateFunction.GetYear()} EMR AXIS, LLC All
										rights reserved.
									</p>
								</div>
							</div>
						</div>
					</footer>
				</>
			) : (
				loggeduser.user_role != "SuperAdmin" && (
					<>
						<div className=" custom-bg bg-cover bg-center h-[45vh] min-[400px]:h-[37vh] min-[520px]:h-[38vh] md:h-[30vh]  lg:h-[55vh]  w-[100%] pt-8  block min-[900px]:hidden bg-[#ECF0FF]">
							<div className="w-[100%] px-4">
								<div className="flex-shrink-0">
									<a
										href={
											process.env.REACT_APP_EMRAXIS_MS_AUTH_SPA_REDIRECT_URL
										}
									>
										<img
											id="PageLayoutImglogo"
											className="h-6 lg:h-9 w-auto"
											src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/logo.png`}
											alt="Logo"
										/>
									</a>
								</div>
								<div
									id="FooterDivtotalPatient"
									className="text-[16px] md:text-[20px] text-[#282358] pt-2 font-inter"
								>
									Total Patient Engagement and Medical Records management
									solution with automated voice based clinical notes
								</div>
								<div className="flex mt-8 items-center ">
									<div className="text-[black]  hover:cursor-pointer pr-4 border-r-2 border-black font-inter">
										<a
											id="AtermsofService2"
											className="!text-[black] underline"
											href="/terms-of-service"
											rel="Terms of Service"
										>
											Terms of Service
										</a>
									</div>
									<div className="text-[black] hover:cursor-pointer pl-4 font-inter">
										<a
											id="FooterAprivacyPolicy2"
											className="!text-[black] underline"
											href="/privacy"
											rel="Privacy Policy"
										>
											Privacy Policy
										</a>
									</div>
								</div>
							</div>
							<div
								id="FooterDivcopyRights2"
								className="mb-4 mt-4 flex justify-center items-center text-[#fc799c] text-[16px] font-semibold font-inter border-t-2 border-gray-600"
							>
								EMR AXIS {DateFunction.GetYear()} &copy; All rights reserved.
							</div>
							<div class="mt-4 flex space-x-4 justify-center">
								<a
									id="facebookLink"
									href="https://www.facebook.com/emraxis/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="rounded-full p-2 bg-[#ccd4ec]">
										<FaFacebookF />
									</div>
								</a>
								<a
									id="instagramLink"
									href="https://www.instagram.com/emr.axis/?igsh=MTM3Zmh2aWk1MHl6dA%3D%3D"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="rounded-full p-2 bg-[#ccd4ec]">
										<FaInstagram />
									</div>
								</a>
								<a
									id="linkedInLink"
									href="https://www.linkedin.com/company/emr-axis"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="rounded-full p-2 bg-[#ccd4ec]">
										<BiLogoLinkedin />
									</div>
								</a>
							</div>
						</div>
						<div className="hidden lg:block">
							<div className=" py-10 pb-4 bg-[#ECF0FF] px-10">
								<div className="flex-shrink-0">
									<a
										href={
											process.env.REACT_APP_EMRAXIS_MS_AUTH_SPA_REDIRECT_URL
										}
									>
										<img
											id="PageLayoutImglogodesktop"
											className="h-6 lg:h-9 w-auto"
											src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/logo.png`}
											alt="Logo"
										/>
									</a>
								</div>
								<div className="flex justify-between w-full pb-8 mt-4">
									<div className="w-1/2 pb-4">
										<div className="text-lg lg:text-base 2xl:text-xl text-[#282358] font-normal">
											Total Patient Engagement and Medical Records management
											solution with automated voice based clinical notes
										</div>
									</div>
									<div className="flex justify-end flex-col mb-8 ">
										<div className="text-[#282358] font-semibold text-base 2xl:text-xl px-4">
											<a
												href="/terms-of-service"
												className="text-[#fc799c] underline"
											>
												Terms & Conditions
											</a>
										</div>
										<div className="text-white font-semibold text-base 2xl:text-xl px-4">
											<a href="/privacy" className="text-[#fc799c] underline">
												Privacy Policy
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-[#282358] text-white py-4 px-6 ">
								<div className="flex justify-between items-center">
									<div>
										EMR AXIS {new Date().getFullYear()} &copy; All rights reserved.
									</div>
									<div className="flex space-x-4">
										<a
											id="facebookLinkdesktop"
											href="https://www.facebook.com/emraxis/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="rounded-full p-2 bg-[#3d3a5a] text-white">
												<FaFacebookF />
											</div>
										</a>
										<a
											id="instagramLinkdesktop"
											href="https://www.instagram.com/emr.axis/?igsh=MTM3Zmh2aWk1MHl6dA%3D%3D"
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="rounded-full p-2 bg-[#3d3a5a] text-white">
												<FaInstagram />
											</div>
										</a>
										<a
											id="linkedInLinkdesktop"
											href="https://www.linkedin.com/company/emr-axis"
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="rounded-full p-2 bg-[#3d3a5a] text-white">
												<BiLogoLinkedin />
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</>
				)
			)}
		</>
	);
};

export default Footer;
