import { createSlice } from '@reduxjs/toolkit';

const profileSideNavbarSlice = createSlice({
    name: 'profileSideNavbar',
    initialState: {
        sidebar: { vitals: false, doctorNotes: false, visitHistory: false, prescription: false, uploadedData: false, labNotes: false, radiology: false, pastHistory:false },
        visitHistory: { historyVitals: false, historyDoctorNotes: false,  historyPrescription: false, historyUploadedData: false, historyLabNotes: false, historyRadiology: false },
        visitPastHistory:{ historyPastVitals: false, historyPastDoctorNotes: false,  historyPastPrescription: false, historyPastUploadedData: false, historyPastLabNotes: false, historyPastRadiology: false },
        autoSaveAll: false,
        selectedAppointment:[],
        activeNavbar: "personal-details",
        activeHistoryNavbar:"personal-details",
        activeSubHistoryNavbar:"personal-details",
        templateName:"history_&_physical",
        defaultTemplateNames:[
            "CHIEF COMPLAINT",
            "HISTORY OF PRESENT ILLNESS",
            "PAST MEDICAL HISTORY",
            "PAST SURGICAL HISTORY",
            "HOME MEDICATIONS",
            "ALLERGIES",
            "SOCIAL HISTORY",
            "FAMILY HISTORY",
            "REVIEW OF SYSTEMS",
            "PHYSICAL EXAMINATION",
            "DIAGNOSTIC STUDIES",
            "DIAGNOSTIC RADIOLOGY",
            "ASSESSMENT AND PLAN"
        ],
        isTemplateview:false,
        isDoctorNotesDataAvailable: false,
        isVisitHistoryDataAvailable: false,
        isPrescriptionDataAvailabale: false,
        isUploadedDataAvailable: false,
        isLabNotesDataAvailable: false,
        isRadiologyDataAvailable: false,
        appoinmentDataValue:[],
        patientProfileMenuSelector: "",
        isGlobalSaveRequired: false,
        isSideNavbarClicked: false,
        newAppointmentId:"",
        historyVisitData:[],
        pastPrescriptionMob:[],        
        loading: false,
        currentVisitOpen:true,
        newDoctor:[],
        appointmentdata:{
            appointmentId:'',patientId:'',patientName:''
        },
        prescriptions:[],
        newPastHistory:[]
    },
    reducers: {
        
        setProfileSideNavbar: (state, action) => {
            state.sidebar = action.payload;
        },
        setPrescriptions(state, action) {
            state.prescriptions = action.payload;
          },
          addPrescription(state, action) {
            state.prescriptions = [...state.prescriptions, action.payload]; // Add new prescription
          },
        setVisitHistorySideNavbar: (state,action) => {
            state.visitHistory = action.payload;
        },
        setCurrentVisit: (state,action) => {
            state.currentVisitOpen = action.payload;
        },
        setVisitPastHistorySideNavbar: (state,action) => {
            state.visitPastHistory = action.payload;
        },
        setAppoinmentDataValue:(state,action) => {
            state.appoinmentDataValue = action.payload;
        },
        setNewPastHistory: (state,action) => {
            state.newPastHistory = action.payload;
        },
        setSelectedAppointment: (state,action) => {
            state.selectedAppointment = action.payload;
        },
        setAutoSaveAll: (state, action) => {
            state.autoSaveAll = action.payload;
        },
        setNewAppointmentId: (state, action) => {
            state.newAppointmentId = action.payload;
        },
        setActiveNavbar: (state, action) => {
            state.activeNavbar = action.payload;
        },
        setHistoryVisitData: (state, action) => {
            state.historyVisitData = action.payload;
        },
        startLoading(state) {
            state.loading = true;
        },
       
        setPastPrescriptionMob: (state, action) => {
            state.pastPrescriptionMob = action.payload;
            state.loading = false;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setNewDoctors(state,action) {
            state.newDoctor =  action.payload;;
        },
        setActiveHistoryNavbar: (state, action) => {
            state.activeHistoryNavbar = action.payload;
        },
        setActiveSubHistoryNavbar: (state, action) => {
            state.activeSubHistoryNavbar = action.payload;
        },
        setDoctorNotesDataAvailable: (state, action) => {
            state.isDoctorNotesDataAvailable = action.payload;
        },
       
        setVisitHistoryDataAvailable: (state, action) => {
            state.isVisitHistoryDataAvailable = action.payload;
        },
        setPrescriptionHistoryDataAvailabale: (state, action) => {
            state.isPrescriptionHistoryDataAvailabale = action.payload;
        },
        setPrescriptionDataAvailabale: (state, action) => {
            state.isPrescriptionDataAvailabale = action.payload;
        },
        
        setUploadedDataAvailable: (state, action) => {
            state.isUploadedDataAvailable = action.payload;
        },
       
        setLabNotesDataAvailable: (state, action) => {
            state.isLabNotesDataAvailable = action.payload;
        },
      
        setRadiologyDataAvailable: (state, action) => {
            state.isRadiologyDataAvailable = action.payload;
        },
        setPatientProfileMenuSelector: (state, action) => {
            state.patientProfileMenuSelector = action.payload;
        },
        setIsGlobalSaveRequired: (state, action) => {
            state.isGlobalSaveRequired = action.payload;
        },
        setIsSideNavbarClicked: (state, action) => {
            state.isSideNavbarClicked = action.payload;
        },
        setAppointmentdata:(state,action)=>{
           state.appointmentdata = action.payload;
        },
        setIsTemplateview:(state,action)=>{
            state.isTemplateview = action.payload;
        },
        setTemplateName:(state,action)=>{
            state.templateName=action.payload;
        },
        setDefaultTemplateNames:(state,action)=>{
            state.defaultTemplateNames = action.payload;
        }
    },
});

export const {setAppointmentdata,setNewDoctors,setCurrentVisit,setHistoryVisitData,setNewPastHistory,addPrescription,startLoading,stopLoading,setPastPrescriptionMob,setNewAppointmentId,setAppoinmentDataValue, setVisitPastHistorySideNavbar,setActiveHistoryNavbar,setActiveSubHistoryNavbar,setProfileSideNavbar,setVisitHistorySideNavbar,setSelectedAppointment, setAutoSaveAll, setActiveNavbar, setDoctorNotesDataAvailable,setLabNotesDataAvailable,setPrescriptionDataAvailabale,setRadiologyDataAvailable,setVisitHistoryDataAvailable, setUploadedDataAvailable, setPatientProfileMenuSelector, setIsGlobalSaveRequired, setIsSideNavbarClicked,setIsTemplateview,setTemplateName ,setDefaultTemplateNames} = profileSideNavbarSlice.actions;
export default profileSideNavbarSlice.reducer;