import React, { useState, useEffect, useRef } from 'react';
import store from '../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../utils/DataDecode';
import AddDepartment from '../Pages/AddDepartment';
import { setNotification } from '../Redux/features/toast/toastSlice';
import { setFont, setTheme } from '../Redux/features/colors/themeSlice';
import ToastMessage from '../components/ToastMessage';
import { font_family, theme } from '../EmraxisTheme/Theme';
import { addLoginDetails } from '../Redux/features/login/loginSlice';
import UploadLogo from './Facility/UploadLogo';
import DoctorsViewport from './Doctor/DoctorViewPort';
import UsersViewPort from './AdminUserManagement/UsersViewPort';
import ActivityLogsViewPort from './UserActivity/ActivityLogsViewPort';
import { setSettingsActiveTab } from '../Redux/features/settingsTabs/settingsTabsSlice';

const Settings = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updatedTheme, setUpdatedTheme] = useState({});
  const [disableThemeButton, setDisableThemeButton] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const settingsActiveTab = useSelector(state => state.settingsTabs.settingsActiveTab)
  const previewTheme = useSelector(state => state.theme.colors);
  const previewFont = useSelector(state => state.theme.fontFamily);
  const [selectedTheme, setSelectedTheme] = useState();
  const [currentTheme, setCurrentTheme] = useState(previewTheme);
  const [fontFamily, setFontFamily] = useState(previewFont?.fontFamily || '');
  const [savedTheme, setSavedTheme] = useState({
    theme: '',
    font_family: ''

  });
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [isUploadLogo, setIsUploadLogo] = useState(false);
  const [file, setFile] = useState(null);
  const [fileRefresh, setFileRefresh] = useState(false);
  const [facilityData, setFacilityData] = useState({});
  const menuRef = useRef();
  useEffect(() => {
    const themeToPreview = theme.filter(t => t.name === selectedTheme)[0] || previewTheme;
    const themeToFont = font_family.filter(t => t.name === selectedTheme) || previewFont;
    setCurrentTheme(themeToFont);
    setCurrentTheme(themeToPreview);
  }, [selectedTheme]);
  const handleSave = () => {
    setDisableThemeButton(true);
    UpdateTheme(savedTheme);
    setDisableThemeButton(false);
    dispatch(setNotification({ message: 'Theme changed Successfully!', status: 'success', action: true }));
    setShowConfirmation(false);
  };
  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };
  const closeSearch = () => {
    setShowSearchBar(false);
  };
  const handleChange = (e) => {
    setSelectedTheme(e.target.value);
    setSavedTheme({
      ...savedTheme,
      theme: e.target.value
    });
  };
  const handleFontChange = (e) => {
    setFontFamily(e.target.value);
    setSavedTheme({
      ...savedTheme,
      font_family: e.target.value
    });
  };
  const UpdateTheme = () => {
    store.UpdateTheme(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, user_id: loggeduser.user_details_id, theme_management: savedTheme }))
      .then((result) => {
        if (result.status === 200) {
          dispatch(addLoginDetails({ ...loggeduser, theme_management: savedTheme }));
          const themeToPreview = theme.filter(t => t.name === savedTheme.theme)[0] || {};
          dispatch(setTheme(themeToPreview));
          dispatch(setFont(savedTheme.font_family));
          setSavedTheme(savedTheme);
        }
      })
      .catch(error => {
        dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
      });
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleTabClick = (tab) => {
    dispatch(setSettingsActiveTab(tab));
  };
  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);

  useEffect(() => {
    setTimeout(() => {
      if (loggeduser)
        fetchFacilityData();
    }, 100)

  }, [])

  useEffect(() => {
    fetchFacilityData();
  }, [fileRefresh])

  const fetchFacilityData = async () => {
    await store.GetFacilityinfo(loggedusertoken, DataDecode.encryptPayload(loggeduser.facilityID))
      .then((result) => {

        if (result.status == 201) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response)
            setFacilityData(data);
            dispatch(addLoginDetails({ ...loggeduser, facility_logo: data.facility_logo }));
          })
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
      })
  }

  useEffect(() => {
    if (loggeduser) {
      const colorTheme = loggeduser?.theme_management?.theme;
      const FontFamily = loggeduser?.theme_management?.font_family;
      setSelectedTheme(colorTheme || 'defaultTheme');
      setFontFamily(FontFamily || 'Arial, sans-serif');
      setSavedTheme({
        theme: colorTheme || 'defaultTheme',
        font_family: FontFamily || 'Arial, sans-serif'
      });
    }
  }, [loggeduser]);

  const fetchDepartmentData = () => {
    try {
      store.GetAllDepartments(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              setDepartment(JSON.parse(response));
            });
          } else {
            setDepartment([]);
          }
        });
    } catch (err) {
      dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
    }
  };
  useEffect(() => {
    if (loggeduser != null && loggeduser.length != 0) {
      fetchDepartmentData();
    }
  }, [refreshData]);

  const openPopup = () => {
    setShowPopup(true);
    setselectedDepartment(null);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleRowClick = (department) => {
  //   setselectedDepartment(department);
  // };

  const handleEditClick = (department) => {
    setShowPopup(true);
    setselectedDepartment(department);
  };
  const deleteDepartment = (department_id) => {
    setDeleteId(department_id)
    setConfirmBox(true);
  }
  const handleDeleteSubmit = () => {
    setDisableButton(true);
    store.deleteDepartment(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'department_id': deleteId.department_id }))
      .then((result) => {
        if (result.status == 204) {
          setRefreshData(r => !r);
          store.CreateActivityLogs(loggeduser, "Settings", `Department Deleted - for ${deleteId.department_name}`, "Delete");
          dispatch(setNotification({ message: 'Department Deleted Successfully!', status: 'success', action: true }));
        }
        else if (result.status == 409) {
          dispatch(setNotification({ message: 'Department is assigned to Doctor!', status: 'info', action: true }));
        }
        else {
          dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
        }
        setDisableButton(false);
        setConfirmBox(false);
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
      })
  }

  const deleteLogo = () => {
    setConfirmBox(true);
  }

  const handleDeleteLogoSubmit = async () => {
    let facility_id = facilityData?.facility_id;
    setDisableButton(true);
    await store.deleteLogo(loggedusertoken, DataDecode.encryptPayload(facility_id))
      .then((result) => {
        if (result.status === 204) {
          setRefreshData(r => !r);
          setDisableButton(false);
          setConfirmBox(false);
          setFileRefresh(o => !o);
          dispatch(setNotification({ message: 'Logo Deleted Successfully!', status: 'success', action: true }));
        } else {
          dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
      })
  }

  return (
    <>
      <div className="bg-white mx-auto px-4 py-2 my-20 w-100%">
        <header id="SettingsHeadersettingsTitle"
          className={`flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
          style={{ fontFamily: previewFont.fontFamily }}
        >
          <button id="settingBack" className='mr-2' onClick={() => { window.history.back() }}>
            <svg id="MobPatientProfileSvgmobReloadIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 12" fill="none" >
              <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" className="fill-current" />
            </svg>
          </button>
          Settings
        </header>

        <div className='flex justify-between mx-auto mt-5 gap-4'>
          <div className="flex justify-center w-full">
            <h1 id="SettingsH1departmentTitle"
              className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
        ${settingsActiveTab === 'Department' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'} `}
              onClick={() => handleTabClick('Department')}>
              Department
            </h1>
            {loggeduser?.user_role === "Admin" &&
              <h1 id="SettingsH1updatefacilityLogo"
                className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
                ${settingsActiveTab === 'Update Facility Logo' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'}`}
                onClick={() => handleTabClick('Update Facility Logo')}>
                Update Facility Logo
              </h1>
            }
            <h1 id="SettingsH1updatefacilityLogo"
              className={`hidden lg:block items-center text-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
                ${settingsActiveTab === 'Doctors' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'}`}
              onClick={() => handleTabClick('Doctors')}>
              Doctors
            </h1>
            {loggeduser?.user_role === "Admin" &&
              <h1 id="SettingsH1updatefacilityLogo"
                className={`hidden lg:block items-center text-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
                ${settingsActiveTab === 'Users' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'}`}
                onClick={() => handleTabClick('Users')}>
                Users
              </h1>
            }
            {loggeduser?.user_role === "Admin" &&
              <h1 id="SettingsH1updatefacilityLogo"
                className={`hidden lg:block items-center text-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
                ${settingsActiveTab === 'User logs' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'}`}
                onClick={() => handleTabClick('User logs')}>
                User logs
              </h1>
            }
          </div>
        </div>
        {/* DEPARTMENT */}
        {
          settingsActiveTab === 'Department' && (
            <>
              {confirmBox &&
                <div id="SettingsDivdeleteDepartment" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] ">
                  <div id="SettingsDivdeleteDepartmentContainer" className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div id="SettingsDivdeleteDepartmentContent" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                      <svg id="SettingsSvgdeleteDepartmentIcon" className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                      <p id="SettingsPdeleteDepartmentText" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Department?</p>
                      <div id="SettingsDivdeleteDepartment" className="flex justify-center items-center space-x-4">
                        <button id="SettingsBtndeleteDepartmentCancel" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}>
                          No, cancel
                        </button>
                        <button id="SettingsBtndeleteDepartmentConfirm" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}>
                          Yes, I'm sure {disableButton &&
                            <svg id="SettingsSvgdeleteDepartmentConfirmIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div id="SettingsDivdepartmentsContainer" className="mx-auto py-2 w-[100%]">
                <header id="DepartmentHeadercontainer" className="flex items-center justify-between">
                  <header id="DepatmentHeadertitle" className={` flex font-bold mb-2 text-2xl lg:text-2xl lg:hidden sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Departments</header>
                  <div id="DepartmentDivheaderButtons" className="lg:hidden flex items-center ml-auto">
                    <button id="DepartmentBtnopenPopup" className="sm:flex md:flex sm:top-section " onClick={openPopup}>
                      <svg id="DepartmentSvgopenPopupIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 fill-red-800">
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                      </svg>
                    </button>
                    <button id="DepartmentBtntoggleSearch" className="sm:flex md:flex sm:top-section" onClick={toggleSearchBar}>
                      <svg id="DepartmentSvgtoggleSearchIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-red-800">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                      </svg>
                    </button>
                  </div>

                </header>
                <div>
                  {showSearchBar && (
                    <div id="DepartmentDivsearchBarContainer" className="lg:hidden right-0 w-full flex items-center">
                      <div id="DepartmentDivsearchBar" className="relative w-full flex items-center">
                        <input id="DepartmentInputsearchBarMobile" type="text" className="w-full pl-2 pr-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search Id,Name,Phone" />
                        <button id="DepartmentBtncloseSearch" onClick={closeSearch} className="absolute right-2">
                          <svg id="DepartmentSvgcloseSearchIcon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <h1 id="DepartmentsH1doctorDataHeader" className=" mb-4 px-2 lg:mt-0">

                  <div id="DepartmentsDivdeptDataContainer" className="flex justify-between items-center">
                    <header id="DepartmentHeaderdeptDataTitle" className={`hidden lg:flex font-bold mb-2 text-2xl lg:text-2xl  sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Departments</header>
                    <div id="DepartmentDivheaderActionButtons" className=' flex justify-between items-center flex-row'>
                      <div id="DepartmentDivsearchInputContainer" className="hidden lg:block relative items-center gap-2 mx-4">
                        <input id="DepartmentsInputsearchDesktop" type="text" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search here" />
                        <svg id="DepartmentsInputsearchIconDesktop" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-800">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                      </div>
                      <div id="DepartmentsDivaddDeptButtonContainer" className="hidden items-center lg:flex flex-row mx-4">

                        <button id="SettingsBtnaddDepartmentButton" className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }} onClick={openPopup}>
                          <svg id="SettingsSvgaddDepartmentIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                            <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                          </svg>
                          Add New Department
                        </button>

                      </div>
                      <button id="SettingsBtnreload" className="hidden lg:block items-center lg:ml-6 sm:ml-4 mx-4" onClick={() => window.location.reload()}>
                        <svg id="SettingsSvgreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-800 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                          <path d="M20 4v5h-5" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </h1>
                {showPopup && <AddDepartment id="SettingsAddDepartmentpopup" isOpen={showPopup} onClose={closePopup} setRefreshData={setRefreshData} department={selectedDepartment} />}
                <table className="hidden lg:inline-table w-[100%] lg:border rounded-md focus:outline-none  border-color:rgb(2 6 23);">
                  <thead id="SettingsTHeaddepartment" className={`rounded-lg text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12 `}>
                    <tr id="SettingsTrdepartmentsHeaderRow" className="px-5 py-2 text-left border-b">
                      {/* <th className="px-2 py-2 text-left ">Department id</th> */}
                      <th id="SettingsTHdepartmentName" className="px-2 py-2 text-left">Department Name</th>
                      <th id="SettingsTHdepartmentAction" className="px-4 py-2 flex items-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {department.map((department, index) => (
                      <tr id={`department-row-${index}`} className='border-b h-12 text-[#424242] hover:bg-white bg-[#FBFBFB] text-sm' key={index}
                        onMouseEnter={() => setHoveredRowId(index)}
                        onMouseLeave={() => {
                          if (!openDropdown) {
                            setHoveredRowId(null);
                          }
                        }} >
                        {/* <td className="px-2 py-2 text-left cursor-pointer">{department.department_id}</td> */}
                        <td id={`department-name-${index}`} className="px-2 py-2 text-left cursor-pointer w-[50%]">{department.department_name || '-'}</td>
                        <td id={`department-actions-${index}`} className="px-2 py-2 text-left cursor-pointer">
                          <button id="DoctorsBtntoggleDropdownButton" onClick={() => toggleDropdown(index)} className={`transition-opacity duration-200 ${hoveredRowId === index ? 'opacity-100' : 'opacity-0'}`}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 mt-2">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1.5C3 1.10218 3.15804 0.720644 3.43934 0.43934C3.72064 0.158035 4.10218 0 4.5 0H4.51C4.90782 0 5.28936 0.158035 5.57066 0.43934C5.85197 0.720644 6.01 1.10218 6.01 1.5V1.51C6.01 1.90782 5.85197 2.28936 5.57066 2.57066C5.28936 2.85196 4.90782 3.01 4.51 3.01H4.5C4.10218 3.01 3.72064 2.85196 3.43934 2.57066C3.15804 2.28936 3 1.90782 3 1.51V1.5ZM3 8.5C3 8.10218 3.15804 7.72064 3.43934 7.43934C3.72064 7.15804 4.10218 7 4.5 7H4.51C4.90782 7 5.28936 7.15804 5.57066 7.43934C5.85197 7.72064 6.01 8.10218 6.01 8.5V8.51C6.01 8.90782 5.85197 9.28936 5.57066 9.57066C5.28936 9.85196 4.90782 10.01 4.51 10.01H4.5C4.10218 10.01 3.72064 9.85196 3.43934 9.57066C3.15804 9.28936 3 8.90782 3 8.51V8.5ZM4.5 14C4.10218 14 3.72064 14.158 3.43934 14.4393C3.15804 14.7206 3 15.1022 3 15.5V15.51C3 15.9078 3.15804 16.2894 3.43934 16.5707C3.72064 16.852 4.10218 17.01 4.5 17.01H4.51C4.90782 17.01 5.28936 16.852 5.57066 16.5707C5.85197 16.2894 6.01 15.9078 6.01 15.51V15.5C6.01 15.1022 5.85197 14.7206 5.57066 14.4393C5.28936 14.158 4.90782 14 4.51 14H4.5Z" fill="black" />
                            </svg>
                          </button>
                          {openDropdown === index && (
                            <div ref={menuRef} className="absolute mt-[-36px] right-[572px] w-30 bg-white border border-gray-300 rounded-md z-10">
                              <ul>
                                <li id={`button-edit-${index}`} className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2" onClick={() => { handleEditClick(department); toggleDropdown(); }}>
                                  Edit</li>
                                <li id={`button-delete-${index}`} className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2" onClick={() => { deleteDepartment(department); toggleDropdown(); }}>
                                  Delete</li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div id="SettingsDivmobileDepartmentsContainer" className="lg:hidden">
                  <div id="SettingsDivmobileDepartmentsList" className="">
                    {department.map((department, index) => (
                      <div id={`mobile-department-item-${index}`} className='grid grid-cols-1 gap-5' key={index} >
                        <div id={`mobile-department-card-${index}`} className='px-2 py-2 w-full bg-white mb-2 border-2 border-gray-200 p-4 rounded-lg shadow-lg items-center'>
                          <div id={`mobile-department-header-${index}`} className="flex items-center justify-between">
                            <div id={`mobile-department-info-${index}`} className='flex items-center space-x-4'>
                              <div id={`mobile-department-name-container-${index}`} className="flex items-center mb-4">
                                <span id={`mobile-department-label-${index}`} className="font-semibold text-xl mr-2">Department Name:</span>
                                <span id={`mobile-department-name-${index}`} className='text-gray-500 font-semibold text-xl'>{department.department_name || '-'}</span>
                              </div>
                              <button id={`mobile-edit-button-${index}`} onClick={() => handleEditClick(department)}>
                                <svg id="SettingsSvgmobEditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                  <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                                </svg>
                              </button>
                              <button id={`mobile-delete-button-${index}`} onClick={() => deleteDepartment(department)}>
                                <svg id="SettingsSvgmobDeleteIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#A31B1B" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          settingsActiveTab === 'Update Facility Logo' && (
            <>
              {confirmBox && (
                <div id="DivthemeConfirmation-Modal" className="fixed inset-0 z-10 flex items-center justify-center  bg-gray-500 bg-opacity-50">
                  <div id="DivthemeConfirmationContainer" className="relative p-4 w-full max-w-sm h-full md:h-auto">
                    <div id="DivthemeConfirmationContent" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                      <svg id="SvgthemeConfirmationIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                      <p id="PthemeConfirmationText" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Logo?</p>
                      <div id="DivthemeConfirmationButtons" className="flex justify-center items-center space-x-4">
                        <button id="BtnthemeCancel" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}>
                          No, cancel
                        </button>
                        <button id="BtnthemeConfirm" type="button" onClick={handleDeleteLogoSubmit} disabled={disableButton} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}>
                          Yes, I'm sure {disableButton &&
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div id="DivthemeManagement" className='text-black'>
                <div id="DivthemeManagementContainer" className='border border-gray-200 rounded-md shadow-md mt-2'>
                  <header id="HeaderthemeManagement" className={`origin-top-left flex font-bold mb-2 text-2xl mt-2 lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewFont.fontFamily }}>Update Facility Logo</header>
                  <div id="DivthemeManagementContent" className="min-w-8  bg-white p-2 mb-2 ml-2 mr-2">
                    <div id="DivthemeManagementSelect" className='block text-center'>

                      <div id="DivthemeSelectContainer" className="flex mt-1 sm:mb-0 w-full px-[14px] py-[7px] bg-white border border-[#CACACA] rounded shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2]">
                        {file ?
                          <div className='w-36 h-36 pr-2 pt-2'>
                            <img id="ImgthemeSelectImageUrl" src={URL.createObjectURL(file)} alt={file.name} className='h-auto max-w-full' />
                          </div> :
                          facilityData.facility_logo ?
                            <div className='w-36 h-36 pr-2 pt-2 relative'>
                              <img id="ImgthemeFacilityName" src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + facilityData.facility_logo} alt={facilityData.facility_name} className='object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-full h-auto' />
                              <button id="BtnthemeDeleteLogo" className="absolute top-2 right-2 bg-gray-100 rounded-full p-1 shadow" type='button' onClick={deleteLogo}>
                                <svg id="SvgthemeDeleteLogoIcon" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                              </button>
                            </div>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-36 h-36 mr-2 bg-gray-300 rounded-sm p-4">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>
                        }
                        <div>
                          <label id="LabeluploadLogo" className="block text-gray-700 mb-2 pl-2 italic text-base">
                            Please upload logo
                          </label>
                          <div className="relative pl-2">
                            <button id="BtnchooseImage" type="button" onClick={() => { setIsUploadLogo(true) }} className={`text-lg p-2 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md cursor-pointer`}>
                              Choose Image
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {isUploadLogo && <UploadLogo setIsUploadLogo={setIsUploadLogo} file={file} setFile={setFile} existingLogo={facilityData.facility_logo} saveDirectly={true} setFileRefresh={setFileRefresh} facilityData={facilityData} />}
            </>
          )
        }
        {settingsActiveTab === 'Doctors' && (
          <>
            <DoctorsViewport />
          </>
        )}
        {settingsActiveTab === 'Users' && (
          <>
            <UsersViewPort />
          </>
        )}
        {settingsActiveTab === 'User logs' && (
          <>
            <ActivityLogsViewPort />
          </>
        )}
      </div>

    </>
  );
};

export default Settings;