import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import RecycleSidebar from './desktop/RecycleSidebar';
import { theme } from '../../EmraxisTheme/Theme';
import PatientBin from './desktop/PatientBin';
import PatientFileBin from './desktop/PatientFileBin';
import ClinicalNotesBin from './desktop/CliniclaNotesBin';
import DepartmentBin from './desktop/DepartmentBin';
import DoctorBin from './desktop/DoctorBin';
import AppointmentBin from './desktop/AppointmentBin';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import { setRecycleBinActiveNavbar, setRecycleBinAppointmentDataAvailable, setRecycleBinDepartmentDataAvailabale, setRecycleBinDoctorDataAvailable, setRecycleBinPatientDataAvailable, setRecycleBinPatientFilesDataAvailable, setRecycleBinSideNavbar ,setRecycleBinClinicalNotesDataAvailable} from '../../Redux/features/recycleBin/recycleBinNavbarSlice';
import { setNotification } from '../../Redux/features/toast/toastSlice';

const DesktopRecycleBin = () => {
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    let isPatientDataAvailable = useSelector(state => state.recycleBinSideNavbar.isPatientDataAvailable);
    let isDoctorDataAvailable = useSelector(state => state.recycleBinSideNavbar.isDoctorDataAvailable);
    let isDepartmentDataAvailabale = useSelector(state => state.recycleBinSideNavbar.isDepartmentDataAvailabale);
    let isAppointmentDataAvailable = useSelector(state => state.recycleBinSideNavbar.isAppointmentDataAvailable);
    let isPatientFilesDataAvailable = useSelector(state => state.recycleBinSideNavbar.isPatientFilesDataAvailable);
    let isClinicalNotesDataAvailable = useSelector(state => state.recycleBinSideNavbar.isClinicalNotesDataAvailable);

    let sidebar = useSelector(state => state.recycleBinSideNavbar.sidebar);

    const dispatch = useDispatch();
    const sectionRefs = useRef([]);

    const [patientData, setPatientData] = useState([]);
    const [patientRefreshData, setPatientRefreshData] = useState(false);
    const [patientDataLoading, setPatientDataLoading] = useState(false);

    const [appointmentData, setAppointmentData] = useState([]);
    const [appointmentRefreshData, setAppointmentRefreshData] = useState(false);
    const [appointmentDataLoading, setAppointmentDataLoading] = useState(false);

    const [doctorData, setDoctorData] = useState([]);
    const [doctorRefreshData, setDoctorRefreshData] = useState(false);
    const [doctorDataLoading, setDoctorDataLoading] = useState(false);

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentRefreshData, setDepartmentRefreshData] = useState(false);
    const [departmentDataLoading, setDepartmentDataLoading] = useState(false);

    const [patientFilesData, setPatientFilesData] = useState([]);
    const [patientFilesRefreshData, setPatientFilesRefreshData] = useState(false);
    const [patientFilesDataLoading, setPatientFilesDataLoading] = useState(false);

    const [clinicalNotesDataRefreshData, setClinicalNotesDataRefreshData] = useState(false);
    const [clinicalNotesData, setClinicalNotesData] = useState([]);
    const [clinicalNotesDataLoading,setClinicalNotesDataLoading]=useState(false);
    const scrollToPosition = (id, yOffset = -100) => {
        const y = sectionRefs.current[id].getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    useEffect(() => {
        dispatch(setRecycleBinSideNavbar({ patient: false, doctor: false, department: false, appointment: false, patientFiles: false, clinicalNotes:false }))
        dispatch(setRecycleBinActiveNavbar("patient"));
        dispatch(setRecycleBinPatientDataAvailable(false));
        dispatch(setRecycleBinDoctorDataAvailable(false));
        dispatch(setRecycleBinDepartmentDataAvailabale(false));
        dispatch(setRecycleBinAppointmentDataAvailable(false));
        dispatch(setRecycleBinPatientFilesDataAvailable(false));
        dispatch(setRecycleBinClinicalNotesDataAvailable(false));
    }, [])

    useEffect(() => {
        if (sidebar.patient) {
            scrollToPosition(0, -90)
        }
        else if (sidebar.appointment) {
            scrollToPosition(1, -90)
        }
        else if (sidebar.doctor) {
            scrollToPosition(2, -90)
        }
        else if (sidebar.department) {
            scrollToPosition(3, -90)
        }
        else if (sidebar.patientFiles) {
            scrollToPosition(4, -90)
        }
        else if (sidebar.clinicalNotes) {
            scrollToPosition(5, -90)
        }
    }, [sidebar])
    // Start Get Patient Data
    const GetPatientRecycleBinData = () => {
        setPatientDataLoading(true)
        store.GetPatientRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                setPatientDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let deletedData = JSON.parse(response);
                        setPatientData(deletedData);
                        dispatch(setRecycleBinPatientDataAvailable(true));
                    })
                }
                else {
                    dispatch(setRecycleBinPatientDataAvailable(false));
                    setPatientData([]);
                }
            })
            .catch(err => {
                setPatientData([]);
                setPatientDataLoading(false);
                dispatch(setRecycleBinPatientDataAvailable(false));
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }


    useEffect(() => {
        if (loggeduser) {
            GetPatientRecycleBinData();
        }
    }, [loggeduser, patientRefreshData])
    // End Get Patient Data

    // Start Get Appointment Data
    const GetAppointmentRecycleBinData = () => {
        setAppointmentDataLoading(true)
        store.GetAppointmentRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                setAppointmentDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let deletedData = JSON.parse(response);
                        setAppointmentData(deletedData);
                        dispatch(setRecycleBinAppointmentDataAvailable(true));
                    })
                }
                else {
                    dispatch(setRecycleBinAppointmentDataAvailable(false));
                    setAppointmentData([]);
                }
            })
            .catch(err => {
                setAppointmentData([]);
                setAppointmentDataLoading(false);
                dispatch(setRecycleBinAppointmentDataAvailable(false));
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }


    useEffect(() => {
        if (loggeduser) {
            GetAppointmentRecycleBinData();
        }
    }, [loggeduser, appointmentRefreshData])

    // End Get Appointment Data

    // Start Get Doctor Data
    const GetDoctorRecycleBinData = () => {
        setDoctorDataLoading(true)
        store.GetDoctorRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                setDoctorDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let deletedData = JSON.parse(response);
                        setDoctorData(deletedData);
                        dispatch(setRecycleBinDoctorDataAvailable(true));
                    })
                }
                else {
                    dispatch(setRecycleBinDoctorDataAvailable(false));
                    setDoctorData([]);
                }
            })
            .catch(err => {
                setDoctorData([]);
                setDoctorDataLoading(false);
                dispatch(setRecycleBinDoctorDataAvailable(false));
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }


    useEffect(() => {
        if (loggeduser) {
            GetDoctorRecycleBinData();
        }
    }, [loggeduser, doctorRefreshData])
    // End Get Doctor Data

   //Start Get Clinical Notes Data
   const  GetClinicalNOtesRecyclBindata= ()=>{
     setClinicalNotesDataLoading(true);
     let FacilityId= loggeduser?.facilityID;
     store.GetClinicalNotesRecycleBinData(loggedusertoken,DataDecode.encryptPayload(FacilityId))
     .then((result)=>{
        if(result.status == 200){
            result.json().then((res)=>{
                let response = DataDecode.decryptResponse(res);
                let deletedData = JSON.parse(response);
                setClinicalNotesData(deletedData);
                dispatch(setRecycleBinClinicalNotesDataAvailable(true));

            })
        }
        else {
            dispatch(setRecycleBinClinicalNotesDataAvailable(false));
            setClinicalNotesData([]);
        }
    })
    .catch(err => {
        setClinicalNotesData([]);
        setClinicalNotesDataLoading(false);
        dispatch(setRecycleBinClinicalNotesDataAvailable(false));
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
    })
   }; 
   useEffect(()=>{
    if(loggeduser){
        GetClinicalNOtesRecyclBindata();
    }
   },[loggeduser,clinicalNotesDataRefreshData])
   // End Clinical Notes Data
    // Start Get Department Data
    const GetDepartmentRecycleBinData = () => {
        setDepartmentDataLoading(true)
        store.GetDepartmentRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                setDepartmentDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let deletedData = JSON.parse(response);
                        setDepartmentData(deletedData);
                        dispatch(setRecycleBinDepartmentDataAvailabale(true));
                    })
                }
                else {
                    dispatch(setRecycleBinDepartmentDataAvailabale(false));
                    setDepartmentData([]);
                }
            })
            .catch(err => {
                setDepartmentData([]);
                setDepartmentDataLoading(false);
                dispatch(setRecycleBinDepartmentDataAvailabale(false));
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }


    useEffect(() => {
        if (loggeduser) {
            GetDepartmentRecycleBinData();
        }
    }, [loggeduser, departmentRefreshData])

    // End Get Department Data

    // Start Get Files Data
    const GetPatientFilesRecycleBinData = () => {
        setPatientDataLoading(true)
        store.GetPatientFilesRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                setPatientDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let deletedData = JSON.parse(response);
                        setPatientFilesData(deletedData);
                        dispatch(setRecycleBinPatientFilesDataAvailable(true));
                    })
                }
                else {
                    dispatch(setRecycleBinPatientFilesDataAvailable(false));
                    setPatientFilesData([]);
                }
            })
            .catch(err => {
                setPatientFilesData([]);
                setPatientDataLoading(false);
                dispatch(setRecycleBinPatientFilesDataAvailable(false));
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            })
    }


    useEffect(() => {
        if (loggeduser) {
            GetPatientFilesRecycleBinData();
        }
    }, [loggeduser, patientFilesRefreshData])

    // End Get Files Data

    //Start On Scroll navbar selection
    useEffect(() => {
        const handleScroll = () => {
            sectionRefs.current.forEach((section) => {
                const rect = section?.getBoundingClientRect();
                if (rect && rect.top <= 150 && rect.bottom > 0) {
                    const selectedMenu = section.getAttribute("data-navbar-section");
                    dispatch(setRecycleBinActiveNavbar(selectedMenu));
                }
            });
        };

        window.addEventListener("scroll", handleScroll);

        // Initial check when the component mounts
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    //End On Scroll navbar selection

    return (
        <div>
            {loggeduser ?
                <div className="mx-auto py-8 my-10 pt-10">
                    <div className="flex">
                        {(isPatientDataAvailable || isAppointmentDataAvailable || isDoctorDataAvailable || isDepartmentDataAvailabale || isPatientDataAvailable || isPatientFilesDataAvailable||isClinicalNotesDataAvailable) &&
                            <div className="flex-none w-64 border-r border-gray-200">
                                <div className='fixed w-64'>
                                    <RecycleSidebar />
                                </div>
                            </div>}
                        <div className="flex-auto w-auto">
                            <div className="origin-top-left flex font-bold mt-2 lg:text-black text-red-700 lg:text-xl sm:text-xl justify-between items-center">
                                <div className='flex justify-between w-full'>
                                    <div>
                                        <span id="binMainTitle" className={`ml-6 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Recycle Bin</span>
                                    </div>
                                </div>
                            </div>
                            <div className='mx-4 bg-red-200 mt-2'>
                                <p id="binWarningMessage" className='p-2 text-slate-700'>Items in the bin will be deleted forever after 30 days</p>
                            </div>
                            {(isPatientDataAvailable || isAppointmentDataAvailable || isDoctorDataAvailable || isDepartmentDataAvailabale || isPatientDataAvailable || isPatientFilesDataAvailable||isClinicalNotesDataAvailable) ?
                                <>
                                    {isPatientDataAvailable &&
                                        <section id="secPatientBin" className='w-full mt-4 px-4' ref={(el) => (sectionRefs.current[0] = el)} data-navbar-section="patient">
                                            <PatientBin data={patientData} setRefreshDate={setPatientRefreshData} dataLoading={patientDataLoading} />
                                        </section>
                                    }
                                    {isAppointmentDataAvailable &&
                                        <section id="secAppointmentBin" className='w-full mt-4 px-4' ref={(el) => (sectionRefs.current[1] = el)} data-navbar-section="appointment">
                                            <AppointmentBin data={appointmentData} setRefreshDate={setAppointmentRefreshData} dataLoading={appointmentDataLoading} />
                                        </section>
                                    }
                                    {isDoctorDataAvailable &&
                                        <section id="secDoctorBin" className='w-full mt-4 px-4' ref={(el) => (sectionRefs.current[2] = el)} data-navbar-section="doctor">
                                            <DoctorBin data={doctorData} setRefreshDate={setDoctorRefreshData} dataLoading={doctorDataLoading} />
                                        </section>
                                    }
                                    {isDepartmentDataAvailabale &&
                                        <section id="secDepartmentBin" className='w-full mt-4 px-4' ref={(el) => (sectionRefs.current[3] = el)} data-navbar-section="department">
                                            <DepartmentBin data={departmentData} setRefreshDate={setDepartmentRefreshData} dataLoading={departmentDataLoading} />
                                        </section>
                                    }
                                    {isPatientFilesDataAvailable &&
                                        <section id="secPatientFilesBin" className='w-full mt-4 px-4' ref={(el) => (sectionRefs.current[4] = el)} data-navbar-section="patient-files">
                                            <PatientFileBin data={patientFilesData} setRefreshDate={setPatientFilesRefreshData} dataLoading={patientFilesDataLoading} />
                                        </section>
                                    }
                                    {isClinicalNotesDataAvailable &&
                                        <section id="secClinicalNotesBin" className='w-full mt-4 px-4' ref={(el) => (sectionRefs.current[5] = el)} data-navbar-section="clinical-notes">
                                            <ClinicalNotesBin data={clinicalNotesData} setRefreshDate={setClinicalNotesDataRefreshData} dataLoading={clinicalNotesDataLoading} />
                                        </section>
                                    }  
                                    <div className='h-screen'></div>
                                </> :
                                <p id="binEmptyText" className='text-red-600 font-semibold text-lg p-10 text-center my-20'>Bin is empty!</p>
                            }
                        </div>
                    </div>
                </div> :
                <p className='text-red-600 font-semibold text-lg p-10 text-center my-20'>You are not authorized to perform this action!</p>
            }
        </div>
    )
}

export default DesktopRecycleBin