import React, { useEffect, useState } from "react";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { PiPencil, PiPrinterFill } from "react-icons/pi";
import { HiOutlineDownload } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentInvoicePrint } from "../../Redux/features/popup/popupSlice";
import GenerateInvoiceTable from "./GenerateInvoiceTable";
import DateFunction from "../../utils/DateFunctions";
import {
    setIsInvoiceExist,
    setShowAppEditInvoice,
} from "../../Redux/features/invoice/invoiceSlice";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import store from "../../API/store";
import DataDecode from "../../utils/DataDecode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { generateInvoiceHtmlForPDF } from "../../../src/utils/invoice";

const GenerateInvoice = ({
    page,
    sortedAlInvoices,
    selectedInvoice,
    setRefreshData,
}) => {
    const dispatch = useDispatch();
    var loggeduser = useSelector((state) => state.login.loginuserData);
    var loggedusertoken = useSelector((state) => state.login.userBarerToken);

    const [totalAmount, setTotalAmount] = useState(0.0);
    const [amount, setAmount] = useState(0.0);
    const [discountAmount, setDiscountAmount] = useState(0.0);
    const [gstAmount, setGstAmount] = useState(0.0);
    const [rows, setRows] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [print, setPrint] = useState(false);
    const [tariffLoad, setTariffLoad] = useState(false);
    var invoiceData = useSelector((state) => state.invoice.appointmentInvoice);
    const [invoiceTerms, setInvoiceTerms] = useState({
        clinic_guid: "",
        terms: "",
        terms_id: 0,
    });
    const [discountIsChecked, setDiscountIsChecked] = useState(false);
    const [discountType, setDiscountType] = useState("");
    const [groupedGSTData, setGroupedGSTData] = useState([]);

    useEffect(() => {
        const grouped = groupByGST(rows);
        setGroupedGSTData(grouped);
    }, [rows]);

    const groupByGST = (data) => {
        const grouped = data.reduce((acc, item) => {
            const gst = item.gst;
            if (!acc[gst]) {
                acc[gst] = { taxableValue: 0, gstValue: 0 };
            }
            const taxableValue = item.amount * item.quantity;
            const gstValue = (taxableValue * gst) / 100;
            acc[gst].taxableValue += taxableValue;
            acc[gst].gstValue += gstValue;
            return acc;
        }, {});

        return Object.entries(grouped).map(([gst, values]) => ({
            gst: gst,
            taxableValue: values.taxableValue,
            gstValue: values.gstValue,
        }));
    };

    const onClose = () => {
        dispatch(setAppointmentInvoicePrint(false));
    };

    const GetInvoiceTerms = () => {
        try {
            store
                .GetInvoiceTerms(
                    loggedusertoken,
                    DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID })
                )
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            var data = JSON.parse(response);
                            setInvoiceTerms(data);
                        });
                    }
                });
        } catch (err) {
            dispatch(
                setNotification({
                    message: "Error Occurred!",
                    status: "error",
                    action: true,
                })
            );
        }
    };

    useEffect(() => {
        if (loggeduser) {
            GetInvoiceTerms();
        }
    }, [loggeduser]);

    const printDiv = (divId) => {
        const content = document.getElementById(divId).innerHTML;
        const printWindow = window.open("", "", "height=600,width=800");
        printWindow.document.write("<html><head><title>Invoice</title>");
        printWindow.document.write(
            '<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">'
        );
        printWindow.document.write(
            "<style>@media print { body { -webkit-print-color-adjust: exact; } }</style>"
        );
        printWindow.document.write(content);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
            onClose();
        }, 1000);
    };

    const [inData, setIndata] = useState({
        patient_op_number: "",
        invoice_id: 0,
        patient_id: "",
        appointmnet_id: "",
        address: "",
        email_id: "",
        gender: "",
        mobile_number: "",
        age: "",
        ref_by: "",
        invoice_no: "N/A",
        invoice_on: DateFunction.GetDate(new Date()),
        payment_status: "Pending",
        payment_mode: "",
    });

    var isAppEditInvoice = useSelector(
        (state) => state.invoice.showAppEditInvoice
    );

    const setCLoseEditInvoice = () => {
        let exist = rows.filter((a) => a.description.trim() === "");
        if (exist.length > 0) {
            dispatch(
                setNotification({
                    message: "* Fields are required!",
                    status: "error",
                    action: true,
                })
            );
        } else {
            dispatch(setShowAppEditInvoice(false));
        }
    };

    const setOpenEditInvoice = () => {
        dispatch(setShowAppEditInvoice(true));
    };

    const GetInvoice = (app_id, pat_id) => {
        setTariffLoad(true);
        store
            .GetInvoice(
                loggedusertoken,
                DataDecode.encryptPayload({
                    clinic_guid: loggeduser.facilityID,
                    invoice_for: invoiceData.targetFrom,
                    appointment_id: app_id,
                    patient_id: pat_id,
                })
            )
            .then((result) => {
                setTariffLoad(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var data = JSON.parse(response);
                        setRows(data.invoiceItems);
                        setIndata({
                            ...inData,
                            invoice_on: DateFunction.GetDate(data.userInvoice.invoice_on),
                            invoice_no: data.userInvoice.invoice_code,
                            invoice_id: data.userInvoice.invoice_id,
                            payment_status: data.userInvoice.payment_status,
                            payment_mode: data.userInvoice.payment_mode,
                            appointment_id: invoiceData.appointment.appointment_id,
                            patient_id: invoiceData.appointment.patient_id,
                            patient_op_number: invoiceData.patient.patient_op_number,
                            FirstName: invoiceData.patient.FirstName,
                            MiddleName: invoiceData.patient.MiddleName,
                            LastName: invoiceData.patient.LastName,
                            mobile_number: invoiceData.patient.mobile_number,
                            email_id: invoiceData.patient.email_id,
                            gender: invoiceData.patient.gender,
                            age: invoiceData?.patient?.dob
                                ? DateFunction.GetAge(invoiceData?.patient?.dob)
                                : "-",
                            ref_by: invoiceData.appointment.reffered_by,
                            address: invoiceData.patient.address,
                        });
                        setTotalAmount(data.userInvoice.total_amount);
                        setAmount(data.userInvoice.amount);
                        setGstAmount(data.userInvoice.gst);
                        setDiscountType(data.userInvoice.discount_type);
                        setDiscountIsChecked(parseInt(data.userInvoice.discount || 0) > 0);
                        setDiscountAmount(data.userInvoice.discount);
                        dispatch(setShowAppEditInvoice(false));
                    });
                } else if (result.status == 404) {
                    setRows([]);
                    dispatch(setShowAppEditInvoice(true));
                }
            })
            .catch((err) => {
                dispatch(
                    setNotification({
                        message: "Error Occurred!",
                        status: "error",
                        action: true,
                    })
                );
            });
    };

    useEffect(() => {
        if (invoiceData.patient) {
            setIndata({
                ...inData,
                appointment_id: invoiceData.appointment.appointment_id,
                patient_id: invoiceData.appointment.patient_id,
                patient_op_number: invoiceData.patient.patient_op_number,
                FirstName: invoiceData.patient.FirstName,
                MiddleName: invoiceData.patient.MiddleName,
                LastName: invoiceData.patient.LastName,
                mobile_number: invoiceData.patient.mobile_number,
                email_id: invoiceData.patient.email_id,
                gender: invoiceData.patient.gender,
                age: invoiceData?.patient?.dob
                    ? DateFunction.GetAge(invoiceData?.patient?.dob)
                    : "-",
                ref_by: invoiceData.appointment.reffered_by,
                address: invoiceData.patient.address,
            });
            if (page != "create Invoice")
                setTimeout(() => {
                    GetInvoice(
                        invoiceData.appointment.appointment_id,
                        invoiceData.appointment.patient_id
                    );
                });
        }
    }, [invoiceData.patient]);

    const dateFormat = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        const hours = String(today.getHours()).padStart(2, "0");
        const minutes = String(today.getMinutes()).padStart(2, "0");
        const seconds = String(today.getSeconds()).padStart(2, "0");

        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    };

    const generatePDF = async (item) => {
        const convertImageToBase64 = async (url) => {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = () => reject("Error converting image to Base64");
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                return null;
            }
        };

        try {
            let facilityLogoBase64 = "";
            if (loggeduser.facility_logo) {
                const facilityLogoUrl = `${process.env.REACT_APP_EMRAXIS_S3_URL}/${loggeduser.facility_logo}`;
                facilityLogoBase64 = await convertImageToBase64(facilityLogoUrl);
            }
            const htmlContent = await generateInvoiceHtmlForPDF(item, {
                ...loggeduser,
                facilityLogoBase64,
            });
            const pdfContainer = document.createElement("div");
            pdfContainer.innerHTML = htmlContent;
            document.body.appendChild(pdfContainer);

            const canvas = await html2canvas(pdfContainer);
            const imgData = canvas.toDataURL("image/png");

            const pdf = new jsPDF();
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

            // Generate file name
            const todayDate = dateFormat();
            const fileName = `Invoice_${todayDate}.pdf`;
            pdf.save(fileName);
            dispatch(
                setNotification({
                    message: `PDF saved as ${fileName}`,
                    status: "success",
                    action: true,
                })
            );
            document.body.removeChild(pdfContainer);
        } catch (error) {
            dispatch(
                setNotification({
                    message: "Error Occurred",
                    status: "error",
                    action: true,
                })
            );
        }
    };

    const saveInvoice = (printRequired) => {
        if (rows.length > 0) {
            setSubmitDisable(true);
            setLoadingSave(true);
            let payload = {
                appointment_id: inData.appointment_id,
                clinic_guid: loggeduser.facilityID,
                invoices: rows,
                patient_id: inData.patient_id,
                payment_mode: inData.payment_mode,
                payment_status: inData.payment_status,
                invoice_id: inData.invoice_id,
                amount: amount,
                discount: discountAmount,
                gst: gstAmount,
                totalAmount: totalAmount,
                invoice_on: DateFunction.SetDbCurrentTimeStamp(),
                targetFrom: invoiceData.targetFrom,
                discount_type: discountType,
                discount: discountAmount,
            };
            if (inData.invoice_id == 0) {
                store
                    .SaveAppointmnetInvoice(
                        loggedusertoken,
                        DataDecode.encryptPayload(payload)
                    )
                    .then((result) => {
                        setSubmitDisable(false);
                        setLoadingSave(false);
                        if (result.status == 201) {
                            result.json().then((res) => {
                                let response = DataDecode.decryptResponse(res);
                                let data = JSON.parse(response);
                                setIndata({ ...inData, invoice_no: data?.invoice_code });
                            });
                            dispatch(setIsInvoiceExist(true));
                            dispatch(
                                setNotification({
                                    message: "Invoice Created",
                                    status: "success",
                                    action: true,
                                })
                            );
                            store.CreateActivityLogs(
                                loggeduser,
                                page,
                                `Invoice Generated - ${invoiceData?.appointment?.doctor_name
                                    ? "for " + invoiceData?.appointment?.doctor_name?.trim()
                                    : ""
                                } with patient ${invoiceData?.patient.FirstName
                                } ${invoiceData?.patient.MiddleName?.charAt(0)} ${invoiceData?.patient.LastName
                                }(${invoiceData?.patient?.patient_op_number
                                }) on ${DateFunction.GetDate(
                                    invoiceData?.appointment?.appointment_time
                                )} ${invoiceData?.appointment?.app_time
                                    ? " at " +
                                    DateFunction.Convert12Hour(
                                        invoiceData?.appointment?.app_time
                                    )
                                    : ""
                                }`,
                                "Create"
                            );
                            setTimeout(() => {
                                if (printRequired) {
                                    printDiv("content-to-print");
                                    setPrint(false)
                                } else {
                                    onClose();
                                }
                            }, 100);
                            if (page != "Appointment") setRefreshData((r) => !r);
                        } else {
                            setLoadingSave(false);
                            dispatch(
                                setNotification({
                                    message: "Error Occured!",
                                    status: "error",
                                    action: true,
                                })
                            );
                        }
                    })
                    .catch((err) => {
                        dispatch(
                            setNotification({
                                message: "Error Occurred!",
                                status: "error",
                                action: true,
                            })
                        );
                    });
            } else {
                store
                    .UpdateAppointmnetInvoice(
                        loggedusertoken,
                        DataDecode.encryptPayload(payload)
                    )
                    .then((result) => {
                        setSubmitDisable(false);
                        setLoadingSave(false);
                        if (result.status == 204) {
                            dispatch(
                                setNotification({
                                    message: "Invoice Updated",
                                    status: "success",
                                    action: true,
                                })
                            );
                            store.CreateActivityLogs(
                                loggeduser,
                                page,
                                `Invoice Updated - ${invoiceData?.appointment?.doctor_name
                                    ? "for " + invoiceData?.appointment?.doctor_name?.trim()
                                    : ""
                                } with patient ${invoiceData?.patient.FirstName
                                } ${invoiceData?.patient.MiddleName?.charAt(0)} ${invoiceData?.patient.LastName
                                }(${invoiceData?.patient?.patient_op_number
                                }) on ${DateFunction.GetDate(
                                    invoiceData?.appointment?.appointment_time
                                )} ${invoiceData?.appointment?.app_time
                                    ? " at " +
                                    DateFunction.Convert12Hour(
                                        invoiceData?.appointment?.app_time
                                    )
                                    : ""
                                }`,
                                "Update"
                            );
                            setTimeout(() => {
                                if (printRequired) {
                                    printDiv("content-to-print");
                                    setPrint(false)
                                } else {
                                    onClose();
                                }
                            }, 100);
                            if (page != "Appointment") setRefreshData((r) => !r);
                        } else {
                            setLoadingSave(false);
                            dispatch(
                                setNotification({
                                    message: "Error Occured!",
                                    status: "error",
                                    action: true,
                                })
                            );
                        }
                    })
                    .catch((err) => {
                        dispatch(
                            setNotification({
                                message: "Error Occurred!",
                                status: "error",
                                action: true,
                            })
                        );
                    });
            }
        } else {
            dispatch(
                setNotification({
                    message: "Please add data!",
                    status: "error",
                    action: true,
                })
            );
        }
    };

    const handleDiscountCheckbox = (evt) => {
        setDiscountIsChecked(evt.target.checked);
        if (!evt.target.checked) {
            setDiscountType("");
            setDiscountAmount(0);
            setTotalAmount(amount + gstAmount);
        }
    };

    const handleDiscountTypeChange = (evt) => {
        if (evt.target.value === "" || /^\d+%?$/.test(evt.target.value)) {
            setDiscountType(evt.target.value);
            if (evt.target.value.includes("%")) {
                let dis = parseFloat(evt.target.value);
                setDiscountAmount(((amount + gstAmount) * dis) / 100);
                setTotalAmount(amount + gstAmount - ((amount + gstAmount) * dis) / 100);
            } else {
                setDiscountAmount(parseFloat(evt.target.value));
                setTotalAmount(amount + gstAmount - parseFloat(evt.target.value));
            }
        }
    };

    return (
        <>
            <div
                id="GenerateInvoiceDivgenerateInvoicemodal"
                className="fixed inset-0 overflow-hidden flex justify-center items-center z-50"
            >
                <div
                    id="GenerateInvoiceDivmodalOverlay"
                    className="absolute inset-0 bg-black opacity-25"
                ></div>
                <div
                    id="GenerateInvoiceDivmodalContent"
                    className="relative bg-white shadow-xl p-8 max-h-[90vh] lg:max-h-[80vh] 2xl:max-h-[80vh] overflow-y-auto"
                    style={{ width: "900px" }}
                >
                    <div
                        id="GenerateInvoiceDivmodalHeader"
                        className="flex justify-between items-center mb-4 w-full"
                    >
                        <div
                            id="GenerateInvoiceDivmodalTitle"
                            className="text-black font-bold text-2xl"
                        >
                            Print Preview
                        </div>
                        <button
                            id="GenerateInvoiceButtoncloseModal"
                            className="text-red-800"
                            onClick={onClose}
                        >
                            <svg
                                id="GenerateInvoiceSvgcloseModalIcon"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-10 h-10"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                            </svg>
                        </button>
                    </div>

                    <div
                        id="content-to-print"
                        className="max-w-4xl mx-auto bg-white rounded-lg"
                        style={{ width: "830px" }}
                    >
                        <div
                            id="GenerateInvoiceDivheaderSection"
                            className=" mx-auto py-2  w-100% "
                        >
                            <div className="flex flex-col w-[100%] mx-auto">
                                <div className="flex flex-row w-full justify-between">
                                    <div className="">
                                        {loggeduser.facility_logo ? (
                                            <img
                                                src={
                                                    process.env.REACT_APP_EMRAXIS_S3_URL +
                                                    "/" +
                                                    loggeduser.facility_logo
                                                }
                                                alt="Facility Logo"
                                                className="w-20 h-20 object-contain justify-center"
                                            />
                                        ) : (
                                            <p className="text-[#4a4a4a] text-center mt-5 text-2xl justify-center font-bold">
                                                {loggeduser?.facility_name}
                                            </p>
                                        )}
                                    </div>

                                    {/* Invoice Details */}
                                    <div className="flex flex-col  justify-between text-[#4a4a4a] ">
                                        <div className="flex flex-row items-center py-1">
                                            <p className=" font-semibold" style={{ width: "100px" }}>
                                                Invoice No.
                                            </p>
                                            <p>: &nbsp;{inData?.invoice_no}</p>
                                        </div>
                                        <div className="flex flex-row items-center py-1">
                                            <p className="font-semibold" style={{ width: "100px" }}>
                                                Invoice Date
                                            </p>
                                            <p>: &nbsp;&nbsp;{inData?.invoice_on}</p>
                                        </div>
                                        <div className="flex flex-row py-1">
                                            <p className="font-semibold" style={{ width: "100px" }}>
                                                Patient ID
                                            </p>
                                            <p>: &nbsp;&nbsp;{inData?.patient_op_number}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Invoice Title */}
                                <h2
                                    style={{ color: "#969696" }}
                                    className="text-center w-full mb-2 -mt-2 text-xl pr-20 font-bold"
                                >
                                    Invoice
                                </h2>
                                <div className="w-full  border-t-2 border-dashed border-[#e9e9e9] mb-2 "></div>

                                <div className="flex flex-row w-[100%] text-[#4a4a4a]">
                                    <div className="w-[50%]">
                                        <div className="flex flex-row py-1">
                                            <p className="w-[21%] font-semibold ">Name</p>
                                            <p>
                                                : &nbsp;
                                                {inData.FirstName +
                                                    " " +
                                                    inData.MiddleName?.charAt(0) +
                                                    " " +
                                                    inData.LastName}
                                            </p>
                                        </div>
                                        <div className="flex flex-row  py-1">
                                            <p className="w-[21%] font-semibold">Gender</p>
                                            <p>: &nbsp;{inData?.gender}</p>
                                        </div>
                                        <div className="flex flex-row  py-1">
                                            <p className="w-[21%] font-semibold">Age</p>
                                            <p>:&nbsp;{inData.age}</p>
                                        </div>
                                    </div>
                                    <div className="w-[50%] ml-80 ">
                                        <div className="flex flex-row py-1">
                                            <p className="w-[20%] font-semibold ">Email</p>
                                            <p>:&nbsp;{inData?.email_id ? inData?.email_id : "-"}</p>
                                        </div>
                                        <div className="flex flex-row py-1">
                                            <p className="w-[20%] font-semibold ">Phone</p>
                                            <p>:&nbsp;{inData?.mobile_number}</p>
                                        </div>
                                        <div className="flex flex-row py-1">
                                            <p className="w-[20%] font-semibold ">Ref By</p>
                                            <p>: &nbsp;{inData?.ref_by ? inData?.ref_by : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full border-t-2 border-dashed border-[#969696] my-2"></div>
                            {tariffLoad ? (
                                <svg
                                    id="GenerateInvoiceSvgloadingSpinner"
                                    aria-hidden="true"
                                    role="status"
                                    className="w-6 h-6 ml-2 text-red-600 animate-spin"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            ) : (
                                <>
                                    {isAppEditInvoice || rows.length == 0 ? (
                                        <GenerateInvoiceTable
                                            rows={rows}
                                            setRows={setRows}
                                            setTotalAmount={setTotalAmount}
                                            setAmount={setAmount}
                                            setGstAmount={setGstAmount}
                                        />
                                    ) : (
                                        <>
                                            <div className="mt-4">
                                                <table className="border rounded-md focus:outline-none focus:border-gray-200 w-full text-[#4a4a4a] mb-12">
                                                    <thead
                                                        style={{ backgroundColor: "#d4d4d4" }}
                                                        className="text-[#4a4a4a] rounded-xl"
                                                    >
                                                        <tr>
                                                            <th
                                                                id="GenerateInvoiceThindex"
                                                                style={{ paddingLeft: "0.5rem" }}
                                                                className=" py-2 text-center"
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                id="GenerateInvoiceThitem"
                                                                className="py-2 text-center"
                                                            >
                                                                Description
                                                            </th>
                                                            <th
                                                                id="GenerateInvoiceThquantity"
                                                                className="py-2 text-center"
                                                            >
                                                                Quantity
                                                            </th>
                                                            <th
                                                                id="GenerateInvoiceThamount"
                                                                className="py-2 text-center"
                                                            >
                                                                Amount
                                                            </th>

                                                            <th
                                                                id="GenerateInvoiceThgstRate"
                                                                className="py-2 text-center"
                                                            >
                                                                GST(%)
                                                            </th>

                                                            <th
                                                                id="GenerateInvoiceThtotal"
                                                                className=" py-2 text-center"
                                                            >
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map((row, index) => (
                                                            <tr key={"inv-item-" + index}>
                                                                <td
                                                                    id={"GenerateInvoiceTdindex" + index}
                                                                    className="rounded-tl-md py-2 text-center"
                                                                >
                                                                    {index + 1}
                                                                </td>
                                                                <td
                                                                    id={"GenerateInvoiceTddescription" + index}
                                                                    className="py-2 text-center"
                                                                >
                                                                    {row.description}
                                                                </td>
                                                                <td
                                                                    id={"GenerateInvoiceTdquantity" + index}
                                                                    className="py-2 text-center"
                                                                >
                                                                    {row.quantity}
                                                                </td>
                                                                <td
                                                                    id={"GenerateInvoiceTdamount" + index}
                                                                    className="py-2 text-center"
                                                                >
                                                                    <p>
                                                                        <LiaRupeeSignSolid className="mt-[0px] inline" />
                                                                        <span>{row.amount}</span>
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    id={"GenerateInvoiceTdgst" + index}
                                                                    className="py-2 text-center"
                                                                >
                                                                    {row.gst}
                                                                </td>

                                                                <td
                                                                    id={"GenerateInvoiceTdtotal" + index}
                                                                    className="py-2 text-center"
                                                                >
                                                                    <p>
                                                                        <LiaRupeeSignSolid className="mt-[0px] inline" />
                                                                        <span>{row.total.toFixed(2)}</span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {(isAppEditInvoice || rows.length == 0) && (
                                <div className="p-1 text-[#4a4a4a] text-semi-bold mb-4">
                                    <input
                                        type="checkbox"
                                        onChange={handleDiscountCheckbox}
                                        checked={discountIsChecked}
                                    />{" "}
                                    &nbsp; Discount
                                </div>
                            )}
                            <div className="w-full border-t-2 border-dashed border-[#969696] "></div>
                            <table className="w-full">
                           
                           {print && <tbody>
                                <tr className="text-[#4a4a4a]  font-semibold w-full">
                                    <td id="GenerateInvoicetdgstTotalText" className="py-2 text-left">
                                    Sub Total
                                    </td>
                                    <td></td>
                                    <td id="GenerateInvoiceTdgstTotalAmountFixed" className="py-2 text-right">
                                        : <LiaRupeeSignSolid className="inline mr-1" /> {amount.toFixed(2)}
                                    </td>
                                </tr>
                                {!isAppEditInvoice && discountAmount > 0 && (
                                    <tr className="text-[#4a4a4a]  font-semibold w-full">
                                    <td id="GenerateInvoiceTdiscountType" className="py-2 text-left">
                                    Discount
                                    {discountType.includes("%") && ` (${discountType})`}
                                    </td>
                                    <td></td>
                                    <td id="GenerateInvoiceTddiscountamountFixed" className="py-2 text-right">
                                        : <LiaRupeeSignSolid className="inline mr-1" />  {discountAmount.toFixed(2)}
                                    </td>
                                </tr>                                   
                                )}
                                <tr className="text-[#4a4a4a]  font-semibold w-full">
                                    <td id="GenerateInvoicetdgstText" className="py-2 text-left">
                                        GST
                                    </td>
                                    <td></td>
                                    <td id="GenerateInvoiceTdgstAmountFixed" className="py-2 text-right">
                                        : <LiaRupeeSignSolid className="inline mr-1" /> {gstAmount.toFixed(2)}
                                    </td>
                                </tr>
 
                                 <tr className=" border-y-2  border-dashed border-y-neutral-400 my-2 text-[#4a4a4a] font-semibold w-full">
                                    <td id="GenerateInvoicetdamountDueText" className="py-2 text-left">
                                        Amount Due
                                    </td>
                                    <td></td>
                                    <td id="GenerateInvoiceTddueAmountFixed" className="py-2 text-right">
                                        : <LiaRupeeSignSolid className="inline mr-1" /> {totalAmount.toFixed(2)}                          
                                        </td>
                                    </tr>
                                </tbody>}
                        {!print &&
                                <tbody>
                                    <tr className="text-[#4a4a4a] font-semibold w-[100%]">
                                        <td className="py-2  text-left  w-[90%]">Sub Total</td>
                                        <td id="amountSymbol" className="py-2  text-right w-[5%]">
                                            : <LiaRupeeSignSolid className="inline mr-1" />
                                        </td>
                                        <td className="py-2  text-right w-[5%]">
                                            {amount.toFixed(2)}
                                        </td>
                                    </tr>

                                    {isAppEditInvoice && discountIsChecked && (
                                        <tr className="text-[#4a4a4a] font-semibold">
                                            <td
                                                id="GenerateInvoiceTddiscount"
                                                className="pb-1 text-[#4a4a4a]"
                                            >
                                                Discount
                                            </td>
                                            <td
                                                className="pb-1 text-[#4a4a4a] text-right"
                                                colSpan={2}
                                            >
                                                <input
                                                    type="text"
                                                    id="GenerateInvoiceInputdiscountChange"
                                                    className="h-8 rounded-md pl-1 w-24 text-right"
                                                    name="discount"
                                                    min={0}
                                                    value={discountType}
                                                    onChange={(event) => handleDiscountTypeChange(event)}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {!isAppEditInvoice && discountAmount > 0 && (
                                        <tr className="text-[#4a4a4a] font-semibold  w-[100%]">
                                            <td
                                                id="GenerateInvoiceTdiscountType"
                                                className="pb-2 text-[#4a4a4a]  w-[90%]"
                                            >
                                                Discount
                                                {discountType.includes("%") && ` (${discountType})`}
                                            </td>
                                            <td className="pb-2  text-right w-[5%]">
                                                : <LiaRupeeSignSolid className="inline mr-1" />
                                            </td>
                                            <td
                                                id="GenerateInvoiceTddiscountamountFixed"
                                                className="pb-2 text-right w-[5%]"
                                            >
                                                {discountAmount.toFixed(2)}
                                            </td>
                                        </tr>
                                    )}
                                    <tr className="text-[#4a4a4a] font-semibold w-[100%]">
                                        <td className="pb-2  text-left  w-[90%]">GST</td>
                                        <td className="pb-2  text-right w-[5%]">
                                            : <LiaRupeeSignSolid className="inline mr-1" />
                                        </td>
                                        <td className="pb-2  text-right w-[5%]">
                                            {gstAmount.toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr className=" border-y-2  border-dashed border-y-neutral-400 my-2 text-[#4a4a4a] font-semibold w-[100%]">
                                        <td
                                            id="GenerateInvoicetdamountDueText"
                                            className="py-1 w-[90%]"
                                        >
                                            Amount Due
                                        </td>
                                        <td
                                            id="GenerateInvoiceTddueAmountFixed"
                                            className="pt-1 text-right w-[5%]"
                                        >
                                            : <LiaRupeeSignSolid className="inline mr-1" />
                                        </td>
                                        <td
                                            id="GenerateInvoiceTddueAmountFixed"
                                            className="pt-1 text-right w-[5%]"
                                        >
                                            {totalAmount.toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>}
                            </table>
                            <div className="mt-4 hidden">
                                {groupedGSTData.length > 0 && (
                                    <table className="border border-gray-400 rounded-md w-full text-sm">
                                        <thead className="bg-gray-300 px-1 text-gray-700 rounded-xl">
                                            <tr className="">
                                                <th
                                                    id="GenerateInvoiceThtaxableValue"
                                                    className="py-1 text-center font-normal border-b border-gray-400"
                                                    rowSpan={2}
                                                >
                                                    Taxable Value
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThcentralTax"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                    colSpan={2}
                                                >
                                                    Central Tax
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThstateTax"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                    colSpan={2}
                                                >
                                                    State Tax
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThtotal"
                                                    className="py-1 text-center border-l border-gray-400 font-normal"
                                                >
                                                    Total
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    id="GenerateInvoiceThrate"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                >
                                                    Rate
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThamount"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                >
                                                    Amount
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThrate2"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                >
                                                    Rate
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThamount2"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                >
                                                    Amount
                                                </th>
                                                <th
                                                    id="GenerateInvoiceThtaxAmount"
                                                    className="py-1 text-center border-l border-b border-gray-400 font-normal"
                                                >
                                                    Tax Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupedGSTData
                                                .filter((a) => a.gst > 0)
                                                .map((grp, ind) => (
                                                    <tr key={"gst-tab-" + ind} className="">
                                                        <th
                                                            id="GenerateInvoiceThfloatTaxvalue"
                                                            className="py-1 px-2 text-left border-l border-b border-gray-400 font-medium"
                                                        >
                                                            {parseFloat(grp.taxableValue || 0).toFixed(2)}
                                                        </th>
                                                        <th
                                                            id="GenerateInvoiceThgst"
                                                            className="py-1 px-2 text-center border-l border-b border-gray-400 font-normal"
                                                        >
                                                            {grp.gst / 2}%
                                                        </th>
                                                        <th
                                                            id="GenerateInvoiceThgstValue"
                                                            className="py-1 px-2 text-right border-l border-b border-gray-400 font-normal"
                                                        >
                                                            {parseFloat(grp.gstValue / 2).toFixed(2)}
                                                        </th>
                                                        <th
                                                            id="GenerateInvoiceThgrpGst"
                                                            className="py-1 px-2 text-center border-l border-b border-gray-400 font-normal"
                                                        >
                                                            {grp.gst / 2}%
                                                        </th>
                                                        <th
                                                            id="GenerateInvoiceThfloatGstvalue"
                                                            className="py-1 px-2 text-right border-l border-b border-gray-400 font-normal"
                                                        >
                                                            {parseFloat(grp.gstValue / 2).toFixed(2)}
                                                        </th>
                                                        <th
                                                            id="GenerateInvoiceThfloatValue"
                                                            className="py-1 px-2 text-right border-l border-b border-gray-400 font-normal"
                                                        >
                                                            {parseFloat(grp.gstValue).toFixed(2)}
                                                        </th>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            {/* <div className='mt-4 lg:hidden xs:block'>
                                <div className='border-slate-200 border rounded px-4 py-2 lg:mr-6 drop-shadow-2xl lg:w-[60%] xs:w-[40%]'>
                                    <p className='text-[#af3d3c] font-semibold pb-2 '>Terms and Conditions</p>
                                    <p className=' pb-2 font-normal text-justify'>Please pay within 15 days from date of invoice, overdue interest @14% will be charged on delayed payments.</p>
                                    <p className='font-normal text-justify'>Please quote invoice number when remitting funds.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div
                        className="mt-4 mb-12 flex items-center justify-end"
                        style={{ width: "800px" }}
                    >
                        {isAppEditInvoice ? (
                            <button
                                id="GenerateInvoiceBtneditInvoice"
                                className=" bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md items-center"
                                onClick={setCLoseEditInvoice}
                            >
                                Submit
                            </button>
                        ) : (
                            <>
                                <div className="flex me-4 hidden ">
                                    <div className="me-1">
                                        <p id="GenerateInvoicePpaymentstatus" className="pb-1">
                                            Payment Status
                                        </p>
                                        <div className="flex items-center ">
                                            <input
                                                id="GenerateInvoiceInputpaymentstatusPending"
                                                type="radio"
                                                value="Pending"
                                                checked={inData.payment_status === "Pending"}
                                                onChange={(e) => {
                                                    setIndata({
                                                        ...inData,
                                                        payment_status: e.target.value,
                                                    });
                                                }}
                                                name="payment_status"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            <label
                                                id="GenerateInvoiceLabelpending"
                                                htmlFor="payment_status-pending"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                            >
                                                Pending
                                            </label>
                                        </div>
                                    </div>
                                    <div className="me-1">
                                        <p className="pb-1">&nbsp;</p>
                                        <div className="flex items-center ">
                                            <input
                                                id="GenerateInvoiceInputpaymentstatusPaid"
                                                type="radio"
                                                value="Paid"
                                                checked={inData.payment_status === "Paid"}
                                                onChange={(e) => {
                                                    setIndata({
                                                        ...inData,
                                                        payment_status: e.target.value,
                                                    });
                                                }}
                                                name="payment_status"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            <label
                                                id="GenerateInvoiceLabelpaid"
                                                htmlFor="payment_status-paid"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                            >
                                                Paid
                                            </label>
                                        </div>
                                    </div>
                                    <div className="me-2 ms-7">
                                        <p id="GenerateInvoicePpaymentMode" className="pb-1">
                                            Payment Mode
                                        </p>
                                        <div className="flex items-center ">
                                            <input
                                                id="GenerateInvoiceInputpaymentmodeCash"
                                                type="radio"
                                                value="UPI"
                                                checked={inData.payment_mode === "UPI"}
                                                disabled={inData.payment_status === "Pending"}
                                                onChange={() => { }}
                                                onClick={(e) => {
                                                    if (inData.payment_mode === "UPI") {
                                                        setIndata({ ...inData, payment_mode: "" });
                                                    } else {
                                                        setIndata({ ...inData, payment_mode: "UPI" });
                                                    }
                                                }}
                                                name="payment_mode"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed"
                                            />
                                            <label
                                                id="Labelupi"
                                                htmlFor="payment_mode-cash"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                            >
                                                UPI
                                            </label>
                                        </div>
                                    </div>
                                    <div className="-ms-10">
                                        <p className="pb-1">&nbsp;</p>
                                        <div className="flex items-center ">
                                            <input
                                                id="GenerateInvoiceInputpaymentmodeCard"
                                                type="radio"
                                                value="Card"
                                                checked={inData.payment_mode === "Card"}
                                                disabled={inData.payment_status === "Pending"}
                                                onChange={() => { }}
                                                onClick={(e) => {
                                                    if (inData.payment_mode === "Card") {
                                                        setIndata({ ...inData, payment_mode: "" });
                                                    } else {
                                                        setIndata({ ...inData, payment_mode: "Card" });
                                                    }
                                                }}
                                                name="payment_mode"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed"
                                            />
                                            <label
                                                id="GenerateInvoiceLabelcard"
                                                htmlFor="payment_mode-card"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                            >
                                                Card
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ms-2">
                                        <p className="pb-1">&nbsp;</p>
                                        <div className="flex items-center ">
                                            <input
                                                id="GenerateInvoiceInputpaymentmodeCard"
                                                type="radio"
                                                value="Cash"
                                                checked={inData.payment_mode === "Cash"}
                                                disabled={inData.payment_status === "Pending"}
                                                onChange={() => { }}
                                                onClick={(e) => {
                                                    if (inData.payment_mode === "Cash") {
                                                        setIndata({ ...inData, payment_mode: "" });
                                                    } else {
                                                        setIndata({ ...inData, payment_mode: "Cash" });
                                                    }
                                                }}
                                                name="payment_mode"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed"
                                            />
                                            <label
                                                id="GenerateInvoiceLabelcash"
                                                htmlFor="payment_mode-card"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500"
                                            >
                                                Cash
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    id="GenerateInvoiceBtnpencil"
                                    disabled={submitDisable}
                                    className="text-xl  mt-4 py-[10px] bg-blue-600 px-[20px] mr-[10px] rounded disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={setOpenEditInvoice}
                                >
                                    <PiPencil className="text-white" />
                                </button>
                                <button
                                    id="GenerateInvoiceBtnprintFill"
                                    disabled={submitDisable}
                                    className="text-xl mt-4 py-[10px] bg-[green] px-[20px] mr-[10px] rounded disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={() => {
                                        saveInvoice(true);
                                        setPrint(true);
                                    }}
                                >
                                    <PiPrinterFill className="text-white" />
                                </button>
                                <button
                                    id="GenerateInvoiceBtnprintdownloadFill"
                                    disabled={submitDisable}
                                    className="text-xl mt-4 py-[10px] bg-gray-400  px-[20px] mr-[10px] rounded disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={() => generatePDF(selectedInvoice)}
                                >
                                    <HiOutlineDownload className="text-white" />
                                </button>
                                <button
                                    id="GenerateInvoiceBtnsave"
                                    disabled={submitDisable}
                                    className=" bg-red-700  mt-4 hover:bg-red-800 text-white py-2 px-4 rounded-md items-center disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={() => saveInvoice(false)}
                                >
                                    Save
                                    {loadingSave && (
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="inline w-4 h-4 ml-2 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GenerateInvoice;
