import React, { useEffect, useState, useMemo, useRef } from "react";
import * as XLSX from "xlsx";
import AddAppointment from "./AddAppointment";
import { useDispatch, useSelector } from "react-redux";
import store from "../../API/store";
import DataDecode from "../../utils/DataDecode";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import TimeInOutToggle from "./TimeInOutToggle";
import Vitals from "./Vitals";
import DateFunction from "../../utils/DateFunctions";
import { setAppoinmentDataValue } from "../../Redux/features/profileDrawer/profileSideNavbarSlice"
import Pagination from "../../components/Pagination";
import AddPatient from "../Patients/AddPatient";
import { setAddAppointmentPopup } from "../../Redux/features/popup/popupSlice";
import GenerateInvoice from "../Invoice/GenerateInvoice";
import { theme } from "../../EmraxisTheme/Theme";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { useSwipeable } from "react-swipeable";
import { useDrag } from "@use-gesture/react";
import GenerateToken from "./GenerateToken";
import GenerateTokenMobile from "./GenerateTokenMobile";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import UploadDocument from "../Patients/Profile/DocumentUploads/UploadDocument";
import ChooseDocument from "../Patients/Profile/DocumentUploads/ChooseDocument";
import { setIsDefaultGridView } from "../../Redux/features/appointmentGridView/gridviewSlice";
import { addLoginDetails } from "../../Redux/features/login/loginSlice";
import {
  setusernames, setradiologyUsernames, setNotRadiologyAddedUser
  , setNotLabAddedUser, setLabOptions, setRadiologyOptions,
  setRadiologyAppointments, setLabAppointments
} from "../../Redux/features/LaboratoryAndRadiology/LabandRadiologySlice";
import { setIsDesktopSidebarOpen } from "../../Redux/features/drawer/drawerSlice";


const Appointment = ({
  patient_id,
  clinic_guid,
  appointment_id,
  doctor_id,
  appointment,
  historyRefs,
  history,
}) => {
  const [openVitals, setOpenVitals] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [confirmBox, setConfirmBox] = useState(false);
  const [clinicGuid, setClinicGuid] = useState(false);
  const [totalpages, settotalpages] = useState(0);
  const [selectedpage, setselectedpage] = useState(1);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState([]);
  const [selectedEditAppointment, setSelectedEditAppointment] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [doctorsTimeslot, setDoctorsTimeSlot] = useState([]);
  const [sortType, setSortType] = useState("all");
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedSearch, setIsExpandedSearch] = useState(false);
  const [recordPopup, setRecordPopup] = useState(false);
  const [stopPopup, setStopPopup] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [isTodayAbove, setIsTodayAbove] = useState(true);
  const todayRef = useRef(null);
  const calendarRef = useRef(null);
  const calendarContainerRef = useRef(null);
  const menuRef = useRef(null);
  const [pastHistory, setPastHistory] = useState([]);

  const [viewHistory, setViewHistory] = useState(false);
  const [viewHistoryData, setViewHistoryData] = useState({});

  const [expandRow, setExpandRow] = useState(false);
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [profile, setProfile] = useState();
  const [patient, setPatient] = useState({});
  const [refreshImage, setRefreshImage] = useState(false);
  const [selectedTab, setSelectedTab] = useState('MyAppointments')
  const [appointmentId, setAppointmentId] = useState(appointment_id);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
  const [patientName, setPatientName] = useState('');
  const startOfMonth = currentDate.startOf("month");
  const endOfMonth = currentDate.endOf("month");
  const startDate = isExpanded
    ? startOfMonth.startOf("week")
    : currentDate.startOf("week");
  const endDate = isExpanded
    ? endOfMonth.endOf("week")
    : currentDate.endOf("week");
  const desiredLabItems = [
    { value: 1, label: "Blood Test" },
    { value: 2, label: "Sugar Test" },
    { value: 3, label: "Insulin Test" },
  ];
  const desiredRadiologyOptions = [
    { value: 1, label: "X-Ray" },
    { value: 2, label: "CT Scan" },
    { value: 3, label: "MRI" },

  ];
  const today = dayjs();
  var selectedAppointmentId = useSelector(
    (state) => state.visitSideNavbar.selectedAppointmentId
  );
  const pastSelected = useSelector(
    (state) => state.visitSideNavbar.pastSelected
  );
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var showAddPatientpopup = useSelector((state) => state.popup.showAddPatient);
  const isDefaultGridView = useSelector(state => state.appGridView.isDefaultGridView);
  const viewType = useSelector(state => state.appGridView.appGridViewSelection);
  const appShowTodaySection = useSelector(state => state.appGridView.appShowTodaySection);
  var showAddAppointmentpopup = useSelector(
    (state) => state.popup.showAddAppointment
  );
  var patientAddAppointment = useSelector(
    (state) => state.popup.setPatientAddAppointment
  );
  var refreshAppointment = useSelector(
    (state) => state.popup.refreshAppointment
  );
  var showAppointmentInvoicePrint = useSelector(
    (state) => state.popup.showAppointmentInvoicePrint
  );
  const previewTheme =
    useSelector((state) => state.theme.colors) ||
    theme.filter((a) => a.name === "defaultTheme")[0];

  var isMobileSidebarOpen = useSelector(
    (state) => state.drawer.isMobileSidebarOpen
  );
  var isDesktopSidebarOpen = useSelector(state => state.drawer.isDesktopSidebarOpen);
  var isProfileDrawerOpen = useSelector(
    (state) => state.profileDrawer.isProfileDrawerOpen
  );
  var showDocumentUploadPopup = useSelector(
    (state) => state.document_upload.showDocumentUploadPopup
  );
  var showUploadPopup = useSelector(
    (state) => state.document_upload.showUploadPopup
  );
  var refreshUploadedFiles = useSelector(
    (state) => state.document_upload.refreshUploadedFiles
  );
  var labOptions = useSelector(state => state.labAndRadiologyDetails.labOptions);
  var radiologyOptions = useSelector(state => state.labAndRadiologyDetails.radiologyOptions);

  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    UpdateAppointmentGrid();
  }, [isDefaultGridView])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const UpdateAppointmentGrid = () => {
    let updateData = { appGridViewSelection: viewType, appShowTodaySection: appShowTodaySection, isDefaultGridView: isDefaultGridView };
    store.UpdateAppointmentGrid(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, user_id: loggeduser.user_details_id, appointment_grid_view: updateData }))
      .then((result) => {
        if (result.status === 200) {
          dispatch(addLoginDetails({ ...loggeduser, appointment_grid_view: updateData }));
        }
      })
      .catch(error => {
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
      });
  };
  const FetchLabAndRadiologyData = async () => {
    let payload = loggeduser?.facilityID;
    await store
      .FetchLabAndRadiologyUsers(
        loggedusertoken,
        DataDecode.encryptPayload(payload)
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            const activeLabUsers = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  user.lab_Management?.is_Lab_Active === true
              )
              .map((user) => user.full_name || "");

            const radiologyUsers = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  user.lab_Management?.is_Radiology_Active === true
              )
              .map((user) => user.full_name || "");
            const users = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  !user.lab_Management?.is_Lab_Active
              )
              .map((user) => user.full_name || "");
            const transformedData = users.map((item, index) => ({
              value: index + 1,
              label: item,
            }));
            const radiologyUs = res
              .filter(
                (user) =>
                  typeof user === "object" &&
                  !user.lab_Management?.is_Radiology_Active
              )
              .map((user) => user.full_name || "");
            const RadtransformedData = radiologyUs.map((item, index) => ({
              value: index + 1,
              label: item,
            }));
            dispatch(setNotRadiologyAddedUser(RadtransformedData));
            dispatch(setNotLabAddedUser(transformedData));
            dispatch(setusernames(activeLabUsers));
            dispatch(setradiologyUsernames(radiologyUsers));
          });
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error while fetching users",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetLabAndRadiologydetails = async () => {

    let FacilityId = loggeduser?.facilityID;
    await store.GetLaboratoryDetails(loggedusertoken, DataDecode.encryptPayload(FacilityId))
      .then((response) => {

        if (response.status === 200) {
          response.json().then((res) => {
            const transformedData = res?.laboratoryTests.map((item, index) => ({
              value: index + 1,
              label: item,
            }));
            const uniqueTests = [...new Set([...transformedData, ...labOptions])];
            const distinctLabTests = uniqueTests.filter(
              (item, index, self) =>
                self.findIndex(t => t.label === item.label) === index
            );

            dispatch(setLabOptions(distinctLabTests));

            const radiologyTests = res?.radiologyTests.map((item, index) => ({
              value: index + 1,
              label: item,
            }));
            const uniqueRadiologytests = [...new Set([...radiologyTests, ...radiologyOptions])];

            const distinctTests = uniqueRadiologytests.filter(
              (item, index, self) =>
                self.findIndex(t => t.label === item.label) === index
            );
            dispatch(setRadiologyOptions(distinctTests));
          })


        }

      }).catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });;
  };
  useEffect(() => {
    if (loggeduser?.facilityID)
      FetchLabAndRadiologyData();
    GetLabAndRadiologydetails();
  }, [loggeduser])
  useEffect(() => {
    if (loggeduser) {
      setClinicGuid(loggeduser.facilityID);
      GetDoctors(loggeduser.facilityID);
    }
  }, [loggeduser]);

  useEffect(() => {
    if (patientAddAppointment?.isOpenAppointment) {
      dispatch(setAddAppointmentPopup(true));
    }
  }, [patientAddAppointment]);

  useEffect(() => {
    if (showAddAppointmentpopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showAddAppointmentpopup]);

  useEffect(() => {
    if (loggeduser) {
      if (query == "") GetAppointments();
      else searchappointment();

      // Set up an interval to fetch data every minute
      // const interval = setInterval(GetAppointments, 60000); // 60000 ms = 1 minute

      // // Clear the interval on component unmount
      // return () => clearInterval(interval);
    }
  }, [
    loggeduser,
    refreshData,
    query,
    selectedpage,
    sortType,
    refreshAppointment,
    selectedTab,
    screenWidth
  ]);

  const handleChangeQuery = (event) => {
    const querys = event.target.value;
    if (querys.trim() != "") setQuery(querys.trim());
    else setQuery("");
  };

  const pageselected = (page) => {
    setselectedpage(page);
  };

  const generateCalendar = () => {
    let date = startDate;
    const calendar = [];

    while (date <= endDate) {
      calendar.push(date);
      date = date.add(1, "day");
    }

    return calendar;
  };

  let calendar = generateCalendar();

  const handlePrev = () => {
    const newDate = isExpanded
      ? currentDate.subtract(1, "month")
      : currentDate.subtract(1, "week");
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = isExpanded
      ? currentDate.add(1, "month")
      : currentDate.add(1, "week");
    setCurrentDate(newDate);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const filteredAppointments = appointments.filter((app) =>
      date.isSame(app.appointment_time, "day")
    );
    setRowsToShow(filteredAppointments);
    scrollToDate(date);
  };
  const toggleSearch = () => {
    setIsExpandedSearch(!isExpandedSearch);
  };
  const bind = useDrag(({ down, movement: [, drag] }) => {
    if (!down && drag > 100) {
      setIsExpanded(true);
    } else if (!down && drag < -100) {
      setIsExpanded(false);
    }
  });
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const hasAppointment = (date) => {
    const d1 = new Date(date);
    d1.setHours(0, 0, 0, 0);
    let isExist = appointments.filter((app) => {
      let d2 = new Date(app.appointment_time);
      d2.setHours(0, 0, 0, 0);
      return d1.getTime() === d2.getTime();
    });
    return isExist.length == 0 ? false : true;
  };
  const toggleMenu = (appointment_id) => {
    setOpenMenuId(openMenuId === appointment_id ? null : appointment_id);
  };
  const toggleDropdown = (appointment_id) => {
    setOpenDropdown(openDropdown === appointment_id ? null : appointment_id);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenuId(null);
      setOpenDropdown(null)
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleRecordButton = () => {
  //     setRecordPopup(true);
  // };

  // const handlePauseClick = () => {
  //     console.log('Pause button clicked');
  // };

  // const handleStopClick = () => {
  //     setStopPopup(true);
  // };

  // const handleProceed = () => {
  //     setRecordPopup(false);
  //     setStopPopup(false);
  // }
  // const handleCancel = () => {
  //     setStopPopup(false);
  // }
  // const onClose = () => {
  //     setRecordPopup(false);
  // };
  // useEffect(() => {
  //     GetAppointments();
  // }, []);

  const scrollToDate = (date) => {
    const element = document.getElementById(date.format("YYYY-MM-DD"));
    const calendarContainer = calendarContainerRef.current;
    const dragHandle = document.querySelector(".drag-handle");

    if (element && calendarContainer && dragHandle) {
      const elementRect = element.getBoundingClientRect();
      const dragHandleRect = dragHandle.getBoundingClientRect();
      const elementTop = elementRect.top + window.scrollY;
      const scrollToPosition = elementTop - dragHandleRect.bottom;
      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    } else {
      dispatch(
        setNotification({
          message: "No Appointment scheduled for this date",
          status: "success",
          action: true,
        })
      );
    }
  };

  useEffect(() => {
    if (appointments.length > 0) {
      scrollToDate(today);
    }
  }, [availableDates]);

  const handleTodayClick = () => {
    calendar = generateCalendar();
    if (todayRef.current) {
      todayRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const GetAppointments = () => {
    setIsAppointmentLoading(true);
    if (loggeduser.user_role == 'Doctor' && selectedTab == 'MyAppointments' && screenWidth < 640) {
      store
        .getAppointmentsByDoctorId(
          loggedusertoken,
          DataDecode.encryptPayload({
            clinic_guid: loggeduser.facilityID,
            currentDate: DateFunction.SetDbCurrentTimeStamp(),
            page: selectedpage,
            sort_by: sortType,
            userId: loggeduser.userId
          })
        )
        .then((result) => {
          if (result.status == 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              var myObject = JSON.parse(response);
              // Sort the appointments by appointment time (assuming it's in a sortable format)
              let sortedAppointments = myObject.appointmentData.sort(
                (a, b) =>
                  new Date(b.appointment_time) - new Date(a.appointment_time)
              );
              setAppointments(sortedAppointments);
              settotalpages(Math.ceil(myObject.totalappointment / 10));
            });
          } else {
            setAppointments([]);
            settotalpages(0);
            setselectedpage(1);
          }
          setTimeout(() => {
            setIsAppointmentLoading(false);
          }, 100);
        })
        .catch((err) => {
          setIsAppointmentLoading(false);
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        });
    } else {
      store.getAppointments(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          currentDate: DateFunction.SetDbCurrentTimeStamp(),
          page: selectedpage,
          sort_by: sortType,
        })
      )
        .then((result) => {
          if (result.status == 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              var myObject = JSON.parse(response);

              // Sort the appointments by appointment time (assuming it's in a sortable format)
              let sortedAppointments = myObject.appointmentData.sort(
                (a, b) =>
                  new Date(b.appointment_time) - new Date(a.appointment_time)
              );
              setAppointments(sortedAppointments);
              settotalpages(Math.ceil(myObject.totalappointment / 10));
            });
          } else {
            setAppointments([]);
            settotalpages(0);
            setselectedpage(1);
          }
          setTimeout(() => {
            setIsAppointmentLoading(false);
          }, 100);
        })
        .catch((err) => {
          setIsAppointmentLoading(false);
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        });
    }
  };

  const searchappointment = () => {
    setIsAppointmentLoading(true);
    store
      .searchAppointment(
        loggedusertoken,
        DataDecode.encryptPayload({
          search_string: query,
          clinic_guid: loggeduser.facilityID,
          page: selectedpage,
          currentDate: DateFunction.SetDbCurrentTimeStamp(),
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            var myObject = JSON.parse(response);
            setAppointments(myObject.appointmentData);
            settotalpages(Math.ceil(myObject.totalappointment / 10));
          });
        } else {
          setAppointments([]);
          settotalpages(0);
          setselectedpage(1);
        }
        setTimeout(() => {
          setIsAppointmentLoading(false);
        }, 100);
      });
  };

  const openPopup = () => {
    dispatch(setAddAppointmentPopup(true));
  };

  const editAppointment = (app) => {
    setSelectedEditAppointment(app);
    toggleMenu(null);
    toggleDropdown(null)
    openPopup();
  };
  const GetPastHistory = (patient_id, appointment_id) => {
    store
      .GetPastHistory(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            if (data.length > 0) {
              const historyData = data.filter(
                (a) => a.appointment_id === appointment_id
              );
              setPastHistory(historyData.length > 0 ? historyData[0] : {});
            }
          });
        } else {
          setPastHistory({});
        }
      })
      .catch((err) => {
        setPastHistory({});
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetPatient = (patient_id) => {
    store
      .GetPatient(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setPatient(JSON.parse(response));
          });
        } else {
          setPatient({});
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };


  useEffect(() => {
    GetPastHistory();
    GetPatient();
  }, [patient_id, clinic_guid, appointment_id, doctor_id]);

  const GetDoctors = (guid) => {
    store
      .getDoctorForAppointment(
        loggedusertoken,
        DataDecode.encryptPayload({ clinic_guid: guid })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setDoctors(data.drDetails);
            setDoctorsTimeSlot(data.drDetailstimeSlots);
          });
        } else {
          setDoctors([]);
          setDoctorsTimeSlot([]);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  const deleteAppointment = (app) => {
    setDeleteId(app);
    setConfirmBox(true);
    toggleMenu(null);
    toggleDropdown(null)
  };

  const editVitals = (app) => {
    setSelectedEditAppointment(app);
    setOpenVitals(true);
  };

  const handleDeleteSubmit = () => {
    setDisableButton(true);
    store
      .deleteAppointment(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          appointment_id: deleteId.appointment_id,
        })
      )
      .then((result) => {
        setDisableButton(false);
        setConfirmBox(false);
        setDisableButton(false);
        if (result.status == 204) {
          setRefreshData((r) => !r);
          store.CreateActivityLogs(loggeduser, (isDefaultGridView ? "Appointment Grid View" : "Appointment List View"), `Appointment Deleted - ${(deleteId.doctor_name ? "for " + deleteId.doctor_name?.trim() : "")} patient ${deleteId.FirstName} ${deleteId.MiddleName?.charAt(0)} ${deleteId.LastName}(${deleteId.patient_op_number}) on ${DateFunction.GetDate(deleteId.appointment_time)} ${(deleteId.app_time ? " at " + DateFunction.Convert12Hour(deleteId.app_time) : "")}`, "Delete");
          dispatch(
            setNotification({
              message: "Appointment Deleted Successfully!",
              status: "success",
              action: true,
            })
          );
        }
        else if (result.status == 409) {
          dispatch(setNotification({ message: "Data available for the selected appointment, hence the appointment cannot be deleted!", status: "info", action: true }));
        } else {
          dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true }));
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const openPatientDetails = (app) => {
    localStorage.setItem("NavigationScreen", "appointments");
    let data = btoa(
      JSON.stringify({
        appointment_id: app.appointment_id,
        patient_id: app.patient_id,
        doctor_id: app.doctor_id || 0,
        clinic_guid: loggeduser.facilityID,
        page_src: "appointment",
      })
    );
    window.location.href = `/patient-profile?profile=${data}`;
    dispatch(setAppoinmentDataValue(data))
    dispatch(setIsDesktopSidebarOpen(false));
  };
  const editVisitHistory = (appointment_id, patient_id, desktop = false) => {
    localStorage.setItem("NavigationScreen", "appointments");
    const data = btoa(
      JSON.stringify({
        appointment_id: appointment_id,
        patient_id: patient_id,
        doctor_id: doctor_id || 0,
        clinic_guid: loggeduser.facilityID,
        page_src: "appointment",
      })
    );
    dispatch(setIsDesktopSidebarOpen(false));
    setPatient({});
    setPastHistory({});
    GetPatient(patient_id);
    GetPastHistory(patient_id, appointment_id);
    setViewHistory(true);
    setViewHistoryData();
    setAppointmentId(appointment_id);
    if (desktop) window.location.href = `/patient-profile?profile=${data}`;
    else window.location.href = `/past-history-details?history=${data}`;
  };


  const AppStatusBadgeColour = (colour) => {
    let className = "border border-[#17A1FA] text-[#17A1FA]";
    if (colour.toLowerCase() === "pending") {
      className = "border border-yellow-200 text-yellow-800";
    } else if (colour.toLowerCase() === "completed") {
      className = "border border-[#00B087] text-[#00B087]";
    }
    return className;
  };

  // Table Sort Start
  const [searchValue, setSearchValue] = useState();
  const [appointmentList, setAppointmentList] = useState([]);
  const [rowsLimit, setRowsLimit] = useState(1);
  const [rowsToShow, setRowsToShow] = useState([]);
  useEffect(() => {
    let allDates = [DateFunction.SetDbDateOfBirth(new Date())];
    if (appointments.length > 0) {
      setRowsLimit(appointments.length);
      setAppointmentList(appointments);
      setRowsToShow(appointments.slice(0, appointments.length));
      let ableDate = appointments
        .filter((filt) => filt.appointment_time)
        .map((item) => DateFunction.SetDbDateOfBirth(item?.appointment_time));
      allDates.push(...ableDate);
      allDates.sort((a, b) => new Date(a) - new Date(b));
    } else {
      setRowsLimit(0);
      setAppointmentList([]);
      setRowsToShow([]);
    }
    let commonDates = [...new Set(allDates.map((item) => item))].map((a) =>
      dayjs(a)
    );
    setAvailableDates(commonDates);
  }, [appointments]);

  const [customPagination, setCustomPagination] = useState([]);
  const [activeColumn, setActiveColumn] = useState([""]);
  const [sortingColumn, setSortingColumn] = useState([""]);
  const [totalPage, setTotalPage] = useState(
    Math.ceil(appointmentList?.length / rowsLimit)
  );
  const [currentPage, setCurrentPage] = useState(0);

  function searchAppointments(keyword) {
    keyword = keyword.toLowerCase();
    setSearchValue(keyword);
    if (!keyword == "") {
      const results = appointments.filter((appointment) => {
        return (
          appointment.patient_op_number.toLowerCase().includes(keyword) ||
          appointment.patient_name.toLowerCase().includes(keyword) ||
          DateFunction.GetDate(appointment.appointment_time)
            .toLowerCase()
            .includes(keyword) ||
          DateFunction.Convert12Hour(appointment.app_time)
            .toLowerCase()
            .includes(keyword) ||
          appointment.mobile_number.toLowerCase().includes(keyword)
        );
      });
      setAppointmentList(results);
      let allDates = [DateFunction.SetDbDateOfBirth(new Date())];
      let ableDate = results
        .filter((filt) => filt.appointment_time)
        .map((item) => DateFunction.SetDbDateOfBirth(item?.appointment_time));
      allDates.push(...ableDate);
      allDates.sort((a, b) => new Date(a) - new Date(b));
      let commonDates = [...new Set(allDates.map((item) => item))].map((a) =>
        dayjs(a)
      );
      setAvailableDates(commonDates);
    } else {
      clearData();
    }
  }

  function searchProducts(keyword) {
    keyword = keyword.toLowerCase();
    setSearchValue(keyword);
    if (!keyword == "") {
      const results = appointments.filter((appointment) => {
        return (
          appointment.patient_op_number.toLowerCase().includes(keyword) ||
          appointment.FirstName?.toLowerCase().includes(keyword) ||
          DateFunction.GetDate(appointment.appointment_time)
            .toLowerCase()
            .includes(keyword) ||
          DateFunction.Convert12Hour(appointment.app_time)
            .toLowerCase()
            .includes(keyword) ||
          appointment.mobile_number.toLowerCase().includes(keyword) ||
          appointment.doctor_name.toLowerCase().includes(keyword)
        );
      });
      setAppointmentList(results);
      setRowsToShow(results.slice(0, rowsLimit));
      setCurrentPage(0);
      setTotalPage(Math.ceil(results?.length / rowsLimit));
    } else {
      clearData();
    }
  }
  const clearData = () => {
    setSearchValue("");
    const sortedProducts = appointments
      .slice()
      .sort((a, b) => b.appointment_time - a.appointment_time);
    setAppointmentList(sortedProducts);
    let allDates = [DateFunction.SetDbDateOfBirth(new Date())];
    let ableDate = sortedProducts
      .filter((filt) => filt.appointment_time)
      .map((item) => DateFunction.SetDbDateOfBirth(item?.appointment_time));
    allDates.push(...ableDate);
    allDates.sort((a, b) => new Date(a) - new Date(b));
    let commonDates = [...new Set(allDates.map((item) => item))].map((a) =>
      dayjs(a)
    );
    setAvailableDates(commonDates);
    setRowsToShow(sortedProducts.slice(0, rowsLimit));
    setTotalPage(Math.ceil(appointments?.length / rowsLimit));
  };
  const sortByColumn = (column, changeSortingColumn = true) => {
    if (column != "") {
      if (sortingColumn?.includes(column) && changeSortingColumn) {
        const sortData = appointmentList
          .slice()
          .sort((a, b) =>
            b[column].toString().localeCompare(a[column].toString())
          );
        setRowsToShow(
          sortData.slice(currentPage * rowsLimit, (currentPage + 1) * rowsLimit)
        );
        if (changeSortingColumn) {
          setSortingColumn([]);
          setAppointmentList(sortData);
        }
      } else {
        const sortData = appointmentList
          .slice()
          .sort((a, b) =>
            a[column].toString().localeCompare(b[column].toString())
          );
        setRowsToShow(
          sortData.slice(currentPage * rowsLimit, (currentPage + 1) * rowsLimit)
        );
        if (changeSortingColumn) {
          setAppointmentList(sortData);
          setSortingColumn([`${column}`]);
        } else {
        }
      }
    } else {
      if (sortingColumn?.includes(column)) {
        const sortedProducts = appointmentList
          .slice()
          .sort((a, b) => b.appointment_time - a.appointment_time);
        setRowsToShow(
          sortedProducts.slice(
            currentPage * rowsLimit,
            (currentPage + 1) * rowsLimit
          )
        );
        if (changeSortingColumn) {
          setSortingColumn([]);
          setAppointmentList(sortedProducts);
        }
      } else {
        const sortedProducts = appointmentList
          .slice()
          .sort((a, b) => a.appointment_time - b.appointment_time);
        setRowsToShow(
          sortedProducts.slice(
            currentPage * rowsLimit,
            (currentPage + 1) * rowsLimit
          )
        );
        if (changeSortingColumn) {
          setSortingColumn([`${column}`]);
          setAppointmentList(sortedProducts);
        }
      }
    }
    setActiveColumn([`${column}`]);
    // setCurrentPage(0);
  };
  const nextPage = () => {
    const startIndex = rowsLimit * (currentPage + 1);
    const endIndex = startIndex + rowsLimit;
    const newArray = appointments.slice(startIndex, endIndex);
    setRowsToShow(newArray);
    setCurrentPage(currentPage + 1);
  };
  const changePage = (value) => {
    const startIndex = value * rowsLimit;
    const endIndex = startIndex + rowsLimit;
    const newArray = appointmentList.slice(startIndex, endIndex);
    setRowsToShow(newArray);
    setCurrentPage(value);
  };
  const previousPage = () => {
    const startIndex = (currentPage - 1) * rowsLimit;
    const endIndex = startIndex + rowsLimit;
    const newArray = appointments.slice(startIndex, endIndex);
    setRowsToShow(newArray);
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(0);
    }
  };

  useEffect(() => {
    const sortedProducts = appointments
      .slice()
      .sort((a, b) => a.appointment_time - b.appointment_time);
    setAppointmentList(sortedProducts);
    setRowsToShow(sortedProducts.slice(0, rowsLimit));
  }, []);
  //Table Sort End

  return (
    <>
      {confirmBox && (
        <div
          id="AppointmentDivdeleteAppointment"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                id="AppointmentSvgdeleteIcon"
                className="text-[#9B1C1C] dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>

              <p
                id="AppointmentPdeleteAppText"
                className={`mb-4 `}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Are you sure you want to delete this appointment?
              </p>
              <div
                id="AppointmentDivcancel"
                className="flex justify-center items-center space-x-4"
              >
                <button
                  id="btnAppCancelCfmdDeleteFile"
                  onClick={() => setConfirmBox(false)}
                  disabled={disableButton}
                  type="button"
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.cancelBtnHoverColor}`
                    }
                  `}
                >
                  No, cancel
                </button>
                <button
                  id="AppointmentBtnsure"
                  type="button"
                  onClick={handleDeleteSubmit}
                  disabled={disableButton}
                  className={`py-2 px-3 text-sm font-medium text-center 
                                text-white  rounded-lg  
                                focus:ring-4 focus:outline-none focus:ring-red-300   ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor}
                                 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed`}
                >
                  Yes, I'm sure{" "}
                  {disableButton && (
                    <svg
                      id="AppointmentSvgsureIcon"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" mx-auto py-2 my-20 w-100% ">
        {/* <div className="flex items-center  ">

                </div> */}
        <div className="flex justify-between items-center lg:mb-4 px-2">
          <header
            id="AppointmentHeaderappointment"
            className={` font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 hidden lg:block ${previewTheme.headingTitleColor}`}
            style={{ fontFamily: previewTheme.fontFamily }}
          >
            Appointments
          </header>

          <div className="hidden lg:flex items-center">
            <div className=" hidden lg:flex flex-row gap-4 justify-between">
            {isDefaultGridView ? (
              <button className={`p-2 disabled:cursor-not-allowed`} disabled={!isDefaultGridView} id='BtnListListViewButton' onClick={() => { dispatch(setIsDefaultGridView(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M20 7H4M15 12H4M9 17H4" stroke="#282358" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </button>
              ) : (
              <button className={`p-2 disabled:cursor-not-allowed`} disabled={isDefaultGridView} id='BtnListGridViewButton' onClick={() => { dispatch(setIsDefaultGridView(true)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g clipPath="url(#clip0_1767_3218)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9 13C9.53043 13 10.0391 13.2107 10.4142 13.5858C10.7893 13.9609 11 14.4696 11 15V19C11 19.5304 10.7893 20.0391 10.4142 20.4142C10.0391 20.7893 9.53043 21 9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15C3 14.4696 3.21071 13.9609 3.58579 13.5858C3.96086 13.2107 4.46957 13 5 13H9ZM19 13C19.5304 13 20.0391 13.2107 20.4142 13.5858C20.7893 13.9609 21 14.4696 21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15C14.4696 21 13.9609 20.7893 13.5858 20.4142C13.2107 20.0391 13 19.5304 13 19V15C13 14.4696 13.2107 13.9609 13.5858 13.5858C13.9609 13.2107 14.4696 13 15 13H19ZM9 3C9.53043 3 10.0391 3.21071 10.4142 3.58579C10.7893 3.96086 11 4.46957 11 5V9C11 9.53043 10.7893 10.0391 10.4142 10.4142C10.0391 10.7893 9.53043 11 9 11H5C4.46957 11 3.96086 10.7893 3.58579 10.4142C3.21071 10.0391 3 9.53043 3 9V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9C21 9.53043 20.7893 10.0391 20.4142 10.4142C20.0391 10.7893 19.5304 11 19 11H15C14.4696 11 13.9609 10.7893 13.5858 10.4142C13.2107 10.0391 13 9.53043 13 9V5C13 4.46957 13.2107 3.96086 13.5858 3.58579C13.9609 3.21071 14.4696 3 15 3H19Z" fill="#282358" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1767_3218">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              )}
              <select
                id="AppointmentSelectsortType"
                value={sortType}
                className="hidden lg:block border text-md rounded-md focus:outline-none border-gray-300 px-2 py-1 cursor-pointer text-gray-500"
                onChange={(e) => setSortType(e.target.value)}
              >
                <option value="all">All</option>
                <option value="today">Today</option>
                <option value="week">This Week</option>
                <option value="month">This Month</option>
              </select>
              <div
                id="AppointmentDivsearchContainer"
                className="hidden lg:flex relative items-center gap-2"
              >
                <input
                  id="AppointmentInputsearch"
                  type="text"
                  className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                  placeholder="Search Id, Name, Phone"
                  // onChange={handleChangeQuery}
                  onChange={(e) => searchProducts(e.target.value)}
                />
                <svg
                  id="AppointmentSvgsearchIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </div>
              <div
                id="AppointmentDivbuttonContainer"
                className="hidden items-center lg:flex flex-row"
              >
                <button
                  id="AppointmentButtonscheduleAppointment"
                  className={`py-2 px-4 rounded-md flex text-sm items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`}
                  style={{ fontFamily: previewTheme.fontFamily }}
                  onClick={() => {
                    setSelectedEditAppointment(null);
                    openPopup();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                  </svg>
                  Book Appointment
                </button>
              </div>
              <button
                id="AppointmentButtonreload"
                className="hidden lg:block items-center lg:ml-6"
                onClick={() => window.location.reload()}
              >
                <svg
                  id="AppointmentButtonreloadIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2  text-[#9B1C1C] hover:text-[#7A1414]`}
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                  <path d="M20 4v5h-5" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          id="AppointmentappointmentTableContainer"
          className="hidden lg:block "
        >
          <table className="table-auto w-full border rounded-md focus:outline-none ">
            <thead
              id="AppointmentTableHead"
              className={`rounded-lg text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12 ${rowsToShow?.length > 0
                ? "border-b-0"
                : "border-b-2 border-black"
                }`}
            >
              <tr
                id="AppointmentTableHeadRow"
                className="px-2 py-2 text-left border-b"
              >
                <th
                  id="AppointmentTableHeadPatientID "
                  className="px-2 py-2 text-left"
                >
                  MRN
                </th>
                <th
                  id="AppointmentTableHeadName"
                  className="px-2 py-2 text-left"
                >
                  Name
                </th>
                <th
                  id="AppointmentTableHeadGender"
                  className="px-2 py-2 text-left "
                >
                  <div
                    id="AppointmentGenderHeader"
                    className="flex items-center"
                  >
                    <svg
                      id="AppointmentSortGenderIcon"
                      className={`w-4 h-4 cursor-pointer ${activeColumn?.includes("gender")
                        ? "text-black"
                        : "text-[#BCBDBE] group-hover:text-black rotate-180"
                        } ${sortingColumn?.includes("gender")
                          ? "rotate-180"
                          : "rotate-0"
                        }
                                         `}
                      onClick={() => sortByColumn("gender")}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                      />
                    </svg>
                    <span
                      id="AppointmentSpangenderText"
                      className="cursor-pointer pl-1"
                      onClick={() => sortByColumn("gender")}
                    >
                      Gender
                    </span>
                  </div>
                </th>
                <th
                  id="AppointmentTableHeadAppointmentTime"
                  className="px-2 py-2 text-left "
                >
                  <div
                    id="AppointmentAppointmentTimeHeader"
                    className="flex items-center"
                  >
                    <svg
                      id="AppointmentSortappointmentTime"
                      className={`w-4 h-4 cursor-pointer ${activeColumn?.includes("appointment_time")
                        ? "text-black"
                        : "text-[#BCBDBE] group-hover:text-black rotate-180"
                        } ${sortingColumn?.includes("appointment_time")
                          ? "rotate-180"
                          : "rotate-0"
                        }
                                         `}
                      onClick={() => sortByColumn("appointment_time")}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                      />
                    </svg>
                    <span
                      id="AppointmentSpanAppointmentTimeText"
                      className="cursor-pointer pl-1"
                      onClick={() => sortByColumn("appointment_time")}
                    >
                      Appointment
                    </span>
                  </div>
                </th>
                <th
                  id="AppointmentTableHeadMobile"
                  className="px-2 py-2 text-left "
                >
                  Mobile
                </th>
                <th
                  id="AppointmentTableHeadDoctor"
                  className="px-2 py-2 text-left pl-6"
                >
                  Doctor
                </th>
                {/* <th className="px-2 py-2 text-left ">Injury/Condition</th> */}
                <th
                  id="AppointmentTableHeadStatus"
                  className="px-2 py-2 text-center "
                >
                  Status
                </th>
                <th
                  id="AppointmentTableHeadTimeIn"
                  className="px-2 py-2 text-center "
                >
                  Time In
                </th>
                <th
                  id="AppointmentTableHeadToken"
                  className="px-2 py-2 text-center "
                >
                  Token
                </th>
                <th className="px-12 py-2 text-left "></th>
              </tr>
            </thead>
            {rowsToShow?.length > 0 ? (
              <tbody id="AppointmentTableBody" className="text-sm">
                {rowsToShow?.map((app, i) => (
                  <tr
                    id="AppointmentTrtable"
                    key={"app-table-" + i}
                    className="border-b h-12 text-[#424242] hover:bg-white bg-[#FBFBFB]"
                    onMouseEnter={() => setHoveredRowId(app.appointment_id)}
                    onMouseLeave={() => {
                      if (!openDropdown) {
                        setHoveredRowId(null);
                      }
                    }}
                  >
                    <td
                      id="TdPatientOpNumber"
                      className="px-2 py-1 text-left cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}
                    >
                      {app.patient_op_number}
                    </td>
                    <td
                      id="TdPatientName"
                      className="px-2 py-1 text-left cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}
                    >
                      <div
                        id="DivPatientName"
                        style={{
                          maxWidth: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        data-tooltip-id="Appointmenttooltip"
                        onMouseOver={(e) => e.stopPropagation()}
                        data-tooltip-content={`${app.FirstName + " " + app.MiddleName?.charAt(0) + " " + app.LastName}`}
                      >
                        {`${app.FirstName + " " + app.MiddleName?.charAt(0) + " " + app.LastName}`}
                      </div>
                      <Tooltip
                        id="Appointmenttooltip"
                        place="top"
                        effect="solid"
                      />
                    </td>
                    <td
                      id="TdGender"
                      className="px-2 py-1 text-left cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}
                    >
                      {app.gender !== "" ?
                        <span
                          className={
                            "text-sm me-2 px-2.5 py-0.5 rounded capitalize " +
                            (app.gender?.toLowerCase() === "female"
                              ? "text-[#424242]"
                              : "text-[#424242]")
                          }
                        >
                          {app.gender}
                        </span> : ""}
                    </td>
                    <td
                      id="TdAppointmentTime"
                      className="px-2 py-1 text-left cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}
                    >
                      {DateFunction.GetDate(app.appointment_time)}{" "}
                      {DateFunction.Convert12Hour(app.app_time)}
                    </td>
                    <td
                      id="TdMobileNumber"
                      className="px-2 py-1 text-left cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}                    >
                      {app.mobile_number}
                    </td>
                    <td
                      id="TdDoctorName"
                      className="px-2 py-1 text-left cursor-pointer text-sm pl-6"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}                    >
                      {app.doctor_name}
                    </td>
                    {/* <td className="px-2 py-2 text-left cursor-pointer" onClick={() => openPatientDetails(app)}>{app.purpose_of_visit}</td> */}
                    <td
                      id="TdStatus"
                      className="px-2 py-1 text-center cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}                    >
                      <span
                        id="SpanStatus"
                        className={
                          "text-md font-medium px-2 py-0.5 rounded capitalize " +
                          AppStatusBadgeColour(app.status)
                        }
                      >
                        {app.status}
                      </span>
                    </td>
                    <td
                      id="TdTimeIn"
                      className="px-2 py-1 text-center cursor-pointer text-sm"
                      onClick={() => {
                        if (app.status === "Completed") {
                          editVisitHistory(app.appointment_id, app.patient_id, true);
                        } else {
                          openPatientDetails(app);
                        }
                      }}                    >
                      <span
                        id="SpanTimeIn"
                        title={
                          app.time_in
                            ? DateFunction.GetDate(app.time_in) +
                            " " +
                            DateFunction.GetTime(app.time_in)
                            : ""
                        }
                      >
                        {app.time_in ? (
                          <span className=" px-2 py-1">
                            {DateFunction.GetTime(app.time_in)}
                          </span>
                        ) : (
                          "-"
                        )}
                      </span>
                    </td>
                    <td id="TdGenerateToken" className="px-2 py-1 text-center text-sm">
                      <GenerateToken
                        appointment={app}
                        setRefreshData={setRefreshData}
                      />
                    </td>
                    <td className="px-2 py-1 text-center relative" >
                      <button
                        id="AppointmentButtonappId"
                        onClick={() => toggleDropdown(app.appointment_id)}
                        className={`transition-opacity duration-200 ${hoveredRowId === app.appointment_id ? 'opacity-100' : 'opacity-0'}`}
                      >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 mt-2">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1.5C3 1.10218 3.15804 0.720644 3.43934 0.43934C3.72064 0.158035 4.10218 0 4.5 0H4.51C4.90782 0 5.28936 0.158035 5.57066 0.43934C5.85197 0.720644 6.01 1.10218 6.01 1.5V1.51C6.01 1.90782 5.85197 2.28936 5.57066 2.57066C5.28936 2.85196 4.90782 3.01 4.51 3.01H4.5C4.10218 3.01 3.72064 2.85196 3.43934 2.57066C3.15804 2.28936 3 1.90782 3 1.51V1.5ZM3 8.5C3 8.10218 3.15804 7.72064 3.43934 7.43934C3.72064 7.15804 4.10218 7 4.5 7H4.51C4.90782 7 5.28936 7.15804 5.57066 7.43934C5.85197 7.72064 6.01 8.10218 6.01 8.5V8.51C6.01 8.90782 5.85197 9.28936 5.57066 9.57066C5.28936 9.85196 4.90782 10.01 4.51 10.01H4.5C4.10218 10.01 3.72064 9.85196 3.43934 9.57066C3.15804 9.28936 3 8.90782 3 8.51V8.5ZM4.5 14C4.10218 14 3.72064 14.158 3.43934 14.4393C3.15804 14.7206 3 15.1022 3 15.5V15.51C3 15.9078 3.15804 16.2894 3.43934 16.5707C3.72064 16.852 4.10218 17.01 4.5 17.01H4.51C4.90782 17.01 5.28936 16.852 5.57066 16.5707C5.85197 16.2894 6.01 15.9078 6.01 15.51V15.5C6.01 15.1022 5.85197 14.7206 5.57066 14.4393C5.28936 14.158 4.90782 14 4.51 14H4.5Z" fill="black" />
                        </svg>
                      </button>

                      {(openDropdown === app.appointment_id) && (
                        <div
                          ref={menuRef}
                          id="AppointmentDivMenuappId"
                          className="absolute right-2 bg-white border border-gray-300 rounded-md z-10 mt-10"
                          style={{
                            width: "80px",
                            top: app === rowsToShow[rowsToShow.length - 1]
                              ? "auto"
                              : "calc(100% - 42px)",
                            bottom: app === rowsToShow[rowsToShow.length - 1]
                              ? "100%"
                              : "auto",
                            marginBottom: app === rowsToShow[rowsToShow.length - 1]
                              ? "-62px"
                              : "0",
                          }}
                        >
                          <ul>
                            <li
                              id="AppointmentLieditAppId"
                              onClick={() => {
                                if (app.status !== "Completed") {
                                  editAppointment(app);
                                } else {
                                  editVisitHistory(app.appointment_id, app.patient_id,true);
                                }
                              }}
                              className={`${app === rowsToShow[rowsToShow.length - 1]
                                ? "px-4 py-1"
                                : "px-4 py-2"} flex items-center gap-2 hover:bg-gray-100 cursor-pointer`}
                            >
                              Edit
                            </li>
                            <li
                              id="AppointmentLideleteAppId"
                              onClick={() => deleteAppointment(app)}
                              className={`px-4 py-2 flex items-center gap-2 hover:bg-gray-100 ${app.status === "Completed" ? "cursor-not-allowed" : "cursor-pointer"}`}
                            >
                              <span className={`${app.status === "Completed" ? "text-gray-400" : "text-black"}`}>
                                Delete
                              </span>
                            </li>
                            <li
                              id="AppointmentLieditVitals"
                              onClick={() => {
                                if (app.status !== "Completed" && DateFunction.isToday(app.appointment_time)) {
                                  editVitals(app);
                                }
                              }}
                              className={`${app === rowsToShow[rowsToShow.length - 1]
                                ? "px-4 py-1"
                                : "px-4 py-2"} flex items-center gap-2 hover:bg-gray-100 ${app.status === "Completed" || !DateFunction.isToday(app.appointment_time)
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"}`}
                            >
                              <span className={`${app.status === "Completed" || !DateFunction.isToday(app.appointment_time)
                                ? "text-gray-400"
                                : "text-black"} flex items-center gap-2`}
                              >
                                Vitals
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody id="AppointmentNorecordTableBody" className="text-sm">
                <tr id="AppointmentNorecordTableTrBody">
                  <td
                    id="AppointmentNorecordTableTdBody"
                    colSpan={12}
                    className="text-center text-red-600 p-2 text-md"
                  >
                    {isAppointmentLoading ? (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline size-5 ml-2 animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    ) : (
                      "No records available!"
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div {...handlers} className="lg:hidden px-4">
          <div className="flex flex-col">

            <div
              id="AppointmentDivcalendarRef"
              ref={calendarContainerRef}
              className={`sticky top-[77px] bg-white ${!isMobileSidebarOpen && !isProfileDrawerOpen ? "z-50" : ""
                }`}
            >
              {loggeduser.user_role == 'Doctor' && <div className="flex justify-center my-2">
                <div className={selectedTab == 'MyAppointments' ? "p-[6px] text-[#53453b] text-center font-medium text-[14px] bg-[#ebe8e8d1] border-[#A31B1B] border-[1px] rounded-lg" : 'p-[6px] text-[#53453b] text-center font-medium text-[14px] bg-[#fff]'} onClick={() => { setSelectedTab('MyAppointments'); setIsExpandedSearch(false) }}>My Appointments</div>
                <div className={selectedTab == 'AllAppointments' ? "p-[6px] text-[#53453b] text-center font-medium text-[14px] bg-[#ebe8e8d1] border-[#A31B1B] border-[1px] rounded-lg" : 'p-[6px] text-[#53453b] text-center font-medium text-[14px] bg-[#fff]'} onClick={() => { setSelectedTab('AllAppointments'); setIsExpandedSearch(false) }}>All Appointments</div>
              </div>}
              <div className="flex items-center  pb-[0.5rem] ">
                <div className="flex items-center">
                  <button
                    id="AppointmentBtnprev"
                    onClick={handlePrev}
                    className="text-red-800"
                  >
                    <svg
                      id="AppointmentSvgprevIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-8 w-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                  <div
                    id="AppointmentDivdateFormat"
                    className="text-md font-semibold"
                  >
                    {currentDate.format("MMMM")}
                  </div>
                  <button
                    id="AppointmentBtnnext"
                    onClick={handleNext}
                    className="text-red-800"
                  >
                    <svg
                      id="AppointmentSvgnextIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-8 w-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </div>

                <div className="flex items-center ml-auto">
                  {isExpandedSearch ? (
                    <div className="relative w-full flex items-center">
                      <svg
                        id="AppointmentSvgsearhIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="absolute left-3 w-6 h-6 text-red-800 cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                        />
                      </svg>
                      <input
                        type="text"
                        id="AppointmentInputsearchField"
                        className="w-full pl-10 pr-4 border border-gray-300 rounded-full shadow-sm focus:outline-none h-10"
                        placeholder="Search"
                        onChange={(e) => searchProducts(e.target.value)}
                      />
                    </div>
                  ) : (
                    <button
                      id="AppointmentButtonsearchToggle"
                      className="sm:flex md:flex sm:top-section"
                      onClick={toggleSearch}
                    >
                      <svg
                        id="AppointmentButtonsearchToggleIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 text-red-800"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <button
                  id="AppointmentButtoneditApp"
                  onClick={() => {
                    setSelectedEditAppointment(null);
                    openPopup();
                  }}
                >
                  <svg
                    id="AppointmentSvgeditApp"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className=" fill-red-800 h-10 w-10"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <div className="w-full h-0.5 bg-red-700"></div> */}
              <div className="grid grid-cols-7 gap-2 text-center border-y-2 border-y-red-700 pb-2">
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                  (day, index) => (
                    <div
                      id={"AppointmentDivpresDay" + index}
                      key={index}
                      className="font-semibold"
                    >
                      {day}
                    </div>
                  )
                )}
                {calendar.map((date, index) => {
                  const isToday = date.isSame(today, "day");
                  const hasAppointmentForDate = hasAppointment(date);

                  const textColorClass =
                    date.month() !== currentDate.month() ||
                      date.isBefore(today, "day")
                      ? "text-gray-400"
                      : "text-gray-900";
                  const bgColorClass = isToday
                    ? "bg-blue-500 text-white"
                    : date.isSame(selectedDate, "day")
                      ? "bg-gray-300"
                      : "";

                  return (
                    <div
                      key={index}
                      className={`p-1 size-8 rounded-full cursor-pointer relative ${textColorClass} ${bgColorClass}`}
                      onClick={() => {
                        handleDateClick(date);
                      }}
                    >
                      {date.date()}
                      {hasAppointmentForDate && (
                        <span
                          id="AppointmentSpannextAppdate"
                          className="block absolute bottom-1 left-1/2 transform -translate-x-1/2 w-1 h-1 bg-red-700 rounded-full"
                        ></span>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* <div className="w-full h-0.5 bg-red-700 mt-2"></div> */}
              <div
                id="AppointmentDivexpanded"
                // {...bind()}
                className="flex justify-center items-center cursor-grab drag-handle mt-4"
                // style={{ touchAction: 'none' }}
                onClick={() => setIsExpanded((pre) => !pre)}
              >
                <div className="w-16 h-2 bg-gray-300 rounded-full"></div>
              </div>
            </div>

            {/* <button className="fixed bottom-24 px-4 py-2 text-red-700 border bg-white rounded-full border-gray-500 text-semibold flex items-center cursor-pointer z-50" onClick={handleTodayClick}>Today</button> */}
            <div
              id="AppointmentDivcalendarContainer"
              ref={calendarRef}
              className="flex-grow overflow-y-auto px-auto"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              {availableDates.map((date, index) => (
                <div
                  key={index}
                  id={date.format("YYYY-MM-DD")}
                  className="mb-4"
                  ref={date.isSame(today, "day") ? todayRef : null}
                >
                  {date.isSame(today, "day") ? (
                    <>
                      <h2
                        id="AppointmentH2todaydate"
                        className="text-xl px-4 py-2 text-red-700 font-bold"
                      >
                        {date.format("MMMM D, YYYY")}
                      </h2>
                      {appointmentList.filter((app) =>
                        date.isSame(app.appointment_time, "day")
                      ).length == 0 ? (
                        <p
                          id="AppointmentPnoAppointments"
                          className="text-center border px-4 py-2"
                        >
                          No appointments
                        </p>
                      ) : (
                        <table
                          id="AppointmentTableappointmentsTable"
                          className="min-w-full bg-white"
                        >
                          {appointmentList
                            .filter((app) =>
                              date.isSame(app.appointment_time, "day")
                            )
                            .map((app, i) => (
                              <tbody key={"app-table-" + i}>
                                <tr className="border-b border-t border-b-gray-400 border-t-gray-400">
                                  <td
                                    id="AppointmentTDappointmentTime"
                                    className="px-2 py-1 text-left text-sm font-semibold text-nowrap"
                                    onClick={() => {
                                      if (app.status === "Completed") {
                                        editVisitHistory(app.appointment_id, app.patient_id);
                                      } else {
                                        openPatientDetails(app);
                                      }
                                    }}
                                  >
                                    {DateFunction.Convert12Hour(app.app_time)}
                                  </td>
                                  <td
                                    id="AppointmentTDgetPatientnameToday"
                                    className="px-2 py-1 flex flex-col border-l border-r border-l-gray-200 border-r-gray-200"
                                    onClick={() => {
                                      if (app.status === "Completed") {
                                        editVisitHistory(app.appointment_id, app.patient_id);
                                      } else {
                                        openPatientDetails(app);
                                      }
                                    }}
                                  >
                                    <span
                                      id="AppointmentSpangetPatientnameToday"
                                      style={{
                                        maxWidth: "100px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    // data-tooltip-id={`tooltip-${app.appointment_id}`}
                                    // onClick={(e) => e.stopPropagation()}
                                    // data-tooltip-content={app.patient_name}
                                    >
                                      {`${app.FirstName + " " + app.MiddleName?.charAt(0) + " " + app.LastName}`}
                                      <Tooltip
                                        id={`tooltip-${app.appointment_id}`}
                                        place="top"
                                        effect="solid"
                                      />
                                    </span>
                                    <span
                                      id="AppointmentSpangetPatientOpNum"
                                      className="text-sm"
                                    >
                                      {app.patient_op_number}
                                    </span>
                                  </td>
                                  <td className="text-center w-36">
                                    <GenerateTokenMobile
                                      id="AppointmentGenerateMobgenerateToken"
                                      appointment={app}
                                      setRefreshData={setRefreshData}
                                    />
                                  </td>
                                  <td className="px-2 py-1 text-center relative">
                                    <button
                                      id="AppointmentButtonappId"
                                      onClick={() =>
                                        toggleMenu(app.appointment_id)
                                      }
                                    >
                                      <svg
                                        id="AppointmentButtonappIdIcon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="6"
                                        height="19"
                                        viewBox="0 0 6 19"
                                        fill="none"
                                      >
                                        <g opacity="0.5">
                                          <path
                                            d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z"
                                            stroke="#A31B1B"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z"
                                            stroke="#A31B1B"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z"
                                            stroke="#A31B1B"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                    {openMenuId === app.appointment_id && (
                                      <div
                                        id="AppointmentDivMenuappId"
                                        ref={menuRef}
                                        className="absolute right-2 bg-white border border-gray-300 rounded-md z-10"
                                        style={{
                                          width: "138px",
                                          top:
                                            app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ]
                                              ? "auto"
                                              : "calc(100% - 42px)",
                                          bottom:
                                            app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ]
                                              ? "100%"
                                              : "auto",
                                          marginBottom:
                                            app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ]
                                              ? "-62px"
                                              : "0",
                                        }}
                                      >
                                        <ul>
                                          <li
                                            id="AppointmentLieditAppId"
                                            onClick={() => {
                                              if (app.status !== "Completed") {
                                                editAppointment(app);
                                              } else {
                                                editVisitHistory(
                                                  app.appointment_id,
                                                  app.patient_id,
                                                  app.doctor_id
                                                );
                                              }
                                            }}
                                            isdisabled={
                                              app.status === "Completed"
                                            }
                                            className={app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ] ? "px-4 py-1 flex items-center gap-2" : "px-4 py-2 flex items-center gap-2"}
                                          >
                                            <svg
                                              id="AppointmentSvgeditAppIdIcon"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              className="w-5 h-5"
                                            >
                                              <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                            </svg>
                                            Edit
                                          </li>
                                          <li
                                            id="AppointmentLideleteAppId"
                                            onClick={() =>
                                              deleteAppointment(
                                                app
                                              )
                                            }
                                            className="px-4 py-2 flex items-center gap-2"
                                          >
                                            <button
                                              id="AppointmentBtndeleteAppId"
                                              disabled={
                                                app.status === "Completed"
                                              }
                                              className={`${app.status === "Completed"
                                                ? "text-red-300"
                                                : "text-red-600"
                                                } flex items-center gap-2`}
                                            >
                                              <svg
                                                id="AppointmentSvgdeleteAppIdIcon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 "
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <span
                                                disabled={
                                                  app.status === "Completed"
                                                }
                                                className={`${app.status === "Completed"
                                                  ? "text-gray-300"
                                                  : "text-black"
                                                  }`}
                                              >
                                                Delete
                                              </span>
                                            </button>
                                          </li>
                                          <li
                                            id="AppointmentLieditVitals"
                                            onClick={() => editVitals(app)}
                                            className={app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ] ? "px-4 py-1 flex items-center gap-2" : "px-4 py-2 flex items-center gap-2"}
                                          >
                                            <button
                                              id="AppointmentBtneditVitals"
                                              disabled={
                                                app.status === "Completed" ||
                                                !DateFunction.isToday(
                                                  app.appointment_time
                                                )
                                              }
                                              className={`${app.status === "Completed" ||
                                                !DateFunction.isToday(
                                                  app.appointment_time
                                                )
                                                ? "text-green-300"
                                                : "text-green-600"
                                                } flex items-center gap-2`}
                                            >
                                              <svg
                                                id="AppointmentSvgeditVitalsIcon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 "
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
                                                  clipRule="evenodd"
                                                />
                                                <path
                                                  fillRule="evenodd"
                                                  d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <span
                                                id="AppointmentSpanvitalsAdd"
                                                disabled={
                                                  app.status === "Completed" ||
                                                  !DateFunction.isToday(
                                                    app.appointment_time
                                                  )
                                                }
                                                className={`${app.status === "Completed" ||
                                                  !DateFunction.isToday(
                                                    app.appointment_time
                                                  )
                                                  ? "text-gray-300"
                                                  : "text-black"
                                                  }`}
                                              >
                                                Add Vitals
                                              </span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      )}
                    </>
                  ) : (
                    appointmentList.length > 0 && (
                      <div
                        key={index}
                        id={date.format("YYYY-MM-DD")}
                        className="mb-4"
                      >
                        <h2
                          id="AppointmentH2dateFormat"
                          className="text-xl px-4 py-2 text-red-700 font-bold"
                        >
                          {date.format("MMMM D, YYYY")}
                        </h2>
                        <table className="min-w-full bg-white">
                          {appointmentList
                            .filter((app) =>
                              date.isSame(app.appointment_time, "day")
                            )
                            .map((app, i) => (
                              <tbody key={"app-table-" + i}>
                                <tr className="border-b border-t border-b-gray-400 border-t-gray-400">
                                  <td
                                    id="AppointmentTDgetAppTime"
                                    className="px-2 py-1 text-left text-sm font-semibold text-nowrap"
                                    onClick={() => {
                                      if (app.status === "Completed") {
                                        editVisitHistory(app.appointment_id, app.patient_id);
                                      } else {
                                        openPatientDetails(app);
                                      }
                                    }}
                                  >
                                    {DateFunction.Convert12Hour(app.app_time)}
                                  </td>
                                  <td
                                    id="AppointmentTDgetPatientname"
                                    className="px-2 py-1 flex flex-col border-l border-r border-l-gray-200 border-r-gray-200"
                                    onClick={() => {
                                      if (app.status === "Completed") {
                                        editVisitHistory(app.appointment_id, app.patient_id);
                                      } else {
                                        openPatientDetails(app);
                                      }
                                    }}
                                  >
                                    <span
                                      id="AppointmentSpangetPatientname"
                                      style={{
                                        maxWidth: "100px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    // data-tooltip-id={`tooltip-${app.appointment_id}`}
                                    // onClick={(e) => e.stopPropagation()}
                                    // data-tooltip-content={app.patient_name}
                                    >
                                      {`${app.FirstName + " " + app.MiddleName?.charAt(0) + " " + app.LastName}`}
                                      <Tooltip
                                        id={`tooltip-${app.appointment_id}`}
                                        place="top"
                                        effect="solid"
                                      />
                                    </span>
                                    <span
                                      id="AppointmentSpangetPatientOpNum"
                                      className="text-sm"
                                    >
                                      {app.patient_op_number}
                                    </span>
                                  </td>

                                  <td className="text-center w-36"
                                    onClick={() => {
                                      if (app.status === "Completed") {
                                        editVisitHistory(app.appointment_id, app.patient_id);
                                      } else {
                                        openPatientDetails(app);
                                      }
                                    }
                                    }
                                  >
                                    <GenerateTokenMobile
                                      appointment={app}
                                      setRefreshData={setRefreshData}
                                    />
                                  </td>
                                  <td className="px-2 py-1 text-center relative">
                                    <button
                                      id="AppointmentBtngetAppId"
                                      onClick={() =>
                                        toggleMenu(app.appointment_id)
                                      }
                                    >
                                      <svg
                                        id="AppointmentBtngetAppIdIcon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="6"
                                        height="19"
                                        viewBox="0 0 6 19"
                                        fill="none"
                                      >
                                        <g opacity="0.5">
                                          <path
                                            d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z"
                                            stroke="#A31B1B"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z"
                                            stroke="#A31B1B"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z"
                                            stroke="#A31B1B"
                                            strokeWidth="2.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                    {openMenuId === app.appointment_id && (
                                      <div
                                        ref={menuRef}
                                        className="absolute right-2 bg-white border border-gray-300 rounded-md z-10"
                                        style={{
                                          width: "138px",
                                          top:
                                            app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ]
                                              ? "auto"
                                              : "calc(100% - 42px)",
                                          bottom:
                                            app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ]
                                              ? "100%"
                                              : "auto",
                                          marginBottom:
                                            app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ]
                                              ? "-62px"
                                              : "0",
                                        }}
                                      >
                                        <ul>
                                          <li
                                            id="AppointmentLigetEdit"
                                            onClick={() => {
                                              if (app.status === "Completed") {
                                                editVisitHistory(app.appointment_id, app.patient_id);
                                              } else {
                                                editAppointment(app);
                                              }
                                            }}
                                            disabled={
                                              app.status === "Completed"
                                            }
                                            className="px-4 py-2 flex items-center gap-2"
                                          >
                                            <svg
                                              id="AppointmentLigetEditIcon"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              className="w-5 h-5"
                                            >
                                              <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                            </svg>
                                            Edit
                                          </li>
                                          <li
                                            id="AppointmentLigetDelete"
                                            onClick={() =>
                                              deleteAppointment(
                                                app
                                              )
                                            }
                                            className="px-4 py-2 flex items-center gap-2"
                                          >
                                            <button
                                              id="AppointmentBtngetDelete"
                                              disabled={
                                                app.status === "Completed"
                                              }
                                              className={`${app.status === "Completed"
                                                ? " text-[#D88C8C]"
                                                : "text-[#9B1C1C] "
                                                } flex items-center gap-2`}
                                            >
                                              <svg
                                                id="AppointmentSvggetDeleteIcon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 "
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <span
                                                id="AppointmentSpandeleteText"
                                                disabled={
                                                  app.status === "Completed"
                                                }
                                                className={`${app.status === "Completed"
                                                  ? "text-gray-300"
                                                  : "text-black"
                                                  }`}
                                              >
                                                Delete
                                              </span>
                                            </button>
                                          </li>
                                          <li
                                            id="AppointmentLigetvitalsAdd"
                                            onClick={() => editVitals(app)}
                                            className={app ===
                                              appointmentList[
                                              appointmentList.length - 1
                                              ] ? "px-4 py-1 flex items-center gap-2" : "px-4 py-2 flex items-center gap-2"}
                                          >
                                            <button
                                              id="AppointmentBtngetvitalsAdd"
                                              disabled={
                                                app.status === "Completed" ||
                                                !DateFunction.isToday(
                                                  app.appointment_time
                                                )
                                              }
                                              className={`${app.status === "Completed" ||
                                                !DateFunction.isToday(
                                                  app.appointment_time
                                                )
                                                ? "text-green-300"
                                                : "text-green-600"
                                                } flex items-center gap-2`}
                                            >
                                              <svg
                                                id="AppointmentSvggetvitalsAddicon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-6 h-6 "
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
                                                  clipRule="evenodd"
                                                />
                                                <path
                                                  fillRule="evenodd"
                                                  d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              <span
                                                id="AppointmentSpanaddVitalsText"
                                                disabled={
                                                  app.status === "Completed" ||
                                                  !DateFunction.isToday(
                                                    app.appointment_time
                                                  )
                                                }
                                                className={`${app.status === "Completed" ||
                                                  !DateFunction.isToday(
                                                    app.appointment_time
                                                  )
                                                  ? "text-gray-300"
                                                  : "text-black"
                                                  }`}
                                              >
                                                Add Vitals
                                              </span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* {totalpages > 1 && appointments.length != 0 &&
                        <Pagination totalpages={totalpages} pageselected={pageselected} selectedpage={selectedpage} />} */}
        {showAddAppointmentpopup && (
          <AddAppointment
            setRefreshData={setRefreshData}
            appointment={selectedEditAppointment}
            doctors={doctors}
            doctorsTimeslot={doctorsTimeslot}
            page="Appointment List View"
          />
        )}
        {openVitals && (
          <Vitals
            openVitals={openVitals}
            setOpenVitals={setOpenVitals}
            setRefreshData={setRefreshData}
            appointment_id={selectedEditAppointment.appointment_id}
            patient={{
              FirstName: selectedEditAppointment?.FirstName,
              LastName: selectedEditAppointment?.LastName,
              MiddleName: selectedEditAppointment?.MiddleName,
              patient_op_number: selectedEditAppointment?.patient_op_number,
              patient_id: selectedEditAppointment?.patient_id,
              appointment: selectedEditAppointment
            }}
            page="Appointment List View"
          />
        )}
        {showAddPatientpopup && (
          <AddPatient
            doctors={doctors}
            setRefreshData={setRefreshData}
            patient={{}}
            page="List View Book Appointment Popup"
          />
        )}
        {showAppointmentInvoicePrint && <GenerateInvoice page="Appointment" />}
      </div>
    </>
  );
};
export default Appointment;
