import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import loginReducer from './features/login/loginSlice';
import facilityReducer from './features/facility/facilitySlice';
import toastReducer from './features/toast/toastSlice';
import userSlice from './features/users/userSlice';
import feedbackSlice from './features/feedback/feedbackSlice';
import popupSlice from './features/popup/popupSlice';
import invoiceSlice from './features/invoice/invoiceSlice';
import themeSlice from './features/colors/themeSlice';
import recordingSlice from './features/recording/recordingSlice';
import featureSlice from './features/feature/featureSlice';
import uploadDocSlice from './features/uploadDoc/uploadDocSlice';
import drawerSlice from './features/drawer/drawerSlice';
import profileDrawerSlice from './features/profileDrawer/profileDrawerSlice';
import profileSideNavbarSlice from './features/profileDrawer/profileSideNavbarSlice';
import LabandRadiologySlice from './features/LaboratoryAndRadiology/LabandRadiologySlice';
import visitSideNavbarSlice from './features/visitDrawer/visitSideNavbarSlice';
import VisitDrawerSlice from './features/visitDrawer/VisitDrawerSlice';
import recycleBinNavbarSlice from './features/recycleBin/recycleBinNavbarSlice';
import gridviewSlice from './features/appointmentGridView/gridviewSlice';
import patientIntakeSlice from './features/patientIntake/patientIntakeSlice';
import gridViewTodaySlice from './features/appointmentGridView/gridViewTodaySlice';
import settingsTabsSlice from './features/settingsTabs/settingsTabsSlice';

const appReducer = combineReducers({
    login: loginReducer,
    facility: facilityReducer,
    toast: toastReducer,
    users: userSlice,
    feedback: feedbackSlice,
    popup: popupSlice,
    invoice: invoiceSlice,
    theme: themeSlice,
    recording: recordingSlice,
    feature: featureSlice,
    document_upload: uploadDocSlice,
    drawer: drawerSlice,
    profileDrawer: profileDrawerSlice,
    profileSideNavbar: profileSideNavbarSlice,
    visitDrawer: VisitDrawerSlice,
    visitSideNavbar: visitSideNavbarSlice,
    recycleBinSideNavbar: recycleBinNavbarSlice,
    appGridView: gridviewSlice,
    patientIntake: patientIntakeSlice,
    appGridViewToday: gridViewTodaySlice,
    labAndRadiologyDetails:LabandRadiologySlice,
    settingsTabs: settingsTabsSlice
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
