import { createSlice } from '@reduxjs/toolkit';

const settingsTabsSlice = createSlice({
  name: 'settingsTabs',
  initialState: {
    settingsActiveTab: 'Department',
  },
  reducers: {
    setSettingsActiveTab: (state,action) => {
      state.settingsActiveTab = action.payload;
    },
    
  },
});

export const {setSettingsActiveTab} = settingsTabsSlice.actions;
export default settingsTabsSlice.reducer;