import React, { useRef, useEffect, useState } from "react";
import { CLINICAL_NOTES_TEMPLATE_OPTIONS } from "../../../../utils/Constant";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Typography, Paper } from "@mui/material";
import MicForTemplate from "../../../../components/Assets/recordIcon.png";
import store from "../../../../API/store";
import DataDecode from "../../../../utils/DataDecode";
import { setNotification } from "../../../../Redux/features/toast/toastSlice";
import {
  setIsTemplateview,
  setDefaultTemplateNames,
  setTemplateName,
} from "../../../../Redux/features/profileDrawer/profileSideNavbarSlice";
import BlueRondedCrossIcon from "../../../../components/Assets/BlueRondedCrossIcon.png";

const TemplatesView = ({
  selectedClinicalNoteTemplate,
  setSelectedClinicalNoteTemplate,
  hanldeEnableRecordIcon,
}) => {
  //const [responseOfTemplate, setResponseOfTemplate] = useState([]);
  const intervalId = useRef(null);
  const dispatch = useDispatch();
  responseOfTemplate = [];
  var responseOfTemplate = useSelector(
    (state) => state.profileSideNavbar.defaultTemplateNames
  );
  const handleCloseTemplatesView = () => {
    dispatch(setIsTemplateview(false));
  };
  const handleRadioChange = async (event) => {
    setSelectedClinicalNoteTemplate(event);
    let TemplateName = event;
    dispatch(setTemplateName(TemplateName));
    let result = await store
      .GetClinicalNotesTemplates(DataDecode.encryptPayload(TemplateName))
      .then((response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            dispatch(setDefaultTemplateNames(res));
          });
        } else {
          dispatch(
            setNotification({
              message: "Error occured while fetching template sections ",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occured while fetching template sections ",
            status: "error",
            action: true,
          })
        );
      });
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[25%_75%] ">
        <div className="flex justify-center items-center w-full">
          <div className="hidden md:block">
            <RadioGroup
              id="templateRadioGroupId"
              aria-label="template"
              name="template"
              value={selectedClinicalNoteTemplate}
              onChange={(e) => {
                handleRadioChange(e.target.value);
              }}
            >
              {CLINICAL_NOTES_TEMPLATE_OPTIONS?.map((item) => (
                <FormControlLabel
                  id="FormControlLabelId"
                  key={item.key}
                  value={item.id}
                  control={<Radio id="RadioId" sx={{ padding: "4px" }} />}
                  label={item.label}
                  labelPlacement="start"
                  sx={{
                    color: "#4cc1bb",
                    marginBottom: 0.5,
                    margin: 0,
                  }}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
        <div className="">
          <div className="flex justify-end">
            <button
              id="handleCloseTemplatesViewId"
              onClick={handleCloseTemplatesView}
            >
              <img
                id="BlueRondedCrossIconId"
                src={BlueRondedCrossIcon}
                alt="Close"
              />
            </button>
          </div>
          <div className="font-['Inter'] text-[#b94035] text-[18px] md:text-[21px] font-semibold text-center md:pb-6 ">
            Pick a template for the Clinical Note
          </div>
          <div className="block md:hidden w-full p-4">
            <FormControl fullWidth>
              <InputLabel id="templateSelectLabelId" className="p-1">
                Select a template
              </InputLabel>
              <Select
                labelId="templateSelectId"
                id="templateSelectId"
                value={
                  selectedClinicalNoteTemplate ||
                  CLINICAL_NOTES_TEMPLATE_OPTIONS[0]?.id
                }
                onChange={(e) => {
                  handleRadioChange(e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      width: 150,
                    },
                  },
                }}
                sx={{
                  borderColor: "#4cc1bb",
                  color: "#4cc1bb",
                  "& .MuiSelect-icon": {
                    color: "#4cc1bb",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#4cc1bb",
                    },
                    "&:hover fieldset": {
                      borderColor: "#4cc1bb",
                    },
                  },
                }}
              >
                {CLINICAL_NOTES_TEMPLATE_OPTIONS?.map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.id}  
                    className="text-[#4cc1bb]"
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="bg-[#EAEAEA] p-4 rounded-lg">
            <Paper
              id="PaperLablesId"
              elevation={3}
              sx={{
                padding: 3,
                maxWidth: "100%",
                margin: "auto",
                overflowY: "auto",
                height: "auto",
                maxHeight: 260,
              }}
            >
              {responseOfTemplate?.map((label, index) => (
                <div key={index} className="mb-4">
                  <Box
                    id={`BoxId${index}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      id={`TypographyId${index}`}
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        flexShrink: 0,
                      }}
                      className="text-[#383434] text-[14px] font-semibold"
                    >
                      {typeof label === "string" ? label.toUpperCase() : "N/A"}:
                    </Typography>
                    <Skeleton
                      id="SkeletonId"
                      variant="rectangular"
                      sx={{
                        flexGrow: 1,
                        height: 14,
                        borderRadius: 1,
                        ml: 2,
                      }}
                    />
                  </Box>
                  <Skeleton
                    id="SkeletonrectangularID"
                    variant="rectangular"
                    sx={{
                      height: 14,
                      borderRadius: 1,
                      mt: 1,
                    }}
                  />
                </div>
              ))}
            </Paper>

            <div onClick={hanldeEnableRecordIcon} className="text-center mt-4">
              <IconButton aria-label="mic_icon" id="mic_icon" size="small">
                <img
                  id="MicrophoneId"
                  src={MicForTemplate}
                  alt="Microphone Icon"
                  className="h-10 w-10"
                />
              </IconButton>
              <div className="font-['Inter'] font-semibold text-[12px] text-[#414141] mt-1">
                Start Recording
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatesView;
