import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from '../../API/store';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import DataDecode from '../../utils/DataDecode';
import PhoneInput from '../PhoneInput';

const AddWhatsApp = ({ onClose, facilityguid}) => {
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(30);
    const otpRefs = useRef([]);
    const otpRefDes = useRef([]);
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [otpMessage, setOtpMessage] = useState("");
    const [errorVerifyOtp, setErrorVerifyOtp] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [phone, setPhone] = useState("");
    const [facilityName, setFacilityName] = useState("");
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const dispatch = useDispatch();
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    };
    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        const validValue = /^\d$/.test(value) ? value : "";
    
        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = validValue;
            if (validValue && index < otp.length - 1) {
                otpRefs.current[index + 1]?.focus();
                otpRefDes.current[index + 1]?.focus();
            }
            if (!validValue && index > 0) {
                otpRefs.current[index - 1]?.focus();
                otpRefDes.current[index - 1]?.focus();
            }
    
            return newOtp;
        });
    };
    
    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "") {
            if (index > 0) {
                otpRefs.current[index - 1]?.focus();
                otpRefDes.current[index - 1]?.focus();
            }
        }
    };
    

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData("text").slice(0, otp.length).split("");
        const newOtp = [...otp];

        pastedData.forEach((char, index) => {
            if (index < otp.length && /^\d$/.test(char)) {
                newOtp[index] = char;
            }
        });
        setOtp(newOtp);
        otpRefs.current[pastedData.length - 1]?.focus();

        e.preventDefault();
    };
    const handleSendOtp = () => {
        const interval = setInterval(() => {
          setTimer((prev) => {
            if (prev === 1) {
              clearInterval(interval);
              setIsButtonDisabled(false);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      };
    const handleResendOtp = () => {
        resetOtpFields();
        OtpApiCall();
        setTimer(30);
    };

    const OtpApiCall = async (event) => {
        event.preventDefault();
        let valid = true;
        const errors = {};
        if (!facilityName.trim()) {
            errors.facilityName = 'Display Name is required';
            valid = false;
        }
        if (!phone.trim()) {
            errors.phone = 'Phone number is required';
            valid = false;
        } 
        let formattedPhone = phone.trim();
        if (formattedPhone.startsWith('91')) {
            formattedPhone = formattedPhone.slice(2);
        }
        const submitForm = { verifiedName: facilityName, phoneNumber: formattedPhone }
        if (valid) {
            await store.OnboardFacilityNumber(loggedusertoken, DataDecode.encryptPayload(submitForm))
                .then((result) => {
                    if (result.status == 200) {
                        handleSendOtp();
                        setIsButtonDisabled(true);
                        dispatch(setNotification({ message: 'OTP sent successfully', status: 'success', action: true }));
                    }
                    else { 
                        dispatch(setNotification({ message: 'Error while generating otp', status: 'error', action: true }));
                        setShowOtpPopup(false);
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                })
        }
        else {
            setShowOtpPopup(false);
            setNameError(errors.facilityName || '');
            setPhoneError(errors.phone || '');
        }
    };
    useEffect(() => {
        if (timer === 30) {
            handleSendOtp();
        }
    }, []);
    const resetOtpFields = () => {
        setOtp(new Array(otp.length).fill(""));
        otpRefs.current[0]?.focus();
    };
    let Phone;
    const onPhonechange = (value, data) => {
        let dots = data.format.split('')
        setPhone(value);
    }
    Phone = <PhoneInput
        phone={phone}
        setPhone={setPhone}
        onPhonechange={onPhonechange}
    />
    const handleVerifyOtpClick = async () => {
        const OtpGenerater = {
            clinic_guid: loggedusertoken,
            Otp: otp.join(""),
        };
        if (otp !== null) {
            await store.validateWhatsAppOtp(DataDecode.encryptPayload(OtpGenerater))
                .then((result) => {
                    if (result.status == 200) {
                        dispatch(setNotification({message: "Otp verified Successfully",status: "success",action: true,}));
                        setShowOtpPopup(false);
                    } else if (result.status == 400) {
                        setOtpMessage("Invalid Otp");
                        setErrorVerifyOtp(true);
                        dispatch(setNotification({message: "Invalid Otp",status: "error",action: true,}));
                    } else {
                        setOtpMessage("Otp Expired");
                        setErrorVerifyOtp(true);
                        dispatch(setNotification({message: "Otp expired",status: "error",action: true,}));
                    }
                })
                .catch((error) => {
                    dispatch(setNotification({message: "Error occurred while validiating otp:" + error,status: "error",action: true,}));
                });
        } else {
            dispatch(setNotification({message: "Please enter the otp to validate" ,status: "error",action: true,
                }));
        }
        setTimeout(() => { }, 2000);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl relative w-96">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                >
                    <svg id="AddWhatsAppSvgcloseIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </button>
                <h2 className="text-xl font-bold text-red-800 mb-4">WhatsApp Details</h2>
                <div className="mb-4">
                    <label htmlFor="facility_name" className="ml-2 block text-sm font-semibold text-gray-700 mb-2">Display Name</label>
                    <input type="text" id="facility_name" name="facility_name" className="w-[95%] ml-2 px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter display name" value={facilityName} onChange={(e) => { setFacilityName(e.target.value) }}
                    />
                    {nameError && <p className="text-red-500 text-xs mt-1 ml-2">{nameError}</p>}
                </div>
                <div className="mb-4">
                    <div id="AddFacilityDivfacilityMobile" className="p-2">
                        <label id="AddFacilityLabelfacilityMobile" className='block text-sm font-semibold text-gray-700 mb-2'>Phone Number</label>{" "}<span id="AddFacilitySpanfacilityMobile" className="text-red-500 text-xl font-semibold"></span>
                        {Phone}
                        {phoneError && <p className="text-red-500 text-xs mt-1">{phoneError}</p>}
                    </div>
                </div>
                <div className="flex justify-between space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                    >
                        Close
                    </button>
                    <button
                        onClick={OtpApiCall}
                        className="px-4 py-2 bg-red-700 text-white rounded"
                    >
                        Confirm
                    </button>
                </div>
            </div>
            {showOtpPopup && (
                <div className="fixed inset-0 bg-opacity-50 flex justify-center items-center">
                    <div className="relative bg-white p-10 rounded-md shadow-lg max-w-md mx-auto">
                        <div className="absolute top-2 right-2">
                            <button
                                id="btnOtpClose"
                                onClick={() => {
                                    setShowOtpPopup(false);
                                }}
                                className="text-gray-600"
                            >
                                <svg
                                    id="btnOtpCross"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <h2 className="text-xl font-bold text-red-800 mb-6 text-center">
                            Please Enter OTP to Proceed
                        </h2>
                        <h2 className="text-sm font-semibold text-gray-500">
                            An OTP has been sent to your number ending with{" "}
                        </h2>
                        <h2 className="text-sm font-semibold text-gray-500 text-center mb-4">
                            +91**
                        </h2>
                        <div className="flex justify-center gap-2 mb-4">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    id={`otp-input-${index}`} // Unique ID for each input
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleOtpChange(e, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                    onPaste={(e) => handlePaste(e)}
                                    ref={(el) => (otpRefDes.current[index] = el)}
                                    className="w-10 h-10 border border-gray-300 px-2 py-2 text-center rounded-md text-lg mr-2"
                                    autoFocus={index === 0}
                                />
                            ))}
                        </div>
                        <div
                            id="OtpTimerId"
                            className="flex items-center mb-4 justify-end"
                        >
                            <span id={"TImerIdForOtp"} className="text-sm text-gray-600">
                                {isButtonDisabled ? formatTime(timer) : ""}
                            </span>
                        </div>
                        {errorVerifyOtp ? (
                            <p id={"OtpErrorMessageId"} className="text-red-800 mb-4">
                                {otpMessage}
                            </p>
                        ) : (
                            ""
                        )}
                        <button
                            id="handleOtpSubmit"
                            className="w-full bg-red-700 text-white rounded-md hover:bg-red-800 mt-4 p-2"
                            onClick={handleVerifyOtpClick}
                        >
                            Submit
                        </button>
                        <center>
                            <p
                                id={"MobResendOtpId"}
                                className="text-black mt-4 cursor-pointer"
                                onClick={handleResendOtp}
                            >
                                Didn't receive code?
                                <span
                                    id={"MObfailsResndId"}
                                    className="text-green-600 underline hover:text-green-800 bg-transparent border-none cursor-pointer"
                                >
                                    Resend
                                </span>
                            </p>
                        </center>
                    </div>
                </div>
            )}
        </div>
    );
};
export default AddWhatsApp;