import React from "react";
import { useSelector } from "react-redux";
import { theme } from '../EmraxisTheme/Theme';

const RoleAccessError = () => {
  const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
  const handleLoginPage = () => {
    window.history.back();
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="flex flex-col items-center justify-center w-11/12 sm:w-10/12 md:w-8/12 h-auto sm:h-3/4 md:h-4/6 bg-white rounded-lg p-4 sm:p-8">
          <div className="flex items-center justify-center text-center mb-4 pt-2">
            <p className="text-2xl sm:text-3xl md:text-4xl font-bold">
              You do not have access to this page
            </p>
          </div>

          <div className="flex items-center justify-center text-center text-gray-600 mb-8 pt-2">
            <p className="text-base sm:text-lg font-bold">
              We're sorry, your account does not have permission to access this
              page.
            </p>
          </div>

          <div className="flex items-center justify-center text-center mt-4 pt-2">
            <button
              className={`h-12 sm:h-14 w-full sm:w-96 text-sm sm:text-lg font-bold ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} hover:${previewTheme.addBtnHoverColor} text-white py-3 px-8 rounded-lg`}
              style={{ fontFamily: previewTheme.fontFamily }}
              onClick={handleLoginPage}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default RoleAccessError;
