import React, { useEffect, useMemo, useState, useRef } from "react";
import store from "../../API/store";
import DataDecode from "../../utils/DataDecode";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../overrides.css";
import Select, { defaultTheme } from "react-select";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import DateFunction from "../../utils/DateFunctions";
import PhoneInput from "react-phone-input-2";
import {
  setAddAppointmentPopup,
  setAddPatientPopup,
  setAppointmentInvoicePrint,
  setPatientAppointment, setPatientProfileAppointment,
  setRefreshAppointment,
} from "../../Redux/features/popup/popupSlice";
import { setAppointmentInvoice } from "../../Redux/features/invoice/invoiceSlice";
import { theme } from "../../EmraxisTheme/Theme";
import { addMonths, setHours, setMinutes, startOfMinute } from "date-fns";
import dayjs from "dayjs";

const AddAppointment = ({
  setRefreshData,
  appointment,
  doctors,
  selectedDate,
  doctorsTimeslot,
  doctorId,
  page
}) => {
  const dispatch = useDispatch();
  var loggeduser = useSelector((state) => state.login.loginuserData);
  const isDefaultGridView = useSelector(state => state.appGridView.isDefaultGridView);
  // var showAddAppointmentpopup = useSelector(state => state.popup.showAddAppointment);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var patientAddAppointment = useSelector(
    (state) => state.popup.setPatientAddAppointment
  );
  var refreshAppointment = useSelector(
    (state) => state.popup.refreshAppointment
  );
  const getCurrentTimeWithDelay = () => {
    const now = new Date();
    const minutes = now.getMinutes();
    const remainder = minutes % 10;
    const roundedMinutes = remainder === 0 ? minutes : minutes + (10 - remainder);
    return startOfMinute(new Date(now.setMinutes(roundedMinutes, 0, 0)));
  };
  var isInvoiceEnabled = useSelector((state) => state.invoice.isInvoiceEnabled);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [patientSearchReadonly, setPatitentSearchReadOnly] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [appointmentType, setAppointmentType] = useState("First Time");
  const colors = useSelector((state) => state.theme.colors) || {};
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [startDate, setStartDate] = useState(
    selectedDate ? new Date(selectedDate) : null
  );
  const [startTime, setStartTime] = useState(null);
  const [minTime, setMinTime] = useState(() => getCurrentTimeWithDelay());
  const [confirmBox, setConfirmBox] = useState(false);
  const [timeOptions, setTimeOptions] = useState([]);
  const [filtertimeOptions, setFilterTimeOptions] = useState([]);
  const [saveType, setSaveType] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedCountryFlag, setSelectedCountryFlag] = useState('');
  const openCalanderRef = useRef(null);
  const [originalAppointment, setOriginalAppointment] = useState({
    appointment_id: appointment && appointment.appointment_id,
    appointment_time: appointment && appointment.appointment_time,
    appointment_type: appointmentType,
    doctor_id: appointment && appointment.doctor_id,
    clinic_guid: appointment && appointment.clinic_guid,
    mobile_number: appointment && appointment.mobile_number,
    purpose_of_visit: appointment && appointment.purpose_of_visit,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    patient_id: appointment && appointment.patient_id,
    reffered_by: appointment && appointment.reffered_by,
    app_time: appointment && appointment.app_time ? appointment.app_time : null,

  })

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true; // Compare primitive types or same reference

    if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
      return false; // Handle cases where they are not objects
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);



    // Compare each key recursively
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  const [newAppointment, setNewAppointment] = useState({
    appointment_id: appointment && appointment.appointment_id
      ? appointment.appointment_id
      : 0,
    patient_id: appointment && appointment.patient_id ? appointment.patient_id : 0,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    doctor_id: appointment && appointment.doctor_id ? appointment.doctor_id : 0,
    clinic_guid: "",
    mobile_number:
      appointment && appointment.mobile_number ? appointment.mobile_number : "",
    appointment_time: appointment && appointment.appointment_time ? appointment.appointment_time : null,
    appointment_type: appointmentType,
    purpose_of_visit: appointment && appointment.purpose_of_visit ? appointment.purpose_of_visit : "",
    reffered_by: appointment && appointment.reffered_by ? appointment.reffered_by : "",
    app_time: appointment && appointment.app_time ? appointment.app_time : null,
    isOverideExistingAppointment: false,
    facility_name: loggeduser.facility_name
  });

  const allDOctors = useMemo(() => {
    return doctors.flatMap((group) => group.options);
  }, [doctors]);


  const [appointmentFormError, setAppointmentFormError] = useState({
    patient_id: false,
    appointment_date: false,
    mobile: false,
  });

  const [isInvoiceEnable, setIsInvoiceEnable] = useState(false);
  useEffect(() => {
    if (loggeduser) {
      let invoice_data = loggeduser.addon_features?.filter(
        (a) => a.feature === "Invoice"
      );
      if (invoice_data.length > 0) {
        setIsInvoiceEnable(invoice_data[0].is_active);
      }
    }
  }, [loggeduser]);
  const previewTheme =
    useSelector((state) => state.theme.colors) || theme.defaultTheme;

  // const theme = useTheme();

  //For Editing the appointment
  useEffect(() => {
    if (appointment && appointment.appointment_id > 0) {
      setAppointmentType(appointment.appointment_type ? appointment.appointment_type : "First Time")
      setStartDate(appointment.appointment_time ? new Date(appointment.appointment_time) : null)
      setPatitentSearchReadOnly(true);
      setSelectedOption({
        value: appointment.patient_id,
        label: appointment.FirstName + " " + appointment.MiddleName?.charAt(0) + " " + appointment.LastName + " - " + appointment.patient_op_number,
      });
      setNewAppointment({
        appointment_time: appointment.appointment_time,
        clinic_guid: loggeduser.facilityID,
        mobile_number: appointment.mobile_number,
        appointment_id: appointment.appointment_id,
        doctor_id: appointment.doctor_id,
        purpose_of_visit: appointment.purpose_of_visit,
        appointment_type: appointment.appointment_type,
        FirstName: appointment.FirstName,
        MiddleName: appointment.MiddleName,
        LastName: appointment.LastName,
        patient_op_number: appointment.patient_op_number,
        reffered_by: appointment.reffered_by,
        app_time: appointment.app_time,
      });
      setTimeout(() => {
        if (appointment.doctor_id > 0 && appointment.appointment_time) {
          var day = DateFunction.getFullDayName(appointment.appointment_time);
          var drOptions = doctorsTimeslot.filter(dr => dr.doctor_id == appointment.doctor_id)[0]?.doctorAvailableTimings;
          var options = drOptions.filter(d => d.dayOfTheWeek === day)[0];
          if (options?.options?.length > 0) {
            setTimeOptions(options.options)
            setStartTime(appointment.app_time ? { label: convertToAmPm(appointment.app_time), value: convertToAmPm(appointment.app_time) } : options.options[0]);
            const selectedTime = new Date(`1970-01-01T${convertTo24Hour(options.options[0]?.value)}`);
            const timeString = selectedTime.toTimeString().split(' ')[0];
            setNewAppointment((prev) => ({
              ...prev,
              app_time: timeString,
            }));
          } else {
            SetInitialTimeOptions();
          }
        }
      }, [500])

    }
  }, [appointment]);

  const convertToAmPm = timeString => {
    const date = new Date(`1970-01-01T${timeString}`);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  useEffect(() => {
    if (
      patientAddAppointment.path === "appointment" &&
      patientAddAppointment.isOpenAppointment
    ) {
      setSelectedOption({
        value: patientAddAppointment?.patient?.patient_id,
        label:
          patientAddAppointment?.patient?.FirstName + " " +
          patientAddAppointment?.patient?.MiddleName?.charAt(0) + " " +
          patientAddAppointment?.patient?.LastName +
          " - " +
          patientAddAppointment?.patient?.patient_op_number,
      });
      setTimeout(() => {
        setNewAppointment({
          ...newAppointment,
          patient_id: patientAddAppointment?.patient?.patient_id,
          FirstName: patientAddAppointment?.patient?.FirstName,
          MiddleName: patientAddAppointment?.patient?.MiddleName,
          LastName: patientAddAppointment?.patient?.LastName,
          patient_op_number: patientAddAppointment?.patient?.patient_op_number,
          mobile_number: patientAddAppointment?.patient?.mobile_number,
          clinic_guid: loggeduser.facilityID,
        });
      }, 100);

      setTimeout(() => {
        dispatch(
          setPatientAppointment({
            isOpenAppointment: false,
            patient: {},
            path: "patient",
          })
        );
      }, 200);
    }
  }, [patientAddAppointment]);

  const onClose = () => {
    dispatch(setAddAppointmentPopup(false));
  };

  const clearAppoitmentForm = () => {
    setNewAppointment({
      ...newAppointment,
      appointment_id: 0,
      patient_id: 0,
      FirstName: "",
      MiddleName: "",
      LastName: "",
      doctor_id: 0,
      mobile_number: "",
      appointment_time: null,
      appointment_type: "First Time",
      purpose_of_visit: "",
      reffered_by: "",
      app_time: null,
      isOverideExistingAppointment: false
    });
    setAppointmentType("First Time");
    setAppointmentFormError({
      patient_id: false,
      appointment_date: false,
      mobile: false,
    });
    setStartDate(null);
    setStartTime(null);
    setSelectedOption(null);
    setSaveType("");
  };

  useEffect(() => {
    if (loggeduser) {
      setNewAppointment({
        ...newAppointment,
        clinic_guid: loggeduser.facilityID,
      });
      if(doctorId){
        setNewAppointment({
          ...newAppointment,
          doctor_id: doctorId,
          clinic_guid: loggeduser.facilityID,
        }); 
      }
    } else {
    }
  }, [loggeduser,doctorId]);

  const addMonths = (date, months) => {
    var newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  };

  const handleAppointmentType = (event) => {
    setAppointmentType(event.target.value);
    setNewAppointment({
      ...newAppointment,
      appointment_type: event.target.value,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewAppointment({
      ...newAppointment,
      [name]: value,
    });
  };

  const showToast = () => {

    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  const loadOptions = debounce((inputValue, callback) => {
    if (inputValue.length == 0) {
      setSearchLoading(false); // No need to show loading for less than 3 characters
      return callback([]);
    } else {
      setSearchLoading(true);
      store
        .searchPatientForAppointment(
          loggedusertoken,
          DataDecode.encryptPayload({
            search_string: inputValue,
            clinic_guid: loggeduser.facilityID,
          })
        )
        .then((result) => {
          setSearchLoading(false);
          if (result.status == 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              let data = JSON.parse(response);
              setSuggestions(data);
              const options = data.map((item) => ({
                label: item.FirstName + " " + item.MiddleName?.charAt(0) + " " +
                  item.LastName + " - " + item.patient_op_number,
                value: item.patient_id,
              }));
              callback(options);
            });
          } else {
            setSearchLoading(false);
            callback([]);
          }
        })
        .catch((err) => {
          setSearchLoading(false);
          callback([]);
          setToast({ show: true, message: "Error occured", type: "error" });
          showToast();
          setLoading(false);
        });
    }
  }, 500);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const selectPatient = (pat) => {
    setSelectedOption(pat);
    let patient = suggestions.filter((a) => a.patient_id == pat.value)[0];
    setNewAppointment({
      ...newAppointment,
      FirstName: patient.FirstName + " " + patient.MiddleName?.charAt(0) + " " + patient.LastName + " - " + patient.patient_op_number,
      patient_id: patient.patient_id,
      mobile_number: patient.mobile_number,
    });
  };

  const handleSubmitAppointment = (gen_invoice) => {
    setSaveType(gen_invoice);
    const appointmentDate = newAppointment.appointment_time ? new Date(newAppointment.appointment_time).toISOString().split('T')[0] : '';

    setAppointmentFormError({
      ...appointmentFormError,
      appointment_date: !appointmentDate,
      mobile: !newAppointment.mobile_number.trim(),
      patient_id: newAppointment.patient_id === 0,
    });

    if (newAppointment.patient_id !== 0 &&
      appointmentDate &&
      newAppointment.mobile_number.trim() !== "") {
      setSubmitDisable(true);
      if (newAppointment.appointment_id == 0) {
        store
          .saveAppointment(
            loggedusertoken,
            DataDecode.encryptPayload(newAppointment)
          )
          .then((result) => {
            setSubmitDisable(false);
            setConfirmBox(false);
            if (result.status == 201) {
              clearAppoitmentForm();
              setRefreshData((r) => !r);
              dispatch(setRefreshAppointment(!refreshAppointment));
              dispatch(
                setNotification({
                  message: "Appointment Created Successfully!",
                  status: "success",
                  action: true,
                })
              );
              onClose();

              result.json().then((res) => {
                let response = DataDecode.decryptResponse(res);
                let data = JSON.parse(response);
                store.CreateActivityLogs(loggeduser, page, `Appointment Created - ${(newAppointment.doctor_name ? "for " + newAppointment.doctor_name?.trim():"")} with patient ${data.patients.FirstName} ${data.patients.MiddleName?.charAt(0)} ${data.patients.LastName}(${data.patients.patient_op_number}) on ${DateFunction.GetDate(data.appointments.appointment_time)} ${(data.appointments.app_time ? " at " + DateFunction.Convert12Hour(data.appointments.app_time) : "")}`,"Create");
                dispatch(setPatientProfileAppointment(data.appointments));
                if (gen_invoice === "invoice") {
                  dispatch(setAppointmentInvoicePrint(true));
                  dispatch(
                    setAppointmentInvoice({
                      patient: data.patients,
                      doctor: newAppointment.doctor_id,
                      appointment: data.appointments,
                      targetFrom: "Appointment",
                    })
                  );
                }

              });
            } else if (result.status == 200) {
              result.json().then((res) => {
                let response = DataDecode.decryptResponse(res);
                let data = JSON.parse(response);
                store.CreateActivityLogs(loggeduser, page, `Appointment Created - ${(newAppointment.doctor_name ? "for " + newAppointment.doctor_name?.trim():"")} with patient ${data.patients.FirstName} ${data.patients.MiddleName?.charAt(0)} ${data.patients.LastName}(${data.patients.patient_op_number}) on ${DateFunction.GetDate(data.appointments.appointment_time)} ${(data.appointments.app_time ? " at " + DateFunction.Convert12Hour(data.appointments.app_time) : "")}`,"Create");
              });
              setConfirmBox(true);
              setNewAppointment({ ...newAppointment, isOverideExistingAppointment: true })
            } else if (result.status == 409) {
              dispatch(
                setNotification({
                  message: "Appointment Not Available!",
                  status: "info",
                  action: true,
                })
              );
            } else {
              dispatch(
                setNotification({
                  message: "Error Occurred!",
                  status: "error",
                  action: true,
                })
              );
            }
          })
          .catch((err) => {
            setToast({ show: true, message: "Error occurred", type: "error" });
            showToast();
            setLoading(false);
          });
      } else {

        const isChanged = !deepEqual(newAppointment, originalAppointment);

        if (!isChanged) {

          setSubmitDisable(false);
          dispatch(
            setNotification({
              message: "No Changes to Update",
              status: "info",
              action: true,
            })
          );
          return; // No changes, exit the function
        }
        store
          .updateAppointment(
            loggedusertoken,
            DataDecode.encryptPayload(newAppointment)
          )
          .then((result) => {
            setSubmitDisable(false);
            if (result.status == 204) {
              store.CreateActivityLogs(loggeduser, page, `Appointment Updated - ${(newAppointment.doctor_name ? "for " + newAppointment.doctor_name?.trim():"")} with patient ${appointment.FirstName} ${appointment.MiddleName?.charAt(0)} ${appointment.LastName}(${appointment.patient_op_number}) on ${DateFunction.GetDate(newAppointment.appointment_time)} ${(newAppointment.app_time ? " at " + DateFunction.Convert12Hour(newAppointment.app_time) : "")}`,"Update");
              clearAppoitmentForm();
              dispatch(
                setNotification({
                  message: "Appointment Updated Successfully!",
                  status: "success",
                  action: true,
                })
              );
              setRefreshData((r) => !r);
              onClose();
              if (gen_invoice === "invoice") {
                dispatch(setAppointmentInvoicePrint(true));
                dispatch(
                  setAppointmentInvoice({
                    patient: appointment,
                    doctor: newAppointment.doctor_id,
                    appointment: newAppointment,
                    targetFrom: "Appointment",
                  })
                );
              }
            } else if (result.status == 409) {
              dispatch(
                setNotification({
                  message: "Appointment Not Available!",
                  status: "info",
                  action: true,
                })
              );
            } else {
              dispatch(
                setNotification({
                  message: "Please fill the mandatory fields!",
                  status: "info",
                  action: true,
                })
              );
            }
          })
          .catch((err) => {
            setToast({ show: true, message: "Error occurred", type: "error" })
            showToast()
            setLoading(false);
          });
      }
    } else {
      dispatch(
        setNotification({
          message: "Please fill the mandatory fields!",
          status: "info",
          action: true,
        })
      );
    }
  };

  const addPatient = () => {
    dispatch(setAddAppointmentPopup(false));
    dispatch(setAddPatientPopup(true));
    dispatch(
      setPatientAppointment({ ...patientAddAppointment, path: "appointment" })
    );
  };

  const handleCreateInvoice = () => {
    dispatch(setAppointmentInvoicePrint(true));
  };

  const updateTimeSlots = () => {
    const today = dayjs().startOf('day');
    const now = dayjs();
    if (dayjs(startDate).isSame(today, 'day')) {
      const isToday = dayjs(startDate).isSame(today, 'day');

      const newOptions = timeOptions.filter(option => {
        const [time, meridian] = option.value.split(' '); // Split time and AM/PM
        const [hours, minutes] = time.split(':'); // Split hours and minutes
        const formattedHours = meridian === 'PM' && hours !== '12' 
          ? parseInt(hours, 10) + 12 
          : hours === '12' && meridian === 'AM'
          ? '00' 
          : hours;
        const optionTime = dayjs(`${today.format('YYYY-MM-DD')} ${formattedHours}:${minutes}`, 'YYYY-MM-DD HH:mm');
        return isToday ? optionTime.isAfter(now) : true;
      });
      setFilterTimeOptions(newOptions);
      setStartTime(newOptions[0])
    const selectedTime = new Date(`1970-01-01T${convertTo24Hour(newOptions[0]?.value)}`);
      const timeString = selectedTime.toTimeString().split(' ')[0];
      setNewAppointment((prev) => ({
        ...prev,
        app_time: timeString,
      }));
    } else {
      setFilterTimeOptions(timeOptions);
      setStartTime(timeOptions[0])
     const selectedTime = new Date(`1970-01-01T${convertTo24Hour(timeOptions[0]?.value)}`);
      const timeString = selectedTime.toTimeString().split(' ')[0];
      setNewAppointment((prev) => ({
        ...prev,
        app_time: timeString,
      }));
    }
  }

  useEffect(() => {
    updateTimeSlots();
    const intervalId = setInterval(updateTimeSlots, 60000);
    return () => clearInterval(intervalId);
  }, [timeOptions, startDate]);

  useEffect(() => {
    const updateMinTime = () => {
      const roundedTime = getCurrentTimeWithDelay();
      setMinTime(roundedTime);
    };

    updateMinTime(); // Initial call to set minTime

    // Set a frequent interval to update minTime every second
    const intervalId = setInterval(updateMinTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTo12Hour = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours < 10 ? "0" + hours : hours}:${minutes} ${ampm}`;
  };

  const generateTimeOptions = (ranges, interval = 10) => {
    const options = [];
    ranges.forEach(({ start, end }) => {
      let startTime = new Date(`1970-01-01T${start}`);
      const endTime = new Date(`1970-01-01T${end}`);
      while (startTime <= endTime) {
        const timeString = formatTo12Hour(startTime);
        options.push({
          value: timeString,
          label: timeString
        });
        startTime.setMinutes(startTime.getMinutes() + interval);
      }
    });
    return options;
  };

  const SetInitialTimeOptions = () => {
    let timeRanges = [];
    if (loggeduser && loggeduser?.open_time && loggeduser?.close_time) {
      timeRanges = [
        { start: loggeduser?.open_time, end: loggeduser?.close_time }
      ];
    }
    else if (loggeduser && loggeduser?.open_time) {
      timeRanges = [
        { start: loggeduser?.open_time, end: '23:55:00' }
      ];
    } else {
      timeRanges = [
        { start: '00:00:00', end: '23:55:00' }
      ];
    }
    let times = generateTimeOptions(timeRanges);
    setTimeOptions(times);

    if (times.length > 0) {
      const firstTime = times[0];
      setStartTime(firstTime);
       const timeString = convertTo24Hour(firstTime.value);
      setNewAppointment((prev) => ({
        ...prev,
        app_time: timeString,
      }));
    }
  }

  useEffect(() => {
    SetInitialTimeOptions();
  }, [loggeduser])


  // Handle date changes
  const handleDateChange = (date) => {
    setStartDate(date);
    setNewAppointment((prev) => ({
      ...prev,
      appointment_time: DateFunction.SetDbDateOfBirth(date),

    }));
    if (newAppointment.doctor_id > 0) {
      var day = DateFunction.getFullDayName(date);
      var drOptions = doctorsTimeslot.filter(dr => dr.doctor_id == newAppointment.doctor_id)[0]?.doctorAvailableTimings;
      var options = drOptions.filter(d => d.dayOfTheWeek === day)[0];
      if (options?.options?.length > 0) {
        setTimeOptions(options.options)
        // setStartTime(options.options[0])

      } else {
        SetInitialTimeOptions();
      }
    }
  };

  const handleSelectDoctor = (selectedOption) => {
    setNewAppointment({
      ...newAppointment,
      doctor_id: selectedOption ? selectedOption.value : 0,
      doctor_name: selectedOption ? selectedOption.label : ""
    });
    if (selectedOption && selectedOption.value > 0 && newAppointment.appointment_time) {
      var day = DateFunction.getFullDayName(newAppointment.appointment_time);
      var drOptions = doctorsTimeslot.filter(dr => dr.doctor_id == selectedOption.value)[0]?.doctorAvailableTimings;
      var options = drOptions.filter(d => d.dayOfTheWeek === day)[0];
      if (options?.options?.length > 0) {
        setTimeOptions(options.options)
      } else {
        SetInitialTimeOptions();
      }
    }
    else if (!selectedOption) {
      SetInitialTimeOptions();
    }
  };

  function autoResizeTextarea(e) {
    const textarea = e.target;
    if (!textarea) return;  // Add this null check to avoid the error
    textarea.style.height = "auto";  // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`;  // Set height based on content
  }

  const convertTo24Hour = (time12) => {
    if (!time12) return null;

    const [timeStr, period] = time12.split(' ');
    let [hours, minutes] = timeStr.split(':');
    hours = parseInt(hours);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}:00`;
  };

  const handleTimeChange = (time) => {
    if (time) {
      const timeString = convertTo24Hour(time.value);
      setStartTime(time);
      setNewAppointment((prev) => ({
        ...prev,
        app_time: timeString,
      }));
    } else {
      setStartTime(null);
      setNewAppointment((prev) => ({
        ...prev,
        app_time: null,
      }));
    }
  };

  const handleContinueAppointmentCfmPopup = () => {
    handleSubmitAppointment(saveType);
  }

  const handleCloseAppointmentCfmPopup = () => {
    clearAppoitmentForm();
    setConfirmBox(false);
    onClose();
  }

  const addYears = (date, years) => {
    var newDate = new Date(date);
    var currentYear = newDate.getFullYear()
    var setDate = new Date(`${currentYear}-12-31`)
    setDate.setFullYear(setDate.getFullYear() + years);
    return setDate;
  };

  return (
    <>
      <div id="AddAppointmentDivfixedInsetContainer" className="fixed inset-0 flex justify-center items-center p-8 z-[99999]">
        <div id="AddAppointmentDivoverlay" className="absolute inset-0 bg-black opacity-25 "></div>
        <div id="AddAppointmentDivmodalContainer"
          className="relative bg-white shadow-xl p-8 min-[370px] max-h-[80vh] lg:max-h-[80vh] 2xl:max-h-[80vh] overflow-y-auto"
         
        >
          <div id="AddAppointmentDivheader" className="flex items-center justify-between ">
            <div id="AddAppointmentDivtitle"
              className={`text-black font-bold text-xl min-[370px]:text-lg sm:mb-6 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              {appointment && appointment?.appointment_id > 0 ? "Update Appointment" : "Book Appointment"}
            </div>
            <button id="AddAppointmentButtonclose" className={`text-red-700 sm:justify-left`} onClick={onClose}>
              <svg id="AddAppointmentSvgcloseIcon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}
              >
                <path id="AddAppointmentPathcloseIcon"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
          </div>

          <div id="AddAppointmentDivgridContainer" className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:gap-[1.5rem]">
            <div id="AddAppointmentDivpatientName" className="col-span-2 lg:col-span-1">
              <label id="AddAppointmentLabelpatientName"
                htmlFor="name"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-auto sm:text-left lg:w-auto"
              >
                Patient Name/OP *
              </label>
              <div id="AddAppointmentDivasyncSelectContainer" className="relative !border-[#999999] rounded-[5px]">
                <AsyncSelect
                  styles={{
                    input: (base) => ({
                      ...base,
                      "input:focus": {
                        boxShadow: "none",
                        borderColor:"#999999"
                      },
                    }),
                    control: (base, state) => ({
          ...base,
          minHeight: '42px',
          borderColor: state.isFocused ? "#999999" : base.borderColor,
          boxShadow: "none",
          borderColor:"#999999",
          "&:hover": {
            borderColor: "#999999", 
          },
        }),
                  }}
                  loadOptions={loadOptions}
                  onChange={selectPatient}
                  value={selectedOption}
                  placeholder=""
                  isLoading={searchLoading}
                  onInputChange={handleInputChange}
                  inputValue={inputValue}
                  isDisabled={patientSearchReadonly}
                />
              </div>
              {appointmentFormError.patient_id &&
                (newAppointment.FirstName === null ||
                  newAppointment.FirstName === "" && !patientSearchReadonly) && (
                  <small id="AddAppointmentSmallappointmentError" className="text-red-600">
                    Select Patient Name!
                  </small>
                )}
              <div id="AddAppointmentDivaddPatientLink" className="">
                <p id="AddAppointmentPaddPatient"
                  className="text-[#276BF3] text-[14px] mt-[2px] flex justify-end cursor-pointer hover:underline"
                  onClick={addPatient}
                >
                  Add New Patient
                </p>
              </div>
            </div>
            <div id="AddAppointmentDivdoctorSelect" className="col-span-2 lg:col-span-1">
              <label id="AddAppointmentLabeldoctorSelect"
                htmlFor="doctor"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-1 lg:w-auto"
              >
                Select Doctor
              </label>
              <div id="AddAppointmentDivselectContainer" className="relative !border-[#999999]">
                <Select
                  styles={{
                    input: (base) => ({
                      ...base,
                      "input:focus": {
                        boxShadow: "none",
                        borderColor:"#999999",
                       
                      },
                    }),
                    control: (base, state) => ({
          ...base,
          minHeight: '42px', 
          borderColor: state.isFocused ? "#999999" : base.borderColor,
          boxShadow: "none",
          borderColor:"#999999",
          "&:hover": {
            borderColor: "#999999", 
          },
        }),
                  }}
                  placeholder=""
                  isClearable={true}
                  isSearchable={true}
                  options={doctors}
                  onChange={handleSelectDoctor}
                  value={
                    newAppointment.doctor_id > 0
                      ? allDOctors.find(
                        (option) => option.value === newAppointment.doctor_id
                      )
                      : null
                  }
                  isDisabled={doctorId}
                />
              </div>
            </div>

            <div id="AddAppointmentDivappointmentDate" className="col-span-2 lg:col-span-1">
              <label id="AddAppointmentLabelappointmentDate"
                htmlFor="date"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-1 lg:w-auto"
              >
                 Date *
              </label>
              <div className="flex border-[#999999] items-center border rounded-[5px] w-full justify-around">
 
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '12px' }}>
<path d="M17 3H5C2.79086 3 1 4.79086 1 7V17C1 19.2091 2.79086 21 5 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 1V5M15 1V5M1 9H21" stroke="#999999" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  <DatePicker
                  ref={openCalanderRef}
                  id="AddAppointmentInputTheDate"
                  selected={startDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  maxDate={addYears(new Date(), 4)}
                  className="w-full py-2 border-[#999999] focus:outline-none focus:ring-0 border-none"
                  dateFormat="MMM dd, yyyy"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  showMonthDropdown
                 
                  onBlur={() => setAppointmentFormError({ ...appointmentFormError, appointment_date: !startDate })}
                />
<svg onClick={() => openCalanderRef.current.setFocus()} className="h-[1rem] w-[0.85rem] text-gray-400 mx-3 cursor-pointer"  viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.65654 7.071L-0.00046164 1.414L1.41354 -4.94551e-07L6.36354 4.95L11.3135 -6.18079e-08L12.7275 1.414L7.07054 7.071C6.88301 7.25847 6.6287 7.36379 6.36354 7.36379C6.09837 7.36379 5.84407 7.25847 5.65654 7.071Z" fill="#989898"/>
</svg>
  
</div>

              {appointmentFormError.appointment_date && <small className="text-red-600">Select Appointment Date!</small>}
            </div>

            <div id="AddAppointmentDivappointmentTime" className="col-span-2 lg:col-span-1">
              <label id="AddAppointmentLabelappointmentTime"
                htmlFor="time"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-1 lg:w-auto"
              >
                 Time
              </label>
              <div id="AddAppointmentDivInputTheTime" style={{
    position: "relative", 
    overflow: "visible", 
  }} className=" flex border-[#999999] items-center border rounded-[5px] w-full justify-around">
              <svg className="mr-2 ml-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0.25C8.07164 0.25 6.18657 0.821828 4.58319 1.89317C2.97982 2.96451 1.73013 4.48726 0.992179 6.26884C0.254225 8.05042 0.061142 10.0108 0.437348 11.9021C0.813554 13.7934 1.74215 15.5307 3.10571 16.8943C4.46928 18.2579 6.20656 19.1865 8.09787 19.5627C9.98919 19.9389 11.9496 19.7458 13.7312 19.0078C15.5127 18.2699 17.0355 17.0202 18.1068 15.4168C19.1782 13.8134 19.75 11.9284 19.75 10C19.7473 7.41498 18.7192 4.93661 16.8913 3.10872C15.0634 1.28084 12.585 0.25273 10 0.25ZM10 18.25C8.36831 18.25 6.77326 17.7661 5.41655 16.8596C4.05984 15.9531 3.00242 14.6646 2.378 13.1571C1.75358 11.6496 1.5902 9.99085 1.90853 8.3905C2.22685 6.79016 3.01259 5.32015 4.16637 4.16637C5.32016 3.01259 6.79017 2.22685 8.39051 1.90852C9.99085 1.59019 11.6497 1.75357 13.1571 2.37799C14.6646 3.00242 15.9531 4.05984 16.8596 5.41655C17.7661 6.77325 18.25 8.3683 18.25 10C18.2475 12.1873 17.3775 14.2843 15.8309 15.8309C14.2843 17.3775 12.1873 18.2475 10 18.25ZM16 10C16 10.1989 15.921 10.3897 15.7803 10.5303C15.6397 10.671 15.4489 10.75 15.25 10.75H10C9.80109 10.75 9.61033 10.671 9.46967 10.5303C9.32902 10.3897 9.25 10.1989 9.25 10V4.75C9.25 4.55109 9.32902 4.36032 9.46967 4.21967C9.61033 4.07902 9.80109 4 10 4C10.1989 4 10.3897 4.07902 10.5303 4.21967C10.671 4.36032 10.75 4.55109 10.75 4.75V9.25H15.25C15.4489 9.25 15.6397 9.32902 15.7803 9.46967C15.921 9.61032 16 9.80109 16 10Z" fill="#999999"/>
 </svg>
              <Select
  className="w-full focus:outline-none focus:ring-0 border-none"
  styles={{
    control: (base, state) => ({
      ...base,
      height: "40px",
      paddingLeft: "0.5rem",
      display: "flex !important",
      alignItems: "center",
      position: "relative",
      overflow: "hidden",
      border:"none",
      boxShadow: state.isFocused ? "none" : base.boxShadow, 
      
    }),
    input: (base) => ({
      ...base,
      marginLeft: "9px",
      "input:focus": {
        boxShadow: "none",
      },
    }),
    singleValue: (base) => ({
      ...base,
      position: "absolute",
      left: "0.5rem",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#000", 
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    placeholder: (base) => ({
      ...base,
      position: "absolute",
      left: "0.75rem",
      top: "50%",
      transform: "translateY(-50%)",
      pointerEvents: "none",
      color: "#999",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0",
      position: "relative",
      overflow: "hidden",
      display:"flex"
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "100%",
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0",
      top: "100%", 
      position: "absolute",
      width: "112%",
      right: "0", 
      zIndex: 9999, 
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px",
      overflowY: "auto",
    }),
  
    dropdownIndicator: (base) => ({
      ...base,
      padding: "8px",
    }),
    indicatorSeparator: () => null,
  }}

  value={startTime}
  onChange={handleTimeChange}
  placeholder="" 
  isClearable={true}
  isSearchable={true}
  options={filtertimeOptions}
/>

</div>
</div>

            <div id="AddAppointmentDivappointmentType" className="col-span-2 lg:col-span-1">
              <label id="AddAppointmentLabelappointmentType"
                htmlFor="inline-radio"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-1 lg:w-auto"
              >
                Appointment Type *
              </label>
              <div id="AddAppointmentDivradioContainer" className="relative">
                <div id="AddAppointmentDivradioOptions" className="flex pt-2 gap-[2rem]">
                  <div id="AddAppointmentDivfirstTime" className="flex items-center me-4">
                    <input
                      id="AddAppointmentInputfirstTime"
                      checked={appointmentType === "First Time"}
                      onChange={handleAppointmentType}
                      type="radio"
                      value="First Time"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-0 cursor-pointer"
                    />
                    <label
                      id="AddAppointmentLabelfirstTime"
                      htmlFor="inline-radio"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      First Time
                    </label>
                  </div>
                  <div id="AddAppointmentDivfollowUp" className="flex items-center me-4">
                    <input
                      id="AddAppointmentInputfollowUp"
                      checked={appointmentType === "Follow Up"}
                      onChange={handleAppointmentType}
                      type="radio"
                      value="Follow Up"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 right-0 cursor-pointer"
                    />
                    <label
                      id="AddAppointmentLabelfollowUp"
                      htmlFor="inline-2-radio"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Follow Up
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div id="AddAppointmentDivmobile" className="col-span-2 lg:col-span-1">
              <label id="AddAppointmentLabelmobile"
                htmlFor="mobile"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-1 lg:w-auto"
              >
                Mobile *
              </label>
              <div id="AddAppointmentDivphoneInputContainer" className="relative !rounded-[5px]  border-[#999999]">
              <PhoneInput
                  country={"in"}
                  disableDropdown={true}
                  onlyCountries={['in']}
                  countryCodeEditable={false}
                  value={newAppointment.mobile_number}
                  enableSearch={false}
                  onChange={(value) => {
                    handleChange({ target: { name: "mobile_number", value } });
                  }}
                  inputProps={{
                    name: "mobile_number",
                    id: "Inputmobile",
                    className:
                      "block w-full border rounded-md focus:outline-none focus:border-gray-400",
                  }}
                  specialLabel=""
                  title=""
                  containerStyle={{ position: "relative", width: "100%" }}
                  buttonStyle={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    height: "100%",
                  }}
                  inputStyle={{ paddingLeft: "56px" }}
                />
                <svg id="AddAppointmentSvgdropdownIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500"
                >
                  <path id="AddAppointmentPathdropdownIcon"
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clipRule="evenodd"
                  />
                </svg>
             
               
              </div>
              {appointmentFormError.mobile &&
                !newAppointment.mobile_number &&
                newAppointment.mobile_number === "" && (
                  <small id="AddAppointmentSmallmobileError" className="text-red-600">Enter Mobile Number!</small>
                )}
            </div>

            <div className="col-span-2">
              <label id="AddAppointmentLabelpurposeVisit"
                htmlFor="purpose_of_visit"
                className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-auto sm:text-left lg:w-auto"
              >
                Purpose Of Visit
              </label>
              <div className="relative">
                <textarea
                  id="AddAppointmentInputpurposeVisit"

                  style={{ height: "auto", minHeight: "40px", resize: "none" }}
                  value={newAppointment.purpose_of_visit}
                  name="purpose_of_visit"
                  onChange={(e) => {
                    handleChange(e);
                    autoResizeTextarea(e);
                  }}
                  className="overflow-y-hidden block w-full border text-sm border-[#999999] rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 resize-none overflow-hidden"
                  rows="1"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="AddAppointmentLabelrefferedby" className="block text-[#A4A4A4] text-[14px] mb-1 sm:w-auto sm:text-left lg:w-auto">Referred by </label>
              <div className="relative">
                <input
                  type="text"
                  id="AddAppointmentInputrefferedby"
                  onChange={handleChange}
                  value={newAppointment.reffered_by}
                  name="reffered_by"
                 
                  className="block w-full border text-sm rounded-md border-[#999999]  focus:outline-none focus:border-gray-500 px-2 py-2"
                />
              </div>
            </div>

            
          </div>

          <div className="space-x-4  text-right flex justify-around mt-[2rem]">
            {isInvoiceEnabled && newAppointment.doctor_id > 1 && (
              <button id="AddAppointmentButtongenerateInvoice"
                disabled={submitDisable}
                className="px-4 py-2 border bg-blue-600 hover:bg-blue-700 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => {
                  handleSubmitAppointment("invoice");
                }}
              >
                Save & Generate Invoice
              </button>
            )}
            <button id="AddAppointmentButtonCancel"
              disabled={submitDisable}
              className={`px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out bg-[#6B7280] ${previewTheme.cancelBtnColor
                }   ${submitDisable
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${previewTheme.cancelBtnHoverColor}`
                }`}
              onClick={onClose}
            >
              Cancel
            </button>
            <button id="AddAppointmentButtonSave"
              disabled={submitDisable}
              className={`px-4 py-2 border rounded-md transition-colors bg-[#282358] duration-300 ease-in-out ${previewTheme.saveBtnColor
                } ${submitDisable
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:bg-[#282358]`
                }`}
              onClick={() => {
                handleSubmitAppointment("");
              }}
            >
              Save
              {submitDisable && (
                <svg id="AddAppointmentSvgsaveIcon"
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 ml-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        {toast.show && (
          <SnackBarComponent
            message={toast.message}
            type={toast.type}
            onClose={() => setToast({ show: false, message: '', type: '' })}
          />
        )}

      </div>
      {confirmBox &&
        <div id="AppointmentDivExistingAppointment" tabIndex="-1" aria-hidden="true" className="fixed inset-0 flex justify-center items-center p-8 z-[99999] top-36 lg:-top-36">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg id="AppointmentSvgContinuIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-orange-500 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
              </svg>
              <p id="AppointmentPExistingAppText" className="mb-4 text-gray-500 dark:text-gray-300">An open appointment available for the selected patient.<br />Do you still wish to book an appointment?</p>
              <div id="AppointmentDivExistingCancel" className="flex justify-center items-center space-x-4">
                <button id="AppointmentBtnContinueCancel" onClick={handleCloseAppointmentCfmPopup} disabled={submitDisable} type="button" className={`mr-2 py-2 px-3 text-sm font-medium  rounded-lg border border-gray-200  focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                  No, cancel
                </button>
                <button id="AppointmentBtnContinueBook"
                  type="button" onClick={handleContinueAppointmentCfmPopup} disabled={submitDisable} className={`py-2 px-3 text-sm font-medium text-center 
                      text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-red-300   ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed`}>
                  Yes, continue {submitDisable &&
                    <svg id="AppointmentSvgContinueIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};
export default AddAppointment;