import React, { useState } from 'react';
import store from '../../API/store';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import { theme } from '../../EmraxisTheme/Theme';

const AddSubscriptions = ({ isOpen, onClose, subscriptions }) => {
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const dispatch = useDispatch();
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [form, setState] = useState({
        subscription_id: 0,
        subscription_name: "",
        subscription_monthly: "",
        subscription_yearly: "",
        subscription_validity: 0,
        subscription_userlimit: 5
    })

    if (!isOpen) return null;

    const clicksave = async (event) => {
        event.preventDefault();
        //check for duplication in the user roles
        var testfound = subscriptions.find((x) => x.subscription_name.toLowerCase() === form.subscription_name.toLowerCase());
        if (testfound == undefined) {
            await store.SaveSubscription(loggedusertoken, form)
                .then((result) => {
                    result.json().then((res) => {
                        if (res == true) {
                            alert("added")
                            onClose();
                        }
                        else {
                            alert("failed")
                        }
                    })
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                })

        }
    }


    const handlerchange = (e) => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    function clickcancel(event) {
        onClose();
    }


    return (
        <div className="fixed inset-0 flex justify-center items-center p-8">
            <div className="absolute inset-0 bg-black opacity-25"></div>
            <div className="relative bg-white shadow-xl p-8 " style={{ width: "700px" }}>
                <div id="AddSubscriptionDivaddSubscriptionCntainer" className="flex justify-between items-center mb-6">
                    <div id="AddSubscriptionDivaddSubscriptionTitle"   className={`text-black font-bold text-2xl sm:mb:6 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}>Add Subscription</div>
                    <button id="AddSubscriptionbtnClose" className="text-red-700" onClick={onClose}>
                        <svg id="AddSubscriptionSvgcloseIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>
                </div>
                <form>
                    <div className="flex items-center">
                        <div className="mb-4 p-2">

                            <label id="AddSubscriptionLabelname"  className="block text-gray-700 mb-2">Name*</label>
                            <div className="relative">
                                <input type="text" id="AddSubscriptionInputsubscriptionName" name="subscription_name" onChange={(event) => { handlerchange(event) }} className="block w-full  border rounded-md focus:outline-none focus:border-gray-500 py-2 pr-10" />
                            </div>

                        </div>
                        <div className="mb-4 p-2">
                            <label id="AddSubscriptionLabeluserLimit" htmlFor="department" className="block text-gray-700 mb-2">User Limit</label>
                            <div className="relative">
                                <input type="text" id="AddSubscriptionInputsubscriptionUserlimit" name="subscription_userlimit" value={form.subscription_userlimit} onChange={(event) => { handlerchange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 py-2  pr-10" />
                            </div>

                        </div>
                    </div>
                    <div className="flex items-center">
                    </div>

                    <div className="flex items-center">
                        <div className="mb-4 p-2">
                            <label id="AddSubscriptionLabelmonth" className="block text-gray-700 mb-2">Subscription/Month</label>
                            <div className="relative">
                                <input type="text" id="AddSubscriptionInputsubscriptionmonth" name="subscription_monthly" onChange={(event) => { handlerchange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10 py-2" />
                            </div>
                        </div>
                        <div className="mb-4 p-2">
                            <label id="AddSubscriptionLabelyearly" className="block text-gray-700 mb-2">Subscription/Yearly</label>
                            <div className="relative">
                                <input type="text" id="AddSubscriptionInputsubscription_yearly" name="subscription_yearly" onChange={(event) => { handlerchange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10 py-2" />
                            </div>
                        </div>
                    </div>


                </form>
                <div className="flex space-x-4">
                    <button id="AddSubscriptionBtncancel"  className={`px-4 disabled:cursor-not-allowed disabled:opacity-50 py-2 border rounded-md text-white transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`} onClick={(event) => clicksave(event)}>Save</button>
                    <button id="AddSubscriptionBtnSave" className={`px-4 disabled:cursor-not-allowed disabled:opacity-50 py-2 border rounded-md text-white transition-colors duration-300 ease-in-out ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`} onClick={(event) => clickcancel(event)}>Cancel</button>


                </div>
            </div>

        </div>
    );
};

export default AddSubscriptions;