import React from "react";
 
const PrivacyPolicy = () => {
	return (
		<div id="PrivacyPolicyDivpolicyContainer" className="max-w-[1536px] xxs:m-4 sm:m-16 !mx-auto xxs:px-8 sm:px-10 md:px-28 xxs:text-[14px] xs:text-[16px] sm:text-[18px] md:text-[20px] !font-['Inter'] xxs:text-justify md:text-start">
			<h2 id="PrivacyPolicyH2PrivacyPolicyTitle" className="text-[#004B93] md:text-[42px] sm:text-[32px] xxs:text-[20px] font-semibold xxs:mb-8 xxs:mt-16 md:mt-32 md:mb-16 text-center">
				EMR AXIS Privacy Policy
			</h2>
			<section>
				<p id="PrivacyPolicyPprivacyPolicyText" >
					EMR AXIS has adopted this Privacy Policy to make it
					easy for you to understand how EMR AXIS collects,
					stores, uses and discloses information which identifies or
					may identify you or other individuals (
					<b>“Personal Information”</b>) and which you provide to
					EMR AXIS. Please read our Terms of Service and this
					Privacy Policy carefully prior to using this Site,
					registering to use or using the Service, and/or submitting
					Personal Information through this Site or the Service. If
					you do not agree with all of the terms of our Terms of
					Service and our Privacy Policy, you are not authorized to
					use this Site, register to use or use the Service, and you
					must stop using this Site and the Service.
				</p>
				<br />
				<br />
				<p id="PrivacyPolicyPpurposesofApplicableEuropeanUnionText">
					<h3 id="PrivacyPolicyH3impInformationText">
						<b>Important Information</b>
					</h3>
					<br />
					For the purposes of applicable European Union (“EU”) data
					protection legislation, the data controller of your Personal
					Information is EMR AXIS, LLC, 9720 Coit Rd, Ste 220-314, Plano, TX 75025, United States.
				</p>
				<br />
				<br />
				<p id="PrivacyPolicyPprivacyPolicycoversPersonalInformationText">
					<h3 id="PrivacyPolicyH3scope">
						<b>Scope</b>
					</h3>
					<br />
					This Privacy Policy covers Personal Information provided
					through this Site and/or the Service. This Privacy Policy
					does not apply to Personal Information submitted to third
					parties even if you link to such third parties through this
					Site. By providing Personal Information through this Site
					and/or the Service, you acknowledge and agree that the
					handling of that Personal Information is governed by this
					Privacy Policy. We will only process your Personal
					Information in accordance with applicable data protection
					and privacy laws and this Privacy Policy.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2CustomerData">
					<b>CUSTOMER DATA</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPconfidentialNatureCustomerdata">
					We respect the confidential nature of the customer data you
					provide to us when using theService (“Customer Data”).
					EMR AXIS will not review, share, distribute, or
					reference any Customer Data, except as provided in this
					Privacy Policy, our Terms of Service, or as may be your
					Customer Data to provide you with the Service, as well as to
					prevent or address service or technical problems. We may
					also access your Customer Data to provide you with customer
					support.
					<br />
					For the purposes of applicable EU data protection
					legislation, you are the data controller of your Customer
					Data and EMR AXIS is the data processor.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2personalInformation">
					<b>PERSONAL INFORMATION</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPyouCanVisitSiteText">
					You can visit this Site without telling us who you are and
					without revealing any of your Personal Information. However,
					there are times when we may need certain Personal
					Information from you, such as when you register to use a
					Service. When you provide Personal Information to us, we use
					your Personal Information to provide you with access to the
					requested Service, content and/or information. We may also
					use your Personal Information to help us understand who is
					visiting this Site and using our Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h3 id="PrivacyPolicyH3yourPersonalInformationText" className="text-start">
					<b>We may also use your Personal Information to:</b>
				</h3>
				<p>
					<ul className="list-none text-start">
						<li id="PrivacyPolicyLiprovideTechnicalSupport" className="my-2">
							Provide technical administration and customer
							support.
						</li>
						<li id="PrivacyPolicyLirespondInquiries" className="my-2">Respond to your inquiries.</li>
						<li id="PrivacyPolicyLisendnotices" className="my-2">
							Send important notices, such as communications about
							purchases and changes to our terms, conditions, and
							policies.
						</li>
						<li id="PrivacyPolicyLiprocessPayment" className="my-2">
							Process payment for purchases you make.
						</li>
						<li id="PrivacyPolicyLideliverProducts" className="my-2">
							Deliver products and services you purchase or
							request.
						</li>
						<li id="PrivacyPolicyLimakeEasier" className="my-2">
							Make it easier for you to log back into this Site
							and the Service.
						</li>
						<li id="PrivacyPolicyLicontactForNewServices" className="my-2">
							Contact you about our specials and new services or
							specials and new services from our affiliated
							companies or other third parties, unless you ask us
							not to contact you about these offers. For details
							on how to opt out of receiving information about
							specials and new services, please contact
							EMR AXIS at privacy@EMR AXIS
						</li>
						<li id="PrivacyPolicyLimanageServices" className="my-2">
							Manage your use of the Service.
						</li>
						<li id="PrivacyPolicyLieligibilityPrizes" className="my-2">
							Verify your eligibility and deliver prizes in
							connection with contests and sweepstakes.
						</li>
						<li id="PrivacyPolicyLienforceServices" className="my-2">Enforce our Terms of Service.</li>
						<li id="PrivacyPolicyLiprotextTransactions" className="my-2">
							Protect against or identify fraudulent transactions.
						</li>
						<li id="PrivacyPolicyLidisclosurePersonalInformation" className="my-2">
							As described in the “Disclosure of Your Personal
							Information” section of this Privacy Policy
						</li>
					</ul>
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2aggregatedText">
					<b>AGGREGATED INFORMATION</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPanalyzeInformation">
					We collect, analyze and use information (“Aggregated
					Information”) to help us provide you with a better user
					experience. For example, we keep track of the domains from
					which users visit this Site and we also measure visitor
					activity on this Site. You hereby acknowledge and agree that
					EMR AXIS may make Aggregated Information publicly
					available, provided that such information does not
					incorporate any Customer Data and/or identify you.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h3 id="PrivacyPolicyH3aggregatedExample" className="text-start">
					<b>By way of example, we use Aggregated Information to:</b>
				</h3>
				<p>
					<ul className="list-none text-start">
						<li id="PrivacyPolicyLianalyzeAudience" className="my-2">
							Analyze our audience size and usage patterns.
						</li>
						<li id="PrivacyPolicyLidevelopDeliver" className="my-2">
							Develop, deliver, and improve our services.
						</li>
						<li id="PrivacyPolicyLidevelopDisplay" className="my-2">
							Develop and display content and advertising tailored
							to your interests.
						</li>
						<li className="my-2">Manage our business.</li>
					</ul>
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2discloseInformaton">
					<b>Disclosure of Your Personal Information</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPsellingCustomerData">
					EMR AXIS is not in the business of sharing or selling your
					Customer Data or Personal Information. We consider this
					information to be a vital part of our relationship with you.
				</p>
				<br />
				<p id="PrivacyPolicyPthirdPartyService">
					<b  className="text-start">Credit Card Processing.</b> We use
					a third-party service provider to manage credit card
					processing. This service provider is not permitted to store,
					retain, or use billing information except for the sole
					purpose of credit card processing on EMR AXIS’s behalf.
				</p>
				<br />
				<p id="PrivacyPolicyPlegalRequirements">
					<b className="text-start">Legal Requirements.</b>{" "}
					EMR AXIS may disclose your Personal Information if
					required to do so by law or in the good faith belief that
					such action is necessary to (i) comply with a legal
					obligation; (ii) enforce, protect or defend the rights or
					property of EMR AXIS; (iii) act in urgent circumstances
					to protect the personal safety of users of this Site or the
					public; or (iv) protect against legal liability.
					EMR AXIS may also disclose your Personal Information in
					response to lawful requests by public authorities, including
					to meet national security or law enforcement requirements.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2security">
					<b>SECURITY</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPpersonalInformatonText">
					The security of your Personal Information is important to
					us. When you enter Personal Information on our registration
					pages or order forms, we encrypt that information using
					secure socket layer technology (SSL). Your data are
					encrypted both at rest and in transit. Your Personal
					Information is then kept in a secure database and reasonable
					precautions are taken to secure this data and information.
					However, no Internet or e-mail transmission is ever fully
					secure or error-free, and you should take special care in
					deciding what information you send through this Site and/or
					the Service. Also, administrative agencies or courts of
					competent jurisdiction may order the disclosure of the
					information we collect. We cannot guarantee, and you should
					not expect, the information you provide to us will always
					remain private. Please keep this in mind when disclosing any
					Personal Information.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2newsletter">
					<b>NEWSLETTER</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPsubscribeNewsletter">
					If you wish to subscribe to our newsletter, we will use your
					name and e-mail address to send the newsletter to you. You
					may opt out of any future contact from EMR AXIS at any
					time. You may do so by contacting EMR AXIS via the
					‘Unsubscribe’ link contained in the newsletter email, or by
					contacting e-mail address in the ‘Customer Support’ section
					below.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2cookies">
					<b>COOKIES</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPcookiesText">
					A cookie is a small text file that is stored on a computer
					for record-keeping purposes. We use cookies on this Site. We
					do not add the information we store in cookies to Personal
					Information you submit through this Site. We use both
					session ID cookies and persistent cookies.
				</p>
				<br />
				<p id="PrivacyPolicyPcookiesTypes">
					EMR AXIS uses the following types of cookies for the
					purposes set out below:
				</p>
				<br />
				<div className="flex flex-col gap-8">
					<span className="grid grid-cols-[30%_70%] xxs:gap-5 sm:gap-6 md:gap-10">
						<h2 id="PrivacyPolicyH2typeOfCookies" className="text-start">
							<b>Type of Cookie</b>
						</h2>
						<h2 id="PrivacyPolicyH2purpose">
							<b>Purpose</b>
						</h2>
					</span>
					<span className="grid grid-cols-[30%_70%] xxs:gap-5 sm:gap-6 md:gap-10">
						<span id="PrivacyPolicySpanessentialCookies" className="text-start hyphens-auto">
							Essential Cookies
						</span>
						<span id="PrivacyPolicySpancookiesService">
							These cookies are essential to provide you with
							services available through our Site and to enable
							you to use some of its features. Without these
							cookies, the services that you have asked for cannot
							be provided, and we only use these cookies to
							provide you with those services.
						</span>
					</span>
					<span className="grid grid-cols-[30%_70%] xxs:gap-5 sm:gap-6 md:gap-10">
						<span id="PrivacyPolicySpanfunctionalityCookies" className="text-start hyphens-auto">
							Functionality Cookies
						</span>
						<span id="PrivacyPolicySpancookiesChoices">
							These cookies allow our Site to remember choices you
							make when you use our Site. For example,
							EMR AXIS uses persistent cookies to store a
							unique cryptographic hash value so you do not have
							to enter your username more than once on a given
							computer. The purpose of these cookies is to provide
							you with a more personal experience and to avoid you
							have to re- enter your preferences every time you
							visit our Site.
						</span>
					</span>
					<span className="grid grid-cols-[30%_70%] xxs:gap-5 sm:gap-6 md:gap-10">
						<span id="PrivacyPolicySpanperformanceCookies" className="text-start hyphens-auto">
							Analytic and Performance Cookies
						</span>
						<span id="PrivacyPolicySpancolloectInformation">
							These cookies are used to collect information about
							traffic to our Site and how users use our Site. As
							described in the ‘Aggregated Information’ section of
							this Privacy Policy, we use this information to help
							operate our Site more efficiently, to gather broad
							demographic information and to monitor the level of
							activity on our Site.
						</span>
					</span>
				</div>
			</section>
			<br />
			<br />
			<section>
				<h3 id="PrivacyPolicyH3disableCookies">
					<b>Disabling cookies</b>
				</h3>
				<p id="PrivacyPolicyPcookiesWebBrowser">
					You may disable cookies on your web browser, however, this
					may cause certain features on this Site and/or the Service
					to be disabled.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2iPAddress">
					<b>IP ADDRESSES</b>
				</h2>
				<p id="PrivacyPolicyPinternetProtocol">
					When you visit this Site, we collect your Internet Protocol
					(“IP”) addresses to track and aggregate non-personal
					information. For example, we use IP addresses to monitor the
					regions from which visitors and users navigate this Site. We
					also collect IP addresses from users when they log into the
					EMR AXIS platform as part of our security features. For
					the purposes of applicable EU data protection legislation,
					IP addresses may be considered Personal Information.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2webBeacons" className="text-start">
					<b>WEB BEACONS / USE ANALYTICS</b>
				</h2>
				<p id="PrivacyPolicyPadvertisingPartners">
					EMR AXIS employs, or our third party advertising
					partners employ, web beacons and usage analytics software
					that help us better manage content on this Site by informing
					us what content is effective. Web beacons are tiny graphics
					with a unique identifier, similar in function to cookies,
					and are used to track the online movements of web users. In
					contrast to cookies, which are stored on a computer hard
					drive, web beacons are embedded invisibly on web pages and
					are about the size of the period at the end of this
					sentence. We do not tie the information gathered by web
					beacons to your Personal Information. However, for the
					purposes of applicable EU data protection legislation, IP
					addresses may be considered Personal Information.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2  id="PrivacyPolicyH2siteInvitations" className="text-start">
					<b>SITE INVITATIONS</b>
				</h2>
				<p id="PrivacyPolicyPinviteOthers">
					If you choose to invite others within your organization or
					from your client base to this Site, we will ask you for the
					other user’s name and email address. We will automatically
					send him/her a one-time email inviting him/her to visit this
					Site. We store this information for the sole purpose of
					sending this one-time email and tracking the success of our
					referral program. Invited users may contact us at
					info@emraxis.com to request that we remove this
					information from our database.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2customerTestimonials" className="text-start">
					<b>CUSTOMER TESTIMONIALS</b>
				</h2>
				<p id="PrivacyPolicyPcustomerTestimonials">
					EMR AXIS may post customer testimonials on this Site,
					which may contain Personal Information. However, we obtain
					the customer’s consent prior to posting their testimonial.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2compliance" className="text-start">
					<b >CAN-SPAM Act compliance</b>
				</h2>
				<p id="PrivacyPolicyPcommittedcompliant">
					We are committed to being compliant with the “Controlling
					the Assault of Non-Solicited Pornography and Marketing Act
					of 2003” (“CAN-SPAM Act“). All e-mails you receive from
					EMR AXIS are intended to fully comply with the CAN-SPAM
					Act.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2regardingChildren" className="text-start">
					<b>Policy Regarding Children</b>
				</h2>
				<p id="PrivacyPolicyPnotforChildrenUse">
					This Site is not intended for use by children and we do not
					knowingly solicit or collect information from children. We
					do not verify the age of any of our visitors or users. By
					using this Site and/or the Service, you are representing
					that you are at least 13.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2sensitiveInformation" className="text-start">
					<b>SENSITIVE PERSONAL INFORMATION</b>
				</h2>
				<p id="PrivacyPolicyPsubjectParagraph">
					Subject to the following paragraph, we ask that you not send
					us, and you not disclose, any sensitive Personal Information
					(e.g., social security numbers, information related to
					racial or ethnic origin, political opinions, religion or
					other beliefs, health, sex life/orientation, biometrics or
					genetic characteristics, criminal background or trade union
					membership) on or through the Site and/or Service or
					otherwise to us. If you send or disclose any sensitive
					Personal Information to us when you submit content to our
					Site and/or Service, you consent to our processing and use
					of such sensitive Personal Information in accordance with
					this Policy. If you do not consent to our processing and use
					of such sensitive Personal Information, you must not submit
					such content to our Site and/or Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2yourRights">
					<b>YOUR RIGHTS</b>
				</h2>
				<p id="PrivacyPolicyPcertainCircumstances">
					In certain circumstances, you may have the following rights
					in respect of your Personal Information:
				</p>
				<ul className="list-disc ml-6">
					<li id="PrivacyPolicyLiOpt-out">
						Opt-out. You may contact us anytime to opt-out of:{" "}
						<ul>
							<li id="PrivacyPolicyLidirectMaking">(i) direct marketing communications;</li>
							<li id="PrivacyPolicyLiprocessPersonalInformation">
								(ii) any new processing of your Personal
								Information that we may carry out beyond the
								original purpose;
							</li>
							<li id="PrivacyPolicyLitransferPersonalInformation">
								or (iii) the transfer of your Personal
								Information outside the EEA. Please note that
								your use of some of the Site may be ineffective
								upon opt-out.
							</li>
						</ul>
					</li>
					<li id="PrivacyPolicyLiaccess">
						Access. You may access the information we hold about you
						at any time [via your profile/account] or by contacting
						us directly.
					</li>
					<li id="PrivacyPolicyLiamend">
						Amend and restrict processing. You can also contact us
						to update or correct any inaccuracies in your Personal
						Information or restrict its processing.
					</li>
					<li id="PrivacyPolicyLimove">
						Move. Your Personal Information is portable – i.e. you
						to have the flexibility to move your Personal
						Information to other service providers as you wish.
					</li>
					<li id="PrivacyPolicyLierase">
						Erase and forget. In certain situations, for example
						when the information we hold about you is no longer
						relevant or is incorrect, you can request that we erase
						your data.
					</li>
				</ul>
				<br />
				<br />
				<p id="PrivacyPolicyPexerciseRights">
					If you wish to exercise any of these rights, please contact
					us using the details in the ‘Customer Support’ section
					below. In your request, please make clear: (i) what Personal
					Information is concerned; and (ii) which of the above rights
					you would like to enforce. For your protection, we may only
					implement requests with respect to the Personal Information
					associated with the particular email address that you use to
					send us your request, and we may need to verify your
					identity before implementing your request. We will try to
					comply with your request as soon as reasonably practicable
					and in any event, within one month of your request. Please
					note that we may need to retain certain information for
					recordkeeping purposes and/or to complete any transactions
					that you began prior to requesting such change or deletion.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2unitedstates" className="text-start">
					<b>
						SENDING, TRANSFERRING AND STORING DATA OUTSIDE THE
						UNITED STATES
					</b>
				</h2>
				<p id="PrivacyPolicyPvisit">
					If you are visiting or using this Site and/or the Service
					from outside the United States, please be aware that your
					Personal Information may be transferred to, stored,
					processed, used and/or disclosed in the United States where
					our servers are located and our central database is
					operated. The data protection and other laws of the United
					States may not be as comprehensive as those in your country.
				</p>
			</section>
			<br />
			<section>
				<h2 id="PrivacyPolicyH2changePolicy" className="text-start">
					<b>Changes to our Privacy Policy</b>
				</h2>
				<p id="PrivacyPolicyPamendPolicy">
					We may amend this Privacy Policy at any time and in our sole
					discretion. You should review this Privacy Policy for
					amendments each time you visit or use this Site or purchase
					or use the Service. For your convenience, we post on this
					Site the last date this Privacy Policy was updated. If our
					Privacy Policy is amended, the amended Privacy Policy will
					take effect immediately for all users of this Site and the
					Service. Your continued use of this Site and/or the Service
					following an amendment will evidence your acceptance of the
					amended Privacy Policy.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2notification" className="text-start">
					<b>
						Special Notification for California Residents – Your
						Privacy Rights
					</b>
				</h2>
				<p id="PrivacyPolicyPcaliforniaAddress">
					Individuals who are residents of California and have
					provided their Personal Information to us may request
					information regarding our disclosures, if any, of their
					Personal Information to third parties for direct marketing
					purposes. Such requests must be submitted to our Privacy
					Officer in writing at info@emraxis.com or:
					<br />
					<br />
					EMR AXIS, LLC
					<br />
					9720 Coit Rd,
					<br />
					Ste 220-314,
					<br />
					Plano, TX 75025,
					<br />
					United States.
					<br />
					Email: info@emraxis.com
					<br />
					<br />
					Such requests must include the reference “Request for
					California Privacy Information” in the subject line and in
					the body of the message and must include the e-mail address
					or mailing address, as applicable, for us to send our
					response. This request may be made no more than once per
					calendar year. We reserve the right not to respond to
					requests submitted to us if not submitted pursuant to the
					terms set forth above.
				</p>
			</section>
			<br />
			<br />
			<section id="PrivacyPolicySectionunResolve">
				<h2 id="PrivacyPolicyH2complaints">
					<b>COMPLAINTS</b>
				</h2>
				<p id="PrivacyPolicyPresolveComplaints">
					We are committed to resolve any complaints about our
					collection or use of your Personal Information. If you would
					like to make a complaint regarding this Policy or our
					practices in relation to your Personal Information, please
					contact us using the contact details in the ‘Customer
					Support’ section below. We will reply to your complaint as
					soon as we can and, in any event, within 45 days. We hope to
					resolve any complaint brought to our attention. However, if
					you feel that your complaint has not been adequately
					resolved, you reserve the right to contact your local data
					protection supervisory authority.
				</p>
				<br />
				Unresolved complaints regarding
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2generateInformation">
					<b>GENERAL INFORMATION</b>
				</h2>
				<p id="PrivacyPolicyPcapitalizedText">
					Capitalized terms not defined in our Privacy Policy will
					have the meaning ascribed to such term in our Terms of
					Service. The Terms of Service are hereby incorporated into
					and made a part of this Privacy Policy.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="PrivacyPolicyH2customerSupport">
					<b>CUSTOMER SUPPORT</b>
				</h2>
				<br />
				<p id="PrivacyPolicyPcontactUs">
					If you have questions, comments or concerns about our
					Privacy Policy, please contact EMR AXIS at:
					<br />
					EMR AXIS, LLC
					<br />
					9720 Coit Rd,
					<br />
					Ste 220-314,
					<br />
					Plano, TX 75025,
					<br />
					United States
					<br />
					Email: info@emraxis.com
					<br />
				</p>
			</section>
			<br />
			<br />
		</div>
 );
};

export default PrivacyPolicy;
